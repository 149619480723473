import React from "react";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";

const carrierConfigurationsService = new CarrierConfigurationsService();

const Delete = ({ id, unmountCallback }) => {
  const deleteCarrierConfiguration = () => {
    carrierConfigurationsService
      .delete(id)
      .then((response) => {
        if (unmountCallback) {
          unmountCallback(true);
        }
        toast.success("Configurazione cancellata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>
            Sei sicuro di voler eliminare la seguente configurazione?
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback(false);
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={deleteCarrierConfiguration}
        >
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
