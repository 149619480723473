import React, { useState, useRef, useEffect } from "react";
import { Input, InputGroup } from "reactstrap";
import debounce from "lodash.debounce";
import Select, { components } from "react-select";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
// import "flatpickr/dist/l10n/it";
import moment from "moment";
import AsyncSelect from "../AsyncSelect/AsyncSelect";

const TableFilter = ({ hasFilter, columns, onSearch, tableRef }) => {
  const flatpickrRef = useRef(null);

  const debouncedSearch = useRef(
    debounce(async (name, value) => {
      onSearch(name, value);
    }, 300)
  ).current;

  const resetDates = () => {
    const today = moment().toDate();
    const thirtyDaysAgo = moment().subtract(1, "months").toDate();
    onSearch(flatpickrRef.current.props.name, [thirtyDaysAgo, today]);
    flatpickrRef.current.flatpickr.setDate([thirtyDaysAgo, today]);
  };

  const onChangeDefaultDate = (period) => {
    const now = moment();
    const yesterday = moment().subtract(1, "days");
    const startOfCurrentMonth = moment().startOf("month");
    const startOfCurrentQuarter = moment().startOf("quarter");
    const thirtyDaysAgo = moment().subtract(1, "months");

    let periodArray = [];
    switch (period) {
      case "today":
        periodArray = [now.startOf("day").toDate(), now.endOf("day").toDate()];
        break;
      case "yesterday":
        periodArray = [
          yesterday.startOf("day").toDate(),
          yesterday.endOf("day").toDate(),
        ];
        break;
      case "current-month":
        periodArray = [
          startOfCurrentMonth.startOf("day").toDate(),
          now.toDate(),
        ];
        break;
      case "current-quarter":
        periodArray = [
          startOfCurrentQuarter.startOf("day").toDate(),
          now.toDate(),
        ];
        break;
      case "last-month":
        periodArray = [thirtyDaysAgo.toDate(), now.toDate()];
        break;
    }

    onSearch(flatpickrRef.current.props.name, periodArray);
    flatpickrRef.current.flatpickr.setDate(periodArray);
  };

  const onReady = (selectedDates, dateStr, instance) => {
    const calendarInstance =
      instance.calendarContainer.children[1].querySelector(
        ".flatpickr-rContainer"
      );

    const today = document.createElement("button");
    today.className = "btn btn-primary btn-sm m-1";
    today.textContent = "Oggi";
    today.addEventListener("click", () => onChangeDefaultDate("today"));
    calendarInstance.appendChild(today);

    const yesterday = document.createElement("button");
    yesterday.className = "btn btn-primary btn-sm m-1";
    yesterday.textContent = "Ieri";
    yesterday.addEventListener("click", () => onChangeDefaultDate("yesterday"));
    calendarInstance.appendChild(yesterday);

    const currentMonth = document.createElement("button");
    currentMonth.className = "btn btn-primary btn-sm m-1";
    currentMonth.textContent = "Mese corrente";
    currentMonth.addEventListener("click", () =>
      onChangeDefaultDate("current-month")
    );
    calendarInstance.appendChild(currentMonth);

    const currentQuarter = document.createElement("button");
    currentQuarter.className = "btn btn-primary btn-sm m-1";
    currentQuarter.textContent = "Quarter corrente";
    currentQuarter.addEventListener("click", () =>
      onChangeDefaultDate("current-quarter")
    );
    calendarInstance.appendChild(currentQuarter);

    const lastMonth = document.createElement("button");
    lastMonth.className = "btn btn-primary btn-sm m-1";
    lastMonth.textContent = "Ultimi 30 giorni";
    lastMonth.addEventListener("click", () =>
      onChangeDefaultDate("last-month")
    );
    calendarInstance.appendChild(lastMonth);
  };

  const handleScroll = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close();
    }
  };

  useEffect(() => {
    if (flatpickrRef.current) {
      const instance = flatpickrRef.current.flatpickr;

      if (instance) {
        const inputElement = instance.input;
        inputElement.classList.add("form-control");

        instance.setDate([moment().subtract(1, "months").toDate(), new Date()]);
      }
    }
  }, [flatpickrRef]);

  useEffect(() => {
    if (tableRef.current) {
      const instance = tableRef.current;

      if (instance) {
        // Aggiungi l'event listener per lo scroll
        instance.addEventListener("scroll", handleScroll);
        return () => {
          // Rimuovi l'event listener quando il componente viene smontato
          instance.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [tableRef]);

  return (
    <>
      <div className="row">
        {columns.map((column) => {
          return column?.filter?.active ? (
            <div
              className={`col-${column?.filter?.col || 3} mb-2`}
              key={column?.accessor}
            >
              <label className="form-label">{column.Header}</label>
              {column?.filter?.type === "search" && (
                <Input
                  type="text"
                  className="css-b62m3t-container"
                  onChange={(e) =>
                    debouncedSearch(
                      column.filter.name,
                      e.target.value,
                      column.filter.preciseSearch
                    )
                  }
                />
              )}
              {column?.filter?.type === "select" && (
                <Select
                  onChange={(value) =>
                    onSearch(column.filter.name, value, true)
                  }
                  defaultMenuIsOpen={false}
                  isMulti={column?.filter?.multi}
                  className="select"
                  options={column?.filter?.options}
                  isClearable={true}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  components={
                    column?.filter?.optionStyle && {
                      Option: (props) => (
                        <components.Option {...props}>
                          {column?.filter?.optionStyle(props.data)}
                        </components.Option>
                      ),
                    }
                  }
                />
              )}
              {column?.filter?.type === "async-select" && (
                <AsyncSelect
                  name={column.filter.name}
                  optionStyle={column.filter.optionStyle}
                  onSearch={onSearch}
                  loadOptions={column.filter.loadOptions}
                  isMulti={column.filter.multi}
                  searchKey={column.filter.searchKey}
                />
              )}
              {column?.filter?.type === "datepicker" && (
                <InputGroup size="md">
                  <Flatpickr
                    ref={flatpickrRef}
                    name={column.filter.name}
                    options={{
                      mode: "range",
                      dateFormat: "d-m-Y",
                      locale: "it",
                      onReady: onReady,
                      onClose: (selectedDates) => {
                        if (selectedDates.length < 2) resetDates();
                      },
                      static: true,
                    }}
                    onChange={(selectedDates) => {
                      if (selectedDates.length === 2) {
                        onSearch(column.filter.name, selectedDates, true);
                      }
                    }}
                  />
                </InputGroup>
              )}
            </div>
          ) : null;
        })}
      </div>
    </>
  );
};

export default TableFilter;
