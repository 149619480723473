import React from "react";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";

const usersService = new UsersService();

const EnabledDisabled = ({ enabled, userId, refreshTable }) => {
  const enabledDisabled = () => {
    usersService
      .enabledDisabled(userId, !enabled)
      .then((response) => {
        refreshTable();
        if (enabled) {
          toast.success("Utente disabilitato correttamente", {
            position: toast.POSITION.TOP_CENTER,
          });
          return false;
        }
        toast.success("Utente abilitato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 404:
            message = "404 - Utente non trovato";
            break;
          case 409:
            message = "409 - Utente già registrato";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <div className="form-check form-switch mb-2">
      <input
        title={enabled ? "Disattiva" : "Attiva"}
        readOnly
        onClick={enabledDisabled}
        checked={enabled}
        type="radio"
        className="form-check-input form-check-input-info cursor-pointer"
      />
    </div>
  );
};
export default EnabledDisabled;
