import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../assets/scss/components/headerNotAuth.scss";
import Logo from "../../assets/images/logo-white.svg";
import LogoIcon from "../../assets/images/logo-icon-1.svg";

const HeaderTopNotAuth = (props) => {
  return (
    <React.Fragment>
      <div
        className="header"
        style={{
          backgroundColor: "#191919",
        }}
      >
        <div className="after" style={{ backgroundColor: "#e1e506" }}></div>
        <Link to="/" className="d-inline-flex align-items-center">
          <img
            className="d-lg-none ms-3"
            src={LogoIcon}
            alt="logo allabox"
            style={{ height: "40px" }}
          />
          <img
            className="d-none d-lg-block ms-3"
            src={Logo}
            alt="logo"
            style={{ height: "40px" }}
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderTopNotAuth;
