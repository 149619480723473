import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faDownload,
  faEye,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import moment from "moment";

import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";
import { Collapse } from "reactstrap";

const CODCollectionShipments = ({ parentId, action }) => {
  const listRef = useRef(null);
  const listProps = useListProps();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={parentId}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>
    </React.Fragment>
  );
};

export default CODCollectionShipments;

const ListItem = React.memo(({ item, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const appContext = useAppContext();
  const language = appContext.language;

  return (
    <React.Fragment key={index}>
      <div className="list-item px-2">
        <div
          className=" d-grid align-items-center"
          style={{
            gridAutoFlow: "column",
            gridTemplateColumns: `1.5fr 1.5fr 2.5fr 2fr 1.5fr 1.5fr 1fr 1.5fr 1fr`,
            gap: "0.5rem",
          }}
        >
          <div>
            <label className="label-2">
              {dictionary["creation_date"][language]}:
            </label>
            <label className="label-3">
              {moment(item.shipment?.creationDate).format("DD/MM/YYYY HH:mm")}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["internal_reference"][language]}:
            </label>
            <TooltipCopy
              target={"internalReference_" + index}
              textToCopy={item.shipment?.internalReference || ""}
            >
              <label
                className={`label-3 ${
                  item.shipment?.internalReference ? "cursor-pointer" : ""
                }`}
              >
                {item.shipment?.internalReference || "-"}
              </label>
            </TooltipCopy>
          </div>
          <div>
            <label className="label-2">
              {dictionary["carrier_reference"][language]}:
            </label>
            <TooltipCopy
              target={"carrierReference_" + index}
              textToCopy={item.shipment?.carrierReference || ""}
            >
              <label
                className={`label-3 ${
                  item.shipment?.carrierReference ? "cursor-pointer" : ""
                }`}
              >
                {item.shipment?.carrierReference || "-"}
              </label>
            </TooltipCopy>
          </div>
          <div>
            <label className="label-2">
              {dictionary["external_reference"][language]}:
            </label>
            <TooltipCopy
              target={"externalReference_" + index}
              textToCopy={item.shipment?.externalReference || ""}
            >
              <label
                className={`label-3 ${
                  item.shipment?.externalReference ? "cursor-pointer" : ""
                }`}
              >
                {item.shipment?.externalReference || "-"}
              </label>
            </TooltipCopy>
          </div>

          <div>
            <label className="label-2">
              {dictionary["expected_amount"][language]}:
            </label>
            <label className="label-3">
              {item.expectedAmount
                ? `${item.expectedAmount.toFixed(2)} €`
                : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["collected_amount"][language]}:
            </label>
            <label className="label-3">
              {item.collectedAmount
                ? `${item.collectedAmount.toFixed(2)} €`
                : "-"}
            </label>
          </div>

          <div>
            <label className="label-2">{dictionary["delta"][language]}:</label>
            <label
              className={`label-3 ${
                item.collectedAmount - item.expectedAmount >= 0
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {item.collectedAmount && item.expectedAmount
                ? `${(item.collectedAmount - item.expectedAmount).toFixed(2)} €`
                : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["collection_date"][language]}:
            </label>
            <label className="label-3">
              {item.collectionDate
                ? moment(item.collectionDate).format("DD/MM/YYYY")
                : "-"}
            </label>
          </div>

          <div className="text-end">
            <Link
              className="button-1 button-1-light button-1-icon me-2"
              to={`/shipments/details/${item.shipment?.id}`}
              title={dictionary["details"][language]}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
            {isOpen ? (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleUp}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary["reduce"][language]}
              />
            ) : (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleDown}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary["expand"][language]}
              />
            )}
          </div>
        </div>

        <Collapse
          isOpen={isOpen}
          className="accordion-collapse mt-2"
          aria-controls="true"
        >
          <div
            className=" d-grid align-items-center"
            style={{
              gridAutoFlow: "column",
              gridTemplateColumns: `1fr 1fr 0.5fr`,
              gap: "0.5rem",
            }}
          >
            <div>
              <label className="label-2">
                {dictionary["sender"][language]}:
              </label>
              <label className={`label-3`}>
                {item.shipment?.senderName || "-"}
              </label>
            </div>
            <div>
              <label className="label-2">
                {dictionary["receiver"][language]}:
              </label>
              <label className={`label-3`}>
                {item.shipment?.deliveryName || "-"}
              </label>
            </div>

            <div>
              <label className="label-2">{dictionary["note"][language]}:</label>
              <label className={`label-3`}>{item.shipment?.note || "-"}</label>
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
});
