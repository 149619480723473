import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useOidcUser } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";

import { contractServiceRuleTypesList } from "../../constants";
import { orderBykey } from "../../utilities";
import { ContractService } from "../../services/contract-service/contract";
import { ContractServicesService } from "../../services/contract-service/contractServices";
import { ServiceRuleGroupsService } from "../../services/contract-service/serviceRuleGroups";
import { ServiceRulesService } from "../../services/contract-service/serviceRules";
import { SurchargesService } from "../../services/contract-service/surcharges";
import { CarriersService } from "../../services/registry-service/carriers";
import ParcelTypesService from "../../services/shipment-service/parcelTypes";
import { ZonesService } from "../../services/registry-service/zones";
import RuleGroupList from "./ruleGroupList";
import SurchargeList from "./surchargeList";

const contractsService = new ContractService();
const contractServicesService = new ContractServicesService();
const carriersService = new CarriersService();
const parcelTypesService = new ParcelTypesService();
const serviceRuleGroupsService = new ServiceRuleGroupsService();
const serviceRulesService = new ServiceRulesService();
const surchargesService = new SurchargesService();
const zonesService = new ZonesService();

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateEditContractService = () => {
  const { id } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const serviceFormRef = useRef();

  const [contract, setContract] = useState({});
  const [parcelTypes, setParcelTypes] = useState([]);
  const [carrierServices, setCarrierServices] = useState([]);
  const [ruleGroups, setRuleGroups] = useState(id ? null : []);
  const [optionsAndSurcharges, setOptionsAndSurcharges] = useState([]);
  const [isClone, setIsClone] = useState(
    useLocation().pathname.includes("clone")
  );
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  const getAllParcelTypes = () => {
    parcelTypesService
      .getAll()
      .then((response) => {
        setParcelTypes(orderBykey(response, "name"));
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero delle tipologie colli",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getContract = (contractId) => {
    contractsService
      .getContract(contractId)
      .then((response) => {
        validation.setFieldValue("contractId", response.id);
        setContract(response);
        getCarrierServices(response);
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero del contratto",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getCarrierServices = (contract) => {
    const filters = {
      page: 0,
      size: 100,
      sort: {
        property: "name",
        mode: "asc",
      },
      search: {
        shipmentType: contract.shipmentType,
        "carrier.code": contract.carrierCode,
      },
    };
    carriersService
      .getCarrierServices(filters)
      .then((response) => {
        setCarrierServices(response);
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero dei servizi associati al vettore",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getService = () => {
    contractServicesService
      .getContractService(id)
      .then((response) => {
        validation.setValues(response);
        getContract(response.contractId);
        getServiceRuleGroups();
        getOptionsAndSurcharges(response.id);
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero del servizio",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getOptionsAndSurcharges = (contractServiceId) => {
    const filters = {
      size: 100,
      sort: {
        property: "name",
        mode: "asc",
      },
      search: {
        "contractService.id": contractServiceId,
      },
    };
    surchargesService
      .getAll(filters)
      .then((response) => {
        if (response.content) {
          const newOptionsAndSurcharges = response.content.map((elem) => {
            return { ...elem, uuid: uuidv4() };
          });

          setOptionsAndSurcharges(newOptionsAndSurcharges);
        }
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero delle opzioni e supplmenti",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getServiceRuleGroups = () => {
    //Recupero gruppi
    serviceRuleGroupsService
      .getAll({
        size: 100,
        search: `contractService.id==${id}`,
        sort: "rank,asc",
      })
      .then((response) => {
        const ruleGroups = response.content || [];
        if (ruleGroups.length > 0) {
          ruleGroups.forEach((elem, RGIndex) => {
            //Assegno uuid al ruleGroup
            elem.uuid = uuidv4();
            //Recupero zona di partenza (zoneIdFrom)
            zonesService
              .getZone(elem.zoneIdFrom)
              .then((response) => {
                elem.zoneIdFrom = response;
                //Recupero zona di arrivo (zoneIdTo)
                zonesService
                  .getZone(elem.zoneIdTo)
                  .then((response) => {
                    elem.zoneIdTo = response;
                    //Recupero regole
                    serviceRulesService
                      .getAll({
                        size: 100,
                        search: `serviceRuleGroup.id==${elem.id}`,
                        sort: "weightFrom,asc",
                      })
                      .then((response) => {
                        let rules = response.content
                          ? [...response.content]
                          : [];
                        //Per ogni regola viene settato un uuid e il corretto obejct ruleType
                        rules = rules.map((rule, index) => {
                          const ruleType = contractServiceRuleTypesList.find(
                            (elem) => elem.code === rule.ruleType
                          );
                          return { ...rule, uuid: uuidv4(), ruleType };
                        });
                        elem.rules = rules || [];
                        if (ruleGroups.length === RGIndex + 1) {
                          setRuleGroups(ruleGroups);
                        }
                      })
                      .catch(() => {
                        toast.error(
                          "Si sono verificati dei problemi nel recupero delle regole",
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                      });
                  })
                  .catch(() => {
                    toast.error(
                      "Si sono verificati dei problemi nel recupero della zona di arrivo",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  });
              })
              .catch(() => {
                toast.error(
                  "Si sono verificati dei problemi nel recupero della zona di partenza",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              });
          });
          return false;
        }
        setRuleGroups(ruleGroups);
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero delle matrici tariffe",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const createContractService = () => {
    contractServicesService
      .createContractService(validation.values)
      .then((response) => {
        const newContractService = { ...validation.values, id: response.id };

        const promiseDeleteOptionsAndSurchages = new Promise(
          (resolveParent, rejectParent) => {
            contractServicesService
              .deleteOptionsAndSurchages(newContractService.id)
              .then((response) => {
                if (optionsAndSurcharges.length > 0) {
                  createOptionsAndSurcharges(
                    newContractService,
                    resolveParent,
                    rejectParent
                  );
                  return false;
                }
                resolveParent();
              })
              .catch((exception) => {
                toast.error(
                  "Si sono verificati dei problemi nella cancellazione di opzioni e supplementi",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              });
          }
        );

        const promiseDeleteServiceRuleGroups = new Promise(
          (resolveParent, rejectParent) => {
            contractServicesService
              .deleteServiceRuleGroups(newContractService.id)
              .then(() => {
                if (ruleGroups.length > 0) {
                  createServiceRuleGroups(
                    newContractService,
                    resolveParent,
                    rejectParent
                  );
                  return false;
                }
                resolveParent();
              })
              .catch((exception) => {
                toast.error(
                  "Si sono verificati dei problemi nella cancellazione delle matrici",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              });
          }
        );

        const allPromises = Promise.all([
          promiseDeleteServiceRuleGroups,
          promiseDeleteOptionsAndSurchages,
        ]);

        allPromises
          .then((response) => {
            navigate(`/contract-services?contractId=${contract.id}`);
          })
          .catch((err) => {
            console.log("Problemi durante il salvataggio", err);
          });
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel salvataggio della tariffa",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const editContractService = () => {
    contractServicesService
      .editContractService(validation.values)
      .then((response) => {
        const promiseDeleteOptionsAndSurchages = new Promise(
          (resolveParent, rejectParent) => {
            contractServicesService
              .deleteOptionsAndSurchages(validation.values.id)
              .then((response) => {
                if (optionsAndSurcharges.length > 0) {
                  createOptionsAndSurcharges(
                    { ...validation.values },
                    resolveParent,
                    rejectParent
                  );
                  return false;
                }
                resolveParent();
              })
              .catch((exception) => {
                toast.error(
                  "Si sono verificati dei problemi nella cancellazione di opzioni e supplementi",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              });
          }
        );

        const promiseDeleteServiceRuleGroups = new Promise(
          (resolveParent, rejectParent) => {
            contractServicesService
              .deleteServiceRuleGroups(validation.values.id)
              .then(() => {
                if (ruleGroups.length > 0) {
                  createServiceRuleGroups(
                    { ...validation.values },
                    resolveParent,
                    rejectParent
                  );
                  return false;
                }
                resolveParent();
              })
              .catch((exception) => {
                toast.error(
                  "Si sono verificati dei problemi nella cancellazione delle matrici",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              });
          }
        );

        const allPromises = Promise.all([
          promiseDeleteServiceRuleGroups,
          promiseDeleteOptionsAndSurchages,
        ]);

        allPromises
          .then((response) => {
            navigate(`/contract-services?contractId=${contract.id}`);
          })
          .catch((err) => {
            console.log("Problemi durante il salvataggio", err);
          });
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel salvataggio del servizio",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const createOptionsAndSurcharges = (
    contractService,
    resolveParent,
    rejectParent
  ) => {
    const optionsAndSurchargesPromises = [];
    optionsAndSurcharges.forEach((elem, index) => {
      let newElem = { ...elem };
      newElem.contractServiceId = contractService.id;

      optionsAndSurchargesPromises.push(
        new Promise((resolve, reject) => {
          surchargesService
            .create(newElem)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        })
      );

      const allPromises = Promise.all(optionsAndSurchargesPromises);
      allPromises
        .then((response) => {
          resolveParent();
        })
        .catch((err) => {
          toast.error(
            "Si sono verificati dei problemi nel salvataggio di una o più opzioni",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          rejectParent(err);
        });
    });
  };

  const createServiceRuleGroups = (
    contractService,
    resolveParent,
    rejectParent
  ) => {
    let newRuleGroups = [...ruleGroups];
    newRuleGroups.forEach((currentRuleGroup, RGIndex) => {
      const serviceRuleGroupNormalized = serviceRuleGroupNormalizer(
        currentRuleGroup.rank,
        currentRuleGroup,
        contractService
      );

      serviceRuleGroupsService
        .createServiceRuleGroup(serviceRuleGroupNormalized)
        .then((response) => {
          const ruleGroupId = response.id;
          currentRuleGroup.id = ruleGroupId;

          if (currentRuleGroup.rules.length > 0) {
            const fixedWeightPromises = [];
            currentRuleGroup.rules.forEach((currentRule, RIndex) => {
              if (currentRule.ruleType.code === "FIXED_WEIGHT") {
                const serviceRuleNormalized = serviceRuleNormalizer(
                  currentRule,
                  ruleGroupId
                );

                fixedWeightPromises.push(
                  new Promise((resolve, reject) => {
                    serviceRulesService
                      .createServiceRule(serviceRuleNormalized)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  })
                );
              }
            });

            const allPromises = Promise.all(fixedWeightPromises);
            allPromises
              .then((response) => {
                const progressiveWeightRules = currentRuleGroup.rules.filter(
                  (rule) => rule.ruleType.code === "PROGRESSIVE_WEIGHT"
                );

                if (progressiveWeightRules.length > 0) {
                  createProgressiveWeightRule(
                    progressiveWeightRules,
                    progressiveWeightRules[0],
                    0,
                    ruleGroupId,
                    RGIndex,
                    resolveParent,
                    rejectParent
                  );
                  return false;
                }
                resolveParent();
              })
              .catch((err) => {
                toast.error(
                  "Si sono verificati dei problemi nel salvataggio di una o più regole fisse per peso. Impossibile proseguire il salvataggio di quelle progressive per peso",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
                rejectParent(err);
              });
            return false;
          }
          resolveParent();
        })
        .catch((exception) => {
          toast.error(
            "Si sono verificati dei problemi nel salvataggio di un gruppo",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    });
  };

  const createProgressiveWeightRule = (
    array,
    element,
    counter,
    parentId,
    parentIndex,
    resolveParent,
    rejectParent
  ) => {
    const serviceRuleNormalized = serviceRuleNormalizer(element, parentId);
    serviceRulesService
      .createServiceRule(serviceRuleNormalized)
      .then((response) => {
        counter++;
        if (counter !== array.length) {
          createProgressiveWeightRule(
            array,
            array[counter],
            counter,
            parentId,
            parentIndex,
            resolveParent,
            rejectParent
          );
          return false;
        }
        if (ruleGroups.length - 1 === parentIndex) {
          resolveParent();
        }
      })
      .catch((err) => {
        rejectParent(err);
      });
  };

  const serviceRuleGroupNormalizer = (
    rank,
    serviceRuleGroup,
    contractService
  ) => {
    return {
      rank,
      contractServiceId: contractService.id,
      zoneIdFrom: serviceRuleGroup.zoneIdFrom.id,
      zoneIdTo: serviceRuleGroup.zoneIdTo.id,
    };
  };

  const serviceRuleNormalizer = (serviceRule, serviceRuleGroupId) => {
    return {
      ...serviceRule,
      serviceRuleGroupId,
      ruleType: serviceRule.ruleType.code,
    };
  };

  const validation = useFormik({
    initialValues: {
      carrierServiceCode: "",
      parcelTypeCode: "",
      name: "",
      externalReference: "",
      description: "",
      weightVolumeRatio: "",
      deliveryHours: "",
      limitParcelHeightFrom: "",
      limitParcelHeightTo: "",
      limitParcelLengthFrom: "",
      limitParcelLengthTo: "",
      limitParcelVolumeFrom: "",
      limitParcelVolumeTo: "",
      limitParcelWeightFrom: "",
      limitParcelWeightTo: "",
      limitParcelWidthFrom: "",
      limitParcelWidthTo: "",
      limitShpParcelNumberFrom: "",
      limitShpParcelNumberTo: "",
      limitShpVolumeFrom: "",
      limitShpVolumeTo: "",
      limitShpWeightFrom: "",
      limitShpWeightTo: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Campo obbligatorio"),
      description: Yup.string(),
      contractId: Yup.string().required("Campo obbligatorio"),
      weightVolumeRatio: Yup.number(),
      externalReference: Yup.string(),
      carrierServiceCode: Yup.string().required("Campo obbligatorio"),
      parcelTypeCode: Yup.string().required("Campo obbligatorio"),
    }),
    onSubmit: (values) => {
      if (values.id) {
        if (isClone) {
          //Clone
          delete values.id;
          createContractService();
          return false;
        }
        //Edit
        editContractService();
        return false;
      }
      //Create
      delete values.id;
      createContractService();
    },
  });

  const changeRuleGroups = (ruleGroups) => {
    setRuleGroups(ruleGroups);
  };

  const changeOptionsAndSurcharges = (optionsAndSurcharges) => {
    setOptionsAndSurcharges(optionsAndSurcharges);
  };

  const toggleTab = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  useEffect(() => {
    //Se esiste contractId siamo in creazione
    if (query.get("contractId")) {
      getContract(query.get("contractId"));
      //Altrimenti modifica o Duplica
    } else {
      getService();
    }
    getAllParcelTypes();
  }, []);

  let optionsCounter = 0;
  let surchargeCounter = 0;
  optionsAndSurcharges.forEach((elem) => {
    if (elem.surcharge.onDemand) {
      optionsCounter++;
      return false;
    }
    surchargeCounter++;
  });
  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Servizi"
          breadcrumbItem={
            id
              ? isClone
                ? "Duplica servizio"
                : "Modifica servizio"
              : "Nuova servizio"
          }
          link={`/contract-services?contractId=${contract.id || ""}`}
        />
      )}
      <div className="content" id="container">
        <Form onSubmit={validation.handleSubmit} ref={serviceFormRef}>
          <div className="row">
            <div className="col">
              <Card className="h-100">
                <CardBody>
                  <div className="row">
                    <div className="col">
                      <Label className=" col-form-label">Servizio</Label>
                      <Select
                        onChange={(value) => {
                          validation.setFieldValue(
                            "carrierServiceCode",
                            value.code
                          );
                          validation.setFieldValue("name", value.name);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.code}
                        defaultMenuIsOpen={false}
                        className="select"
                        options={orderBykey(carrierServices, "name")}
                        value={carrierServices?.find(
                          (elem) =>
                            elem.code === validation.values.carrierServiceCode
                        )}
                      />
                      <Input
                        type="hidden"
                        name="carrierServiceCode"
                        value={validation.values.carrierServiceCode || ""}
                        invalid={
                          validation.touched.carrierServiceCode &&
                          validation.errors.carrierServiceCode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.carrierServiceCode &&
                      validation.errors.carrierServiceCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.carrierServiceCode}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col">
                      <Label className="col-form-label ">Nome</Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Nome del servizio"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <Label className="col-form-label ">Descrizione</Label>
                      <Input
                        type="text"
                        name="description"
                        placeholder="Descrizione del servizio"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col">
                      <Label className=" col-form-label">Tipologia collo</Label>
                      <Select
                        onChange={(value) => {
                          validation.setFieldValue(
                            "parcelTypeCode",
                            value.code
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.code}
                        defaultMenuIsOpen={false}
                        className="select"
                        options={orderBykey(parcelTypes, "name")}
                        value={parcelTypes?.find(
                          (elem) =>
                            elem.code === validation.values.parcelTypeCode
                        )}
                      />
                      <Input
                        type="hidden"
                        name="parcelTypeCode"
                        value={validation.values.parcelTypeCode || ""}
                        invalid={
                          validation.touched.parcelTypeCode &&
                          validation.errors.parcelTypeCode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.parcelTypeCode &&
                      validation.errors.parcelTypeCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.parcelTypeCode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <Label className="col-form-label ">
                        Riferimento esterno
                      </Label>
                      <Input
                        type="text"
                        name="externalReference"
                        placeholder="EXTREF"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.externalReference || ""}
                        invalid={
                          validation.touched.externalReference &&
                          validation.errors.externalReference
                            ? true
                            : false
                        }
                      />
                      {validation.touched.externalReference &&
                      validation.errors.externalReference ? (
                        <FormFeedback type="invalid">
                          {validation.errors.externalReference}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col">
                      <Label className="col-form-label ">
                        Rapporto peso/volume
                      </Label>
                      <Input
                        type="number"
                        name="weightVolumeRatio"
                        placeholder="1"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={`${validation.values.weightVolumeRatio}` || ""}
                        invalid={
                          validation.touched.weightVolumeRatio &&
                          validation.errors.weightVolumeRatio
                            ? true
                            : false
                        }
                      />
                      {validation.touched.weightVolumeRatio &&
                      validation.errors.weightVolumeRatio ? (
                        <FormFeedback type="invalid">
                          {validation.errors.weightVolumeRatio}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col">
                      <Label className="col-form-label ">
                        Tempo di consegna
                      </Label>
                      <Input
                        type="number"
                        name="deliveryHours"
                        placeholder="24"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={`${validation.values.deliveryHours}` || ""}
                        invalid={
                          validation.touched.deliveryHours &&
                          validation.errors.deliveryHours
                            ? true
                            : false
                        }
                      />
                      {validation.touched.deliveryHours &&
                      validation.errors.deliveryHours ? (
                        <FormFeedback type="invalid">
                          {validation.errors.deliveryHours}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-6">
              <Card className="h-100">
                <CardBody>
                  <Nav
                    tabs
                    className="nav nav-tabs nav-tabs-underline border-bottom-0"
                  >
                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        Massimali spedizione
                      </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        Massimali colli
                      </NavLink>
                    </NavItem>

                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        Opzioni e supplementi
                        {}
                        <span className="badge bg-yellow text-dark rounded-pill ms-1">
                          {optionsCounter}
                        </span>{" "}
                        <span className="badge bg-purple rounded-pill ms-1">
                          {surchargeCounter}
                        </span>{" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <div className="row mt-3">
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">
                              Quantità colli
                            </Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpParcelNumberFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitShpParcelNumberFrom ||
                                  ""
                                }
                                invalid={
                                  validation.touched.limitShpParcelNumberFrom &&
                                  validation.errors.limitShpParcelNumberFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpParcelNumberFrom &&
                              validation.errors.limitShpParcelNumberFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpParcelNumberFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpParcelNumberTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitShpParcelNumberTo || ""
                                }
                                invalid={
                                  validation.touched.limitShpParcelNumberTo &&
                                  validation.errors.limitShpParcelNumberTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpParcelNumberTo &&
                              validation.errors.limitShpParcelNumberTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpParcelNumberTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">Peso (kg)</Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpWeightFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitShpWeightFrom || ""
                                }
                                invalid={
                                  validation.touched.limitShpWeightFrom &&
                                  validation.errors.limitShpWeightFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpWeightFrom &&
                              validation.errors.limitShpWeightFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpWeightFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpWeightTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.limitShpWeightTo || ""}
                                invalid={
                                  validation.touched.limitShpWeightTo &&
                                  validation.errors.limitShpWeightTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpWeightTo &&
                              validation.errors.limitShpWeightTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpWeightTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">Volume (m3)</Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpVolumeFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitShpVolumeFrom || ""
                                }
                                invalid={
                                  validation.touched.limitShpVolumeFrom &&
                                  validation.errors.limitShpVolumeFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpVolumeFrom &&
                              validation.errors.limitShpVolumeFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpVolumeFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitShpVolumeTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.limitShpVolumeTo || ""}
                                invalid={
                                  validation.touched.limitShpVolumeTo &&
                                  validation.errors.limitShpVolumeTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitShpVolumeTo &&
                              validation.errors.limitShpVolumeTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitShpVolumeTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row mt-3">
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">
                              Larghezza (cm)
                            </Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelWidthFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelWidthFrom || ""
                                }
                                invalid={
                                  validation.touched.limitParcelWidthFrom &&
                                  validation.errors.limitParcelWidthFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelWidthFrom &&
                              validation.errors.limitParcelWidthFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelWidthFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelWidthTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelWidthTo || ""
                                }
                                invalid={
                                  validation.touched.limitParcelWidthTo &&
                                  validation.errors.limitParcelWidthTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelWidthTo &&
                              validation.errors.limitParcelWidthTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelWidthTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">
                              Profondità (cm)
                            </Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelLengthFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelLengthFrom || ""
                                }
                                invalid={
                                  validation.touched.limitParcelLengthFrom &&
                                  validation.errors.limitParcelLengthFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelLengthFrom &&
                              validation.errors.limitParcelLengthFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelLengthFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelLengthTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelLengthTo || ""
                                }
                                invalid={
                                  validation.touched.limitParcelLengthTo &&
                                  validation.errors.limitParcelLengthTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelLengthTo &&
                              validation.errors.limitParcelLengthTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelLengthTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">Altezza (cm)</Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelHeightFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelHeightFrom || ""
                                }
                                invalid={
                                  validation.touched.limitParcelHeightFrom &&
                                  validation.errors.limitParcelHeightFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelHeightFrom &&
                              validation.errors.limitParcelHeightFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelHeightFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelHeightTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelHeightTo || ""
                                }
                                invalid={
                                  validation.touched.limitParcelHeightTo &&
                                  validation.errors.limitParcelHeightTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelHeightTo &&
                              validation.errors.limitParcelHeightTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelHeightTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">Peso (kg)</Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelWeightFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelWeightFrom || ""
                                }
                                invalid={
                                  validation.touched.limitParcelWeightFrom &&
                                  validation.errors.limitParcelWeightFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelWeightFrom &&
                              validation.errors.limitParcelWeightFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelWeightFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelWeightTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelWeightTo || ""
                                }
                                invalid={
                                  validation.touched.limitParcelWeightTo &&
                                  validation.errors.limitParcelWeightTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelWeightTo &&
                              validation.errors.limitParcelWeightTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelWeightTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Label className="text-center">Volume (m3)</Label>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelVolumeFrom"
                                placeholder="min"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelVolumeFrom || ""
                                }
                                invalid={
                                  validation.touched.limitParcelVolumeFrom &&
                                  validation.errors.limitParcelVolumeFrom
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelVolumeFrom &&
                              validation.errors.limitParcelVolumeFrom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelVolumeFrom}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <Input
                                type="number"
                                name="limitParcelVolumeTo"
                                placeholder="max"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.limitParcelVolumeTo || ""
                                }
                                invalid={
                                  validation.touched.limitParcelVolumeTo &&
                                  validation.errors.limitParcelVolumeTo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.limitParcelVolumeTo &&
                              validation.errors.limitParcelVolumeTo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.limitParcelVolumeTo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <SurchargeList
                        carrierCode={contract?.carrierCode}
                        list={optionsAndSurcharges}
                        changeOptionsAndSurcharges={changeOptionsAndSurcharges}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <RuleGroupList
                list={ruleGroups}
                changeRuleGroups={changeRuleGroups}
              />
            </div>
          </div>

          <div className="row">
            <div className="col text-start">
              <button
                type="button"
                className="btn btn-light btn-sm"
                onClick={() => {
                  navigate(`/contract-services?contractId=${contract.id}`);
                }}
              >
                <i className="ph-arrow-left ph-sm me-2"></i>Torna all'elenco
              </button>
            </div>
            <div className="col text-end">
              <Button
                type="submit"
                color="success"
                className="btn btn-primary ms-3"
              >
                Salva
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateEditContractService;
