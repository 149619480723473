import axios from "axios";
// import AuthService from "../services/authentication";

export default class HttpClient {
  _checkStatus(response, isLogin) {
    if (!response) {
      throw {
        error: "NO RESPONSE FROM BACKEND",
      };
    }

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status === 401) {
      // let auth = new AuthService();
      // auth.logout();

      if (isLogin) {
        response.data.error = "AUTENTICAZIONE FALLITA";
      }
      throw response.data;
    } else {
      throw response.data;
    }
  }

  fetch(url, options) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // let auth = new AuthService();
    // if (auth.isLoggedIn()) {
    // }

    // headers["Authorization"] =
    //   "Bearer eyJraWQiOiI3YWEyZWE3Yi03MDkzLTQ1OTAtYmY0YS00ZTdjYjk2MTk5OTEiLCJhbGciOiJSUzI1NiJ9.eyJvd25lciI6IjlmMzA2NWExLTIzMGEtNGE1MC1hNTRkLTE0NzllYTNmOTQ1MCIsInN1YiI6ImFkbWluQGFsbGFib3guaXQiLCJhdWQiOiJzd2FnZ2VyIiwibmJmIjoxNjkwMzE1NTQ1LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIl0sImlzcyI6Imh0dHBzOi8vYWxsYWJveC1hdXRoLmVsaXBzZS5pdCIsImV4cCI6MTY5MDMxNTg0NSwiaWF0IjoxNjkwMzE1NTQ1fQ.VOObJQc3IPrfvELSWlwIufvrB8pYV1CpKvskBRL6CPtlXZoOnLj3viJtgNUQqwXd1-af70VMiZStzrjSd71YqHmuf7t_MKBKiEugLC-TPw7ze3JqzWCpa9X-YYODuweZVpR0g_yInqjxjdGeoKjQ6XI0XJOeac8AaRFIOB8hjFatN_AT_Vaizvpb3E-N2N0KVNrZIAJ6FbIC1-I8KSIDyZ_ZBJZUOGG0f1zVd-dbWWY3TaD3xAkFMaNtnnNpxCQFfYHbphRBTggI6Zg9V5KrQv2KoQvUPGIX_o31-BQWCnzXf-pliGAxNjK6r_VvaNe2Ek6bWyc0HFWBySOGKP-SoA";

    switch (options.method) {
      case "GET":
        return axios
          .get(url, { headers: { ...headers } })
          .then((response) => this._checkStatus(response))
          .catch((error) => {
            this._checkStatus(error.response);
          });
      case "POST":
        return axios
          .post(url, options.body, { headers: { ...headers } })
          .then((response) => this._checkStatus(response, options.isLogin))
          .catch((error) => {
            this._checkStatus(error.response, options.isLogin);
          });
      case "PUT":
        return axios
          .put(url, options.body, { headers: { ...headers } })
          .then((response) => this._checkStatus(response))
          .catch((error) => {
            this._checkStatus(error.response);
          });
      case "DELETE":
        return axios
          .delete(url, { headers: { ...headers } })
          .then((response) => this._checkStatus(response))
          .catch((error) => {
            this._checkStatus(error.response);
          });
      case "HEAD":
        return axios
          .head(url, { headers: { ...headers } })
          .then((response) => this._checkStatus(response))
          .catch((error) => {
            this._checkStatus(error.response);
          });
      default:
    }
  }
}

axios.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `token ${token}`;
    }
    return config;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);
