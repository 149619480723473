import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";

export class LocationsService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getDetail(locationId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/locations/${locationId}`,
      requestOptions
    );
  }

  create(location) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(location),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/locations`,
      requestOptions
    );
  }

  edit(location) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(location),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/locations/${location.id}`,
      requestOptions
    );
  }
}
