import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ShipmentsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}`,
      requestOptions
    );
  }

  confirm(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/confirm`,
      requestOptions
    );
  }

  exportCSV(search = "", freeSearch = "") {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/shipments/export/csv?search=${search}&freeSearch=${freeSearch}`,
      requestOptions,
      { Accept: "text/csv" }
    );
  }
}
