import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { ConfirmOrderContext } from "./providers/Confirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Confirm = ({
  cashOnDeliveryValue,
  cashOnDeliveryCollectionMethodCode,
  currency,
}) => {
  const { orders, ordersError, ordersLoader, confirmOrders, unmountCallback } =
    useContext(ConfirmOrderContext);

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {orders?.length > 1
              ? "Sei sicuro di voler confermare gli ordini selezionati?"
              : cashOnDeliveryCollectionMethodCode && cashOnDeliveryValue
              ? `Sei sicuro di voler confermare il seguente ordine con contrassegno di ${cashOnDeliveryValue} ${currency} e metodo di incasso ${cashOnDeliveryCollectionMethodCode}?`
              : "Sei sicuro di voler confermare il seguente ordine?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn ms-3"
          onClick={confirmOrders}
          disabled={ordersLoader}
        >
          {ordersLoader ? <FontAwesomeIcon icon={faLoader} spin /> : "Conferma"}
        </Button>
      </div>
    </form>
  );
};

export default Confirm;
