import HttpInterface from "../../helpers/HttpInterface";
import { API_BASE_URL } from "../../constants/index";
import { buildUrl, createQueryString } from "../../utilities/index";

export class CashOnDeliveryCollectionMethodsService {
  // Initializing important variables
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(
        `${API_BASE_URL}/registry-service/cash-on-delivery-collection-methods`,
        params
      ),
      requestOptions
    );
  }

  get(code) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/cash-on-delivery-collection-methods/${code}`,
      requestOptions
    );
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url =
      API_BASE_URL + `/registry-service/cash-on-delivery-collection-methods?`;
    url = createQueryString(url, filters);

    return this.httpProxy.fetch(url, requestOptions);
  }

  getCashOndeLiveryCollectionMethod(code) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/cash-on-delivery-collection-methods/${code}`,
      requestOptions
    );
  }
}
