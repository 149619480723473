import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import {
  DeleteOrderDetailContext,
  useDeleteOrderDetailContext,
} from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Delete = () => {
  const context = useDeleteOrderDetailContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {context.orderDetails?.length > 1
              ? "Sei sicuro di voler eliminare i dettagli ordine selezionati?"
              : "Sei sicuro di voler eliminare il seguente dettaglio ordine?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={context.deleteOrderDetails}
          disabled={context.orderDetailsLoader}
        >
          {context.orderDetailsLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Elimina"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Delete;
