import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { CollectionsService } from "../../services/shipment-service/collections";
import { Loader } from "../../Components/common/Loader";
import Moment from "react-moment";
import { writeLabelAddress } from "../../utilities";

import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import { convertFromJson } from "fiql-query-builder";
import useFetch from "../../customHooks/useFetch";
import { getUniqueSelectors } from "../../utilities";
import { getStatus, getStatusProps } from "./utilities";
import { Link } from "react-router-dom";

const collectionsService = new CollectionsService();

const Details = ({ id }) => {
  const [collection, setCollection] = useState({});
  const [shipmentsInCollection, setShipmentsInCollection] = useState([]);
  const [loader, setLoader] = useState(true);

  // const [pagination, setPagination] = useState({
  //   page: 0,
  //   size: 5,
  //   sort: "desc",
  // });
  // const [queryParams, setQueryParams] = useState([
  //   {
  //     equals: {
  //       selector: "shipmentId",
  //       args: id,
  //     },
  //   },
  // ]);
  // const [querySearch, setQuerySearch] = useState();

  const getCollection = () => {
    collectionsService
      .getCollection(id)
      .then((response) => {
        if (response) {
          setCollection(response);
          getShipmentsInCollection();
        }
      })
      .catch((exception) => {
        setLoader(false);
        toast.error(`Recupero ritiro fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getShipmentsInCollection = () => {
    collectionsService
      .getShipmentsInCollection(id)
      .then((response) => {
        if (response) {
          setShipmentsInCollection(response);
          setLoader(false);
        }
      })
      .catch((exception) => {
        setLoader(false);
        toast.error(`Recupero spedizioni associato al ritiro fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // const columns = [
  //   {
  //     Header: "Rif. interno",
  //     accessor: "internalReference",
  //   },
  //   {
  //     Header: "Larghezza (cm)",
  //     accessor: "width",
  //   },
  //   {
  //     Header: "Profondità (cm)",
  //     accessor: "length",
  //   },
  //   {
  //     Header: "Altezza (cm)",
  //     accessor: "height",
  //   },
  //   {
  //     Header: "Volume (m3)",
  //     accessor: "volume",
  //     Cell: ({ row }) => {
  //       return row.original.volume;
  //     },
  //   },
  //   {
  //     Header: "Peso (kg)",
  //     accessor: "weight",
  //     Cell: ({ row }) => {
  //       return row.original.weight.toFixed(2);
  //     },
  //   },
  // ];

  // const handlePagination = (paginationOpt) => {
  //   setPagination({ ...pagination, ...paginationOpt });
  // };

  // const onSearch = (selector, value, preciseSearch = false) => {
  //   setQueryParams((queryParams) => {
  //     return getUniqueSelectors(selector, value, preciseSearch, queryParams);
  //   });
  //   handlePagination({ page: 0 });
  // };

  // const buildQuerySearch = (query = []) => {
  //   return encodeURIComponent(convertFromJson({ and: [...query] }));
  // };

  // const { data, loading, error, refreshData } = useFetch(
  //   `/shipment-service/collections/${collection.id}/shipments?page=${
  //     pagination.page
  //   }&size=${pagination.size}&sort=internalReference,${pagination.sort}${
  //     queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
  //   }`
  // );

  // useEffect(() => {
  //   setQuerySearch(buildQuerySearch(queryParams));
  // }, [queryParams, querySearch, pagination]);

  useEffect(() => {
    getCollection();
    getShipmentsInCollection();
  }, []);

  return (
    <React.Fragment>
      {loader ? <Loader show={loader} /> : ""}

      <div className="row align-items-center">
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Cliente</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.owner?.name || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Rif. interno</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.internalReference}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Rif. esterno</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.externalReference || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Rif. vettore</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.carrierReference || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          {collection.status && (
            <div
              style={{ backgroundColor: collection.status?.color }}
              className={`status-container float-end`}
            >
              <span className={`status-name`}>{collection.status?.name}</span>
            </div>
          )}
        </div>
      </div>
      <h5 className="mt-3">Fasce orarie</h5>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Apertura mat.:</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.morningOpenTime || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Chiusura mat.:</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.morningCloseTime || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Apertura pom.:</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.afternoonOpenTime || "-"}</label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Apertura mat.:</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{collection.afternoonCloseTime || "-"}</label>
            </div>
          </div>
        </div>
      </div>
      <h5 className="mt-3">Luogo del ritiro</h5>
      <div className="row">
        <div className="col-4">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Giorno di ritiro</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>
                <Moment date={collection.date} format="DD/MM/YYYY" />
              </label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label className="fw-semibold">Punto di presa</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>
                {writeLabelAddress(collection.addressBook?.location)}
              </label>
            </div>
          </div>
        </div>
      </div>
      {shipmentsInCollection.length > 0 && (
        <React.Fragment>
          <h5 className="mt-3">Spedizioni incluse nel ritiro</h5>
          <div className="row">
            {shipmentsInCollection.map((elem) => {
              return (
                <Link target="_blank" to={`/shipments/details/${elem.id}`}>
                  {elem.internalReference}
                </Link>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Details;
