import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";

export class ZonesService {
  // Initializing important variables
  constructor() {
    this.httpClient = new HttpClient();
  }

  getZones(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;
    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;
    let url = `${API_BASE_URL}/registry-service/zones?page=${currentPage}&size=${rows}`;

    if (search) {
      url = url + "&search=" + search;
    }
    if (freeSearch) {
      url = url + "&freeSearch=" + freeSearch;
    }
    if (sort) {
      url = url + "&sort=" + sort + ",ignorecase";
    }

    return this.httpClient.fetch(url, requestOptions);
  }

  getZone(zoneId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}`,
      requestOptions
    );
  }
  createZone(zone) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(zone),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones`,
      requestOptions
    );
  }

  editZone(zoneId, zone) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(zone),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}`,
      requestOptions
    );
  }

  deleteZone(zoneId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}`,
      requestOptions
    );
  }

  createZoneLocation(zoneId, location) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(location),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}/locations`,
      requestOptions
    );
  }

  deleteZoneLocation(zoneId, locationId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/registry-service/zones/${zoneId}/locations/${locationId}`,
      requestOptions
    );
  }

  getZoneLocations(zoneId) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}/locations`,
      requestOptions
    );
  }
}
