import React, { createContext, useEffect, useState } from "react";

import { ArticlesService } from "../../../services/logistic/articles";

export const DeleteArticleContext = createContext();

const articlesService = new ArticlesService();

export const DeleteArticleProvider = ({ children, data, unmountCallback }) => {
  const [articles, setArticles] = useState(data);
  const [articlesError, setArticlesError] = useState(null);
  const [articlesLoader, setArticlesLoader] = useState(false);
  const deleteArticles = () => {
    setArticlesLoader(true);
    const promises = articles.map((elem) => {
      return articlesService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setArticlesLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        setArticlesError(err);
      });
  };

  return (
    <DeleteArticleContext.Provider
      value={{
        //#region Article
        articles,
        articlesError,
        articlesLoader,
        deleteArticles,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </DeleteArticleContext.Provider>
  );
};
