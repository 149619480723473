import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities";

export default class ParcelTemplatesService {
  constructor(domain) {
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/shipment-service/parcel-templates?`;
    url = createQueryString(url, filters);
    url += `&page=${0}&size=${20}&sort=${"asc"}`;
    return this.httpClient.fetch(url, requestOptions);
  }

  getParcelTemplate(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcel-templates/${id}`,
      requestOptions
    );
  }

  create(parcelTemplate) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(parcelTemplate),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcel-templates`,
      requestOptions
    );
  }

  edit(parcelTemplate) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(parcelTemplate),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcel-templates/${parcelTemplate.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcel-templates/${id}`,
      requestOptions
    );
  }
}
