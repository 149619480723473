import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import logoAnimation from "../../assets/images/landingPage/logo-icon-animation.json";

export const LoaderAllaboxIcon = ({ message = "loading...", show }) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    if (loaderRef.current) {
      lottie.loadAnimation({
        container: loaderRef.current,
        animationData: logoAnimation,
        renderer: "svg", // Tipo di renderer (SVG, canvas, HTML)
        loop: true, // Riproduzione in loop
        autoplay: true, // Avvio automatico dell'animazione
      });
    }
  }, []);

  return (
    <>
      {show && (
        <div
          className="position-absolute d-flex justify-content-center align-items-center flex-column"
          style={{
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 10,
            background: "#191919",
            height: "100%",
          }}
        >
          <div style={{ width: "20%", height: "auto" }} ref={loaderRef}></div>
          <div className="mt-3 color-white">{message}</div>
        </div>
      )}
    </>
  );
};
