import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class AddressBookService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/registry-service/address-book?`;
    url = createQueryString(url, filters);
    url += `&page=${0}&size=${20}`;
    return this.httpClient.fetch(url, requestOptions);
  }

  getAddress(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/address-book/${id}`,
      requestOptions
    );
  }

  create(addressBook) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(addressBook),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/address-book`,
      requestOptions
    );
  }

  edit(addressBook) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(addressBook),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/address-book/${addressBook.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/address-book/${id}`,
      requestOptions
    );
  }
}
