import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useOidcUser } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

import Save from "./save";
import GeneralInformations from "./generalInformations";
import Parcel, { defaultParcel } from "./parcel";
import {
  calcSingleVolume,
  euCountries,
  orderBykey,
  countries,
} from "../../utilities";
import {
  currenciesList,
  parcelTypesList,
  shipmentTypesList,
} from "../../constants/index";

import ParcelTypesService from "../../services/shipment-service/parcelTypes";
import { AddressBookService as AddressBookRegistryService } from "../../services/registry-service/addressbook";
import { ParcelsService as ParcelsShipmentService } from "../../services/shipment-service/parcels";
import { ShipmentsService as ShipmentsShipmentService } from "../../services/shipment-service/shipments";
import Contact, { defaultContact } from "./contact";
import CustomData from "./customData";
import { needsCustomData } from "./util";
import { CustomDataService } from "../../services/shipment/customData";
import GenericFields from "./genericFields";

const addressBookRegistryService = new AddressBookRegistryService();
const parcelTypesService = new ParcelTypesService();
const shipmentsShipmentService = new ShipmentsShipmentService();
const parcelsShipmentService = new ParcelsShipmentService();
const customDataService = new CustomDataService();
const defaultGeneralInformations = {
  cashOnDeliveryValue: "",
  cashOnDeliveryValueCurrency: { name: "Euro", code: "EUR" },
  insuranceValue: "",
  insuranceValueCurrency: { name: "Euro", code: "EUR" },
  goodsValue: "",
  goodsValueCurrency: { name: "Euro", code: "EUR" },
  shipmentType: { name: "Spedizione espressa", code: "EXPRESS" },
  parcelType: { code: "PKG", name: "Pacco" },
  volume: "",
  weight: "",
  content: "",
  note: "",
  externalReference: "",
};

const CreateEditShipment = () => {
  const { id } = useParams();
  const { oidcUser } = useOidcUser();

  const [parcelTypes, setParcelTypes] = useState([]);

  const [shipment, setShipment] = useState({});

  const [pickup, setPickup] = useState({ ...defaultContact });
  const [loadingOnChangePickup, setLoadingOnChangePickup] = useState(false);
  const [delivery, setDelivery] = useState({ ...defaultContact });
  const [loadingOnChangeDelivery, setLoadingOnChangeDelivery] = useState(false);
  const [customDataVisibility, setCustomDataVisibility] = useState(false);
  const [generalInformations, setGeneralInformations] = useState(
    defaultGeneralInformations
  );
  const [parcels, setParcels] = useState([
    { ...defaultParcel, uuid: uuidv4() },
  ]);

  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [errors, setErrors] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [isClone, setIsClone] = useState(
    useLocation().pathname.includes("clone")
  );
  const [isReturn, setIsReturn] = useState(
    useLocation().pathname.includes("return")
  );

  const editCustomData = (customData) => {
    setCustomData(customData);
  };

  const getShipment = () => {
    shipmentsShipmentService
      .getShipment(id)
      .then((response) => {
        const shipment = { ...response };

        const cashOnDeliveryValueCurrency = currenciesList.find((elem) => {
          if (elem.code === shipment.cashOnDeliveryValueCurrency) {
            return { ...elem };
          }
        });
        const insuranceValueCurrency = currenciesList.find((elem) => {
          if (elem.code === shipment.insuranceValueCurrency) {
            return { ...elem };
          }
        });
        const goodsValueCurrency = currenciesList.find((elem) => {
          if (elem.code === shipment.goodsValueCurrency) {
            return { ...elem };
          }
        });
        let shipmentType = shipmentTypesList.find((elem) => {
          if (elem.code === shipment.shipmentType) {
            return { ...elem };
          }
        });
        const parcelType = parcelTypesList.find((elem) => {
          if (elem.code === shipment.parcelType.code) {
            return { ...elem };
          }
        });

        if (isReturn) {
          const newPickup = shipment.delivery;
          const newDelivery = shipment.pickup;
          shipment.pickup = newPickup;
          shipment.delivery = newDelivery;
          shipmentType = shipmentTypesList.find((elem) => {
            if (elem.code === "EXPRESS_RETURN") {
              return { ...elem };
            }
          });
          delete shipment.cashOnDeliveryValue;
        }

        shipment.pickupId = shipment.pickup.id;
        shipment.deliveryId = shipment.delivery.id;

        setPickup({ ...shipment.pickup, isNew: true });
        setDelivery({ ...shipment.delivery, isNew: true });

        setGeneralInformations({
          cashOnDeliveryValue: shipment.cashOnDeliveryValue || "",
          cashOnDeliveryValueCurrency,
          insuranceValue: shipment.insuranceValue || "",
          insuranceValueCurrency,
          goodsValue: shipment.goodsValue || "",
          goodsValueCurrency,
          parcelType,
          shipmentType,
          content: shipment.content,
          note: shipment.note,
          externalReference: shipment.externalReference,
        });

        setShipment(shipment);

        getParcels();
        getCustomDatas();
      })
      .catch((exception) => {
        toast.error(`Recupero spedizione fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(exception);
      });
  };

  const getCustomDatas = () => {
    const filters = {
      search: [
        {
          selector: "shipmentId",
          value: id,
          preciseSearch: true,
        },
      ],
    };
    customDataService
      .all(filters)
      .then((response) => {
        let customData =
          response.data.content &&
          response.data.content.map((elem) => {
            return {
              ...elem,
              uuid: uuidv4(),
              countryIsoCode:
                countries.find((x) => x.code === elem.countryIsoCode2) || {},
            };
          });

        setCustomData(customData || []);
      })
      .catch((exception) => {
        toast.error(`Recupero Dati doganali fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getParcels = () => {
    const filters = {
      search: {
        shipmentId: id,
      },
    };
    parcelsShipmentService
      .getAll(filters)
      .then((response) => {
        let parcels =
          response.content &&
          response.content.map((elem) => {
            return { ...elem, uuid: uuidv4(), quantity: 1 };
          });

        setParcels(parcels || []);
      })
      .catch((exception) => {
        setShipment(shipment);
        toast.error(`Recupero colli fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getAllPickupAddressBook = () => {
    const filters = {
      search: {
        myAddress: "true",
        ownerId: oidcUser?.owner,
      },
    };
    addressBookRegistryService
      .getAll(filters)
      .then((response) => {
        const pickup =
          response.content && response.content[0]
            ? response.content[0]
            : { ...defaultContact };
        setPickup(pickup);
      })
      .catch((exception) => {
        toast.error(`Recupero indirizzi mittente fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getAllParcelTypes = () => {
    parcelTypesService
      .getAll()
      .then((response) => {
        setParcelTypes(response);
      })
      .catch((exception) => {
        toast.error(`Recupero tipologie colli fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const toggleTab = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const handleSelectChange = (option, event) => {
    const newGeneralInformations = { ...generalInformations };
    newGeneralInformations[event.name] = option;
    setGeneralInformations(newGeneralInformations);
  };

  const checkErrors = (errors) => {
    setErrors([...errors]);
  };

  const editPickup = (pickup) => {
    setPickup(pickup);
    setLoadingOnChangePickup(false);
  };

  const editDelivery = (delivery) => {
    setDelivery(delivery);
    setLoadingOnChangeDelivery(false);
  };

  const addParcel = () => {
    const newParcels = [...parcels, { ...defaultParcel, uuid: uuidv4() }];
    setParcels(newParcels);
  };

  const editParcel = (parcel) => {
    const newParcels = parcels.map((elem, index) => {
      if (elem.uuid === parcel.uuid) {
        return { ...parcel };
      }
      return { ...elem };
    });

    setParcels(newParcels);
  };

  const removeParcel = (parcel) => {
    const newParcels = parcels.filter((elem) => {
      return elem.uuid !== parcel.uuid;
    });
    setParcels(newParcels);
  };

  const calculateMeasures = () => {
    if (parcels) {
      let totalVolume = 0;
      let totalWeight = 0;

      parcels.forEach((elem) => {
        const volume = calcSingleVolume(elem.width, elem.length, elem.height);
        if (volume) totalVolume += parseFloat(volume) * parseInt(elem.quantity);
        if (elem.weight) totalWeight += elem.weight * elem.quantity;
      });

      setShipment((prevShipment) => ({
        ...prevShipment,
        volume: totalVolume,
        weight: totalWeight,
      }));

      setGeneralInformations({
        ...generalInformations,
        volume: totalVolume,
        weight: totalWeight,
      });
    }
  };

  useEffect(() => {
    calculateMeasures();
  }, [parcels]);

  useEffect(() => {
    if (!id && oidcUser) {
      getAllPickupAddressBook();
    }
  }, [oidcUser]);

  useEffect(() => {
    if (id) {
      getShipment();
    }
    getAllParcelTypes();
  }, []);

  useEffect(() => {
    const shipmentUpdated = {
      ...shipment,
      ...{ pickup, delivery, parcelType: generalInformations.parcelType },
    };
    setCustomDataVisibility(needsCustomData(shipmentUpdated));
  }, [pickup, delivery, generalInformations]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Spedizioni"
          breadcrumbItem={
            id
              ? isClone
                ? "Duplica spedizione"
                : "Modifica spedizione"
              : "Nuova spedizione"
          }
          link={`/shipments`}
        />
      )}
      <div className="content" id="container">
        {errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {errors.map((elem, index) => {
              return (
                <div key={index}>
                  <span className="fw-semibold">{elem.section} - </span>
                  <span>{elem.error}</span>
                </div>
              );
            })}
          </div>
        )}

        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                <h5>Mittente</h5>
                {oidcUser && (
                  <Contact
                    data={pickup}
                    edit={editPickup}
                    mode={true} //true = addressBook; false = locationAutocomplete
                    createAddressBookInRegistryEnabled={true}
                    editAddressBookInRegistryEnabled={true}
                    filters={{
                      sort: {
                        property: "name",
                        mode: "asc",
                      },
                      search: {
                        myAddress: "true",
                        ownerId: oidcUser?.owner,
                      },
                    }}
                    loadingOnChange={loadingOnChangePickup}
                  />
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <Card>
              <CardBody>
                <h5>Destinatario</h5>
                {oidcUser && (
                  <Contact
                    data={delivery}
                    edit={editDelivery}
                    mode={id ? true : false} //true = addressBook; false = locationAutocomplete
                    createAddressBookInRegistryEnabled={true}
                    editAddressBookInRegistryEnabled={true}
                    filters={{
                      sort: {
                        property: "name",
                        mode: "asc",
                      },
                      search: { myAddress: false },
                    }}
                    loadingOnChange={loadingOnChangeDelivery}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                <Nav
                  tabs
                  className="nav nav-tabs nav-tabs-underline border-bottom-0"
                >
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1");
                      }}
                    >
                      Informazioni generali
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2");
                      }}
                    >
                      Campi aggiuntivi
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      disabled
                      className={classnames({
                        active: currentActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleTab("3");
                      }}
                    >
                      Allegati
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <GeneralInformations
                      data={generalInformations}
                      edit={setGeneralInformations}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <GenericFields
                      data={generalInformations}
                      edit={setGeneralInformations}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    {/* <div className="row mt-3">
                        <div className="col">
                          <p className="fw-light">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Donec interdum nibh id luctus sollicitudin.
                            Integer consequat purus quis neque tempor mattis. Ut
                            eget nisi diam. Proin ac elementum sapien. Nullam
                            commodo risus quis dolor pharetra, at tincidunt
                            neque venenatis. Nam cursus nulla sed dolor auctor
                            efficitur.
                          </p>
                        </div>
                      </div> */}
                    <div className="row mt-3">
                      <div className="col">
                        <div className="input-group">
                          <input type="file" className="form-control" />
                          <span className="input-group-text">.pdf, .png</span>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </div>
        {customDataVisibility && (
          <CustomData
            list={customData}
            edit={editCustomData}
            errors={errors?.filter((err) => {
              return err.sectionId === "customData";
            })}
          />
        )}
        {/* Colli */}
        <div className="row">
          <div className="col">
            <Card>
              <CardHeader>
                <div className="row align-items-center">
                  <div className="col-1">
                    <h5 className="mb-0">Colli</h5>
                  </div>
                  <div className="col-5">
                    <label className="form-label mb-0">Peso totale:</label>
                    &nbsp;
                    <label className="form-label mb-0 me-4">
                      {generalInformations.weight
                        ? generalInformations.weight.toFixed(3)
                        : "-"}{" "}
                      kg
                    </label>
                    <label className="form-label mb-0">Volume totale:</label>
                    &nbsp;
                    <label className="form-label mb-0">
                      {generalInformations.volume
                        ? `${generalInformations.volume.toFixed(6)}`.replace(
                            /^0+(\d)|(\d)0+$/gm,
                            "$1$2"
                          ) + " m3"
                        : ""}
                    </label>
                  </div>
                  <div className="col">
                    <div className="row justify-content-end">
                      <div className="col">
                        <Select
                          name="parcelType"
                          onChange={handleSelectChange}
                          defaultMenuIsOpen={false}
                          className="select"
                          options={orderBykey(parcelTypes, "name")}
                          value={generalInformations.parcelType}
                          required
                          getOptionValue={(option) => option["code"]}
                          getOptionLabel={(option) => option["name"]}
                        />
                      </div>
                      <div className="col-4">
                        <button
                          color="light"
                          type="button"
                          className="btn btn-outline-success btn-sm w-100"
                          onClick={addParcel}
                        >
                          Aggiungi collo <i className="ph-plus ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {parcels.length > 0 ? (
                  parcels.map((elem, index) => {
                    return (
                      <Parcel
                        key={index}
                        item={elem}
                        remove={removeParcel}
                        edit={editParcel}
                        parcelTypeCode={generalInformations.parcelType.code}
                      />
                    );
                  })
                ) : (
                  <div className="row row-cols-1">
                    <div className="col text-center">
                      <label>Nessun collo</label>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <Save
          data={shipment}
          pickup={pickup}
          delivery={delivery}
          generalInformations={generalInformations}
          parcels={parcels}
          customData={customData}
          editShp={setShipment}
          checkErrors={checkErrors}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateEditShipment;
