import React, { useState, useEffect } from "react";
import Select from "react-select";
import { currenciesList, shipmentTypesList } from "../../constants/index";
import { orderBykey } from "../../utilities";
import InputBox from "../../Components/common/InputBox";

const GeneralInformations = ({ data, edit }) => {
  const [generalInformations, setGeneralInformations] = useState(data);
  const [shipmentTypes, setShipmentTypes] = useState(shipmentTypesList);

  const handleSelectChange = (option, event) => {
    edit(event.name, option);
    const newGeneralInformations = { ...generalInformations };
    newGeneralInformations[event.name] = option;
    edit(newGeneralInformations);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    edit({ ...generalInformations, [name]: value });
  };

  useEffect(() => {
    setGeneralInformations(data);
  }, [data]);

  return (
    <React.Fragment>
      {/* <div className="row mt-3">
        <div className="col">
          <p className="fw-light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            interdum nibh id luctus sollicitudin. Integer consequat purus quis
            neque tempor mattis. Ut eget nisi diam. Proin ac elementum sapien.
            Nullam commodo risus quis dolor pharetra, at tincidunt neque
            venenatis. Nam cursus nulla sed dolor auctor efficitur.
          </p>
        </div>
      </div> */}
      <div className="row mt-3">
        <div className="col-3">
          <div className="row align-items-end">
            <div className="col-7">
              <label className="form-label">Valore contrassegno:</label>
              <InputBox
                name="cashOnDeliveryValue"
                value={generalInformations.cashOnDeliveryValue || ""}
                placeholder={"100"}
                type="positive-number"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-5">
              <Select
                name="cashOnDeliveryValueCurrency"
                onChange={handleSelectChange}
                defaultMenuIsOpen={false}
                className="select"
                options={orderBykey(currenciesList, "name")}
                value={generalInformations.cashOnDeliveryValueCurrency || ""}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row align-items-end">
            <div className="col-7">
              <label className="form-label">Valore assicurazione:</label>
              <InputBox
                name="insuranceValue"
                value={generalInformations.insuranceValue || ""}
                placeholder={"100"}
                type="positive-number"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-5">
              <Select
                name="insuranceValueCurrency"
                onChange={handleSelectChange}
                defaultMenuIsOpen={false}
                className="select"
                options={orderBykey(currenciesList, "name")}
                value={generalInformations.insuranceValueCurrency || ""}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row align-items-end">
            <div className="col-7">
              <label className="form-label">Valore merce:</label>
              <InputBox
                name="goodsValue"
                value={generalInformations.goodsValue || ""}
                placeholder={"100"}
                type="positive-number"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-5">
              <Select
                name="goodsValueCurrency"
                onChange={handleSelectChange}
                defaultMenuIsOpen={false}
                className="select"
                options={orderBykey(currenciesList, "name")}
                value={generalInformations.goodsValueCurrency || ""}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row align-items-end">
            <label className="form-label">Tipologia spedizione:</label>
            <div className="col">
              <Select
                name="shipmentType"
                onChange={handleSelectChange}
                defaultMenuIsOpen={false}
                className="select"
                options={orderBykey(shipmentTypes, "name")}
                value={generalInformations.shipmentType || ""}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralInformations;
