import React, { useState, useEffect } from "react";

const ColumnSort = ({ data, onSortingChange, sortingOpt }) => {
  const [column, setColumn] = useState(data);

  const sortIndex = sortingOpt.findIndex((x) => x.column === column.id);

  useEffect(() => {
    setColumn({ ...data, isSorted: sortingOpt[sortIndex] || false });
  }, []);

  return (
    <i
      className={
        sortingOpt[sortIndex]?.orderBy === "asc"
          ? "cursor-pointer ph ph-sort-descending"
          : sortingOpt[sortIndex]?.orderBy === "desc"
          ? "cursor-pointer ph ph-sort-ascending"
          : "cursor-pointer ph ph-arrows-down-up"
      }
      onClick={() => {
        onSortingChange({
          orderBy: column.isSorted ? "desc" : "asc",
          column: column.id,
        });
        setColumn({ ...column, isSorted: !column.isSorted });
      }}
    ></i>
  );
};

export default ColumnSort;
