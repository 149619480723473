export const useListProps = () => {
  return {
    path: "/shipment-service/shipments",
    pagination: { enabled: false },
    selectRowsEnabled: false,
    actionBar: { enabled: false },
    filters: {
      name: "manifest",
      preserve: true,
      list: [],
    },
  };
};
