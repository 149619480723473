import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik, ErrorMessage } from "formik";
import { Form, Label, Input, FormFeedback, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";

import Contact, { defaultContact } from "../Shipments/contact";
import CompaniesService from "../../services/registry-service/companies";
import { valueIsEmpty } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";

const companiesService = new CompaniesService();

const collectionSettings = ({
  userOwnerId,
  companyId,
  modalToogle,
  refreshTable,
}) => {
  const [contact, setContact] = useState({ ...defaultContact });
  const [useShipmentPickup, setUseShipmentPickup] = useState(true);
  const [errors, setErrors] = useState({});

  const editContact = (contact) => {
    setContact(contact);
  };

  const collectionValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickupId: "",
      morningOpeningTime: "09:00",
      morningCloseTime: "13:00",
      afternoonOpeningTime: "14:00",
      afternoonCloseTime: "18:00",
    },
    validationSchema: Yup.object({
      morningOpeningTime: Yup.string().required("Campo obbligatorio"),
      morningCloseTime: Yup.string().required("Campo obbligatorio"),
      afternoonOpeningTime: Yup.string().required("Campo obbligatorio"),
      afternoonCloseTime: Yup.string().required("Campo obbligatorio"),
    }),
  });

  const contactValidation = () => {
    let errors = {};
    var { name, location } = contact;

    if (valueIsEmpty(name)) errors["name"] = dictionary["nullfield"]["it"];
    if (valueIsEmpty(location.longitude))
      errors["location"] = dictionary["nullfield"]["it"];

    return errors;
  };

  const saveCollection = () => {
    const contactErrors = contactValidation();
    const errors = { ...contactErrors };

    if (valueIsEmpty(errors)) {
      //salvataggio
      //AddressBook se è nuovo va salvato in shipment-service
      return false;
    }

    setErrors(errors);
  };

  return (
    <React.Fragment>
      <Form>
        <div className="row">
          <h5>Fasce orarie</h5>
          <div className="col">
            <label className="form-label">Apertura mat.:</label>
            <Flatpickr
              className="form-control date-buttons"
              placeholder="Seleziona orario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              onChange={(date) => {
                collectionValidation.setFieldValue(
                  "morningOpeningTime",
                  moment(date[0]).format("HH:mm")
                );
              }}
              value={collectionValidation.values.morningOpeningTime}
            />
            <Input
              type="hidden"
              name="morningOpeningTime"
              value={collectionValidation.values.morningOpeningTime || ""}
              onChange={collectionValidation.handleChange}
              invalid={
                collectionValidation.touched.morningOpeningTime &&
                collectionValidation.errors.morningOpeningTime
              }
            />
            {collectionValidation.touched.morningOpeningTime &&
            collectionValidation.errors.morningOpeningTime ? (
              <FormFeedback type="invalid">
                {collectionValidation.errors.morningOpeningTime}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">Chiusura mat.:</label>
            <Flatpickr
              className="form-control date-buttons"
              placeholder="Seleziona orario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              onChange={(date) => {
                collectionValidation.setFieldValue(
                  "morningCloseTime",
                  moment(date[0]).format("HH:mm")
                );
              }}
              value={collectionValidation.values.morningCloseTime}
            />
            <Input
              type="hidden"
              name="morningCloseTime"
              value={collectionValidation.values.morningCloseTime || ""}
              onChange={collectionValidation.handleChange}
              invalid={
                collectionValidation.touched.morningCloseTime &&
                collectionValidation.errors.morningCloseTime
              }
            />
            {collectionValidation.touched.morningCloseTime &&
            collectionValidation.errors.morningCloseTime ? (
              <FormFeedback type="invalid">
                {collectionValidation.errors.morningCloseTime}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">Apertura pom.:</label>
            <Flatpickr
              className="form-control date-buttons"
              placeholder="Seleziona orario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              onChange={(date) => {
                collectionValidation.setFieldValue(
                  "afternoonOpeningTime",
                  moment(date[0]).format("HH:mm")
                );
              }}
              value={collectionValidation.values.afternoonOpeningTime}
            />
            <Input
              type="hidden"
              name="afternoonOpeningTime"
              value={collectionValidation.values.afternoonOpeningTime || ""}
              onChange={collectionValidation.handleChange}
              invalid={
                collectionValidation.touched.afternoonOpeningTime &&
                collectionValidation.errors.afternoonOpeningTime
              }
            />
            {collectionValidation.touched.afternoonOpeningTime &&
            collectionValidation.errors.afternoonOpeningTime ? (
              <FormFeedback type="invalid">
                {collectionValidation.errors.afternoonOpeningTime}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">Chiusura pom.:</label>
            <Flatpickr
              className="form-control date-buttons"
              placeholder="Seleziona orario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              onChange={(date) => {
                collectionValidation.setFieldValue(
                  "afternoonCloseTime",
                  moment(date[0]).format("HH:mm")
                );
              }}
              value={collectionValidation.values.afternoonCloseTime}
            />
            <Input
              type="hidden"
              name="afternoonCloseTime"
              value={collectionValidation.values.afternoonCloseTime || ""}
              onChange={collectionValidation.handleChange}
              invalid={
                collectionValidation.touched.afternoonCloseTime &&
                collectionValidation.errors.afternoonCloseTime
              }
            />
            {collectionValidation.touched.afternoonCloseTime &&
            collectionValidation.errors.afternoonCloseTime ? (
              <FormFeedback type="invalid">
                {collectionValidation.errors.afternoonCloseTime}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row">
              <div className="col">
                <h5>Punto di ritiro</h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label className="form-check mb-3">
                  <input
                    type="checkbox"
                    name="ztl"
                    className="form-check-input form-check-input-success"
                    value={useShipmentPickup}
                    checked={useShipmentPickup}
                    onChange={() => {
                      setUseShipmentPickup(!useShipmentPickup);
                      setContact({ ...defaultContact });
                      setErrors({});
                    }}
                  />
                  <span className="form-check-label">
                    Usa sempre l’indirizzo di presa della spedizione
                  </span>
                </label>
              </div>
            </div>
            {!useShipmentPickup && (
              <div className="row">
                <div className="col">
                  <Contact
                    data={contact}
                    edit={editContact}
                    filters={{
                      sort: {
                        property: "name",
                        mode: "asc",
                      },
                      search: {
                        myAddress: "true",
                        ownerId: companyId,
                      },
                    }}
                    setErrors={setErrors}
                    errors={errors}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <Button
              type="button"
              className="btn btn-primary ms-3"
              onClick={saveCollection}
            >
              Salva
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default collectionSettings;
