import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class LogisticIntegrationsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(
        `${API_BASE_URL}/logistic-service/logistic-integrations`,
        params
      ),
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/logistic-integrations/${id}`,
      requestOptions
    );
  }

  create(logisticIntegration) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(logisticIntegration),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/logistic-integrations`,
      requestOptions
    );
  }

  edit(logisticIntegration) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(logisticIntegration),
    };
    return this.httpProxy.fetch(
      API_BASE_URL +
        `/logistic-service/logistic-integrations/${logisticIntegration.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/logistic-integrations/${id}`,
      requestOptions
    );
  }
}
