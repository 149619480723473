import React, { useEffect } from "react";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { useCreateEditOrderDetailContext } from "./providers/CreateEdit";
import { useCreateEditOrderContext } from "../Orders/providers/CreateEdit";
import {
  getAllArticles,
  getAllWarehouseArticles,
} from "../../utilities/asyncSelectCallAPI";

import InputBox from "../../Components/common/InputBox";
import Select from "react-select";
import { Loader } from "../../Components/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { generateNumberObjects } from "../../utilities";
import {
  getArticleOptionStyle,
  getWarehouseArticleOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";

export const CreateEdit = () => {
  const context = useCreateEditOrderDetailContext();
  const createEditOrderContext = useCreateEditOrderContext();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    context.editOrderDetail({
      ...context.orderDetail,
      [name]: value,
    });
    context.removeError(name);
  };

  const handleSelectChange = (option, event) => {
    context.editOrderDetail({
      ...context.orderDetail,
      [event.name]: option,
    });
    context.removeError(event.name);
  };

  const handleAsyncSelectChange = (selector, value) => {
    context.editOrderDetail({
      ...context.orderDetail,
      [selector]: value.article,
    });
  };

  useEffect(() => {
    if (!context.id) {
      if (context.autosave) {
        context.createOrderDetail();
      }
    } else {
      context.getOrderDetail();
    }
  }, []);

  return (
    <React.Fragment>
      {context.orderDetailLoader && <Loader show={context.orderDetailLoader} />}
      <div className="row">
        <div className="col-9">
          <label className="form-label">Prodotto:</label>
          <AsyncSelect
            name="article"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleziona prodotto"
            isClearable={false}
            value={context?.orderDetail?.article}
            onSearch={handleAsyncSelectChange}
            loadOptions={(inputValue, pagination) => {
              return getAllWarehouseArticles(
                inputValue,
                pagination,
                {
                  orderBy: "asc",
                  selector: "article.name",
                },
                [],
                createEditOrderContext?.order?.warehouse?.id
              );
            }}
            optionStyle={getWarehouseArticleOptionStyle}
            error={context.orderDetailError?.response?.data?.articleId}
          />
        </div>
        <div className="col-3">
          <label className="form-label">Quantità:</label>
          <Select
            name="quantity"
            onChange={handleSelectChange}
            defaultMenuIsOpen={false}
            className="select"
            options={generateNumberObjects(1, 50)}
            value={context.orderDetail?.quantity}
            getOptionValue={(option) => option["code"]}
            getOptionLabel={(option) => option["name"]}
            placeholder="Seleziona..."
            isDisabled={
              context.orderDetail?.id &&
              context.orderDetail?.article.uniqueBarcodeItem
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">Peso unitario:</label>
          <InputBox
            name="unitWeight"
            value={context.orderDetail?.unitWeight}
            placeholder={"1.5"}
            type="positive-number"
            precision={3}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Peso totale:</label>
          <InputBox
            name="totalWeight"
            value={context.orderDetail?.totalWeight}
            placeholder={"1.5"}
            type="positive-number"
            precision={3}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Prezzo unitario:</label>
          <InputBox
            name="unitPrice"
            value={context.orderDetail?.unitPrice}
            placeholder={"1.5"}
            type="positive-number"
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Sconto:</label>
          <InputBox
            name="discount"
            value={context.orderDetail?.discount}
            placeholder={"1.5"}
            type="positive-number"
            toFixed={2}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3">
          <label className="form-label">Prezzo totale:</label>
          <InputBox
            name="totalPrice"
            value={context.orderDetail?.totalPrice}
            placeholder={"1.5"}
            type="positive-number"
            onChange={handleInputChange}
          />
        </div>
      </div>
      {!context.autosave && (
        <div className="row mt-3">
          <div className="col">
            <button
              className="btn btn-link p-0"
              onClick={() => context.callback()}
            >
              Annulla
            </button>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-outline-success btn-sm btn btn-light"
              onClick={() => context.createEditOrderDetail(context.id)}
              disabled={context.incomingGoodDetailLoader}
            >
              {context.incomingGoodDetailLoader ? (
                <FontAwesomeIcon icon={faLoader} spin />
              ) : (
                "Salva"
              )}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
