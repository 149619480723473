import React, { createContext, useContext, useEffect, useState } from "react";
import { AddressBookService as RegistryAddressBookService } from "../../../services/registry/addressBook";
import { LocationsService as RegistryLocationsService } from "../../../services/registry/locations";

const registryAddressBookService = new RegistryAddressBookService();
const registryLocationsService = new RegistryLocationsService();

const CreateEditContactContext = createContext();

const CreateEditContactProvider = ({
  children,
  defaultContact,
  autosave = false,
  callback,
}) => {
  const [contact, setContact] = useState(
    defaultContact || { gdo: false, priv: false, ztl: false, myAddress: false }
  );
  const [saveInAddressBook, setSaveInAddressBook] = useState(false);
  const [registryAddressBookError, setRegistryAddressBookError] =
    useState(false);

  const createContactInRegistryAddressBook = (contact) => {
    contact.myAddress = true;
    createLocation(contact);
  };

  const createLocation = (contact) => {
    registryLocationsService
      .create(contact.location)
      .then((res) => {
        createContact({
          ...contact,
          location: res.data,
          locationId: res.data.id,
        });
      })
      .catch((err) => {
        setRegistryAddressBookError(err);
      });
  };

  const createContact = (contact) => {
    registryAddressBookService.create(contact).catch((err) => {
      setRegistryAddressBookError(err);
    });
  };

  useEffect(() => {
    if (defaultContact) {
      setContact(defaultContact);
    }
  }, [defaultContact]);

  return (
    <CreateEditContactContext.Provider
      value={{
        saveInAddressBook,
        setSaveInAddressBook,

        contact,
        setContact,

        createContactInRegistryAddressBook,
        registryAddressBookError,

        autosave,
        callback,
      }}
    >
      {children}
    </CreateEditContactContext.Provider>
  );
};

const useCreateEditContactContext = () => {
  return useContext(CreateEditContactContext);
};

export { CreateEditContactProvider, useCreateEditContactContext };
