import React, { createContext, useContext, useState } from "react";

import { WarehousesService } from "../../../services/logistic/warehouses";

export const DeleteWarehouseContext = createContext();

const warehousesService = new WarehousesService();

const DeleteWarehouseProvider = ({ children, data, unmountCallback }) => {
  const [warehouses, setWarehouses] = useState(data);
  const [warehousesError, setWarehousesError] = useState(null);
  const [warehousesLoader, setWarehousesLoader] = useState(false);
 
  const deleteWarehouses = () => {
    console.log(warehouses);
    setWarehousesLoader(true);
    const promises = warehouses.map((elem) => {
      return warehousesService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setWarehousesLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        setWarehousesError(err);
      });
  };

  return (
    <DeleteWarehouseContext.Provider
      value={{
        //#region Order
        warehouses,
        warehousesError,
        warehousesLoader,
        deleteWarehouses,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </DeleteWarehouseContext.Provider>
  );
};

const useWarehouseContext = () => {
  return useContext(DeleteWarehouseContext);
};

export { DeleteWarehouseProvider, useWarehouseContext };
