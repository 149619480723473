import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callErrorToast, modalDefault } from "../../../utilities";

export const IndexExportsContext = createContext();

const IndexExportsProvider = ({ children }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  return (
    <IndexExportsContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      {children}
    </IndexExportsContext.Provider>
  );
};

const useIndexExportsContext = () => {
  return useContext(IndexExportsContext);
};

export { IndexExportsProvider, useIndexExportsContext };
