import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import Tesla from "../../assets/images/brands/tesla.svg";
import Debi from "../../assets/images/brands/debijenkorf.svg";
import Klm from "../../assets/images/brands/klm.svg";
import Shell from "../../assets/images/brands/shell.svg";
import BP from "../../assets/images/brands/bp.svg";
import Face24 from "../../assets/images/demo/users/face24.jpg";
import Face1 from "../../assets/images/demo/users/face1.jpg";
import Face3 from "../../assets/images/demo/users/face3.jpg";

const BreadcrumbItem = (props) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div id="fixed-header" className="page-header page-header-light shadow">
        <div className="page-header-content d-lg-flex" id="container1">
          <div className="d-flex">
            <h4 className="page-title mb-0">
              {props.title} -{" "}
              <span className="fw-normal">{props.breadcrumbItem}</span>
            </h4>

            <Link
              to={props.link || "#"}
              className="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
              data-bs-toggle="collapse"
            >
              <i className="ph-caret-down collapsible-indicator ph-sm m-1"></i>
            </Link>
          </div>
          {/* 
          <div
            className="collapse d-lg-block my-lg-auto ms-lg-auto"
            id="page_header"
          >
            <div className="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
              <Dropdown
                isOpen={open}
                toggle={toggleOpen}
                className="dropdown w-100 w-sm-auto"
              >
                <DropdownToggle
                  to="#"
                  type="button"
                  tag="button"
                  className="btn border-0 d-flex align-items-center text-body lh-1 dropdown-toggle py-sm-2"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                >
                  <img src={Tesla} className="w-32px h-32px me-2" alt="" />
                  <div className="me-auto me-lg-1">
                    <div className="fs-sm text-muted mb-1">Customer</div>
                    <div className="fw-semibold">Tesla Motors Inc</div>
                  </div>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-lg-end w-100 w-lg-auto wmin-300 wmin-sm-350 pt-0">
                  <div className="d-flex align-items-center p-3">
                    <h6 className="fw-semibold mb-0">Customers</h6>
                    <Link to="#" className="ms-auto">
                      View all
                      <i className="ph-arrow-circle-right ms-1"></i>
                    </Link>
                  </div>
                  <DropdownItem to="#" className="active py-2">
                    <img src={Tesla} className="w-32px h-32px me-2" alt="" />
                    <div>
                      <div className="fw-semibold">Tesla Motors Inc</div>
                      <div className="fs-sm text-muted">42 users</div>
                    </div>
                  </DropdownItem>
                  <DropdownItem to="#" className="py-2">
                    <img src={Debi} className="w-32px h-32px me-2" alt="" />
                    <div>
                      <div className="fw-semibold">De Bijenkorf</div>
                      <div className="fs-sm text-muted">49 users</div>
                    </div>
                  </DropdownItem>
                  <DropdownItem to="#" className="py-2">
                    <img src={Klm} className="w-32px h-32px me-2" alt="" />
                    <div>
                      <div className="fw-semibold">Royal Dutch Airlines</div>
                      <div className="fs-sm text-muted">18 users</div>
                    </div>
                  </DropdownItem>
                  <DropdownItem to="#" className="py-2">
                    <img src={Shell} className="w-32px h-32px me-2" alt="" />
                    <div>
                      <div className="fw-semibold">Royal Dutch Shell</div>
                      <div className="fs-sm text-muted">54 users</div>
                    </div>
                  </DropdownItem>
                  <DropdownItem to="#" className="py-2">
                    <img src={BP} className="w-32px h-32px me-2" alt="" />
                    <div>
                      <div className="fw-semibold">BP plc</div>
                      <div className="fs-sm text-muted">23 users</div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div className="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>
              <div className="d-inline-flex mt-3 mt-sm-0">
                <Link to="#" className="status-indicator-container ms-1">
                  <img
                    src={Face24}
                    className="w-32px h-32px rounded-pill"
                    alt=""
                  />
                  <span className="status-indicator bg-warning"></span>
                </Link>
                <Link to="#" className="status-indicator-container ms-1">
                  <img
                    src={Face1}
                    className="w-32px h-32px rounded-pill"
                    alt=""
                  />
                  <span className="status-indicator bg-success"></span>
                </Link>
                <Link to="#" className="status-indicator-container ms-1">
                  <img
                    src={Face3}
                    className="w-32px h-32px rounded-pill"
                    alt=""
                  />
                  <span className="status-indicator bg-danger"></span>
                </Link>
                <Button
                  color="outline-primary"
                  type="button"
                  className="btn btn-outline-primary btn-icon w-32px h-32px rounded-pill ms-3"
                >
                  <i className="ph-plus"></i>
                </Button>
              </div>
            </div>
          </div> */}
        </div>
        {window.location.pathname === "/navbar_single_header_after" ? (
          ""
        ) : (
          <div
            className="page-header-content d-lg-flex border-top "
            id="container2"
          >
            <div className="d-flex">
              <Breadcrumb className="breadcrumb py-2">
                <Link to="/index" className="breadcrumb-item">
                  <i className="ph-house"></i>
                </Link>
                <Link to={props.link || "#"} className="breadcrumb-item">
                  {props.title}
                </Link>
                <span className="breadcrumb-item active">
                  {props.breadcrumbItem}
                </span>
              </Breadcrumb>

              <Link
                to="#"
                className="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
                data-bs-toggle="collapse"
              >
                <i className="ph-caret-down collapsible-indicator ph-sm m-1"></i>
              </Link>
            </div>
            {/* 
            <div
              className="collapse d-lg-block ms-lg-auto"
              id="breadcrumb_elements"
            >
              <div className="d-lg-flex mb-2 mb-lg-0">
                <Link
                  to="#"
                  className="d-flex align-items-center text-body py-2"
                >
                  <i className="ph-lifebuoy me-2"></i>
                  Support
                </Link>

                <UncontrolledDropdown className="ms-lg-3">
                  <DropdownToggle
                    to="#"
                    role="button"
                    tag="a"
                    type="button"
                    className="d-flex align-items-center text-body dropdown-toggle py-2"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ph-gear me-2"></i>
                    <span className="flex-1">Settings</span>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end w-100 w-lg-auto">
                    <DropdownItem to="#">
                      <i className="ph-shield-warning me-2"></i>
                      Account security
                    </DropdownItem>
                    <DropdownItem to="#">
                      <i className="ph-chart-bar me-2"></i>
                      Analytics
                    </DropdownItem>
                    <DropdownItem to="#">
                      <i className="ph-lock-key me-2"></i>
                      Privacy
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem to="#">
                      <i className="ph-gear me-2"></i>
                      All settings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BreadcrumbItem;
