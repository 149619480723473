import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ShipmentsService } from "../../../services/shipment/shipments";
import { callErrorToast } from "../../../utilities";

export const DeleteShipmentContext = createContext();

const shipmentsService = new ShipmentsService();

const DeleteShipmentProvider = ({ children, data, callback }) => {
  const [shipments, setShipments] = useState(data);
  const [shipmentsError, setShipmentsError] = useState(null);
  const [shipmentsLoader, setShipmentsLoader] = useState(false);
  const prevShipmentsError = useRef();

  const deleteShipments = () => {
    setShipmentsLoader(true);
    const promises = shipments.map((elem) => {
      return shipmentsService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setShipmentsLoader(false);
        callback();
      })
      .catch((err) => {
        setShipmentsLoader(false);
        setShipmentsError(err);
      });
  };

  useEffect(() => {
    if (
      shipmentsError &&
      JSON.stringify(prevShipmentsError.current) !==
        JSON.stringify(shipmentsError)
    ) {
      callErrorToast(shipmentsError);
    }
  }, [shipmentsError]);

  return (
    <DeleteShipmentContext.Provider
      value={{
        shipments,
        shipmentsError,
        shipmentsLoader,
        deleteShipments,

        callback,
      }}
    >
      {children}
    </DeleteShipmentContext.Provider>
  );
};

const useDeleteShipmentContext = () => {
  return useContext(DeleteShipmentContext);
};

export { DeleteShipmentProvider, useDeleteShipmentContext };
