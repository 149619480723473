import React from "react";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "reactstrap";

const shipmentsService = new ShipmentsService();

const Confirm = ({ id, callback }) => {
  const confirmShipment = () => {
    shipmentsService
      .confirm(id)
      .then((response) => {
        if (callback) {
          callback(true);
        }
        toast.success("Spedizione confermata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(() => {
        toast.error("Spedizione non confermata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <React.Fragment>
      <form>
        <label>
          Dopo la conferma, il tracking della spedizione verrà attivato e non
          sarà più possibile eliminare la spedizione. Sei sicuro di voler
          confermare la spedizione?
        </label>
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (callback) {
                  callback(false);
                }
              }}
            >
              Annulla
            </button>
            <Button
              onClick={confirmShipment}
              type="button"
              className="btn ms-3"
            >
              Conferma
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Confirm;
