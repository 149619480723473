import React, { useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "../../assets/scss/pages/homepage.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowUp,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { getWeekDaysInfo } from "../../utilities";
import moment from "moment";
import "moment/locale/it";

const days = getWeekDaysInfo();
const collections = [
  {
    date: "2024-06-05T22:00:00Z",
    carrier: {
      code: "SDA_IT",
      name: "SDA Italia",
      logoUrl:
        "https://www.sda.it/SDAitThemeStatic/SDAitTheme/risorse_dt/condivise/immagini/loghi/logo-sda.png",
      stockManagementEnabled: false,
      websiteUrl: "https://www.sda.it/wps/portal/sdait.home/",
      addressValidationEnabled: false,
      ratingEnabled: false,
      pickupEnabled: false,
      collectionCreationEnabled: true,
      collectionDeleteEnabled: false,
      collectionCutoffTime: "12:30",
      collectionStandaloneCreationEnabled: true,
    },
    shipmentsCounter: 2,
  },
  {
    date: "2024-06-05T22:00:00Z",
    carrier: {
      code: "SDA_IT",
      name: "SDA Italia",
      logoUrl:
        "https://www.sda.it/SDAitThemeStatic/SDAitTheme/risorse_dt/condivise/immagini/loghi/logo-sda.png",
      stockManagementEnabled: false,
      websiteUrl: "https://www.sda.it/wps/portal/sdait.home/",
      addressValidationEnabled: false,
      ratingEnabled: false,
      pickupEnabled: false,
      collectionCreationEnabled: true,
      collectionDeleteEnabled: false,
      collectionCutoffTime: "12:30",
      collectionStandaloneCreationEnabled: true,
    },
    shipmentsCounter: 2,
  },
  {
    date: "2024-06-05T22:00:00Z",
    carrier: {
      code: "SDA_IT",
      name: "SDA Italia",
      logoUrl:
        "https://www.sda.it/SDAitThemeStatic/SDAitTheme/risorse_dt/condivise/immagini/loghi/logo-sda.png",
      stockManagementEnabled: false,
      websiteUrl: "https://www.sda.it/wps/portal/sdait.home/",
      addressValidationEnabled: false,
      ratingEnabled: false,
      pickupEnabled: false,
      collectionCreationEnabled: true,
      collectionDeleteEnabled: false,
      collectionCutoffTime: "12:30",
      collectionStandaloneCreationEnabled: true,
    },
    shipmentsCounter: 2,
  },
];
const Collections = (props) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [selectedDay, setSelectedDay] = useState(
    days.findIndex((elem) => elem.isToday)
  );
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="row align-items-center">
      <div className="col">
        <h3>
          Settimana {days[0].dayNumber}-{days[6].dayNumber} {days[6].month}
        </h3>
        <div className="weekly-calendar-container">
          {days.map((elem, index) => {
            return (
              <div key={index} className="day">
                <label className="name">
                  {elem.dayOfWeek.substring(0, 3).toUpperCase()}
                </label>
                <div
                  onClick={() => setSelectedDay(index)}
                  className={`number ${selectedDay === index && "active"}`}
                >
                  {elem.dayNumber}
                </div>
                <div className="signal">
                  {elem.collections && <span></span>}
                </div>
                <span className="counter">
                  {elem.collections ? elem.collections : "-"}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col">
        <div className="weekly-calendar-details">
          <button className="button button-dark">
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </button>
          <div className="details-container">
            {collections
              .map((elem, index) => {
                moment.locale("it");
                const date = moment(elem.date);
                const dayName = date
                  .format("dddd")
                  .substring(0, 3)
                  .toUpperCase();
                const dayNumber = date.format("D");
                return (
                  <div className="detail">
                    <div className="day">
                      <span className="name">{dayName}</span>
                      <span className="number">{dayNumber}</span>
                    </div>
                    <div className="content">
                      <label>Numero spedizioni per ritiro</label>
                      <span>{elem.shipmentsCounter}</span>
                    </div>
                    <div className="logo">
                      <img src={elem.carrier.logoUrl} width={60} />
                    </div>
                  </div>
                );
              })
              .slice(0, showAll ? collections.length : 2)}
            <Link
              className="link text-center"
              onClick={() => setShowAll(!showAll)}
              to={"#"}
            >
              {showAll ? (
                <React.Fragment>
                  <FontAwesomeIcon icon={faLongArrowUp} />
                  Mostra di meno
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon icon={faLongArrowDown} />
                  Mostra di più
                </React.Fragment>
              )}
            </Link>
          </div>
          <button className="button button-dark">
            <FontAwesomeIcon icon={faLongArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Collections;
