import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { DeleteArticleContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Delete = () => {
  const {
    articles,
    articlesError,
    articlesLoader,
    deleteArticles,
    unmountCallback,
  } = useContext(DeleteArticleContext);

  useEffect(() => {
    if (articlesError) {
      callErrorToast(articlesError);
    }
  }, [articlesError]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {articles?.length > 1
              ? "Sei sicuro di voler eliminare i prodotti selezionati?"
              : "Sei sicuro di voler eliminare il seguente prodotto?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={deleteArticles}
          disabled={articlesLoader}
        >
          {articlesLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Elimina"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Delete;
