import React, { createContext, useState, useContext, useEffect } from "react";
import { callErrorToast } from "../../../utilities/index";

import { KPIService } from "../../../services/shipment/kpi";

export const ShipmentsContext = createContext();

const kpiService = new KPIService();

const ShipmentsProvider = ({ children, params }) => {
  const [kpi, setKpi] = useState(null);
  const [kpiError, setKpiError] = useState(null);
  const [kpiLoader, setKpiLoader] = useState(false);

  const getStatistics = () => {
    setKpiLoader(true);
    kpiService
      .statistics(params)
      .then((response) => {
        setKpi(response.data);
        setKpiLoader(false);
      })
      .catch((error) => {
        setKpiError(error);
        setKpiLoader(false);
      });
  };

  const getPerformances = () => {
    setKpiLoader(true);
    kpiService
      .performance(params)
      .then((response) => {
        setKpi(response.data);
        setKpiLoader(false);
      })
      .catch((error) => {
        setKpiError(error);
        setKpiLoader(false);
      });
  };

  useEffect(() => {
    if (kpiError) {
      callErrorToast(kpiError);
    }
  }, [kpiError]);

  return (
    <ShipmentsContext.Provider
      value={{
        kpi,
        kpiLoader,

        getStatistics,
        getPerformances,
      }}
    >
      {children}
    </ShipmentsContext.Provider>
  );
};

const useShipmentsContext = () => {
  return useContext(ShipmentsContext);
};

export { ShipmentsProvider, useShipmentsContext };
