import React from "react";
import { Input } from "reactstrap";
import ColumnSort from "./ColumnSort";

const TableHeader = ({
  headerGroups,
  selectMultipleRows,
  isAllRowsSelected,
  onChangeStatusAllRows,
  onSortingChange,
  sortingOpt,
  theadClass = "",
  thClass = "",
}) => {
  return (
    <>
      <thead className={theadClass || ""}>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {selectMultipleRows && (
              <th className={(thClass || "") + " " + "pinning pinning-left"}>
                <div className={"form-check-input"}>
                  <Input
                    type={"checkbox"}
                    checked={isAllRowsSelected}
                    onChange={(e) => {
                      onChangeStatusAllRows(e.target.checked);
                    }}
                  />
                </div>
              </th>
            )}
            {headerGroup.headers.map((column, i) => {
              const pinningClass = `${thClass || ""}${
                column.pinning ? " pinning pinning-" + column.pinning : ""
              }`;
              return (
                <th key={column.id} className={pinningClass}>
                  {!column.hiddenHeader && column.render("Header")}
                  {column.sortable && (
                    <ColumnSort
                      data={column}
                      onSortingChange={onSortingChange}
                      sortingOpt={sortingOpt}
                    />
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    </>
  );
};

export default TableHeader;
