import React, { useState, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import { useLocation } from "react-router-dom";
import { convertFromJson } from "fiql-query-builder";
import { useOidcUser } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import Create from "./create";
import Edit from "./edit";
import Delete from "./delete";
import { parcelTypesList } from "../../constants";
import {
  getParcelTypeBadge,
  getUniqueSelectors,
  buildQuerySort,
} from "../../utilities";

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const defaultQuerySearch = [
  // {
  //   equals: {
  //     selector: "myAddress",
  //     args: "true",
  //   },
  // },
];

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ParcelTemplates = () => {
  const query = useQuery();
  const { oidcUser } = useOidcUser();
  const [queryParams, setQueryParams] = useState([...defaultQuerySearch]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);
  const columns = [
    {
      Header: "Tipologia",
      accessor: "parcelTypeCode",
      Cell: ({ row }) => {
        var parcelType = parcelTypesList.find(
          (elem) => elem.code === row.original.parcelTypeCode
        );
        return getParcelTypeBadge(parcelType);
      },
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Larghezza (cm)",
      accessor: "width",
      Cell: ({ row }) => {
        return row.values.width || "-";
      },
    },
    {
      Header: "Profondità (cm)",
      accessor: "length",
      Cell: ({ row }) => {
        return row.values.length || "-";
      },
    },
    {
      Header: "Altezza (cm)",
      accessor: "height",
      Cell: ({ row }) => {
        return row.values.height || "-";
      },
    },
    {
      Header: "Peso (kg)",
      accessor: "weight",
      Cell: ({ row }) => {
        return row.values.weight?.toFixed(3) || "-";
      },
    },
    {
      Header: "Volume (m3)",
      accessor: "volume",
      Cell: ({ row }) => {
        return row.values.volume
          ? `${row.values.volume.toFixed(6)}`.replace(
              /^0+(\d)|(\d)0+$/gm,
              "$1$2"
            )
          : "-";
      },
    },
    {
      Header: "Azioni",
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Modifica template collo",
                    icon: "ph-note-pencil",
                    open: !modalSettings.open,
                    action: "edit",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina template collo",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    action: "delete",
                    id: [values.id],
                  });
                }}
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    const uniqueSelectors = getUniqueSelectors(selector, value, preciseSearch, [
      ...queryParams,
    ]);
    handlePagination({ page: 0 });
    setQueryParams(uniqueSelectors);
  };

  const { data, loading, error, refreshData } = useFetch(
    `/shipment-service/parcel-templates?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Template colli" breadcrumbItem={""} />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo template collo",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  Nuovo template collo <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            {oidcUser && (
              <TableAdvanced
                hasFilter={false}
                columns={columns}
                data={data}
                className="custom-header-css"
                tableClass={"table-xxs"}
                paginationOpt={pagination}
                onPaginationChange={handlePagination}
                refreshTable={refreshData}
                sortingOpt={sort}
                onSortingChange={handleSorting}
                onSearch={onSearch}
                onFreeSearch={onSearch}
                showLoader={loading}
              />
            )}

            <Modal isOpen={modalSettings.open} className="modal-content">
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "create" && (
                  <Create
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "edit" && (
                  <Edit
                    parcelTemplateId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "delete" && (
                  <Delete
                    parcelTemplateId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ParcelTemplates;
