import React, { useState, useEffect, useContext } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { useCreateEditWarehouseArticleContext } from "./providers/CreateEdit";
import { getAllArticles } from "../../utilities/asyncSelectCallAPI";
import { warehouseArticleNormalizer } from "./utilities";
import { callErrorToast } from "../../utilities";
import InputBox from "../../Components/common/InputBox";
import { Loader } from "../../Components/common/Loader";

export const CreateEdit = ({ id, parentId, unmountCallback }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const context = useCreateEditWarehouseArticleContext();

  const handleWarehouseArticleInputChange = (event) => {
    const { name, value } = event.target;
    const warehouseArticleNormalized = warehouseArticleNormalizer(
      { ...context?.warehouseArticle, [name]: value },
      parentId
    );
    context?.editWarehouseArticle(warehouseArticleNormalized);
  };

  const handleArticleAsyncSelectChange = (selector, value) => {
    let newWarehouseArticle = { ...context?.warehouseArticle };
    delete newWarehouseArticle.article;
    delete newWarehouseArticle.articleId;
    if (value) {
      newWarehouseArticle.article = value;
    }
    if (id) {
      context?.editWarehouseArticle(
        warehouseArticleNormalizer(newWarehouseArticle, parentId)
      );
      return false;
    }
    context?.createWarehouseArticle(
      warehouseArticleNormalizer(newWarehouseArticle, parentId)
    );
  };

  useEffect(() => {
    if (context?.warehouseArticleError) {
      callErrorToast(context?.warehouseArticleError);
    }
  }, [context?.warehouseArticleError]);

  useEffect(() => {
    if (id) {
      context?.getWarehouseArticle(id);
    }
  }, []);

  return (
    <React.Fragment>
      {context?.warehouseArticleLoader && (
        <Loader show={context.warehouseArticleLoader} />
      )}
      <div className="row">
        <div className="col">
          <label className="form-label">Prodotto:</label>
          <AsyncSelect
            name="article"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleziona prodotto"
            isClearable={false}
            value={context?.warehouseArticle?.article}
            onSearch={handleArticleAsyncSelectChange}
            loadOptions={(inputValue, pagination) => {
              return getAllArticles(inputValue, pagination, {
                orderBy: "asc",
                selector: "name",
              });
            }}
          />
        </div>
      </div>
      {/* effectiveStockQuantity: 0,
    effectiveCommittedQuantity: 0,
    blockedQuantity: 0,
    effectiveAvailableQuantity: 0,
    effectiveIncomingQuantity: 0, */}
      {context.warehouseArticle.id && (
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">Quantità in arrivo:</label>
            <InputBox
              name="effectiveIncomingQuantity"
              value={context?.warehouseArticle?.effectiveIncomingQuantity}
              placeholder={"1"}
              type="positive-integer-number"
              onChange={handleWarehouseArticleInputChange}
              error={
                context.warehouseArticleError?.response?.data
                  ?.effectiveIncomingQuantity
              }
            />
          </div>
          <div className="col">
            <label className="form-label">Quantità in giacenza:</label>
            <InputBox
              name="effectiveStockQuantity"
              value={context?.warehouseArticle?.effectiveStockQuantity}
              placeholder={"1"}
              type="positive-integer-number"
              onChange={handleWarehouseArticleInputChange}
              error={
                context.warehouseArticleError?.response?.data
                  ?.effectiveStockQuantity
              }
            />
          </div>
          <div className="col">
            <label className="form-label">Quantità bloccata:</label>
            <InputBox
              name="blockedQuantity"
              value={context?.warehouseArticle?.blockedQuantity}
              placeholder={"1"}
              type="positive-integer-number"
              onChange={handleWarehouseArticleInputChange}
              error={
                context.warehouseArticleError?.response?.data?.blockedQuantity
              }
            />
          </div>
          <div className="col">
            <label className="form-label"> Quantità impegnata :</label>
            <InputBox
              name="effectiveCommittedQuantity"
              value={context?.warehouseArticle?.effectiveCommittedQuantity}
              placeholder={"1"}
              type="positive-integer-number"
              onChange={handleWarehouseArticleInputChange}
              error={
                context.warehouseArticleError?.response?.data
                  ?.effectiveCommittedQuantity
              }
            />
          </div>
          <div className="col">
            <label className="form-label"> Quantità disponibile :</label>
            <InputBox
              name="effectiveAvailableQuantity"
              value={context?.warehouseArticle?.effectiveAvailableQuantity}
              placeholder={"1"}
              type="positive-integer-number"
              onChange={handleWarehouseArticleInputChange}
              error={
                context.warehouseArticleError?.response?.data
                  ?.effectiveAvailableQuantity
              }
            />
          </div>
        </div>
      )}
      {context?.unmountCallback && (
        <div className="row mt-3">
          <div className="col">
            <button className="btn btn-link" onClick={context?.unmountCallback}>
              Chiudi
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
