import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import { hasRole } from "../../utilities";
import {
  faHouse,
  faMapLocationDot,
  faLock,
  faTruckFast,
  faFileContract,
  faPuzzle,
  faAddressCard,
  faProjectDiagram,
  faFileExport,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { icon } from "@fortawesome/fontawesome-svg-core";

const Navdata = () => {
  const { oidcUser } = useOidcUser();
  const navigate = useNavigate();

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isDashboard, setIsDashboard] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isRegistry, setIsRegistry] = useState(false);
  const [isLogistic, setIsLogistic] = useState(false);
  const [isContracts, setIsContracts] = useState(false);
  const [isLocations, setIsLocations] = useState(false);
  const [isIntegrations, setIsIntegrations] = useState(false);
  const [isExports, setIsExports] = useState(false);

  useEffect(() => {
    if (iscurrentState === "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Authentication") {
      setIsAuthentication(false);
    }
    if (iscurrentState !== "Shipping") {
      setIsShipping(false);
    }
    if (iscurrentState !== "Registry") {
      setIsRegistry(false);
    }
    if (iscurrentState !== "Logistic") {
      setIsLogistic(false);
    }
    if (iscurrentState !== "Locations") {
      setIsLocations(false);
    }
    if (iscurrentState !== "Contracts") {
      setIsContracts(false);
    }
    if (iscurrentState !== "Integrations") {
      setIsIntegrations(false);
    }
    if (iscurrentState !== "Exports") {
      setIsExports(false);
    }
  }, [
    navigate,
    iscurrentState,
    isAuthentication,
    isDashboard,
    isShipping,
    isRegistry,
    isLogistic,
    isContracts,
    isIntegrations,
    isLocations,
    isExports,
  ]);

  const userRoles = oidcUser?.roles || [];

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: faHouse,
      link: "/dashboard",
      stateVariables: isDashboard,
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
    },
    {
      id: "locations",
      label: "Località",
      icon: faMapLocationDot,
      stateVariables: isLocations,
      click: function (e) {
        e.preventDefault();
        setIsLocations(!isLocations);
        setIscurrentState("Locations");
      },
      visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
      subItems: [
        {
          id: "zones",
          label: "Zone",
          link: "/locations/zones",
          parentId: "locations",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "authentication",
      label: "Autenticazioni",
      icon: faLock,
      stateVariables: isAuthentication,
      click: function (e) {
        e.preventDefault();
        setIsAuthentication(!isAuthentication);
        setIscurrentState("Authentication");
      },
      visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
      subItems: [
        {
          id: "users",
          label: "Utenti",
          link: "/users",
          parentId: "authentication",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "shipping",
      label: "Spedizioni",
      icon: faTruckFast,
      stateVariables: isShipping,
      click: function (e) {
        e.preventDefault();
        setIsShipping(!isShipping);
        setIscurrentState("Shipping");
      },
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
      subItems: [
        {
          id: "shipments",
          label: "Spedizioni",
          link: "/shipments",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "collections",
          label: "Ritiri",
          link: "/collections",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "parcelTemplates",
          label: "Template colli",
          link: "/parcel-templates",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "codCollections",
          label: "Incasso contrassegni",
          link: "/cod-collections",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "contracts",
      label: "Contratti",
      icon: faFileContract,
      stateVariables: isContracts,
      click: function (e) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
      },
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
      subItems: [
        {
          id: "shipments",
          label: "Contratti",
          link: "/contracts",
          parentId: "contracts",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "integrations",
      label: "Integrazioni",
      icon: faPuzzle,
      stateVariables: isIntegrations,
      click: function (e) {
        e.preventDefault();
        setIsIntegrations(!isIntegrations);
        setIscurrentState("Integrations");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "carriers",
          label: "Vettori",
          link: "/carriers-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
        },
        {
          id: "stores",
          label: "Stores",
          link: "/stores-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
          isNew: true,
        },
        {
          id: "logistic",
          label: "Logistica",
          link: "/logistic-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
          isNew: true,
        },
      ],
    },
    {
      id: "registry",
      label: "Anagrafiche",
      icon: faAddressCard,
      stateVariables: isRegistry,
      click: function (e) {
        e.preventDefault();
        setIsRegistry(!isRegistry);
        setIscurrentState("Registry");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "customers",
          label: "Clienti",
          link: "/customers",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
        },
        {
          id: "affiliates",
          label: "Affiliati",
          link: "/affiliates",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_ORGANIZATION_ADMINISTRATOR"),
        },
        {
          id: "organizations",
          label: "Organizzazioni",
          link: "/organizations",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_SPECIAL_ADMINISTRATOR"),
        },
        {
          id: "addressBook",
          label: "Rubrica destinazioni",
          link: "/address-book",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
        },
      ],
    },
    {
      id: "logistic",
      label: "Logistica",
      icon: faProjectDiagram,
      link: "/#",
      stateVariables: isLogistic,
      click: function (e) {
        e.preventDefault();
        setIsLogistic(!isLogistic);
        setIscurrentState("Logistic");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "articles",
          label: "Prodotti",
          link: "/articles",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "orders",
          label: "Ordini",
          link: "/orders",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "warehouses",
          label: "Magazzini",
          link: "/warehouses",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "incomingGoods",
          label: "Bolle entrata merce",
          link: "/incoming-goods",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "export",
      label: "Esportazioni",
      icon: faFileExport,
      link: "/exports",
      stateVariables: isExports,
      click: function (e) {
        e.preventDefault();
        setIsExports(!isExports);
        setIscurrentState("Exports");
      },
      visibility: hasRole(userRoles),
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
