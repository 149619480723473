import moment from "moment";
import { collectionStatusList } from "../../constants";
import {
  getCarrierOptionStyle,
  getShipmentStatusOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import { getAllCarriers } from "../../utilities/asyncSelectCallAPI";
import {
  checkIfIsBusinessDay,
  getNextWorkingDay,
  valueIsEmpty,
} from "../../utilities";
import { validation as addressBookValidation } from "../AddressBook/utilities";
import { dictionary } from "../../utilities/dictionary";

const collectionStatusValidForDelete = ["DRAFT"];
const collectionStatusValidForEdit = ["DRAFT"];

export const calculateCollectionDate = (time) => {
  const currentTime = moment();
  if (time) {
    const isAfterCutoff = currentTime.isAfter(moment(time, "HH:mm"));

    if (isAfterCutoff || !checkIfIsBusinessDay()) {
      return getNextWorkingDay();
    }
  }
  // Altrimenti, se currentTime è prima di time e oggi è un giorno lavorativo
  return currentTime.toDate();
};

export const normalizer = (
  collection,
  carrier,
  carrierConfiguration,
  andBook
) => {
  let newCollection = { ...collection };
  newCollection.date = moment(newCollection.date, "DD/MM/YYYY").startOf("day");
  newCollection.addressBookId = newCollection.addressBook.id;
  newCollection.carrierCode =
    carrier?.code || carrierConfiguration?.carrierCode || null;
  if (carrierConfiguration) {
    newCollection.carrierConfigurationId = carrierConfiguration.id;
  }
  newCollection.book = andBook;
  return newCollection;
};

export const validation = (collection, carrierConfiguration, standAlone) => {
  let errors = {};
  const { numPackages, totalWeight } = collection;

  if (standAlone && valueIsEmpty(carrierConfiguration)) {
    errors["carrierConfiguration"] = dictionary["nullfield"]["it"];
  }

  if ("numPackages" in collection && !numPackages) {
    errors["numPackages"] = dictionary["nullfield"]["it"];
  }
  if ("totalWeight" in collection && !totalWeight) {
    errors["totalWeight"] = dictionary["nullfield"]["it"];
  }

  errors = { ...errors, ...addressBookValidation(collection.addressBook) };

  return errors;
};

export const defaultCollection = {
  morningOpenTime: "09:00",
  morningCloseTime: "13:00",
  afternoonOpenTime: "14:00",
  afternoonCloseTime: "18:00",
  numPackages: 1,
  totalWeight: 1.0,
  externalReference: "",
};

export const allSelectedCollectionsCanBeDeleted = (
  collectionSelectedIds = [],
  collections = []
) => {
  const idSelected = collectionSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const collectionsSelected = collections.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllCollectionsBeDeleted(collectionsSelected);
};

export const canCollectionBeDeleted = (collection) => {
  if (
    collection.status === "BOOKED" &&
    !collection.carrier.collectionDeleteEnabled
  ) {
    return false;
  }
  return true;
};

export const canAllCollectionsBeDeleted = (collections) => {
  if (!collections || collections.length === 0) return false;
  const selectedCollectionCanBeDeleted = collections.map((x) =>
    canCollectionBeDeleted(x)
  );
  return selectedCollectionCanBeDeleted.every((x) => x);
};

//#region Index columns
export const columns = [
  {
    position: 1,
    Header: "Stato",
    accessor: "status",
    style: { minWidth: "12rem" },
    filter: {
      active: true,
      name: "status",
      type: "select",
      options: collectionStatusList,
      multi: true,
      optionStyle: getShipmentStatusOptionStyle,
      col: 3,
    },
    Cell: ({ row }) => {
      const status = collectionStatusList.find(
        (elem) => elem.value === row.original.status
      );
      return (
        <span style={{ backgroundColor: status.color }} className="badge">
          {status.label}
        </span>
      );
    },
  },
  {
    position: 2,
    Header: "Vettore",
    accessor: "carrierCode",
    filter: {
      active: true,
      name: "carrierCode",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllCarriers(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getCarrierOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.carrierCode) {
        return <img width={50} src={row.carrier.logoUrl} />;
      }
      return "-";
    },
  },
  {
    position: 3,
    Header: "Data di ritiro",
    accessor: "date",
    sortable: true,
    Cell: ({ cell }) => {
      return moment(cell.row.original.date).format("DD/MM/YYYY");
    },
  },
  {
    position: 4,
    Header: "Cliente",
    accessor: "owner.name",
  },
  {
    position: 5,
    Header: "Rif.Interno",
    accessor: "internalReference",
  },
  {
    position: 6,
    Header: "Rif.Esterno",
    accessor: "externalReference",
  },
  {
    position: 7,
    Header: "Rif.Vettore",
    accessor: "carrierReference",
  },
];
//#endregion
