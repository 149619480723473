import React from "react";
import { Button } from "reactstrap";
import { useIncomingGoodDetailContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

export const Delete = () => {
  const context = useIncomingGoodDetailContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {context.incomingGoodDetails?.length > 1
              ? "Sei sicuro di voler eliminare i dettagli selezionati?"
              : "Sei sicuro di voler eliminare il seguente dettaglio?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={() => context.deleteIncomingGoodDetails()}
          disabled={context.incomingGoodDetailsLoader}
        >
          {context.incomingGoodDetailsLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Elimina"
          )}
        </Button>
      </div>
    </form>
  );
};
