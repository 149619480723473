import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class AttachmentsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  exportPDF(data) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/attachments/export/pdf`,
      requestOptions,
      { Accept: "*/*" },
      "blob"
    );
  }
}
