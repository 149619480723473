import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class UsersService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/registry-service/carriers`, params),
      requestOptions
    );
  }

  getUser(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/user-service/users/${id}`,
      requestOptions
    );
  }

  create(user) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(user),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users`,
      requestOptions
    );
  }

  edit(user) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(user),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users/${user.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users/${id}`,
      requestOptions
    );
  }

  resetPassword(id, password) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(password),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users/${id}/password-reset`,
      requestOptions
    );
  }

  getAvailableRoles(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users/${id}/available-roles`,
      requestOptions
    );
  }

  enabledDisabled(id, enabled) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/user-service/users?state=${enabled}&id=${id}`,
      requestOptions
    );
  }
}
