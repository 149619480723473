import React from "react";
import { CollectionsService } from "../../services/shipment/collections";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast, callSuccessToast } from "../../utilities";

const collectionsService = new CollectionsService();

const Book = ({ id, unmountCallback }) => {
  const bookCollection = () => {
    collectionsService
      .book(id)
      .then((response) => {
        if (unmountCallback) {
          unmountCallback(true);
        }
        callSuccessToast({ status: 200 });
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  return (
    <React.Fragment>
      <form>
        <label>Sei sicuro di voler prenotare il seguente ritiro?</label>
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (unmountCallback) {
                  unmountCallback(false);
                }
              }}
            >
              Annulla
            </button>
            <Button onClick={bookCollection} type="button" className="btn ms-3">
              Prenota
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Book;
