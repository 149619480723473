import React, { useState, useEffect } from "react";
import Select from "react-select";
import { countries, orderBykey } from "../../utilities";
import InputBox from "../../Components/common/InputBox";
import { FormFeedback } from "reactstrap";

export const defaultCustomData = {
  description: "",
  quantity: "",
  unitWeight: "",
  unitPrice: "",
  hsCode: "",
  countryIsoCode: "",
};

const CustomDataItem = ({ data, edit, remove, uuid, errors }) => {
  const [customDataItem, setCustomDataItem] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    edit({ ...customDataItem, [name]: value });
  };

  const handleSelectChange = (option, event) => {
    edit({
      ...customDataItem,
      [event.name]: option,
    });
  };

  useEffect(() => {
    setCustomDataItem(data);
  }, [data]);

  const err = errors?.[0]?.errors;
  const countryIsoCodeError = err?.find(
    (x) => x.field === "countryIsoCode"
  )?.value;

  return (
    <React.Fragment>
      <div className="row align-items-start" key={uuid}>
        <div className="col-2">
          <label className="form-label">Descrizione:</label>
          <InputBox
            type="text"
            name="description"
            className="form-control"
            placeholder="Descrizione merce"
            value={customDataItem.description || ""}
            onChange={handleInputChange}
            min={0}
            max={100}
            error={err?.find((x) => x.field === "description")?.value}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Quantità:</label>
          <InputBox
            type="positive-number"
            name="quantity"
            className="form-control"
            placeholder="1"
            min={0}
            value={customDataItem.quantity || ""}
            onChange={handleInputChange}
            error={err?.find((x) => x.field === "quantity")?.value}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Peso unitario:</label>
          <InputBox
            type="positive-number"
            name="unitWeight"
            placeholder="10"
            min={0}
            value={customDataItem.unitWeight || ""}
            onChange={handleInputChange}
            precision={3}
            error={err?.find((x) => x.field === "unitWeight")?.value}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Valore unitario:</label>
          <InputBox
            type="positive-number"
            name="unitPrice"
            placeholder="10"
            min={0}
            value={customDataItem.unitPrice || ""}
            onChange={handleInputChange}
            error={err?.find((x) => x.field === "unitPrice")?.value}
          />
        </div>
        <div className="col-2">
          <label className="form-label">HS Code:</label>
          <InputBox
            type="text"
            name="hsCode"
            placeholder="4801–4823"
            value={customDataItem.hsCode || ""}
            onChange={handleInputChange}
            min={0}
            max={50}
            error={err?.find((x) => x.field === "hsCode")?.value}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Paese di origine:</label>
          <Select
            name="countryIsoCode"
            onChange={handleSelectChange}
            defaultMenuIsOpen={false}
            className={`${countryIsoCodeError ? "is-invalid" : ""} select`}
            options={orderBykey(countries, "value")}
            value={customDataItem.countryIsoCode || ""}
            placeholder="Seleziona paese"
            getOptionValue={(option) => option["code"]}
            getOptionLabel={(option) => option["value"]}
            invalid={!!countryIsoCodeError}
          />
          {countryIsoCodeError && (
            <FormFeedback type="invalid">{countryIsoCodeError}</FormFeedback>
          )}
        </div>
        <div className="col-1">
          <label className="form-label invisible">Cancella</label>
          <div className={"form-control border border-0 p-0"}>
            <button
              title="Elimina"
              type="button"
              className="btn btn-outline-danger p-1"
              onClick={() => remove(customDataItem)}
            >
              <i className="ph-trash m-1"></i>
            </button>
          </div>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default CustomDataItem;
