import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export default class CompaniesService {
  // Initializing important variables
  constructor(domain) {
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };
    let url = API_BASE_URL + (filters.url || "");
    if (!filters.url) {
      url += `/registry-service/companies?page=${0}&size=${1000}`;
      url = createQueryString(url, filters);
    }
    return this.httpClient.fetch(url, requestOptions);
  }

  getCompany(id) {
    const requestOptions = {
      method: "GET",
    };
    let url = API_BASE_URL + `/registry-service/companies/${id}`;
    return this.httpClient.fetch(url, requestOptions);
  }

  getAvailableRoles(id) {
    const requestOptions = {
      method: "GET",
    };
    let url =
      API_BASE_URL + `/registry-service/companies/${id}/available-roles`;
    return this.httpClient.fetch(url, requestOptions);
  }

  getGenericFields(id) {
    const requestOptions = {
      method: "GET",
    };
    let url = API_BASE_URL + `/registry-service/companies/${id}/generic-fields`;
    return this.httpClient.fetch(url, requestOptions);
  }

  createGenericFields(companyId, genericField) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(genericField),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/companies/${companyId}/generic-fields`,
      requestOptions
    );
  }

  deleteGenericFields(companyId, rank) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/registry-service/companies/${companyId}/generic-fields/${rank}`,
      requestOptions
    );
  }
}
