import React, { useEffect, useRef, useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import "../../assets/scss/pages/landing.scss";
import "./utilities";
import LogoIcon1 from "../../assets/images/landingPage/logo-icon-1.svg";
import LogoWhite from "../../assets/images/landingPage/logo-white.svg";
import LogoWriteWhite from "../../assets/images/landingPage/logo-write-white.svg";
import VideoTexture from "../../assets/images/landingPage/video-texture.png";
import Image1 from "../../assets/images/landingPage/image-1.png";
import DoubleArrow from "../../assets/images/landingPage/double-arrow.svg";
import Intermediaries from "../../assets/images/landingPage/intermediaries-icon.svg";
import ParcelSenders from "../../assets/images/landingPage/parcel-sender-icon.svg";
import PrivateParcelSender from "../../assets/images/landingPage/private-parcel-sender-icon.svg";
import ParcelTransport from "../../assets/images/landingPage/parcel-transport-icon.svg";
import Recipient from "../../assets/images/landingPage/recipient-icon.svg";
import Icon1 from "../../assets/images/landingPage/icon-1.svg";
import Icon2 from "../../assets/images/landingPage/icon-2.svg";
import Icon3 from "../../assets/images/landingPage/icon-3.svg";
import Icon4 from "../../assets/images/landingPage/icon-4.svg";
import lottie from "lottie-web";
import logoAnimation from "../../assets/images/landingPage/logo-icon-animation.json";
import writeAnimation from "../../assets/images/landingPage/write-animated.json";
import HowWorksIcon1Animation from "../../assets/images/landingPage/how-works-icon-1.json";
import HowWorksIcon2Animation from "../../assets/images/landingPage/how-works-icon-2.json";
import HowWorksIcon3Animation from "../../assets/images/landingPage/how-works-icon-3.json";
import VideoIntro from "../../assets/video/video-intro.mp4";
import { Link } from "react-router-dom";
import { Footer } from "../../Components/common/Footer";

const LandingPage = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const logoAnimationRef = useRef(null);
  const writeAnimationRef = useRef(null);

  const [counterHowWorks, setCounterHowWorks] = useState(0);
  const howWorksSliderRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [yourProfile, setYourProfile] = useState("");
  const [contactUs, setContactUs] = useState({ profiling: false });

  const handleNextClick = () => {
    const slider = howWorksSliderRef.current;
    const sliderItemsCount = slider.children.length;
    const widthHowWorksItem = slider.offsetWidth;
    let newCounterHowWorks = counterHowWorks + 1;
    if (newCounterHowWorks >= sliderItemsCount) {
      newCounterHowWorks = 0;
    }
    setCounterHowWorks(newCounterHowWorks);
    slider.style.transform = `translateX(-${
      widthHowWorksItem * newCounterHowWorks
    }px)`;
  };

  const onChangeDropdown = (option) => {
    setYourProfile(option);
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (logoAnimationRef.current) {
      lottie.loadAnimation({
        container: logoAnimationRef.current,
        animationData: logoAnimation,
        renderer: "svg", // Tipo di renderer (SVG, canvas, HTML)
        loop: true, // Riproduzione in loop
        autoplay: true, // Avvio automatico dell'animazione
      });
    }
    if (writeAnimationRef.current) {
      lottie.loadAnimation({
        container: writeAnimationRef.current,
        animationData: writeAnimation,
        renderer: "svg", // Tipo di renderer (SVG, canvas, HTML)
        loop: true, // Riproduzione in loop
        autoplay: true, // Avvio automatico dell'animazione
      });
    }
  }, []);

  return (
    <main id="landing-page">
      <img src={LogoIcon1} className="scroller-allabox-icon" />
      <header className="bg-color-1 texture-light">
        <div className="scroller-allabox light"></div>
        <a
          className={`button position-absolute top-0 end-0 m-3 button${
            accessTokenPayload ? "-secondary" : "-primary "
          }`}
          href="/dashboard"
        >
          {accessTokenPayload ? " AREA PERSONALE" : "LOGIN"}
        </a>
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-7 col-xxl-7">
              <div className="navbar-allabox">
                <div className="logo-allabox">
                  <div id="animated-logo" ref={logoAnimationRef}></div>
                  <Link to="/">
                    <img src={LogoWriteWhite} alt="Logo" />
                  </Link>
                </div>
              </div>
              <div className="header-content">
                <h1>
                  The All-in-
                  <div className="lottie-write-container">
                    <span>One</span>
                    <div id="lottie-write" ref={writeAnimationRef}></div>
                  </div>
                  <br />
                  Logistic Platform <br />
                  for Everyone
                </h1>
                <hr className="line-1" />
                <p className="paragraph-1">
                  Embrace the power of simplicity, <br />
                  an all-encompassing solution designed <br />
                  for ease, flexibility, and speed.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-5 col-xl-5 col-xxl-5 p-0">
              <div className="header-video">
                <div className="polygon-1"></div>
                <div className="polygon-2"></div>
                <div className="polygon-3"></div>
                <img src={VideoTexture} className="texture" />
                <video autoPlay loop muted>
                  <source src={VideoIntro} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="welcome bg-color-1">
        <div className="scroller-allabox dark"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-5 col-xxl-5 p-0">
              <div className="img-container">
                <div className="polygon-4 texture-dark"></div>
                <img src={Image1} />
              </div>
            </div>
            <div className="col-12 col-lg-7 col-xl-7 col-xxl-7">
              <div className="welcome-content">
                <h2>
                  Welcome to a new standard in logistics excellence,
                  <br />
                  crafted by those who truly understand the field.
                </h2>
                <div className="paragraph-container">
                  <div className="polygon-5"></div>
                  <p>
                    With over 10 years of dedicated service <br />
                    in logistics and spedition,
                    <br />
                    AllaBox introduces a refined platform <br />
                    that embodies our profound industry knowledge. <br />
                    Seamlessly blending ease of use <br />
                    with unmatched speed and flexibility, <br />
                    AllaBox is your{" "}
                    <span className="color-secondary">
                      go-to solution <br />
                      for simplified shipment processes.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="how-works bg-color-3 texture-dark-2">
        <div className="scroller-allabox light"></div>
        <h2 className="web">How AllaBox Works</h2>
        <div className="how-works-steps-container-web web">
          <div className="polygon-6 texture-dark-2"></div>
          <div className="written-repeat">
            <img src={DoubleArrow} />
            <span>Repeat and Repeat again</span>
            <img src={DoubleArrow} />
          </div>
          <ol>
            <li>
              <div
                data-animation-path={HowWorksIcon1Animation}
                className="lottie-container"
              ></div>
            </li>
            <li>
              <div
                // data-animation-path={HowWorksIcon2}
                className="lottie-container"
              ></div>
            </li>
            <li>
              <div
                // data-animation-path={HowWorksIcon3}
                className="lottie-container"
              ></div>
            </li>
          </ol>
          <div className="steps">
            <div className="step-item">
              <h3>Import your orders</h3>
              <p>
                Easy automatic import your orders from anywhere. <br />
                E-commerce, API, SFTP ad other integrations
              </p>
            </div>
            <div className="step-item">
              <h3>Book the shipment and print the label</h3>
              <p>
                Assemble your package and conveniently attach our label provided
                by AllaBox. Streamlining the process for a hassle-free shipping
                experience.
              </p>
            </div>
            <div className="step-item">
              <h3>Hand over the package to the courier</h3>
              <p>
                Entrust your package to the courier, confident that AllaBox has
                simplified the process from booking to handover, ensuring a
                smooth and efficient shipping journey.
              </p>
            </div>
          </div>
        </div>
        <div className="how-works-steps-container-mobile mobile">
          <div className="my-slider-container">
            <div className="my-slider" ref={howWorksSliderRef}>
              <div className="step">
                <div
                  // data-animation-path={HowWorksIcon1}
                  className="lottie-container"
                ></div>
                <div className="line">
                  <div className="point"></div>
                  <hr />
                </div>
                <div className="content">
                  <h3>Import your orders</h3>
                  <p>
                    Easy automatic import your orders from anywhere. E-commerce,
                    API, SFTP ad other integrations
                  </p>
                </div>
              </div>
              <div className="step">
                <div
                  // data-animation-path={HowWorksIcon2}
                  className="lottie-container"
                ></div>
                <div className="line">
                  <div className="point"></div>
                  <hr />
                </div>
                <div className="content">
                  <h3>Book the shipment and print the label</h3>
                  <p>
                    Assemble your package and conveniently attach our label
                    provided by AllaBox. Streamlining the process for a
                    hassle-free shipping experience.
                  </p>
                </div>
              </div>
              <div className="step">
                <div
                  // data-animation-path={HowWorksIcon3}
                  className="lottie-container"
                ></div>
                <div className="line">
                  <div className="point"></div>
                  <hr />
                </div>
                <div className="content">
                  <h3>Hand over the package to the courier</h3>
                  <p>
                    Entrust your package to the courier, confident that AllaBox
                    has simplified the process from booking to handover,
                    ensuring a smooth and efficient shipping journey.
                  </p>
                </div>
              </div>
              <div className="step">
                <div className="line">
                  <hr />
                  <div className="written-repeat">
                    <img src={DoubleArrow} />
                  </div>
                </div>
                <div className="content">
                  <h3>
                    Repeat <br />
                    and Repeat again
                  </h3>
                </div>
              </div>
            </div>
            <div className="my-slider-controller">
              <div className="prev">
                <img src={DoubleArrow} />
              </div>
              <div className="next">
                <img src={DoubleArrow} onClick={handleNextClick} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="who-are-you">
        <div className="scroller-allabox light"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h2>Who are you?</h2>
              <div className="dropdown-allabox-container">
                <div className="dropdown-allabox">
                  <button
                    className="dropdown-allabox-toggle"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    SELECT YOUR PROFILE
                  </button>

                  <div className="dropdown-allabox-menu" hidden={!dropdownOpen}>
                    <div
                      className="dropdown-allabox-item"
                      onClick={() => onChangeDropdown("parcel-transport")}
                    >
                      <img src={ParcelTransport} />
                      <div className="text-container">
                        <label>Parcel transport</label>
                        <span>Local & global couriers</span>
                      </div>
                    </div>
                    <div
                      className="dropdown-allabox-item"
                      onClick={() => onChangeDropdown("intermediaries")}
                    >
                      <img src={Intermediaries} />
                      <div className="text-container">
                        <label>Intermediaries</label>
                        <span>Logistic companies, Brokers, PUDO owners</span>
                      </div>
                    </div>
                    <div
                      className="dropdown-allabox-item"
                      data-value=""
                      onClick={() => onChangeDropdown("parcel-sender")}
                    >
                      <img src={ParcelSenders} />
                      <div className="text-container">
                        <label>Parcel sender</label>
                        <span>
                          Companies who wants to ship something and monitor it
                        </span>
                      </div>
                    </div>
                    <div
                      className="dropdown-allabox-item"
                      onClick={() => onChangeDropdown("private-parcel-sender")}
                    >
                      <img src={PrivateParcelSender} />
                      <div className="text-container">
                        <label>Private Parcel sender</label>
                        <span>
                          People who wants to ship something and monitor it
                        </span>
                      </div>
                    </div>
                    <div
                      className="dropdown-allabox-item"
                      onClick={() => onChangeDropdown("recipient")}
                    >
                      <img src={Recipient} />
                      <div className="text-container">
                        <label>Recipient</label>
                        <span>
                          People or companies who are waiting for something
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-11 col-xl-11 col-xxl-11 offset-lg-1 offset-xl-1 offset-xxl-1 pe-0">
                  <div className="my-slider-container">
                    <div className="my-slider">
                      <div className="my-slides">
                        <div
                          className="my-slide-wrap"
                          hidden={yourProfile !== "parcel-transport"}
                        >
                          <div className="my-slide">
                            <div className="polygon-7"></div>
                            <div className="icon-container">
                              <img src={Icon1} />
                            </div>
                            <div className="description-container">
                              <label>import/export data</label>
                              <p>
                                Import export data in every format via REST API,
                                Webhook, SFTP
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="my-slide-wrap"
                          hidden={yourProfile !== "parcel-transport"}
                        >
                          <div className="my-slide">
                            <div className="polygon-7"></div>
                            <div className="icon-container">
                              <img src={Icon2} />
                            </div>
                            <div className="description-container">
                              <label>shipment forward</label>
                              <p>
                                Manage your contracts and choose your best
                                solution to forward the outlanded shipments
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="my-slide-wrap"
                          hidden={yourProfile !== "parcel-transport"}
                        >
                          <div className="my-slide">
                            <div className="polygon-7"></div>
                            <div className="icon-container">
                              <img src={Icon3} />
                            </div>
                            <div className="description-container">
                              <label>manage your customer</label>
                              <p>
                                Manage your customers and give the full
                                visibility of their shipments
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="my-slide-wrap"
                          hidden={yourProfile !== "parcel-transport"}
                        >
                          <div className="my-slide">
                            <div className="polygon-7"></div>
                            <div className="icon-container">
                              <img src={Icon4} />
                            </div>
                            <div className="description-container">
                              <label>monitor the activities</label>
                              <p>
                                Monitor all shipments in one place and focus on
                                situations that require your attention
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="allabox-login bg-color-1 texture-light-2">
        <div className="scroller-allabox light"></div>
        <h2>Do you already have an account?</h2>
        <p>Log in to the platform.</p>
        <a
          className={`button button${
            accessTokenPayload ? "-secondary" : "-primary"
          }`}
          href="/dashboard"
          target="_blank"
        >
          {accessTokenPayload ? " AREA PERSONALE" : "LOGIN"}
        </a>
      </section>
      <section className="contact-us bg-color-3">
        <div className="scroller-allabox light"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="form-container">
                <h2>Contact us</h2>
                <p>
                  Do you need more informations? <br />
                  Submit the form below, and we'll be happy to contact you
                </p>
                <form name="contact-us-form" id="contact-us-form">
                  <label className="label-input">Name</label>
                  <input
                    className="my-input"
                    name="name"
                    placeholder="Insert your name"
                    id="name"
                  />
                  <label className="label-input">Email</label>
                  <input
                    className="my-input"
                    name="email"
                    placeholder="Insert your email"
                    id="email"
                  />
                  <label className="label-input">Select your profile</label>
                  <select className="my-select" name="profile" id="profile">
                    <option value="">Select</option>
                    <option value="parcel-transport">Parcel transport</option>
                    <option value="intermediaries">Intermediarie</option>
                    <option value="parcel-sender">Parcel sender</option>
                    <option value="private-parcel-sender">
                      Private parcel sender
                    </option>
                    <option value="recipient">Recipient</option>
                  </select>
                  <label className="label-input">
                    What are you interested in?
                  </label>
                  <select
                    className="my-select"
                    name="motivation"
                    id="motivation"
                  >
                    <option value="">Select</option>
                  </select>
                  <label className="form-check mb-2">
                    <input
                      id="profiling"
                      name="profiling"
                      type="checkbox"
                      className="form-check-input form-check-input-success"
                      value={contactUs.profiling || false}
                      checked={contactUs.profiling}
                      onChange={() =>
                        setContactUs((prev) => {
                          return { ...prev, profiling: !prev.profiling };
                        })
                      }
                    />
                    <span className="form-check-label">
                      Presto il consenso alla profilazione e al trattamento dei
                      dati personali
                    </span>
                  </label>
                  <button
                    className="button button-primary mt-3"
                    id="send-contact-us"
                    disabled={!contactUs.profiling}
                  >
                    SEND
                  </button>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 p-0">
              <div className="final-img"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default LandingPage;
