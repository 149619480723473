import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { WarehousesService } from "../../../services/logistic/warehouses";
import { IncomingGoodsService } from "../../../services/logistic/incomingGoods";
import { incomingGoodNormalizer } from "../utilities";
import { callErrorToast } from "../../../utilities";

const warehousesService = new WarehousesService();
const incomingGoodsService = new IncomingGoodsService();

export const CreateEditIncomingGoodContext = createContext();

const CreateEditIncomingGoodProvider = ({ children, autosave, callback }) => {
  const { id } = useParams();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [incomingGood, setIncomingGood] = useState(null);
  const [incomingGoodError, setIncomingGoodError] = useState(null);
  const [incomingGoodLoader, setIncomingGoodLoader] = useState(false);

  const [warehousesError, setWarehousesError] = useState(null);

  const getWarehouses = () => {
    return warehousesService
      .all()
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        setWarehousesError(err);
      });
  };

  const getIncomingGood = () => {
    setIncomingGoodLoader(true);
    incomingGoodsService
      .get(id)
      .then((res) => {
        setIncomingGood(res.data);
        setIncomingGoodLoader(false);
      })
      .catch((err) => {
        setIncomingGoodError(err);
      });
  };

  const createIncomingGood = () => {
    setIncomingGoodLoader(true);

    const getWarehousesPromise = new Promise((resolve, reject) => {
      resolve(getWarehouses());
    });

    Promise.all([getWarehousesPromise]).then(([warehouses]) => {
      //2 - Viene preso il primo-> se non presente, errore
      const defaultWarehouse = warehouses[0];
      if (!defaultWarehouse) {
        setWarehousesError({
          response: {
            status: 400,
            message:
              "Nessun magazzino trovato. Crearne uno prima di inserire una nuova bolla entrata merce",
          },
        });
        return false;
      }

      const incomingGoodNormalized = incomingGoodNormalizer(
        { ...incomingGood, warehouse: defaultWarehouse },
        accessTokenPayload
      );

      incomingGoodsService
        .create(incomingGoodNormalized)
        .then((res) => {
          setIncomingGood(res.data);
          setIncomingGoodLoader(false);
        })
        .catch((err) => {
          setIncomingGoodError(err);
        });
    });
  };

  const editIncomingGood = (incomingGood) => {
    if (autosave) {
      const incomingGoodNormalized = incomingGoodNormalizer(
        incomingGood,
        accessTokenPayload
      );
      incomingGoodsService
        .edit(incomingGoodNormalized)
        .then((res) => {
          setIncomingGood(incomingGood);
          setIncomingGoodError(null);
        })
        .catch((err) => {
          setIncomingGoodError(err);
        });

      return false;
    }

    setIncomingGood(incomingGood);
  };

  useEffect(() => {
    if (incomingGoodError) {
      callErrorToast(incomingGoodError);
    }
  }, [incomingGoodError]);

  useEffect(() => {
    if (warehousesError) {
      callErrorToast(warehousesError);
    }
  }, [warehousesError]);

  return (
    <CreateEditIncomingGoodContext.Provider
      value={{
        //#region Incoming good
        incomingGood,
        incomingGoodLoader,
        getIncomingGood,
        createIncomingGood,
        editIncomingGood,
        //#endregion

        callback,
        autosave,
      }}
    >
      {children}
    </CreateEditIncomingGoodContext.Provider>
  );
};

const useIncomingGoodContext = () => {
  return useContext(CreateEditIncomingGoodContext);
};

export { CreateEditIncomingGoodProvider, useIncomingGoodContext };
