import React from "react";

export const TextFilter = ({
  filter,
  value,
  onChange,
  placeholder = "",
  isDisabled = false,
}) => {
  return (
    <input
      type="text"
      className="input-box"
      name={filter.fieldName}
      onChange={(e) =>
        onChange(filter.fieldName, e.target.value, filter.preciseSearch)
      }
      value={value}
      placeholder={placeholder}
      disabled={isDisabled}
    />
  );
};
