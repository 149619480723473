import React, { useEffect } from "react";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Doughnut, Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShipmentsContext } from "./providers/Shipments";
import { useAppContext } from "../../AppProvider";
import { dictionary } from "../../utilities/dictionary";
import { stringToColor } from "../../utilities";
import { doughnutCenterTextPlugin, doughnutOptions } from "./utilities";

const ShipmentsByCarrier = () => {
  const context = useShipmentsContext();
  const appContext = useAppContext();
  const language = appContext?.language;

  useEffect(() => {
    context.getStatistics();
  }, []);

  const shipmentSummary =
    context?.kpi?.carrierServices?.reduce((acc, service) => {
      const carrierKey = service.type.carrier.code; // Usa il codice del carrier come chiave

      // Se il vettore è già presente, somma il totale, altrimenti aggiungilo
      if (acc[carrierKey]) {
        acc[carrierKey].total += service.total; // Somma il totale
      } else {
        // Crea un nuovo oggetto per il vettore e include l'oggetto carrier
        acc[carrierKey] = {
          carrier: service.type.carrier,
          total: service.total,
        };
      }

      return acc;
    }, {}) || {};

  // Converti l'oggetto risultato in un array
  const carriers = Object.values(shipmentSummary);

  const data = {
    labels: carriers.map((c) => c.carrier.name),
    datasets: [
      {
        data: carriers.map((c) => c.total),
        backgroundColor: carriers.map((c) => stringToColor(c.carrier.code)),
      },
    ],
  };

  const totalShipments =
    carriers?.reduce((acc, carrier) => acc + carrier.total, 0) || "ND";

  return (
    context && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        {context.kpiLoader ? (
          <FontAwesomeIcon icon={faLoader} spin size="4x" />
        ) : (
          <>
            <label className="label-4 mb-2">
              {dictionary["shipments_by_carrier"][language]}
            </label>
            {data.datasets[0].data.length > 0 ? (
              <>
                <Doughnut
                  data={data}
                  options={doughnutOptions}
                  plugins={[doughnutCenterTextPlugin(totalShipments)]}
                />
              </>
            ) : (
              <label className="label-3 mt-5">
                {dictionary["no_data_show"][language]}
              </label>
            )}
          </>
        )}
      </div>
    )
  );
};

export { ShipmentsByCarrier };
