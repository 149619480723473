import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class OrderDetailsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/logistic-service/order-details`, params),
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/order-details/${id}`,
      requestOptions
    );
  }

  create(orderDetail) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(orderDetail),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/order-details`,
      requestOptions
    );
  }

  edit(orderDetail) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(orderDetail),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/order-details/${orderDetail.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/order-details/${id}`,
      requestOptions
    );
  }
}
