import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import RegistryService from "../../services/registry";
import CompaniesService from "../../services/registry-service/companies";
import useFetch from "../../customHooks/useFetch";

const registryService = new RegistryService();
const companiesService = new CompaniesService();

const GenericFields = ({
  companyId,
  modalToogle,
  refreshTable,
  userOwnerId,
}) => {
  const [genericFields, setGenericFields] = useState([]);

  const getGenericFields = () => {
    companiesService
      .getGenericFields(companyId)
      .then((response) => {
        let newGenericFields = response.map((elem) => {
          return { ...elem, uuid: uuidv4() };
        });
        setGenericFields(newGenericFields);
      })
      .catch((err) => {
        console.log("company", err);
        toast.error("Si è verificato un errore nel caricamento del", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const addGenericField = () => {
    setGenericFields([
      ...genericFields,
      { rank: "", value: "", uuid: uuidv4() },
    ]);
  };

  const removeGenericField = (rank) => {
    // setGenericFields((prevGenericFields) =>
    //   prevGenericFields.filter((elem) => {
    //     return elem.uuid !== id;
    //   })
    // );
    companiesService
      .deleteGenericFields(companyId, rank)
      .then((response) => {
        toast.success("Campo aggiuntivo cancellato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
        getGenericFields();
      })
      .catch((err) => {
        toast.error(
          "Si è verificato un errore nella cancellazione del campo aggiuntivo",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const handleInputChange = (event) => {
    console.log(event);
    let { name, value, id } = event.target;
    const newGenericFields = genericFields.map((elem, index) => {
      if (elem.uuid == id) {
        return { ...elem, [name]: value };
      }
      return { ...elem };
    });
    setGenericFields(newGenericFields);
  };

  const onSubmitGenericFields = () => {
    let isValid = true;
    genericFields.forEach((elem, index) => {
      if (!elem.value || !elem.rank) {
        toast.error("Tutti i campi aggiuntivi devono essere valorizzati", {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
        return false;
      }
    });
    if (isValid) {
      saveGenericFields();
    }
  };

  const saveGenericFields = () => {
    genericFields.forEach((elem, index) => {
      companiesService
        .createGenericFields(companyId, elem)
        .then((response) => {
          if (genericFields.length - 1 === index) {
            refreshTable();
            modalToogle();
          }
        })
        .catch((err) => {
          console.log("company", err);
          toast.error(
            "Si è verificato un errore nel salvataggio del campo aggiuntivo",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    });
  };

  useEffect(() => {
    getGenericFields();
  }, []);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <div className="col text-end">
            <button
              color="light"
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={addGenericField}
            >
              Nuova campo aggiuntivo <i className="ph-plus ms-2"></i>
            </button>
          </div>
        </div>
        <hr />
        {genericFields.length === 0 ? (
          <div className="row mt-3">
            <div className="col">
              <h6 className="text-center">Nessun campo aggiuntivo</h6>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="row">
              <div className="col text-center">
                <label>Rank</label>
              </div>
              <div className="col text-center">
                <label>Testo della label</label>
              </div>
            </div> */}
            {genericFields.map((elem, index) => {
              return (
                <div className="row mt-2" key={index}>
                  <div className="col">
                    <div className="input-group">
                      <input
                        type="text"
                        name="rank"
                        id={elem.uuid}
                        className="form-control"
                        placeholder="Rank"
                        onChange={handleInputChange}
                        value={elem.rank || ""}
                      />
                      <input
                        type="text"
                        name="value"
                        id={elem.uuid}
                        className="form-control"
                        placeholder={`Label campo aggiuntivo`}
                        onChange={handleInputChange}
                        value={elem.value || ""}
                      />
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => removeGenericField(elem.rank)}
                      >
                        <i className="ph-trash" />
                      </button>
                    </div>
                    <div className="input-group"></div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="card-footer d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-success ms-3"
            disabled={genericFields.length === 0}
            onClick={onSubmitGenericFields}
          >
            Salva <i className="ph-paper-plane-tilt ms-2"></i>
          </button>
        </div>
      </form>
      <ToastContainer />
    </React.Fragment>
  );
};

export default GenericFields;
