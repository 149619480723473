import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";

export class ContractService {
  // Initializing important variables
  constructor() {
    this.httpClient = new HttpClient();
  }

  getContracts(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;
    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;
    let url = `${API_BASE_URL}/contract-service/contracts?page=${currentPage}&size=${rows}`;

    if (search) {
      url = url + "&search=" + search;
    }
    if (freeSearch) {
      url = url + "&freeSearch=" + freeSearch;
    }
    if (sort) {
      url = url + "&sort=" + sort;
    }

    return this.httpClient.fetch(url, requestOptions);
  }

  getContract(contractId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}`,
      requestOptions
    );
  }
  createContract(contract) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(contract),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts`,
      requestOptions
    );
  }

  editContract(contractId, contract) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(contract),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}`,
      requestOptions
    );
  }

  editContractState(id, state) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts?state=${state}&id=${id}`,
      requestOptions
    );
  }

  deleteContract(contractId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}`,
      requestOptions
    );
  }

  createContractCredential(contractId, credential) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(credential),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}/credentials`,
      requestOptions
    );
  }

  deleteContractCredential(contractId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}/credentials`,
      requestOptions
    );
  }

  getContractCredentials(contractId) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contracts/${contractId}/credentials`,
      requestOptions
    );
  }
}
