import React, { createContext, useContext, useState } from "react";

import { WarehouseArticlesService } from "../../../services/logistic/warehouseArticles";

const DeleteWarehouseArticleContext = createContext();

const warehouseArticlesService = new WarehouseArticlesService();

const DeleteWarehouseArticleProvider = ({
  children,
  data,
  unmountCallback,
}) => {
  const [warehouseArticles, setWarehouseArticles] = useState(data);
  const [warehouseArticlesError, setWarehouseArticlesError] = useState(null);
  const [warehouseArticlesLoader, setWarehouseArticlesLoader] = useState(false);

  const deleteWarehouseArticles = () => {
    setWarehouseArticlesLoader(true);
    const promises = warehouseArticles.map((elem) => {
      return warehouseArticlesService.delete(elem);
    });
    Promise.all(promises)
      .then(() => {
        setWarehouseArticlesLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        setWarehouseArticlesError(err);
      });
  };

  return (
    <DeleteWarehouseArticleContext.Provider
      value={{
        //#region Warehouse Article s
        warehouseArticles,
        warehouseArticlesError,
        warehouseArticlesLoader,
        deleteWarehouseArticles,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </DeleteWarehouseArticleContext.Provider>
  );
};

const useDeleteWarehouseArticleContext = () => {
  return useContext(DeleteWarehouseArticleContext);
};

export { DeleteWarehouseArticleProvider, useDeleteWarehouseArticleContext };
