import React, { useState, useEffect } from "react";
import { Input, FormFeedback } from "reactstrap";

const inputTypes = [
  "text",
  "password",
  "email",
  "number",
  "radio",
  "date",
  "url",
  "file",
  "color",
];

const InputBox = ({
  value,
  name,
  type = "text",
  placeholder,
  className = "",
  onChange,
  readOnly = false,
  disabled = false,
  error = "",
  min,
  max,
  precision = 2,
  dataId,
}) => {
  //value -> valore effettivo -> pronto per essere inviato al BE
  //inputValue -> valore mostrato nell'input
  const [inputValue, setInputValue] = useState("");

  //Controlla se il numero inserito è interno ad un eventuale range di valori passati
  const checkNumber = (value) => {
    if (min && value < min) {
      value = `${min}`;
    }
    if (max && value > max) {
      value = `${max}`;
    }
    return value;
  };

  const checkString = (value) => {
    if (min && max) {
      value = value.substring(min, max);
    } else if (min && !max) {
      value = value.substring(max);
    } else if (!min && max) {
      value = value.substring(0, max);
    }
    return value;
  };

  const handleInputChange = (event) => {
    let { value } = event.target;
    let regex;
    let valueIsValid = true;

    switch (type) {
      case "positive-number":
        regex = /^(?:\d+(?:[.,]\d*)?|)$/;
        break;
      case "positive-integer-number":
        regex = /^(?:[0-9]\d*|)$/;
        break;
    }

    if (regex) {
      valueIsValid = regex.test(value);
      if (valueIsValid) {
        setInputValue(value);
      }
      return false;
    }

    setInputValue(value);
  };

  const handleInputBlur = (event) => {
    let { value } = event.target;
    switch (type) {
      case "positive-number":
        value = value.replaceAll(",", ".").replace(/[.,]$/, "");
        value = checkNumber(value);
        if (value) {
          value = parseFloat(value).toFixed(precision);
        }
        break;
      case "positive-integer-number":
        value = checkNumber(value);
        break;
      case "text":
        value = checkString(value);
        break;
    }

    //Questo setInputValue ha la funzione di far visualizzare sempre l'ultimo valore digitato dall'utente,
    //anche quando il compontente non si aggiorna perchè non riconosce cambiamenti
    setInputValue(value);
    if (onChange) {
      onChange({ target: { name, value, dataId } });
    }
  };

  useEffect(() => {
    if (type === "positive-number" && value) {
      value = parseFloat(value).toFixed(precision);
    }
    setInputValue(value);
  }, [value]);

  return (
    <React.Fragment>
      <Input
        type={inputTypes.includes(type) ? type : "text"}
        name={name}
        className={`form-control ${className}`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        readOnly={readOnly}
        disabled={disabled}
        invalid={error ? true : false}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </React.Fragment>
  );
};

export default InputBox;
