import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useExportForActivationOrderContext } from "./providers/ExportForActivation";

const ExportForActivation = () => {
  const exportForActivationOrderContext = useExportForActivationOrderContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {exportForActivationOrderContext.orders?.length > 1
              ? "Sei sicuro di voler esportare gli ordini selezionati?"
              : "Sei sicuro di voler esportare il seguente ordine?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (exportForActivationOrderContext.unmountCallback) {
              exportForActivationOrderContext.unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn ms-3"
          onClick={exportForActivationOrderContext.exportOrders}
          disabled={exportForActivationOrderContext.ordersLoader}
        >
          {exportForActivationOrderContext.ordersLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Esporta"
          )}
        </Button>
      </div>
    </form>
  );
};

export default ExportForActivation;
