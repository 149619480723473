import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class CollectionsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/collections/${id}`,
      requestOptions
    );
  }

  create(collection) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(collection),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections`,
      requestOptions
    );
  }

  edit(collection) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(collection),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections/${collection.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}`,
      requestOptions
    );
  }

  book(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}/book`,
      requestOptions
    );
  }

  getShipmentsInCollection(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}/shipments`,
      requestOptions
    );
  }

  createShipmentInCollection(id, shipmentId) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpProxy.fetch(
      API_BASE_URL +
        `/shipment-service/collections/${id}/shipments/${shipmentId}`,
      requestOptions
    );
  }

  deleteShipmentRelations(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}/shipments`,
      requestOptions
    );
  }
}
