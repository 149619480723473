export let dictionary = {
  welcome_back: { en: "Welcome back", it: "Bentornato" },
  overview: { en: "Overview", it: "Panoramica" },
  show: { en: "Show", it: "Mostra" },
  see_all: { en: "See all", it: "Vedi tutti" },
  pages: { en: "Pages", it: "Pagine" },
  page: { en: "Page", it: "Pagina" },
  items: { en: "Items", it: "Elementi" },
  item: { en: "Item", it: "Elemento" },
  selected_items: { en: "Selected items", it: "Elementi selezionati" },
  filter_by: { en: "Filter by", it: "Filtra per" },
  filters: { en: "Filters", it: "Filtri" },
  sort_by: { en: "Sort by", it: "Ordina per" },
  select_all: { en: "Select all", it: "Seleziona tutti" },
  select: { en: "Select", it: "Seleziona" },
  no_data_show: { en: "No data to show", it: "Nessun dato da mostrare" },
  clear: { en: "Clear", it: "Pulisci" },
  export: { en: "Export", it: "Esporta" },
  today: { en: "Today", it: "Oggi" },
  yesterday: { en: "Yesterday", it: "Ieri" },
  current_month: { en: "Current month", it: "Mese corrente" },
  current_quarter: { en: "Current quarter", it: "Quarter corrente" },
  last_month: { en: "Last month", it: "Ultimi 30 giorni" },
  from_the: { en: "From the", it: "Dal" },
  yes: { en: "Yes", it: "Si" },
  no: { en: "No", it: "No" },
  copy: { en: "Copy", it: "Copia" },
  copied: { en: "Copied", it: "Copiato" },
  back: { en: "Back", it: "Indietro" },

  references: { en: "References", it: "Riferimenti" },
  status: { en: "Status", it: "Stato" },
  carrier: { en: "Carrier", it: "Vettore" },
  creation_date: { en: "Creation date", it: "Data di creazione" },
  service: { en: "Service", it: "Servizio" },
  internal_reference: { en: "Internal ref.", it: "Rif. Interno" },
  external_reference: { en: "External ref.", it: "Rif. Esterno" },
  sender_reference: { en: "Sender ref.", it: "Rif. Mittente" },
  carrier_reference: { en: "Carrier ref.", it: "Rif. Vettore" },
  package: { en: "Package", it: "Pacco" },
  parcels: { en: "Parcels", it: "Colli" },
  envelope: { en: "Envelope", it: "Busta" },
  document: { en: "Document", it: "Documento" },
  pallet: { en: "Pallet", it: "Pallet" },
  sender: { en: "Sender", it: "Mittente" },
  receiver: { en: "Receiver", it: "Destinatario" },
  recipient_address: {
    en: "Recipient destinatario",
    it: "Indirizzo destinatario",
  },
  cash_on_delivery: { en: "Cash on delivery", it: "Contrassegno" },
  cod_collected: {
    en: "Cash on delivery collected",
    it: "Contrassegno incassato",
  },
  insurance: { en: "Insurance", it: "Assicurazione" },
  collection: { en: "Collection", it: "Ritiro" },
  tracking: { en: "Tracking", it: "Tracking" },
  print: { en: "Print", it: "Stampa" },
  print_label: { en: "Print label", it: "Stampa etichetta" },
  print_labels: { en: "Print labels", it: "Stampa etichette" },
  print_manifest: { en: "Print manifest", it: "Stampa manifest" },
  book_collection: { en: "Book collection", it: "Prenota ritiro" },
  creation_user: { en: "Creation user", it: "Utente di creazione" },
  collected_date: { en: "Collected date", it: "Data di incasso" },
  liquidated_date: { en: "Liquidated date", it: "Data di liquidazione" },
  delivery_date: { en: "Delivery date", it: "Data di consegna" },
  expected_amount: { en: "Expected amount", it: "Importo previsto" },
  collected_amount: { en: "Collected amount", it: "Importo incassato" },
  delta: { en: "Delta", it: "Delta" },
  note: { en: "Note", it: "Note" },
  day: { en: "Day", it: "Giorno" },
  supplier: { en: "Supplier", it: "Fornitore" },
  customer: { en: "Customer", it: "Cliente" },
  type: { en: "Type", it: "Tipologia" },
  description: { en: "Description", it: "Descrizione" },
  downloads: { en: "Downloads", it: "Downloads" },
  weight: { en: "Weight", it: "Peso" },
  volume: { en: "Volume", it: "Volume" },
  signature_for_acceptance: {
    en: "Signature for acceptance",
    it: "Firma per accettazione",
  },

  //shipments
  my_shipments: { en: "My shipments", it: "Le mie spedizioni" },
  shipments: { en: "Shipments", it: "Spedizioni" },
  shipments_today: { en: "Shipments today", it: "Spedizioni di oggi" },
  shipments_last_30: {
    en: "Shipments last 30 days",
    it: "Spedizioni ultimi 30 gg",
  },
  shipments_to_be_verfied: {
    en: "Shipments to be verified",
    it: "Spedizioni da verificare",
  },
  create_new_shipment: {
    en: "Create new shipment",
    it: "Crea nuova spedizione",
  },
  shipment_price: { en: "Shipment price", it: "Costo spedizione" },
  shipment_type: { en: "Shipment type", it: "Tipologia spedizione" },
  express: { en: "Express", it: "Espressa" },
  express_return: { en: "Express return", it: "Reso" },
  postal: { en: "Postal", it: "Postale" },
  package_type: { en: "Package type", it: "Tipologia collo" },

  //cod collections
  cod_collection: {
    en: "Cash on delivery collection",
    it: "Incasso contrassegno",
  },
  my_cod_collections: {
    en: "My cash on delivery collections",
    it: "I miei incassi contrassegno",
  },
  collection_date: {
    en: "Collection date",
    it: "Data di incasso",
  },
  cod_collection_details: {
    en: "Cash on delivery collection details",
    it: "Dettaglio incasso contrassegno",
  },

  //export
  my_exports: {
    en: "My exports",
    it: "Le mie esportazioni",
  },

  //orders
  my_orders: { en: "My orders", it: "I miei ordini" },

  //collections
  my_collections: { en: "My collections", it: "I miei ritiri" },

  //kpi
  the_numbers: {
    en: "The numbers",
    it: "I numeri",
  },
  the_performances: {
    en: "The performances",
    it: "Le performance",
  },
  shipments_by_status: {
    en: "Shipments by status",
    it: "Spedizioni per stato",
  },
  shipments_by_carrier: {
    en: "Shipments by carrier",
    it: "Spedizioni per vettore",
  },
  average_delivery_time_per_service: {
    en: "Average delivery time per service",
    it: "Tempo medio di consegna per servizio",
  },
  working_days: {
    en: "Working days",
    it: "Giorni lavorativi",
  },

  //status
  draft: { en: "Draft", it: "Bozza" },
  booked: { en: "Booked", it: "Prenotata" },
  confirmed: { en: "Confirmed", it: "Confermata" },
  received: { en: "Received", it: "Ricevuto" },
  cancelled: { en: "Cancelled", it: "Cancellato" },
  processing: { en: "Processing", it: "In lavorazione" },
  shipped: { en: "Shipped", it: "Spedito" },
  available: {
    en: "Available for collection",
    it: "Disponibile per il ritiro",
  },
  in_transit: {
    en: "In trasit",
    it: "In transito",
  },
  out_of_delivery: {
    en: "Out of delivery",
    it: "In consegna",
  },
  delivery_exception: {
    en: "Problems with delivery",
    it: "Problema con la consegna",
  },
  failed_delivery: {
    en: "Failed delivery",
    it: "Tentativo di consegna fallito",
  },
  delivered: {
    en: "Delivered",
    it: "Consegnata",
  },
  info_rcvd: {
    en: "Info received",
    it: "Informazioni ricevute",
  },
  ret_sender: {
    en: "Return to sender",
    it: "Reso al mittente",
  },
  error: { en: "Error", it: "In errore" },

  //actions
  create: { en: "Create", it: "Crea" },
  edit: { en: "Edit", it: "Modifica" },
  details: { en: "Details", it: "Dettaglio" },
  clone: { en: "Clone", it: "Duplica" },
  confirm: { en: "Confirm", it: "Conferma" },
  download: { en: "Download", it: "Scarica" },
  expand: { en: "Expand", it: "Espandi" },
  reduce: { en: "Reduce", it: "Riduci" },
  duplicate: { en: "Duplicate", it: "Duplica" },
  delete: { en: "Delete", it: "Elimina" },
  delete_selected: { en: "Delete selected", it: "Elimina selezionati" },
  cancel: { en: "Cancel", it: "Annulla" },
  text_copied_correctly: {
    en: "Text copied correctly",
    it: "Testo copiato correttamente",
  },

  //modals
  irreversible_action: {
    en: "This action is irreversible.",
    it: "Questa azione è irreversibile.",
  },
  confirm_deletion: { en: "Confirm deletion", it: "Conferma eliminazione" },
  confirm_download: { en: "Confirm download", it: "Conferma download" },
  confirm_shipment: {
    en: "Confirm shipment",
    it: "Conferma spedizione",
  },
  booking_confirmation: {
    en: "Booking confirmation",
    it: "Conferma prenotazione",
  },
  ask_confirm_deletion: {
    en: "Are you sure you want to delete",
    it: "Confermi di voler eliminare",
  },
  ask_download_shipment_label: {
    en: "By clicking on the download button you will download the selected shipping label.",
    it: "Cliccando sul pulsante download scaricherai l'etichetta della spedizione selezionata.",
  },
  ask_download_shipment_labels: {
    en: 'By clicking on the download button you will download the labels of the shipments in "booked" and "confirmed" status selected.',
    it: 'Cliccando sul pulsante download scaricherai le etichette delle spedizioni in stato "prenotato" e "confermato" selezionate.',
  },
  ask_confirm_shipment: {
    en: "Are you sure you want to confirm the selected shipment?",
    it: "Sei sicuro di voler confermare la spedizione selezionata?",
  },
  ask_confirm_shipments: {
    en: "Are you sure you want to confirm the selected shipments?",
    it: "Sei sicuro di voler confermare le spedizioni selezionate?",
  },
  //errors
  nullfield: { en: "Required field", it: "Campo obbligatorio" },
  unequalValues: {
    en: "The values ​​do not match",
    it: "I valori non coincidono",
  },
};
