import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";
import ParcelTemplatesService from "../../services/shipment-service/parcelTemplates";

const parcelTemplatesService = new ParcelTemplatesService();

const Delete = ({ parcelTemplateId, modalToogle, refreshTable }) => {
  const deleteParcelTemplate = () => {
    parcelTemplatesService
      .delete(parcelTemplateId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success("Template collo cancellato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nella cancellazione del template collo",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>
            Sei sicuro di voler eliminare il seguente template collo?
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            refreshTable();
            modalToogle();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteParcelTemplate}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
