import React, { useState } from "react";
import { Button } from "reactstrap";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";
import InputBox from "../../Components/common/InputBox";
import { checkErrors, valueIsEmpty } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";
import { Loader } from "../../Components/common/Loader";

const usersService = new UsersService();

const ResetPassword = ({ userId, modalToogle, refreshTable }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const resetPassword = () => {
    usersService
      .resetPassword(userId, { password })
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success("Password resettata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });

        setLoading(false);
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 404:
            message = "404 - Utente non trovato";
            break;
          case 409:
            message = "409 - Utente già registrato";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const passwordValidation = () => {
    let errors = {};

    if (valueIsEmpty(password))
      errors["password"] = dictionary["nullfield"]["it"];
    if (valueIsEmpty(confirmPassword))
      errors["confirmPassword"] = dictionary["nullfield"]["it"];
    if (
      !valueIsEmpty(password) &&
      !valueIsEmpty(confirmPassword) &&
      password !== confirmPassword
    )
      errors["confirmPassword"] = dictionary["unequalValues"]["it"];

    return errors;
  };

  const savePassword = () => {
    const errors = passwordValidation();
    if (valueIsEmpty(errors)) {
      setLoading(true);
      resetPassword();
    }

    setErrors(errors);
  };

  return (
    <React.Fragment>
      {loading ? <Loader show={loading} /> : ""}

      <div className="row">
        <div className="col">
          <label className="form-label">Password:</label>
          <InputBox
            name="password"
            value={password}
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
              checkErrors(event.target.name, errors, setErrors);
            }}
            error={errors && errors["password"]}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">Conferma password:</label>
          <InputBox
            name="confirmPassword"
            value={confirmPassword}
            type="password"
            onChange={(event) => {
              setConfirmPassword(event.target.value);
              checkErrors(event.target.name, errors, setErrors);
            }}
            error={errors && errors["confirmPassword"]}
          />
        </div>
      </div>
      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                modalToogle();
                refreshTable();
              }}
            >
              Annulla
            </button>
            <Button type="button" className="btn ms-3" onClick={savePassword}>
              Salva
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResetPassword;
