import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class CarriersService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/registry-service/carriers`, params),
      requestOptions
    );
  }

  get(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/carriers/${code}`,
      requestOptions
    );
  }

  getCashOnDeliveryCollectionMethods(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/carriers/${code}/cash-on-delivery-collection-methods`,
      requestOptions
    );
  }

  getCredentialLabels(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/carriers/${code}/credential-labels`,
      requestOptions
    );
  }
}
