import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ShipmentsService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
    this.httpProxy = new HttpInterface();
  }

  getShipment(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}`,
        requestOptions
    );
  }

  create(shipment) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(shipment),
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments`,
        requestOptions
    );
  }

  edit(shipment) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(shipment),
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipment.id}`,
        requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}`,
        requestOptions
    );
  }

  confirm(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}/confirm`,
        requestOptions
    );
  }

  rateConfirm(id, rate) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(rate),
    };

    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}/rate-confirm`,
        requestOptions
    );
  }

  book(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}/book`,
        requestOptions
    );
  }

  getGenericFields(shipmentId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/generic-fields`,
        requestOptions
    );
  }

  createGenericFields(shipmentId, genericFields) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(genericFields),
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/generic-fields`,
        requestOptions
    );
  }

  deleteGenericFields(shipmentId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/generic-fields`,
        requestOptions
    );
  }

  deleteParcels(shipmentId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/parcels`,
        requestOptions
    );
  }

  getShipmentEnums(type) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/enums/${type}`,
        requestOptions
    );
  }

  getOptions(shipmentId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/options`,
        requestOptions
    );
  }

  getRate(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}/rate`,
        requestOptions
    );
  }

  getRateSimplified(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${id}/rate-simplified`,
        requestOptions
    );
  }

  createOptions(options, shipmentId) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(options),
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/options`,
        requestOptions
    );
  }

  deleteOptions(shipmentId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
        API_BASE_URL + `/shipment-service/shipments/${shipmentId}/options`,
        requestOptions
    );
  }

  getPublicTracking(shipmentReference, senderReference) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpProxy.fetch(
      API_BASE_URL +
        `/shipment-service/shipments/public/tracking/${
          senderReference
            ? `${senderReference}/${shipmentReference}`
            : shipmentReference
        }`,
      requestOptions
    );
  }

  getPublicTrackingLatestEvent(shipmentReference, senderReference) {
    const requestOptions = {
      method: "GET",
    };

    return this.httpProxy.fetch(
      API_BASE_URL +
        `/shipment-service/shipments/public/tracking/latest/${
          senderReference
            ? `${senderReference}/${shipmentReference}`
            : shipmentReference
        }`,
      requestOptions
    );
  }
}