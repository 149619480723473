import { API_BASE_URL } from "../../constants/index";
import HttpInterface from "../../helpers/HttpInterface";

export class LocationsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  create(location) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(location),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/locations`,
      requestOptions
    );
  }

  edit(location) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(location),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/locations/${location.id}`,
      requestOptions
    );
  }
}
