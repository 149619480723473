export const warehouseArticleNormalizer = (warehouseArticle, warehouseId) => {
  warehouseArticle.articleId = warehouseArticle.article?.id;
  warehouseArticle.articleId = warehouseArticle.article?.id;
  warehouseArticle.warehouseId = warehouseId;
  return warehouseArticle;
};

// //#region Index columns
export const columns = [
  {
    position: 1,
    Header: "Nome",
    accessor: "article.name",
  },
  {
    position: 3,
    Header: "Rif.Logistica",
    accessor: "logisticReference",
  },
  {
    position: 4,
    Header: "Qtà effettiva in entrata",
    accessor: "effectiveIncomingQuantity",
  },
  {
    position: 5,
    Header: "Qtà effettiva in magazzino",
    accessor: "effectiveStockQuantity",
  },
  {
    position: 6,
    Header: "Qtà bloccata",
    accessor: "blockedQuantity",
  },
  {
    position: 6,
    Header: "Qtà effettiva impegnata",
    accessor: "effectiveCommittedQuantity",
  },
  {
    position: 7,
    Header: "Qtà effettiva disponibile",
    accessor: "effectiveAvailableQuantity",
  },
];
// //#endregion
