import moment from "moment";
import { getUniqueSelectors } from "../../../utilities";
import { useState } from "react";

const getFiltersFromlocalStorage = (localStorageName, preserve) => {
  //Priorità ai filtri della pagina, se non ci sono allora quelli salvati della tabella
  const filters =
    JSON.parse(localStorage.getItem("page_filters")) ||
    JSON.parse(localStorage.getItem(localStorageName + "_filters")) ||
    {};

  for (let f in filters) {
    //Se il valore del filtro è un array e gli elementi non sono date valide
    if (
      Array.isArray(filters[f]) &&
      filters[f].every(
        (item) =>
          typeof item === "string" &&
          !moment(item, moment.ISO_8601, true).isValid()
      )
    ) {
      filters[f] = filters[f].map((x) => {
        return { value: x };
      });
    }
  }

  if (!preserve) {
    localStorage.removeItem(localStorageName + "_filters");
  }
  localStorage.removeItem("page_filters");

  return filters;
};

// Hook personalizzato per gestire query string e valori di default dei filtri
export const useDefaultValues = (filters, defaultDate) => {
  //Aggiungo a filters anche la data di default della tabella nella actions bar
  //useMemo non permette renderizzazioni inutili di filters
  const filtersList = defaultDate?.enabled
    ? [...filters.list, defaultDate]
    : filters.list;

  //Valori definiti nel local storage
  const localStorageFilters = getFiltersFromlocalStorage(
    filters.name,
    filters.preserve
  );

  // Valori di default definiti nei filtri
  const defaultFilterValues = filtersList.reduce((acc, filter) => {
    if (filter.value) {
      acc[filter.fieldName] = filter.value;
    }
    return acc;
  }, {});

  // Merge dei valori: i valori della query string sovrascrivono i valori di default
  const initialFilters = { ...defaultFilterValues, ...localStorageFilters };

  // Inizializzazione dei parametri di default
  const initialParams = Object.keys(initialFilters).reduce((acc, fieldName) => {
    return getUniqueSelectors(fieldName, initialFilters[fieldName], false, acc);
  }, []);

  // Stato per i parametri e filtri di default
  const [defaultParams, setDefaultParams] = useState(initialParams);
  const [defaultFilters, setDefaultFilters] = useState(initialFilters);

  // Funzione per resettare i valori di default
  const cleanDefaults = () => {
    setDefaultParams([]);
    setDefaultFilters({});
  };

  return { defaultParams, defaultFilters, cleanDefaults };
};
