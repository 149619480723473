import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import moment from "moment";

import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";

const Exports = () => {
  const listRef = useRef(null);
  const listProps = useListProps();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>
    </React.Fragment>
  );
};

export default Exports;

const ListItem = React.memo(({ item, index }) => {
  const appContext = useAppContext();
  const language = appContext.language;
  console.log(item);
  return (
    <React.Fragment key={index}>
      <div
        className="list-item d-grid align-items-center px-2"
        style={{
          gridAutoFlow: "column",
          gridTemplateColumns: `1fr 1.5fr 1fr 2fr 0.5fr 0.25fr`,
          gap: "0.5rem",
        }}
      >
        <div>
          <label className="label-2">
            {dictionary["creation_date"][language]}:
          </label>
          <label className="label-3">
            {moment(item.creationDate).format("DD/MM/YYYY HH:mm")}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["creation_user"][language]}:
          </label>

          <label className={`label-3 `}>{item.creationUser || "-"}</label>
        </div>
        <div>
          <label className="label-2">{dictionary["type"][language]}:</label>
          <label className={`label-3`}>{item.type || "-"}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["description"][language]}:
          </label>
          <label className={`label-3`}>{item.description || "-"}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["downloads"][language]}:
          </label>
          <label className={`label-3`}>{item.downloads}</label>
        </div>
        <div className="text-end">
          <Link
            // target="_blank"
            className="button-1 button-1-light button-1-icon"
            to={item.url}
            title={dictionary["details"][language]}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
});
