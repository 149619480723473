import { valueIsEmpty } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";

export const validation = (addressBook) => {
  let errors = {};
  var { name, phone, email, location } = addressBook;

  if (valueIsEmpty(name)) errors["name"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(phone)) errors["phone"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(email)) errors["email"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(location.longitude))
    errors["location"] = dictionary["nullfield"]["it"];

  return errors;
};
