import React, { useEffect } from "react";
import withRouter from "../Components/common/withRouter";
import HeaderTopNotAuth from "./VerticalLayouts/HeaderTopNotAuth";

const NonAuthLayout = ({ header, children }) => {
  return React.Children.map(children, (child) => {
    return (
      <React.Fragment>
        {header && <HeaderTopNotAuth />}
        {React.cloneElement(child)}
        {/* <Footer /> */}
      </React.Fragment>
    );
  });
};
export default withRouter(NonAuthLayout);
