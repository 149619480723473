import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useWarehouseContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Delete = () => {
  const warehouseContext = useWarehouseContext();

  useEffect(() => {
    if (warehouseContext?.warehousesError) {
      callErrorToast(warehouseContext?.warehousesError);
    }
  }, [warehouseContext?.warehousesError]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {warehouseContext?.warehouses?.length > 1
              ? "Sei sicuro di voler eliminare i magazzini selezionati?"
              : "Sei sicuro di voler eliminare il seguente magazzino?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (warehouseContext?.unmountCallback) {
              warehouseContext?.unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={warehouseContext?.deleteWarehouses}
          disabled={warehouseContext?.warehousesLoader}
        >
          {warehouseContext?.warehousesLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Elimina"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Delete;
