import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ArticleImagesService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/logistic-service/article-images`, params),
      requestOptions
    );
  }

  create(articleImage) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(articleImage),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/article-images`,
      requestOptions
    );
  }

  edit(articleImage) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(articleImage),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/article-images/${articleImage.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/article-images/${id}`,
      requestOptions
    );
  }
}
