import React, { useState, useEffect } from "react";
import InputBox from "../../Components/common/InputBox";

const GenericFields = ({ data, edit }) => {
  const [genericFields, setGenericFields] = useState(data);

  const handleInputChange = (event) => {
    let { name, value, type } = event.target;
    if (type === "number" && value < 0) {
      value = "";
    }
    const newGenericFields = { ...genericFields };
    newGenericFields[name] = value;
    edit(newGenericFields);
  };

  useEffect(() => {
    setGenericFields(data);
  }, [data]);

  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">Riferimento esterno:</label>
          <InputBox
            type="text"
            name="externalReference"
            className="form-control"
            placeholder=""
            value={genericFields.externalReference || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Contenuto:</label>
          <InputBox
            type="text"
            name="content"
            className="form-control"
            placeholder=""
            value={genericFields.content || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Note:</label>
          <InputBox
            type="text"
            name="note"
            className="form-control"
            placeholder=""
            value={genericFields.note || ""}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GenericFields;
