import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class ContractServicesService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;
    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;
    let url = `${API_BASE_URL}/contract-service/contract-services?page=${currentPage}&size=${rows}`;

    if (search) {
      url = url + "&search=" + search;
    }
    if (freeSearch) {
      url = url + "&freeSearch=" + freeSearch;
    }
    if (sort) {
      url = url + "&sort=" + sort;
    }

    return this.httpClient.fetch(url, requestOptions);
  }

  getContractService(contractServiceId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contract-services/${contractServiceId}`,
      requestOptions
    );
  }

  createContractService(service) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(service),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contract-services`,
      requestOptions
    );
  }

  editContractService(service) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(service),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contract-services/${service.id}`,
      requestOptions
    );
  }

  editContractServiceState(id, state) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services?state=${state}&id=${id}`,
      requestOptions
    );
  }

  deleteContractService(contractServiceId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/contract-services/${contractServiceId}`,
      requestOptions
    );
  }

  getCompaniesInContractService(contractServiceId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/companies`,
      requestOptions
    );
  }

  createCompaniesInContractService(contractServiceId, companyId) {
    const requestOptions = {
      method: "POST",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/companies/${companyId}`,
      requestOptions
    );
  }

  deleteCompaniesInContractService(contractServiceId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/companies`,
      requestOptions
    );
  }

  getRelatableContractServices(params) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services?search=%28%28contract.ownerId%3D%3D${
          params.ownerId
        }%3Bcontract.type%3D%3DPASSIVE%29%2C%28contract.ownerId%21%3D${
          params.ownerId
        }%3Bcontract.type%3D%3DACTIVE%29%29%3BparcelTypeCode%3D%3D${
          params.parcelType.code
        }%3Bcontract.carrierCode%3D%3D${
          params.carrierCode
        }%3Bcontract.shipmentType%3D%3D${
          params.shipmentType
        }&page=${0}&size=${100}`,
      requestOptions
    );
  }

  getSourceServiceRelations(contractServiceId) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/service-relations/sources`,
      requestOptions
    );
  }

  createSourceServiceRelation(contractServiceId, sourceId) {
    const requestOptions = {
      method: "POST",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/service-relations/${sourceId}`,
      requestOptions
    );
  }

  deleteSourceServiceRelations(contractServiceId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/service-relations/sources`,
      requestOptions
    );
  }

  deleteServiceRuleGroups(contractServiceId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/service-rule-groups`,
      requestOptions
    );
  }

  deleteOptionsAndSurchages(contractServiceId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/contract-service/contract-services/${contractServiceId}/service-surcharges`,
      requestOptions
    );
  }
}
