import React, { useState, useEffect } from "react";
import { useListContext } from "./Providers/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowLeft,
  faLongArrowRight,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const getWindow = (current, total) => {
  let start, end;

  // Se totalPages è minore di 9, mostra semplicemente tutte le pagine
  if (total <= 9) {
    start = 1;
    end = total;
  }
  // Caso centrale: mantieni il currentNumber al centro
  else if (current > 4 && current <= total - 4) {
    start = current - 4;
    end = current + 4;
  }
  // Se il currentNumber è tra 1 e 4: prendi i primi 9 numeri
  else if (current <= 4) {
    start = 1;
    end = 9;
  }
  // Se il currentNumber è tra 14 e 17: prendi gli ultimi 9 numeri
  else {
    start = total - 8;
    end = total;
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const Pagination = () => {
  const context = useListContext();

  const [isFirstPage, setIsFirstPage] = useState(null);
  const [isLastPage, setIsLastPage] = useState(null);

  const gotoPage = (page) => {
    context.handlePagination({ page });
  };

  const previousPage = () => {
    context.handlePagination({ page: context.data.number - 1 });
  };

  const nextPage = () => {
    context.handlePagination({ page: context.data.number + 1 });
  };

  useEffect(() => {
    setIsFirstPage(context.data.first);
    setIsLastPage(context.data.last);
  }, [context.data]);

  const numbersInWindow = getWindow(
    context.data.number,
    context.data.totalPages
  );

  return (
    <>
      {context.data?.content && context.data?.content.length > 0 && (
        <div className="pagination">
          <FontAwesomeIcon
            icon={faLongArrowLeft}
            className={`${!isFirstPage ? "cursor-pointer" : ""}`}
            onClick={() => !isFirstPage && previousPage()}
          />
          {numbersInWindow.map((item, index) => {
            return (
              <span
                key={index}
                className={`number ${
                  item - 1 === context.data.number ? "active" : ""
                }`}
                onClick={() =>
                  context.data.number + 1 !== item ? gotoPage(item - 1) : ""
                }
              >
                {item}
              </span>
            );
          })}
          <FontAwesomeIcon
            icon={faLongArrowRight}
            className={`${!isLastPage ? "cursor-pointer" : ""}`}
            onClick={() => !isLastPage && nextPage()}
          />
        </div>
      )}
    </>
  );
};
