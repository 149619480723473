import React from "react";
import LogoWhite from "../../assets/images/landingPage/logo-white.svg";
import moment from "moment";
import termsAndConditions from "../../assets/documents/termsAndConditions.pdf";

export const Footer = () => {
  return (
    <footer>
      <div className="informations-container">
        <img src={LogoWhite} />
        <label>P.IVA 13091980014 | REA. TO-1338507 </label>
      </div>
      <div className="legals-informations-container">
        <label>© {moment().year()} AllaBox srl. All Rights Reserved.</label>{" "}
        <a
          href="https://www.iubenda.com/privacy-policy/89207242"
          className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe me-1"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/89207242/cookie-policy"
          className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe me-1 "
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
        <a href={termsAndConditions} target="_blank">
          Termini e condizioni
        </a>
      </div>
    </footer>
  );
};
