import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ContractsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/contract-service/contracts`, params),
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/contract-service/contracts/${id}`,
      requestOptions
    );
  }

  create(contract) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(contract),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/contract-service/contracts`,
      requestOptions
    );
  }

  edit(contract) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(contract),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/contract-service/contracts/${contract.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/contract-service/contracts/${id}`,
      requestOptions
    );
  }

  editState(id, state) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/contract-service/contracts?state=${state}&id=${id}`,
      requestOptions
    );
  }
}
