import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class SurchargesService {
  constructor(domain) {
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;

    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;

    let url = `${API_BASE_URL}/contract-service/service-surcharges?`;
    url = createQueryString(url, { search, freeSearch, sort });
    url += `&page=${currentPage}&size=${rows}`;
    return this.httpClient.fetch(url, requestOptions);
  }

  create(optionOrSurcharge) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(optionOrSurcharge),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/service-surcharges`,
      requestOptions
    );
  }
}
