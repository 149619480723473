import React, { useState, useEffect } from "react";
import Select from "react-select";
import classnames from "classnames";
import { Collapse, Card, CardHeader, CardBody, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { currenciesList, customDataCategories } from "../../constants/index";
import { orderBykey } from "../../utilities";
import CustomDataItem, { defaultCustomData } from "./customDataItem";
import { v4 as uuidv4 } from "uuid";

const CustomData = ({ list, edit, errors = [] }) => {
  const [customData, setCustomData] = useState([]);
  const [category, setCategory] = useState("");
  const [unitPriceCurrency, setCurrency] = useState("");
  const [collapse, setCollapse] = useState(false);

  const addCustomDataItem = () => {
    const newCustomData = {
      ...defaultCustomData,
      uuid: uuidv4(),
      category: category,
      unitPriceCurrency: unitPriceCurrency,
    };
    const newCustomDataList = [...customData, newCustomData];
    edit(newCustomDataList);
    
  };

  const editCustomDataItem = (customDataItem) => {
    const newList = customData.map((elem, index) => {
      if (elem.uuid === customDataItem.uuid) {
        return { ...customDataItem };
      }
      return {
        ...elem,
        category: category,
        unitPriceCurrency: unitPriceCurrency,
      };
    });
    edit(newList);
  };

  const editCustomDataCommonField = (field, option) => {
    console.log("editCustomDataCommonField", field, option)
    if (field === "category") {
      setCategory(option || null);
      const newCustomData = customData.map((elem, index) => {
        return {
          ...elem,
          category: option,
        };
      });
      setCustomData(newCustomData);
    }
    if (field === "unitPriceCurrency") {
      setCurrency(option || null);
      const newCustomData = customData.map((elem, index) => {
        return {
          ...elem,
          unitPriceCurrency: option,
        };
      });
      setCustomData(newCustomData);
    }


  };

  const deleteCustomDataItem = (customDataItem) => {
    const newList = customData.filter((elem) => {
      return elem.uuid !== customDataItem.uuid;
    });
    console.log(newList);
    edit([...newList]);
  };

  // useEffect(() => {
  //   edit(customData);
  // }, [customData]);

  useEffect(() => {
    if (list.length > 0) {
      console.log("useEffect", list)
      setCustomData(list);
      setCollapse(true);
      setCurrency(
        currenciesList.find(
          (elem) => elem.code === (list[0].unitPriceCurrencyCode || list[0].unitPriceCurrency?.code)
        ) || ""
      );
      setCategory(
        customDataCategories.find((elem) => elem.code === (list[0].categoryCode || list[0].category?.code)) ||
          ""
      );
    }
  }, [list]);

  const accordionHeaderClass =
    errors && errors.length && !collapse
      ? "border border-danger accordion-header"
      : "accordion-header";
  const err = errors?.[0]?.innerErrors?.[0]?.errors;
  const categoryError = err?.find((x) => x.field === "category")?.value;
  const unitPriceCurrencyError = err?.find((x) => x.field === "unitPriceCurrency")?.value;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <Card>
            <div className="accordion" id="accordion">
              <h5 className={accordionHeaderClass} id="headingOne">
                <Link
                  to="#"
                  color="primary"
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !collapse,
                  })}
                  type="button"
                  onClick={() => setCollapse(!collapse)}
                  style={{ cursor: "pointer", fontSize: "1.125rem" }}
                >
                  Dati doganali
                </Link>
              </h5>

              <Collapse isOpen={collapse} className="accordion-collapse">
                <div className="accordion-body">
                  <div className="row justify-content-start">
                    <div className="col">
                      <Select
                        name="category"
                        onChange={(option) =>
                          editCustomDataCommonField("category", option || null)
                        }
                        defaultMenuIsOpen={false}
                        className={`${
                          unitPriceCurrencyError ? "is-invalid" : ""
                        } select`}
                        options={orderBykey(customDataCategories, "name")}
                        value={category}
                        placeholder="Seleziona categoria"
                        getOptionValue={(option) => option["code"]}
                        getOptionLabel={(option) => option["name"]}
                        invalid={!!categoryError}
                      />
                      {categoryError && (
                        <FormFeedback type="invalid">
                          {categoryError}
                        </FormFeedback>
                      )}
                    </div>
                    <div className="col">
                      <Select
                        id={"unitPriceCurrency"}
                        name="unitPriceCurrency"
                        onChange={(option) =>
                          editCustomDataCommonField("unitPriceCurrency", option || null)
                        }
                        defaultMenuIsOpen={false}
                        className={`${
                          unitPriceCurrencyError ? "is-invalid" : ""
                        } select`}
                        options={orderBykey(currenciesList, "name")}
                        value={unitPriceCurrency}
                        placeholder="Seleziona valuta"
                        getOptionValue={(option) => option["code"]}
                        getOptionLabel={(option) => option["name"]}
                        invalid={!!unitPriceCurrencyError}
                      />
                      {unitPriceCurrencyError && (
                        <FormFeedback type="invalid">
                          {unitPriceCurrencyError}
                        </FormFeedback>
                      )}
                    </div>
                    <div className="col-2">
                      <button
                        color="light"
                        type="button"
                        className="btn btn-outline-success btn-sm w-100"
                        onClick={addCustomDataItem}
                      >
                        Aggiungi dato <i className="ph-plus ms-2"></i>
                      </button>
                    </div>
                  </div>
                  <hr />
                  {customData.length > 0 ? (
                    customData.map((elem, index) => {
                      return (
                        <CustomDataItem
                          key={index}
                          data={elem}
                          remove={deleteCustomDataItem}
                          edit={editCustomDataItem}
                          errors={errors[0]?.innerErrors?.filter((err) => {
                            return err.uuid === elem.uuid;
                          })}
                        />
                      );
                    })
                  ) : (
                    <div className="row row-cols-1">
                      <div className="col text-center">
                        <label>Nessun dato doganale</label>
                      </div>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomData;
