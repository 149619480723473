import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";
import Select from "react-select";
import { FormFeedback } from "reactstrap";
import { dictionary } from "../../../utilities/dictionary";
import { useAppContext } from "../../../AppProvider";

const AsyncSelect = ({
  name,
  value,
  optionStyle,
  onSearch,
  loadOptions,
  isMulti = false,
  error = "",
  optionValue = "value",
  optionLabel = "label",
  searchKey,
  isClearable = true,
  placeholder = "Select",
  isDisabled = false,
  closeMenuOnSelect = true,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    isFirst: false,
    isLast: false,
  });
  const appContext = useAppContext();
  const language = appContext.language;

  const debounced = useRef(
    debounce(async (value, pagination, callback) => {
      callback(value, pagination).then((response) => {
        const resultArray = response.data
          ? Array.isArray(response.data)
            ? response.data
            : response.data.content || []
          : [];
        const result =
          resultArray?.map((elem) => {
            return {
              ...elem,
              value: elem.id || elem.code,
              label: elem.name,
            };
          }) || [];
        setOptions(result);
        setSearchValue(value);
        setPagination({
          ...pagination,
          isFirst: response.data?.last !== false ? true : false,
          isLast: response.data?.last !== false ? true : false,
        });
        setLoading(false);
      });
    }, 300)
  ).current;

  return (
    <div style={{ width: "100%" }}>
      <Select
        maxMenuHeight={200}
        isMulti={isMulti}
        options={options}
        closeMenuOnSelect={closeMenuOnSelect}
        noOptionsMessage={() => (
          <span>{dictionary["no_data_show"][language]}</span>
        )}
        getOptionValue={(option) => option[optionValue]}
        getOptionLabel={(option) => option[optionLabel]}
        formatOptionLabel={(option) =>
          optionStyle
            ? optionStyle(option, optionValue, optionLabel)
            : option[optionLabel]
        }
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={(value) => {
          onSearch(name, value, true);
        }}
        onInputChange={(inputValue, event) => {
          setLoading(true);
          if (event.action === "input-change") {
            debounced(
              inputValue,
              {
                ...pagination,
                page: 0,
              },
              loadOptions
            );
          }
        }}
        filterOption={() => true}
        onMenuOpen={() => {
          setLoading(true);
          loadOptions("", pagination).then((response) => {
            let result = [];
            if (response) {
              const resultArray = response?.data
                ? Array.isArray(response.data)
                  ? response.data
                  : response.data.content || []
                : [];
              result =
                resultArray?.map((elem) => {
                  return {
                    ...elem,
                    value: elem[searchKey] || elem.id || elem.code,
                    label: elem.name,
                  };
                }) || [];

              setPagination({
                ...pagination,
                isFirst: response.data?.last !== false ? true : false,
                isLast: response.data?.last !== false ? true : false,
              });
            }

            setOptions(result);
            setLoading(false);
          });
        }}
        onMenuClose={() => {
          setPagination({
            page: 0,
            size: 10,
            isFirst: false,
            isLast: false,
          });
          setOptions([]);
          setSearchValue("");
          setLoading(false);
        }}
        onMenuScrollToBottom={() => {
          if (!pagination.isLast) {
            setLoading(true);
            const newPagination = {
              ...pagination,
              page: pagination.page + 1,
            };

            loadOptions(searchValue, newPagination).then((response) => {
              const resultArray = response.data
                ? Array.isArray(response.data)
                  ? response.data
                  : response.data.content || []
                : [];
              const result =
                resultArray?.map((elem) => {
                  return {
                    ...elem,
                    value: elem[searchKey] || elem.id || elem.code,
                    label: elem.name,
                  };
                }) || [];
              setOptions([...options, ...result]);
              setPagination({
                ...newPagination,
                isFirst: response.data?.last !== false ? true : false,
                isLast: response.data?.last !== false ? true : false,
              });
              setLoading(false);
            });
          }
        }}
        isLoading={loading}
        className={`${error && "is-invalid"}`}
        value={value}
        isDisabled={isDisabled}
        styles={{
          container: (provided) => ({
            ...provided,
            display: "inline-grid",
            width: "100%",
          }),
          control: (provided, state) => ({
            ...provided,
            width: "100%", // Mantieni il controllo al 100% del contenitore
            minWidth: 0, // Rimuovi qualsiasi larghezza minima
            overflow: "hidden", // Nascondi contenuto in eccesso
            whiteSpace: "nowrap", // Impedisci ritorno a capo
            outline: "none", // Rimuove l'outline blu di default
            boxShadow: state.isFocused ? "none" : null, // Rimuove anche il box-shadow
            borderColor: state.isFocused ? "#999" : provided.borderColor, // Definisci il colore del bordo quando è in focus
            "&:hover": {
              borderColor: "#999", // Cambia il colore del bordo quando è in hover
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            display: "flex",
            flexWrap: "nowrap", // Evita che le opzioni vadano a capo
            overflow: "hidden", // Nascondi il testo in eccesso
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 2,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? "#f5f6a8" // Colore di sfondo su hover
              : state.isSelected
              ? "#d9dfe6" // Colore di sfondo quando è selezionato
              : "#fff", // Colore di sfondo predefinito
            color: state.isSelected ? "#000" : "#333", // Colore del testo quando è selezionato
            cursor: "pointer",
            ":active": {
              backgroundColor: "#f5f7fa", // Colore di sfondo al clic
            },
          }),
        }}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default AsyncSelect;
