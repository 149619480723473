import React, { useRef } from "react";
import debounce from "lodash.debounce";
import { Input } from "reactstrap";

const TableOmniSearch = ({ onSearch, placeholder }) => {
  const debouncedOmnisearch = useRef(
    debounce(async (value) => {
      onSearch(value);
    }, 300)
  ).current;

  return (
    <div className="col-3 d-flex align-items-center">
      <Input
        type="text"
        className="css-b62m3t-container"
        placeholder={placeholder}
        onChange={(e) => {
          debouncedOmnisearch(e.target.value);
        }}
      />
    </div>
  );
};

export default TableOmniSearch;
