import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import navdata from "./SidebarData";
import withRouter from "../../Components/common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarContent = (props) => {
  const navData = navdata().props.children;
  const path = props.router.location.pathname;

  const activateParentDropdown = useCallback((item) => {
    const parentCollapseDiv = item.closest(".nav-item-submenu");
    const parentCollapseSubDiv = item.closest(".nav-group-sub");
    if (item.classList.contains("nav-link")) {
      item.classList.add("active");
    }

    if (parentCollapseDiv) {
      parentCollapseDiv.classList.toggle("nav-item-open");

      const isActive = parentCollapseDiv.classList.contains("nav-item-open");
      parentCollapseDiv
        .querySelector(".nav-link")
        .classList.toggle("active", isActive);
      parentCollapseDiv
        .querySelector(".nav-link")
        .setAttribute("aria-expanded", isActive ? "true" : "false");
      const collapseElement = parentCollapseDiv.querySelector(".collapse");
      if (collapseElement) {
        collapseElement.classList.toggle("show", isActive);
      }
      if (parentCollapseSubDiv) {
        parentCollapseSubDiv.parentElement.previousElementSibling.classList.toggle(
          "active"
        );
        parentCollapseSubDiv.parentElement.previousElementSibling.setAttribute(
          "aria-expanded",
          "true"
        );
      }

      if (parentCollapseDiv.parentElement.closest(".nav-group-sub")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        const parentLink = parentCollapseDiv.parentElement.closest(".nav-link");
        if (parentLink && parentLink.previousElementSibling) {
          parentLink.previousElementSibling.classList.toggle("active");
        }
        const parentCollapse =
          parentCollapseDiv.parentElement.closest(
            ".collapse"
          ).previousElementSibling;
        if (parentCollapse && parentCollapse.closest(".collapse")) {
          parentCollapse.closest(".collapse").classList.add("show");
          parentCollapse
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        }
      }
    }
  }, []);

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
        if (item && item.closest(".nav-item-submenu")) {
          item.closest(".nav-item-submenu").classList.remove("nav-item-open");
        }
      }
      if (parent) {
        parent.classList.remove("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("active"); // li
            parent3.childNodes[0].classList.remove("active");
            parent3.classList.remove("show");
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("show"); // li
                parent5.childNodes[0].classList.remove("active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const ul = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      const itemsArray = Array.from(items); // converts NodeList to Array
      removeActivation(itemsArray);
      const pathName = process.env.PUBLIC_URL + path;
      const matchingMenuItem = itemsArray.find((x) => x.pathname === pathName);

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [activateParentDropdown, path, props.isOpen]);

  return (
    <ul className="nav nav-sidebar" data-nav-type="accordion" id="navbar-nav">
      {(navData || []).map((item, key) => {
        return props.isOpen ? (
          <React.Fragment key={key}>
            {item.subItems ? (
              <li
                className="nav-item nav-item-submenu"
                hidden={item.visibility === false}
              >
                <Link
                  to="/#"
                  onClick={item.click}
                  className={`nav-link ${item.stateVariables ? "open" : ""}`}
                >
                  <div className="nav-icon-container me-2">
                    <FontAwesomeIcon
                      className="me-2"
                      size="lg"
                      icon={item.icon}
                    />
                  </div>
                  <span>{item.label}</span>
                </Link>
                <Collapse isOpen={item.stateVariables}>
                  <ul className="nav-group-sub">
                    {/* subItems */}
                    {item.subItems &&
                      item.subItems.map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <li
                              className={`nav-item`}
                              hidden={subItem.visibility === false}
                            >
                              <Link
                                to={subItem.link ? subItem.link : "/#"}
                                className={`nav-link ${
                                  subItem.disabled ? "pe-none" : ""
                                }`}
                              >
                                {subItem.label}
                                {subItem.isNew && (
                                  <span className="sidebar-new-badge ms-2">
                                    NEW
                                  </span>
                                )}
                              </Link>
                            </li>
                          ) : (
                            <li
                              className="nav-item"
                              hidden={item.visibility === false}
                            >
                              <Link
                                to="/#"
                                onClick={subItem.click}
                                className="nav-link"
                                data-bs-toggle="collapse"
                              >
                                {subItem.label}
                                {subItem.isNew && (
                                  <span className="sidebar-new-badge ms-2">
                                    NEW
                                  </span>
                                )}
                              </Link>
                              <Collapse isOpen={subItem.stateVariables}>
                                <ul className="nav-group-sub">
                                  {/* child subItems */}
                                  {subItem.childItems &&
                                    subItem.childItems.map((childItem, key) => (
                                      <React.Fragment key={key}>
                                        {!childItem.childItems ? (
                                          <li className="nav-item">
                                            <Link
                                              to={
                                                childItem.link
                                                  ? childItem.link
                                                  : "/#"
                                              }
                                              className="nav-link"
                                            >
                                              {childItem.label}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li className="nav-item">
                                            <Link
                                              to="/#"
                                              className="nav-link"
                                              onClick={childItem.click}
                                              data-bs-toggle="collapse"
                                            >
                                              {childItem.label}
                                            </Link>

                                            <Collapse
                                              isOpen={childItem.stateVariables}
                                            >
                                              <ul className="nav-group-sub">
                                                {childItem.childItems.map(
                                                  (subChildItem, key) => (
                                                    <li
                                                      className="nav-item"
                                                      key={key}
                                                    >
                                                      <Link
                                                        to={subChildItem.link}
                                                        className="nav-link"
                                                        data-key="t-basic-action"
                                                      >
                                                        {subChildItem.label}{" "}
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </Collapse>
                                          </li>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : (
              <li className="nav-item" hidden={item.visibility === false}>
                <Link to={item.link ? item.link : "/#"} className="nav-link">
                  <div className="nav-icon-container me-2">
                    <FontAwesomeIcon size="lg" icon={item.icon} />
                  </div>
                  <span>
                    {item.label}
                    {item.innertext && (
                      <span className="d-block fw-normal opacity-50">
                        {item.innertext}
                      </span>
                    )}
                  </span>
                </Link>
              </li>
            )}
          </React.Fragment>
        ) : (
          <li
            className="nav-item"
            hidden={item.visibility === false}
            onClick={props.toggleSidebar}
          >
            <div className={`nav-link cursor-pointer`}>
              <FontAwesomeIcon className="" size="lg" icon={item.icon} />
              <span>{item.label}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default withRouter(SidebarContent);
