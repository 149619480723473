import { getStoreOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { valueIsEmpty } from "../../utilities";
import { getAllStores } from "../../utilities/asyncSelectCallAPI";
import { dictionary } from "../../utilities/dictionary";

export const defaultArticle = {
  name: "",
  externalReference: "",
  height: "",
  length: "",
  width: "",
  weight: "",
  bodyHtmlUrl: "",
  type: "",
  vendor: "",
};

export const defaultArticleVariant = {
  name: "",
  barcode: "",
  barcodeType: "EAN",
  asin: "",
  sku: "",
  originalPrice: "",
  price: "",
  weight: "",
  weightUnit: "KG",
  taxCode: "",
  originCountryCode: "",
  hs: "",
  shippingRequired: true,
};

export const defaultArticleImage = {
  storeReference: "",
  imageUrl:
    "https://allabox.it/static/media/no-image-available.5f09172c692a365b3843.png",
};

//#region Index columns
export const columns = [
  {
    position: 0,
    Header: "",
    accessor: "imageUrl",
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.imageUrl) {
        return <img height={30} src={row.imageUrl} />;
      }
      return "-";
    },
  },
  {
    position: 1,
    Header: "Store",
    accessor: "storeIntegration.store.name",
    filter: {
      active: true,
      name: "storeIntegration.storeCode",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllStores(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getStoreOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.storeIntegration) {
        return <img width={50} src={row.storeIntegration.store.logoUrl} />;
      }
      return "-";
    },
  },

  {
    position: 2,
    Header: "Nome",
    accessor: "name",
    sortable: true,
    filter: {
      active: true,
      name: "name",
      type: "search",
      preciseSearch: true,
    },
  },
  {
    position: 3,
    Header: "Rif. interno",
    accessor: "internalReference",
    cellClass: "text-nowrap",
  },
  {
    position: 4,
    Header: "Rif. esterno",
    accessor: "externalReference",
    cellClass: "text-nowrap",
  },
  {
    position: 5,
    Header: "Rif. store",
    accessor: "storeReference",
    cellClass: "text-nowrap",
  },
  {
    position: 6,
    Header: "Venditore",
    accessor: "vendor",
    cellClass: "text-nowrap",
  },
  {
    position: 7,
    Header: "Tipologia",
    accessor: "type",
    cellClass: "text-nowrap",
  },
  {
    position: 8,
    Header: "",
    accessor: "pub",
    cellClass: "text-nowrap",
    Cell: ({ row }) => {
      const { original } = row;
      return original.pub ? (
        <span style={{ backgroundColor: "#628fee" }} className="badge">
          Pubblico
        </span>
      ) : (
        <span style={{ backgroundColor: "#5fc64b" }} className="badge">
          Privato
        </span>
      );
    },
  },
];
//#endregion

export const allSelectedArticlesCanBeDeleted = (
  articleSelectedIds = [],
  articles = []
) => {
  const idSelected = articleSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const articlesSelected = articles.filter((x) => idSelected.includes(x.id));
  return canAllArticlesBeDeleted(articlesSelected);
};

export const canArticleBeDeleted = (article) => {
  if (article.status === "BOOKED" && !article.carrier.collectionDeleteEnabled) {
    return false;
  }
  return true;
};

export const canAllArticlesBeDeleted = (articles) => {
  if (!articles || articles.length === 0) return false;
  const selectedArticleCanBeDeleted = articles.map((x) =>
    canArticleBeDeleted(x)
  );
  return selectedArticleCanBeDeleted.every((x) => x);
};

// export const articleNormalizer = (article, accessTokenPayload) => {
//   article.storeIntegrationId = article.storeIntegration?.id;
//   article.ownerId = article.ownerId || accessTokenPayload.owner;
//   return article;
// };

export const articleVariantNormalizer = (articleVariant) => {
  articleVariant.barcodeType = articleVariant.barcodeType?.code;
  articleVariant.originCountryCode = articleVariant.originCountryCode?.code;

  return articleVariant;
};

export const validation = (article, articleVariants, articleImages) => {
  let errors = {};

  const { name } = article;
  if (valueIsEmpty(name)) {
    errors["name"] = dictionary["nullfield"]["it"];
  }

  articleVariants.forEach((elem) => {
    let error = {};
    if (valueIsEmpty(elem.name)) {
      error["name"] = dictionary["nullfield"]["it"];
    }

    if (!valueIsEmpty(error)) {
      if (!errors.variants) {
        errors.variants = [];
      }
      error.uuid = elem.uuid;
      errors.variants.push(error);
    }
  });

  articleImages.forEach((elem) => {
    let error = {};
    if (valueIsEmpty(elem.imageUrl)) {
      error["imageUrl"] = dictionary["nullfield"]["it"];
    }

    if (!valueIsEmpty(error)) {
      if (!errors.images) {
        errors.images = [];
      }
      error.uuid = elem.uuid;
      errors.images.push(error);
    }
  });

  return errors;
};

export const normalizer = (article, accessTokenPayload) => {
  article.ownerId = article.ownerId || accessTokenPayload.owner;
  article.sourceIntegrationId = article.sourceIntegration?.id;
  if (article.sourceIntegrationId) {
    article.sourceType = "STORE";
  }
  return article;
};
