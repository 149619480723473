import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { DetailsOrderContext } from "./providers/Details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import OrderDetails from "../OrderDetails";
import moment from "moment";
import { Loader } from "../../Components/common/Loader";
import { useIncomingGoodContext } from "./providers/CreateEdit";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import IncomingGoodDetails from "../IncomingGoodDetails";

export const Details = () => {
  const { id } = useParams();

  const context = useIncomingGoodContext();

  useEffect(() => {
    if (id) {
      context.getIncomingGood();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title="Logistica"
        breadcrumbItem="Dettaglio bolla entrata merce"
      />
      <div className="content" id="container">
        {context.incomingGoodLoader && (
          <Loader show={context.incomingGoodLoader} />
        )}
        <Card>
          <CardBody>
            <h5>Informazioni generali</h5>
            <div className="row">
              <div className="col">
                <label className="fw-semibold d-block">Vettore</label>
                <label>
                  {context.incomingGood?.carrier?.logoUrl && (
                    <img
                      className="me-1"
                      width={60}
                      src={context.incomingGood?.carrier?.logoUrl}
                    />
                  )}
                  {context.incomingGood?.carrier?.name || "-"}
                </label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Rif. Interno</label>
                <label>{context.incomingGood?.internalReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Rif. Vettore</label>
                <label>{context.incomingGood?.carrierReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Rif. Mittente</label>
                <label>{context.incomingGood?.senderReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Rif. Destinatario</label>
                <label>{context.incomingGood?.receiverReference || "-"}</label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label className="fw-semibold d-block">Magazzino</label>
                <label>
                  {context.incomingGood?.warehouse
                    ? `${context.incomingGood?.warehouse.name} - ${context.incomingGood?.warehouse?.location?.label}`
                    : "-"}
                </label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Data di creazione</label>
                {context.incomingGood?.creationDate
                  ? moment(context.incomingGood?.creationDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Data di partenza</label>
                {context.incomingGood?.departureDate
                  ? moment(context.incomingGood?.departureDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Arrivo previsto</label>
                {context.incomingGood?.expectedDeliveryDate
                  ? moment(context.incomingGood?.expectedDeliveryDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Data arrivo</label>
                {context.incomingGood?.effectiveDeliveryDate
                  ? moment(context.incomingGood?.effectiveDeliveryDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="fw-semibold d-block">Note mittente</label>
                <label>{context.incomingGood?.senderNote || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">Note destinatario</label>
                <label>{context.incomingGood?.receiverNote || "-"}</label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <IncomingGoodDetails
                  parentId={context.incomingGood?.id}
                  viewOnly={true}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
