import React from "react";
import { getLocationLevelBadge } from "../../../utilities";

export const LocationListElement = ({
  location,
  icon,
  iconColor,
  handleDelete,
}) => {
  return (
    <div
      className="location-item-wrapper position-relative mt-3"
      style={{
        paddingRight: 30,
        paddingLeft: 30,
      }}
    >
      {icon && (
        <i
          className={`position-absolute top-50 start-0 ph fs-1 ${icon} me-1 align-middle`}
          style={{
            color: iconColor,
            transform: "translateY(-50%)",
          }}
        ></i>
      )}

      <div
        className="m-0 text d-inline-block align-middle"
        style={{ maxWidth: "100%" }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={location.label}
      >
        <div>
          <b className="me-2">{location.label}</b>{" "}
          {getLocationLevelBadge(
            location.normalized?.matchLevelCode || location.matchLevelCode
          )}
        </div>
      </div>

      <i
        className="ph ph-x fs-6 position-absolute top-50 end-0"
        style={{
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={() => {
          if (handleDelete) {
            handleDelete(location);
          }
        }}
      ></i>
    </div>
  );
};
