import React, { useState, useEffect } from "react";
import { useOidcUser } from "@axa-fr/react-oidc";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import { convertFromJson } from "fiql-query-builder";
import CreateCustomer from "./Create";
import Edit from "./Edit";
import Delete from "./Delete";

import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import { getUniqueSelectors } from "../../utilities";
// import GenericFields from "./GenericFields";
// import ActiveServices from "./ActiveServices";

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const Organizations = () => {
  const { oidcUser } = useOidcUser();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const fixedCol = [
    {
      accessor: "logoUrl",
      Cell: ({ row }) => {
        const { values } = row;
        if (values.logoUrl) {
          return (
            <img
              style={{ maxWidth: "50px" }}
              src={`${values.logoUrl}`}
              className="table-image"
              alt=""
            />
          );
        }
      },
    },

    {
      Header: "Nome",
      accessor: "name",
      sortable: false,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },
    {
      Header: "Riferimento esterno",
      accessor: "externalReference",
      sortable: false,
      filter: {
        active: true,
        name: "externalReference",
        type: "search",
      },
    },
    {
      Header: "PIVA",
      accessor: "vatNumber",
      sortable: false,
      filter: {
        active: true,
        name: "vatNumber",
        type: "search",
      },
    },
    {
      Header: "Azioni",
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Modifica Organizzazione",
                    icon: "ph-note-pencil",
                    open: !modalSettings.open,
                    action: "edit",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Duplica Organizzazione",
                    icon: "ph-users",
                    open: !modalSettings.open,
                    action: "clone",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-users me-2"></i>
                Duplica
              </DropdownItem>
              <DropdownItem href={`/addresses?companyId=${row.original.id}`}>
                <i className="ph-map-pin-line me-2"></i>I miei indirizzi
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina Organizzazione",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    action: "delete",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const defaultQuerySearch = [
    {
      equals: {
        selector: "type",
        args: "ORGANIZATION",
      },
    },
  ];

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const [queryParams, setQueryParams] = useState(defaultQuerySearch);
  const [querySearch, setQuerySearch] = useState();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    sort: "asc",
  });

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    console.log("onSearch", queryParams);
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/registry-service/companies?page=${pagination.page}&size=${
      pagination.size
    }&sort=${pagination.sort}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    refreshData();
  }, [pagination]);

  useEffect(() => {
    console.log("data loaded", data, oidcUser);
  }, [data]);

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, []);

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Anagrafiche" breadcrumbItem="Organizzazioni" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuova Organizzazione",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  Nuova organizzazione <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            {oidcUser && (
              <TableAdvanced
                hasFilter={true}
                columns={fixedCol}
                data={data}
                className="custom-header-css"
                tableClass={"table-xxs"}
                paginationOpt={pagination}
                onPaginationChange={handlePagination}
                refreshTable={refreshData}
                onSearch={onSearch}
                onFreeSearch={onSearch}
                showLoader={loading}
              />
            )}

            <Modal
              isOpen={modalSettings.open}
              className="modal-content"
              size={modalSettings.size || "md"}
            >
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "create" && (
                  <CreateCustomer
                    userOwnerId={oidcUser.owner}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "edit" && (
                  <Edit
                    userOwnerId={oidcUser.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "clone" && (
                  <Edit
                    userOwnerId={oidcUser.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    clone={true}
                  />
                )}
                {modalSettings.action === "delete" && (
                  <Delete
                    userOwnerId={oidcUser.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Organizations;
