import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import { ArticlesService } from "../../../services/logistic/articles";
import { normalizer as articleNormalizer } from "../utilities";
import { callErrorToast, valueIsEmpty } from "../../../utilities";

export const CreateEditArticleContext = createContext();

const articlesService = new ArticlesService();

const CreateEditArticleProvider = ({ children, autosave, callback }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [article, setArticle] = useState(null);
  const [articleError, setArticleError] = useState(null);
  const [articleLoader, setArticleLoader] = useState(false);

  const prevArticleError = useRef();

  const getArticle = () => {
    setArticleLoader(true);
    articlesService
      .get(id)
      .then((res) => {
        setArticle(res.data);
        setArticleLoader(false);
      })
      .catch((err) => {
        setArticleError(err);
      });
  };

  const createArticle = () => {
    setArticleLoader(true);

    const articleNormalized = articleNormalizer(
      { ...article },
      accessTokenPayload
    );

    articlesService
      .create(articleNormalized)
      .then((res) => {
        setArticle(res.data);
        setArticleLoader(false);
      })
      .catch((err) => {
        setArticleError(err);
      });
  };

  const editArticle = (article) => {
    if (autosave) {
      const articleNormalized = articleNormalizer(article, accessTokenPayload);
      articlesService
        .edit(articleNormalized)
        .then((res) => {
          setArticle(article);
          setArticleError(null);
        })
        .catch((err) => {
          setArticleError(err);
        });

      return false;
    }

    setArticle(article);
  };

  const removeError = (property) => {
    const newArticleError = { ...articleError };
    delete newArticleError.response?.data[property];
    if (!valueIsEmpty(newArticleError)) {
      prevArticleError.current = newArticleError;
      setArticleError(newArticleError);
    }
  };

  useEffect(() => {
    if (
      articleError &&
      JSON.stringify(prevArticleError.current) !== JSON.stringify(articleError)
    ) {
      callErrorToast(articleError);
    }
  }, [articleError]);

  return (
    <CreateEditArticleContext.Provider
      value={{
        article,
        articleError,
        articleLoader,
        getArticle,
        createArticle,
        editArticle,

        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </CreateEditArticleContext.Provider>
  );
};

const useArticleContext = () => {
  return useContext(CreateEditArticleContext);
};

export { CreateEditArticleProvider, useArticleContext };
