import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Button } from "reactstrap";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import { ListBox } from "primereact/listbox";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "../../assets/scss/internal/listbox-multiple.scss";

import { ContractServicesService } from "../../services/contract-service/contractServices";
import CompaniesService from "../../services/registry-service/companies";
import {
  carrierLogos,
  getParcelTypeBadge,
  getShipmentTypeBadge,
} from "../../utilities";

const contractServicesService = new ContractServicesService();
const companiesService = new CompaniesService();

const Relate = ({
  contractServiceId,
  modalToogle,
  refreshTable,
  contract,
  parcelType,
}) => {
  const { oidcUser } = useOidcUser();
  //Tutti i servizi relazionabili ricevuti dal BE
  const [allSourceOptions, setAllSourceOptions] = useState([]);

  //Servizi relazionabili raggruppati in gruppi per vettore
  const [sourceGroups, setSourceGroups] = useState([]);
  //Servizi relazionati raggruppati in gruppi per vettore
  const [targetGroups, setTargetGroups] = useState([]);

  //Servizi relazionabili selezionati nella listbox
  const [sourceOptionsSelected, setSourceOptionsSelected] = useState([]);
  //Servizi relazionati selezionati nella listbox
  const [targetOptionsSelected, setTargetOptionsSelected] = useState([]);

  const getRelatableContractServices = (sourceServiceRelations) => {
    contractServicesService
      .getRelatableContractServices({
        ownerId: oidcUser.owner,
        carrierCode: contract.carrierCode,
        parcelType: parcelType,
        shipmentType: contract.shipmentType,
      })
      .then((response) => {
        let sourceGroups = [];
        let targetGroups = [];

        if (response.content) {
          response.content.forEach((elem, index) => {
            const id = elem.id;
            const groupCode = elem.carrierService.carrier.code;
            const groupName = elem.carrierService.carrier.name;
            const serviceName = elem.name;
            const parcelType = elem.parcelType;
            const owner = {
              id: elem.ownerId,
              name:
                elem.ownerId === contract.ownerId
                  ? contract.owner.name
                  : "Ereditato",
            };

            let sourceGroup = sourceGroups.find(
              (sourceGroup) => sourceGroup.code === groupCode
            );
            if (!sourceGroup) {
              sourceGroup = {
                label: groupName,
                code: groupCode,
                items: [],
              };
              sourceGroups.push(sourceGroup);
            }

            if (sourceServiceRelations.includes(id)) {
              let targetGroup = targetGroups.find(
                (targetGroup, gIndex) => targetGroup.code === groupCode
              );
              if (!targetGroup) {
                targetGroup = {
                  label: groupName,
                  code: groupCode,
                  items: [],
                };
                targetGroups.push(targetGroup);
              }
              targetGroup.items.push({
                label: serviceName,
                value: id,
                parcelType,
                owner,
              });
            } else {
              sourceGroup.items.push({
                label: serviceName,
                value: id,
                parcelType,
                owner,
              });
            }
          });
        }

        sourceGroups = sourceGroups.filter(
          (sourceGroup) => sourceGroup.items.length > 0
        );

        setSourceGroups(sourceGroups);
        setTargetGroups(targetGroups);

        setAllSourceOptions(response.content || []);
      })
      .catch(() => {
        toast.error(
          "Si è verificato un errore nel caricamento dei servizi relazionabili",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getSourceServiceRelations = () => {
    contractServicesService
      .getSourceServiceRelations(contractServiceId)
      .then((response) => {
        getRelatableContractServices(response);
      })
      .catch(() => {
        toast.error(
          "Si è verificato un errore nel caricamento dei servizi selezionati",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const deleteSourceServiceRelations = () => {
    contractServicesService
      .deleteSourceServiceRelations(contractServiceId)
      .then((response) => {
        if (targetGroups.length > 0) {
          createSourceServiceRelation();
          return false;
        }
        refreshTable();
        modalToogle();
        toast.success("Servizio associato correttamente");
      })
      .catch(() => {
        toast.error(
          "Si è verificato un errore nella cancellazione dei servizi associati",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const createSourceServiceRelation = () => {
    const companiesSelectedCounter = targetGroups.length;
    targetGroups.forEach((elem, index) => {
      elem.items.forEach((item) => {
        contractServicesService
          .createSourceServiceRelation(contractServiceId, item.value)
          .then((response) => {
            if (index === companiesSelectedCounter - 1) {
              refreshTable();
              modalToogle();
              toast.success("Servizio associato correttamente");
            }
          })
          .catch(() => {
            toast.error(
              "Si è verificato un errore nell'associzione della company al servizio",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      });
    });
  };

  const moveSelectedOptionsFromSourceToTarget = () => {
    const values = sourceOptionsSelected;
    const optionsSelected = allSourceOptions.filter((elem) =>
      values.includes(elem.id)
    );

    const newContractServicesSelected = assignOptionsToCorrectGroup(
      optionsSelected,
      [...targetGroups]
    );

    const newRelatableContractServices = removeOptionsFromGroups(
      optionsSelected,
      [...sourceGroups]
    );

    setTargetGroups(newContractServicesSelected);
    setSourceGroups(newRelatableContractServices);
    setSourceOptionsSelected([]);
  };

  const moveSelectedOptionsFromTargetToSource = () => {
    const values = targetOptionsSelected;
    const optionsSelected = allSourceOptions.filter((elem) =>
      values.includes(elem.id)
    );

    const newContractServicesSelected = removeOptionsFromGroups(
      optionsSelected,
      [...targetGroups]
    );

    const newRelatableContractServices = assignOptionsToCorrectGroup(
      optionsSelected,
      [...sourceGroups]
    );

    setTargetGroups(newContractServicesSelected);
    setSourceGroups(newRelatableContractServices);
    setTargetOptionsSelected([]);
  };

  const moveAllOptionsFromSourceToTarget = () => {
    const newContractServicesSelected = assignOptionsToCorrectGroup(
      [...allSourceOptions],
      []
    );
    setTargetGroups(newContractServicesSelected);
    setSourceGroups([]);
  };

  const moveAllOptionsFromTargetToSource = () => {
    const newRelatableContractServices = assignOptionsToCorrectGroup(
      [...allSourceOptions],
      []
    );
    setTargetGroups([]);
    setSourceGroups(newRelatableContractServices);
  };

  const assignOptionsToCorrectGroup = (options, targetArray) => {
    let groups = [...targetArray];
    options.forEach((elem, index) => {
      const optionId = elem.id;
      const optionLabel = elem.name;
      const groupId = elem.carrierService.carrier.code;
      const groupLabel = elem.carrierService.carrier.name;
      const parcelType = elem.parcelType;

      let group = groups.find((group) => group.code === groupId);
      if (!group) {
        group = {
          label: groupLabel,
          code: groupId,
          items: [],
        };
        groups.push(group);
      }

      group.items.push({
        label: optionLabel,
        value: optionId,
        parcelType,
        owner: {
          id: elem.ownerId,
          name:
            elem.ownerId === contract.ownerId
              ? contract.owner.name
              : "Ereditato",
        },
      });
    });

    return groups;
  };

  const removeOptionsFromGroups = (options, groupsArray) => {
    let thereAreEmptyItems = false;

    groupsArray.forEach((group) => {
      options.forEach((option) => {
        if (option.carrierService.carrier.code === group.code) {
          group.items = group.items.filter((item) => item.value !== option.id);
          if (group.items.length === 0) {
            thereAreEmptyItems = true;
          }
        }
      });
    });

    if (thereAreEmptyItems) {
      groupsArray = groupsArray.filter((group) => group.items.length > 0);
    }
    return groupsArray;
  };

  const groupTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.label}
          src={carrierLogos[option.code]}
          className={``}
          style={{ maxHeight: "30px", maxWidth: "50px" }}
        />{" "}
        <label>{option.label}</label>
      </div>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div className="border-bottom pb-2">
        <div className="row">
          <div className="col">
            <span style={{ fontSize: "10px" }}>
              {getParcelTypeBadge(option.parcelType)} {getShipmentTypeBadge("")}{" "}
              {option.owner.name}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">{option.label}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (oidcUser) {
      getSourceServiceRelations();
    }
  }, [oidcUser]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <p>
            <i className="ph ph-info me-1"></i>Selezionare i servizi desiderati
            e cliccare su una delle frecce azione per spostarli nell'altra
            colonna.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row align-items-center">
            <div className="col-5">
              <h5>Tutti</h5>
              <ListBox
                value={sourceOptionsSelected}
                onChange={(e) => setSourceOptionsSelected(e.value)}
                options={sourceGroups}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupTemplate}
                itemTemplate={itemTemplate}
                className="w-full md:w-14rem"
                listStyle={{ height: "350px" }}
                multiple
                filter
                filterBy="label,owner.id,parcelType.name"
                emptyFilterMessage={() => (
                  <label className="text-center d-block">
                    Nessun servizio trovato
                  </label>
                )}
                emptyMessage={() => {
                  <label className="text-center d-block">
                    Tutti i servizi sono stati relazionati
                  </label>;
                }}
              />
            </div>
            <div className="col-2">
              <div className="row">
                <button
                  title="Muovi tutti i servizi in Selezionati"
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveAllOptionsFromSourceToTarget}
                >
                  <i className="ph-caret-double-right"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title="Muovi i servizi selezionati in Selezionati"
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveSelectedOptionsFromSourceToTarget}
                >
                  <i className="ph-caret-right"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title="Muovi i servizi selezionati in Tutti"
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveSelectedOptionsFromTargetToSource}
                >
                  <i className="ph-caret-left"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title="Muovi tutti i servizi in Tutti"
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveAllOptionsFromTargetToSource}
                >
                  <i className="ph-caret-double-left"></i>
                </button>
              </div>
            </div>
            <div className="col-5">
              <h5>Selezionati</h5>
              <ListBox
                value={targetOptionsSelected}
                onChange={(e) => setTargetOptionsSelected(e.value)}
                options={targetGroups}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupTemplate}
                itemTemplate={itemTemplate}
                className="w-full md:w-14rem"
                listStyle={{ height: "350px" }}
                multiple
                filter
                filterBy="label,owner.id,parcelType.name"
                emptyFilterMessage={() => (
                  <label className="text-center d-block">
                    Nessun servizio trovato
                  </label>
                )}
                emptyMessage={() => {
                  <label className="text-center d-block">
                    Nessun servizio relazionato
                  </label>;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          type="button"
          className="btn btn-primary ms-3"
          onClick={deleteSourceServiceRelations}
        >
          Salva <i className="ph-paper-plane-tilt ms-2"></i>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Relate;
