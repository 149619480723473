import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class CodCollectionsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/cod-collections/${id}`,
      requestOptions
    );
  }
}
