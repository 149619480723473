import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "./style.scss";
import { useTable } from "react-table";
import { Table, Collapse } from "reactstrap";

import _ from "lodash";
import { Loader } from "../Loader";
import TablePagination from "./TablePagination";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableFilter from "./TableFilter";
import TableOmniSearch from "./TableOmniSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilter,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const TableAdvanced = ({
  columns,
  data,
  tableClass,
  theadClass = "",
  thClass = "",
  paginationOpt,
  sortingOpt,
  hasFilter,
  onSearch,
  onPaginationChange,
  onSortingChange,
  showLoader,
  selectMultipleRows = false,
  toExport = null,
  statusOfTableRows = [],
  onChangeStatusRow,
  onChangeStatusAllRows,
  omnisearch,
  myData,
}) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const tableRef = useRef(null);

  const [tableContent, setTableContent] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [filtersCollapse, setFiltersCollapse] = useState(true);

  useEffect(() => {
    if (data) {
      setTableContent(Array.isArray(data) ? data : data.content || []);
    }
  }, [data]);

  useEffect(() => {
    if (data.content) {
      let allRowIsSelected = false;
      if (
        data?.content?.length === statusOfTableRows.length &&
        statusOfTableRows.length
      ) {
        //verifico prima se in selectstatusOfTableRows esiste almeno un negativo
        const anyDeselected = statusOfTableRows.some(
          (element) => !element.value
        );
        if (!anyDeselected) {
          const statusRowIds = statusOfTableRows.map((x) => x.id);
          const dataRowIds = data?.content?.map((x) => x.id);
          if (_.isEqual(statusRowIds.sort(), dataRowIds?.sort())) {
            allRowIsSelected = true;
          }
        }
      }
      setIsAllRowsSelected(allRowIsSelected);
    }
  }, [data, statusOfTableRows]);

  const { headerGroups, prepareRow, rows } = useTable({
    columns: columns,
    data: tableContent,
    manualPagination: true,
  });

  const onChangePageSizeSelect = (event) => {
    onPaginationChange({ page: 0, size: Number(event.target.value) });
  };

  return (
    <>
      <Loader show={showLoader} />

      <div className="mt-3">
        <div className="row align-items-center">
          <div className="col d-flex align-items-center">
            {hasFilter && (
              <FontAwesomeIcon
                onClick={() => setFiltersCollapse(!filtersCollapse)}
                icon={faFilter}
                size="lg"
                className="me-2 cursor-pointer"
              />
            )}
            {paginationOpt && (
              <select
                className="form-select w-auto"
                value={paginationOpt.size}
                onChange={onChangePageSizeSelect}
              >
                {[5, 10, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostra {pageSize}
                  </option>
                ))}
              </select>
            )}
            {toExport && toExport.types.includes("csv") && (
              <button
                type="button"
                title="Esporta CSV"
                className="btn btn-light ms-2"
                onClick={() => toExport.onExport("csv")}
                disabled={toExport.loading}
              >
                {toExport.loading ? (
                  <i class="ph-spinner spinner"></i>
                ) : (
                  <FontAwesomeIcon icon={faFileCsv} size="lg" />
                )}
              </button>
            )}
          </div>
          {myData?.active && (
            <button
              className="btn btn-outline-success btn-sm btn btn-light w-auto"
              onClick={() => {
                onSearch("creationUser", accessTokenPayload.sub, true);
              }}
            >
              {myData.label}
            </button>
          )}
          {omnisearch?.active && (
            <TableOmniSearch
              onSearch={omnisearch.onSearch}
              placeholder={omnisearch.placeholder}
            />
          )}
        </div>
        {hasFilter && (
          <Collapse
            isOpen={filtersCollapse}
            className="accordion-collapse mt-2"
          >
            <TableFilter
              selectMultipleRows={selectMultipleRows}
              columns={columns}
              onSearch={onSearch}
              tableRef={tableRef}
            />
          </Collapse>
        )}
        <div className="table-responsive" ref={tableRef}>
          <Table hover className={tableClass}>
            <TableHeader
              headerGroups={headerGroups}
              selectMultipleRows={selectMultipleRows}
              isAllRowsSelected={isAllRowsSelected}
              onChangeStatusAllRows={onChangeStatusAllRows}
              sortingOpt={sortingOpt}
              onSortingChange={onSortingChange}
              theadClass={theadClass}
              thClass={thClass}
            />
            <TableBody
              rows={rows}
              prepareRow={prepareRow}
              statusOfTableRows={statusOfTableRows}
              selectMultipleRows={selectMultipleRows}
              onChangeStatusRow={onChangeStatusRow}
              numberOfColumn={columns.length}
            />
          </Table>
        </div>
        {paginationOpt && (
          <TablePagination
            data={data}
            onPaginationChange={onPaginationChange}
          />
        )}
      </div>
    </>
  );
};

TableAdvanced.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableAdvanced;
