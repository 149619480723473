import React from "react";
import SidebarContent from "./SidebarContent";
import LogoIcon from "../../assets/images/logo-icon-1.svg";
import LogoWhite from "../../assets/images/logo-white.svg";
import { useAppContext } from "../../AppProvider";

const Sidebar = (props) => {
  const appContext = useAppContext();
  return (
    <React.Fragment>
      <div
        className={`d-none d-lg-block sidebar ${
          props.isOpen ? "sidebar-open" : ""
        }`}
      >
        <div className="sidebar-content">
          <div className={`sidebar-logo ${props.isOpen ? "open" : ""}`}>
            {appContext?.brandSettings?.logoLightUrl ? (
              props.isOpen ? (
                <img src={appContext.brandSettings.logoLightUrl} />
              ) : (
                // logo solo icona
                <img src={appContext.brandSettings.logoLightUrl} width={30} />
              )
            ) : (
              ""
            )}
          </div>
          <div className="sidebar-section">
            <SidebarContent
              isOpen={props.isOpen}
              toggleSidebar={props.toggleSidebar}
            />
          </div>
        </div>
      </div>
      <div
        className={`d-block d-lg-none ${
          props.isOpen ? "nav-links open p-4" : "nav-links p-4"
        }`}
      >
        <SidebarContent
          isOpen={props.isOpen}
          toggleSidebar={props.toggleSidebar}
        />
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
