import API from "../helpers/API";
import HereAPI from "../helpers/hereAPI";

const api = new API();
const hereApi = new HereAPI();

export const API_BASE_URL = "https://api." + api.getURL();

export const AWS_LOCATION_KEY = hereApi.HERE_API_KEY;

export const AWS_LOCATION_PLACE_INDEX = hereApi.PLACE_INDEX;

export const timezoneValues = [
  {
    label: "(UTC-12:00) International Date Line West",
    value: "Dateline Standard Time",
  },
  { label: "(UTC-11:00) Coordinated Universal Time-11", value: "UTC-11" },
  { label: "(UTC-10:00) Aleutian Islands", value: "Aleutian Standard Time" },
  { label: "(UTC-10:00) Hawaii", value: "Hawaiian Standard Time" },
  { label: "(UTC-09:30) Marquesas Islands", value: "Marquesas Standard Time" },
  { label: "(UTC-09:00) Alaska", value: "Alaskan Standard Time" },
  { label: "(UTC-09:00) Coordinated Universal Time-09", value: "UTC-09" },
  {
    label: "(UTC-08:00) Baja California",
    value: "Pacific Standard Time (Mexico)",
  },
  { label: "(UTC-08:00) Coordinated Universal Time-08", value: "UTC-08" },
  {
    label: "(UTC-08:00) Pacific Time (US &amp; Canada)",
    value: "Pacific Standard Time",
  },
  { label: "(UTC-07:00) Arizona", value: "US Mountain Standard Time" },
  {
    label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    value: "Mountain Standard Time (Mexico)",
  },
  {
    label: "(UTC-07:00) Mountain Time (US &amp; Canada)",
    value: "Mountain Standard Time",
  },
  {
    label: "(UTC-06:00) Central America",
    value: "Central America Standard Time",
  },
  {
    label: "(UTC-06:00) Central Time (US &amp; Canada)",
    value: "Central Standard Time",
  },
  { label: "(UTC-06:00) Easter Island", value: "Easter Island Standard Time" },
  {
    label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    value: "Central Standard Time (Mexico)",
  },
  { label: "(UTC-06:00) Saskatchewan", value: "Canada Central Standard Time" },
  {
    label: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    value: "SA Pacific Standard Time",
  },
  { label: "(UTC-05:00) Chetumal", value: "Eastern Standard Time (Mexico)" },
  {
    label: "(UTC-05:00) Eastern Time (US &amp; Canada)",
    value: "Eastern Standard Time",
  },
  { label: "(UTC-05:00) Haiti", value: "Haiti Standard Time" },
  { label: "(UTC-05:00) Havana", value: "Cuba Standard Time" },
  { label: "(UTC-05:00) Indiana (East)", value: "US Eastern Standard Time" },
  {
    label: "(UTC-05:00) Turks and Caicos",
    value: "Turks And Caicos Standard Time",
  },
  { label: "(UTC-04:00) Asuncion", value: "Paraguay Standard Time" },
  {
    label: "(UTC-04:00) Atlantic Time (Canada)",
    value: "Atlantic Standard Time",
  },
  { label: "(UTC-04:00) Caracas", value: "Venezuela Standard Time" },
  { label: "(UTC-04:00) Cuiaba", value: "Central Brazilian Standard Time" },
  {
    label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    value: "SA Western Standard Time",
  },
  { label: "(UTC-04:00) Santiago", value: "Pacific SA Standard Time" },
  { label: "(UTC-03:30) Newfoundland", value: "Newfoundland Standard Time" },
  { label: "(UTC-03:00) Araguaina", value: "Tocantins Standard Time" },
  { label: "(UTC-03:00) Brasilia", value: "E. South America Standard Time" },
  {
    label: "(UTC-03:00) Cayenne, Fortaleza",
    value: "SA Eastern Standard Time",
  },
  {
    label: "(UTC-03:00) City of Buenos Aires",
    value: "Argentina Standard Time",
  },
  { label: "(UTC-03:00) Greenland", value: "Greenland Standard Time" },
  { label: "(UTC-03:00) Montevideo", value: "Montevideo Standard Time" },
  { label: "(UTC-03:00) Punta Arenas", value: "Magallanes Standard Time" },
  {
    label: "(UTC-03:00) Saint Pierre and Miquelon",
    value: "Saint Pierre Standard Time",
  },
  { label: "(UTC-03:00) Salvador", value: "Bahia Standard Time" },
  { label: "(UTC-02:00) Coordinated Universal Time-02", value: "UTC-02" },
  {
    label: "(UTC-02:00) Mid-Atlantic - Old",
    value: "Mid-Atlantic Standard Time",
  },
  { label: "(UTC-01:00) Azores", value: "Azores Standard Time" },
  { label: "(UTC-01:00) Cabo Verde Is.", value: "Cape Verde Standard Time" },
  { label: "(UTC) Coordinated Universal Time", value: "UTC" },
  {
    label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    value: "GMT Standard Time",
  },
  {
    label: "(UTC+00:00) Monrovia, Reykjavik",
    value: "Greenwich Standard Time",
  },
  { label: "(UTC+00:00) Sao Tome", value: "Sao Tome Standard Time" },
  { label: "(UTC+01:00) Casablanca", value: "Morocco Standard Time" },
  {
    label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "W. Europe Standard Time",
  },
  {
    label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "Central Europe Standard Time",
  },
  {
    label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "Romance Standard Time",
  },
  {
    label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "Central European Standard Time",
  },
  {
    label: "(UTC+01:00) West Central Africa",
    value: "W. Central Africa Standard Time",
  },
  { label: "(UTC+02:00) Amman", value: "Jordan Standard Time" },
  { label: "(UTC+02:00) Athens, Bucharest", value: "GTB Standard Time" },
  { label: "(UTC+02:00) Beirut", value: "Middle East Standard Time" },
  { label: "(UTC+02:00) Cairo", value: "Egypt Standard Time" },
  { label: "(UTC+02:00) Chisinau", value: "E. Europe Standard Time" },
  { label: "(UTC+02:00) Damascus", value: "Syria Standard Time" },
  { label: "(UTC+02:00) Gaza, Hebron", value: "West Bank Standard Time" },
  {
    label: "(UTC+02:00) Harare, Pretoria",
    value: "South Africa Standard Time",
  },
  {
    label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: "FLE Standard Time",
  },
  { label: "(UTC+02:00) Jerusalem", value: "Israel Standard Time" },
  { label: "(UTC+02:00) Kaliningrad", value: "Kaliningrad Standard Time" },
  { label: "(UTC+02:00) Khartoum", value: "Sudan Standard Time" },
  { label: "(UTC+02:00) Tripoli", value: "Libya Standard Time" },
  { label: "(UTC+02:00) Windhoek", value: "Namibia Standard Time" },
  { label: "(UTC+03:00) Baghdad", value: "Arabic Standard Time" },
  { label: "(UTC+03:00) Istanbul", value: "Turkey Standard Time" },
  { label: "(UTC+03:00) Kuwait, Riyadh", value: "Arab Standard Time" },
  { label: "(UTC+03:00) Minsk", value: "Belarus Standard Time" },
  {
    label: "(UTC+03:00) Moscow, St. Petersburg",
    value: "Russian Standard Time",
  },
  { label: "(UTC+03:00) Nairobi", value: "E. Africa Standard Time" },
  { label: "(UTC+03:30) Tehran", value: "Iran Standard Time" },
  { label: "(UTC+04:00) Abu Dhabi, Muscat", value: "Arabian Standard Time" },
  {
    label: "(UTC+04:00) Astrakhan, Ulyanovsk",
    value: "Astrakhan Standard Time",
  },
  { label: "(UTC+04:00) Baku", value: "Azerbaijan Standard Time" },
  { label: "(UTC+04:00) Izhevsk, Samara", value: "Russia Time Zone 3" },
  { label: "(UTC+04:00) Port Louis", value: "Mauritius Standard Time" },
  { label: "(UTC+04:00) Saratov", value: "Saratov Standard Time" },
  { label: "(UTC+04:00) Tbilisi", value: "Georgian Standard Time" },
  { label: "(UTC+04:00) Volgograd", value: "Volgograd Standard Time" },
  { label: "(UTC+04:00) Yerevan", value: "Caucasus Standard Time" },
  { label: "(UTC+04:30) Kabul", value: "Afghanistan Standard Time" },
  { label: "(UTC+05:00) Ashgabat, Tashkent", value: "West Asia Standard Time" },
  { label: "(UTC+05:00) Ekaterinburg", value: "Ekaterinburg Standard Time" },
  { label: "(UTC+05:00) Islamabad, Karachi", value: "Pakistan Standard Time" },
  { label: "(UTC+05:00) Qyzylorda", value: "Qyzylorda Standard Time" },
  {
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "India Standard Time",
  },
  {
    label: "(UTC+05:30) Sri Jayawardenepura",
    value: "Sri Lanka Standard Time",
  },
  { label: "(UTC+05:45) Kathmandu", value: "Nepal Standard Time" },
  { label: "(UTC+06:00) Astana", value: "Central Asia Standard Time" },
  { label: "(UTC+06:00) Dhaka", value: "Bangladesh Standard Time" },
  { label: "(UTC+06:00) Omsk", value: "Omsk Standard Time" },
  { label: "(UTC+06:30) Yangon (Rangoon)", value: "Myanmar Standard Time" },
  {
    label: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    value: "SE Asia Standard Time",
  },
  { label: "(UTC+07:00) Barnaul, Gorno-Altaysk", value: "Altai Standard Time" },
  { label: "(UTC+07:00) Hovd", value: "W. Mongolia Standard Time" },
  { label: "(UTC+07:00) Krasnoyarsk", value: "North Asia Standard Time" },
  { label: "(UTC+07:00) Novosibirsk", value: "N. Central Asia Standard Time" },
  { label: "(UTC+07:00) Tomsk", value: "Tomsk Standard Time" },
  {
    label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    value: "China Standard Time",
  },
  { label: "(UTC+08:00) Irkutsk", value: "North Asia East Standard Time" },
  {
    label: "(UTC+08:00) Kuala Lumpur, Singapore",
    value: "Singapore Standard Time",
  },
  { label: "(UTC+08:00) Perth", value: "W. Australia Standard Time" },
  { label: "(UTC+08:00) Taipei", value: "Taipei Standard Time" },
  { label: "(UTC+08:00) Ulaanbaatar", value: "Ulaanbaatar Standard Time" },
  { label: "(UTC+08:45) Eucla", value: "Aus Central W. Standard Time" },
  { label: "(UTC+09:00) Chita", value: "Transbaikal Standard Time" },
  { label: "(UTC+09:00) Osaka, Sapporo, Tokyo", value: "Tokyo Standard Time" },
  { label: "(UTC+09:00) Pyongyang", value: "North Korea Standard Time" },
  { label: "(UTC+09:00) Seoul", value: "Korea Standard Time" },
  { label: "(UTC+09:00) Yakutsk", value: "Yakutsk Standard Time" },
  { label: "(UTC+09:30) Adelaide", value: "Cen. Australia Standard Time" },
  { label: "(UTC+09:30) Darwin", value: "AUS Central Standard Time" },
  { label: "(UTC+10:00) Brisbane", value: "E. Australia Standard Time" },
  {
    label: "(UTC+10:00) Canberra, Melbourne, Sydney",
    value: "AUS Eastern Standard Time",
  },
  {
    label: "(UTC+10:00) Guam, Port Moresby",
    value: "West Pacific Standard Time",
  },
  { label: "(UTC+10:00) Hobart", value: "Tasmania Standard Time" },
  { label: "(UTC+10:00) Vladivostok", value: "Vladivostok Standard Time" },
  { label: "(UTC+10:30) Lord Howe Island", value: "Lord Howe Standard Time" },
  {
    label: "(UTC+11:00) Bougainville Island",
    value: "Bougainville Standard Time",
  },
  { label: "(UTC+11:00) Chokurdakh", value: "Russia Time Zone 10" },
  { label: "(UTC+11:00) Magadan", value: "Magadan Standard Time" },
  { label: "(UTC+11:00) Norfolk Island", value: "Norfolk Standard Time" },
  { label: "(UTC+11:00) Sakhalin", value: "Sakhalin Standard Time" },
  {
    label: "(UTC+11:00) Solomon Is., New Caledonia",
    value: "Central Pacific Standard Time",
  },
  {
    label: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    value: "Russia Time Zone 11",
  },
  {
    label: "(UTC+12:00) Auckland, Wellington",
    value: "New Zealand Standard Time",
  },
  { label: "(UTC+12:00) Coordinated Universal Time+12", value: "UTC+12" },
  { label: "(UTC+12:00) Fiji", value: "Fiji Standard Time" },
  {
    label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    value: "Kamchatka Standard Time",
  },
  {
    label: "(UTC+12:45) Chatham Islands",
    value: "Chatham Islands Standard Time",
  },
  { label: "(UTC+13:00) Coordinated Universal Time+13", value: "UTC+13" },
  { label: "(UTC+13:00) Nuku'alofa", value: "Tonga Standard Time" },
  { label: "(UTC+13:00) Samoa", value: "Samoa Standard Time" },
  {
    label: "(UTC+14:00) Kiritimati Island",
    value: "Line Islands Standard Time",
  },
];

export const currenciesList = [
  { name: "Euro", code: "EUR" },
  { name: "US Dollar", code: "USD" },
  { name: "British Pound Sterling", code: "GBP" },
];

export const customDataCategories = [
  { code: "COMMERCIAL_SAMPLE", name: "Commercial sample" },
  { code: "COMMERCIAL_PARCEL", name: "Commercial parcel" },
  { code: "DOCUMENT", name: "Document" },
  { code: "GIFT", name: "Gift" },
  { code: "OTHER", name: "Other" },
  { code: "RETURN_OF_GOODS", name: "Return of goods" },
];

export const shipmentTypesList = [
  { code: "EXPRESS", name: "Spedizione espressa" },
  { code: "EXPRESS_RETURN", name: "Spedizione di reso" },
  { code: "POSTAL", name: "Spedizione postale" },
];

export const contractServiceRuleTypesList = [
  { code: "FIXED_WEIGHT", name: "Fisso per peso" },
  { code: "PROGRESSIVE_WEIGHT", name: "Progressivo per peso" },
];

export const parcelTypesList = [
  { code: "ENV", name: "Busta" },
  { code: "DOC", name: "Documento" },
  { code: "PKG", name: "Pacco" },
  { code: "PAL", name: "Pallet" },
];

export const surchargeTypes = [
  { code: "SURCHARGE", name: "Supplemento" },
  { code: "OPTION", name: "Opzione" },
];

export const percentageTargetTypes = [
  { code: "NOLO", name: "Nolo" },
  { code: "INSURANCE_VALUE", name: "Valore Assicurazione" },
  { code: "CASH_ON_DELIVERY", name: "Valore Contrassegno" },
  { code: "GOODS_VALUE", name: "Valore Merce" },
];

export const collectionStatusList = [
  { value: "DRAFT", label: "Bozza", color: "#c8c6c6" },
  { value: "BOOKED", label: "Prenotato", color: "#a5a3a3" },
  { value: "DELETED", label: "Cancellato", color: "#e51212" },
];

export const barcodeTypes = [
  { code: "EAN", name: "EAN" },
  { code: "ISBN", name: "ISBN" },
  { code: "UPC", name: "UPC" },
];

export const countryIsoCodes2 = [
  { code: "AF", name: "Afghanistan" },
  { code: "AX", name: "Aland Islands" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarctica" },
  { code: "AG", name: "Antigua And Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia" },
  { code: "BA", name: "Bosnia And Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazil" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo}, Democratic Republic" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Cote D'Ivoire" },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FK", name: "Falkland Islands (Malvinas)" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Island & Mcdonald Islands" },
  { code: "VA", name: "Holy See (Vatican City State)" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran}, Islamic Republic Of" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IM", name: "Isle Of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KR", name: "Korea" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: "Lao Peoples Democratic Republic" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libyan Arab Jamahiriya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia}, Federated States Of" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "AN", name: "Netherlands Antilles" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestinian Territory}, Occupied" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Reunion" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russian Federation" },
  { code: "RW", name: "Rwanda" },
  { code: "BL", name: "Saint Barthelemy" },
  { code: "SH", name: "Saint Helena" },
  { code: "KN", name: "Saint Kitts And Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "MF", name: "Saint Martin" },
  { code: "PM", name: "Saint Pierre And Miquelon" },
  { code: "VC", name: "Saint Vincent And Grenadines" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome And Principe" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "GS", name: "South Georgia And Sandwich Isl." },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard And Jan Mayen" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syrian Arab Republic" },
  { code: "TW", name: "Taiwan" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad And Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks And Caicos Islands" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UM", name: "United States Outlying Islands" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "VG", name: "Virgin Islands}, British" },
  { code: "VI", name: "Virgin Islands}, U.S." },
  { code: "WF", name: "Wallis And Futuna" },
  { code: "EH", name: "Western Sahara" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
];
