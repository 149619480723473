import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";
import { AWS_LOCATION_KEY, AWS_LOCATION_PLACE_INDEX } from "../constants/index";
import { LocationClient } from "@aws-sdk/client-location";
import { SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";

const authHelper = await withAPIKey(AWS_LOCATION_KEY); // use API Key id for credentials

const client = new LocationClient({
  region: "eu-west-1",
  ...authHelper.getLocationClientConfig(), // sets up the Location client to use the API Key defined above
});

export const searchPlaceForText = async (text) => {
  const command = new SearchPlaceIndexForTextCommand({
    Text: text,
    IndexName: AWS_LOCATION_PLACE_INDEX,
    Language: "it",
  });
  const response = await client.send(command);
  return response;
};
