import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class ServiceRulesService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;
    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;
    let url = `${API_BASE_URL}/contract-service/service-rules?page=${currentPage}&size=${rows}`;

    if (search) {
      url = url + "&search=" + search;
    }
    if (freeSearch) {
      url = url + "&freeSearch=" + freeSearch;
    }
    if (sort) {
      url = url + "&sort=" + sort;
    }

    return this.httpClient.fetch(url, requestOptions);
  }

  createServiceRule(serviceRule) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(serviceRule),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/service-rules`,
      requestOptions
    );
  }

  deleteServiceRules() {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/contract-service/service-rules`,
      requestOptions
    );
  }
}
