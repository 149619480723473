import React, { useState, useEffect, useRef } from "react";

import { ArticlesService } from "../../services/logistic/articles";
import { ArticleImagesService } from "../../services/logistic/articleImages";
import { Loader } from "../../Components/common/Loader";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { Card, CardBody } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useParams } from "react-router-dom";
import CarouselImages from "../../Components/common/CarouselImages/CarouselImages";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { getAllArticles } from "../../utilities/asyncSelectCallAPI";
import moment from "moment";

const articlesService = new ArticlesService();
const articleImagesService = new ArticleImagesService();

const Details = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(true);

  const [article, setArticle] = useState({});
  const [articleImages, setArticleImages] = useState([]);
  const [articleVariant, setArticleVariant] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");

  const getArticle = () => {
    return articlesService
      .get(id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  const getArticleImages = () => {
    return articleImagesService
      .all({
        search: [
          {
            selector: "articleId",
            value: id,
            preciseSearch: true,
          },
        ],
      })
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  useEffect(() => {
    let getArticlePromise, getArticleImagesPromise;

    if (id) {
      getArticlePromise = new Promise((resolve, reject) => {
        resolve(getArticle());
      });
      getArticleImagesPromise = new Promise((resolve, reject) => {
        resolve(getArticleImages());
      });
    }

    Promise.all([getArticlePromise, getArticleImagesPromise]).then(
      ([article, articleImages]) => {
        setArticle(article);
        setArticleImages(articleImages);
        setLoader(false);
      }
    );
  }, []);

  return (
    <React.Fragment>
      {loader ? <Loader show={loader} /> : ""}
      <div dangerouslySetInnerHTML={{ __html: "" }} />

      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Prodotti"
          breadcrumbItem={article?.name || "-"}
        />
      )}

      <div className="content" id="container">
        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">{article.name || "-"}</h5>
                  </div>
                  <div className="col-3 text-end">
                    {article.storeIntegration?.store?.logoUrl && (
                      <img
                        src={article.storeIntegration.store.logoUrl}
                        width={100}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label className="fw-semibold d-block">Rif. Interno:</label>
                    <label>{article.internalReference || "-"}</label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Rif. Esterno:</label>
                    <label>{article.externalReference || "-"}</label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Rif. Store: </label>
                    <label>{article.storeReference || "-"}</label>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Proprietario: </label>{" "}
                        <label>{article.owner?.name || "-"}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Store: </label>{" "}
                        <label>
                          {article.storeIntegration?.store?.name || "-"}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Venditore: </label>{" "}
                        <label>{article.vendor || "-"}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Tipologia: </label>{" "}
                        <label>{article.type || "-"}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Larghezza: </label>{" "}
                        <label>{article.width || "-"}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Altezza: </label>{" "}
                        <label>{article.height || "-"}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Lunghezza: </label>{" "}
                        <label>{article.length || "-"}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Peso: </label>{" "}
                        <label>{article.weight || "-"}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Data creazione: </label>{" "}
                        <label>
                          {article.storeCreationDate
                            ? moment(article.storeCreationDate)
                                .utc()
                                .format("DD/MM/YYYY HH:mm:ss")
                            : "-"}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">
                          Data aggiornamento:{" "}
                        </label>{" "}
                        <label>
                          {article.storeUpdateDate
                            ? moment(article.storeUpdateDate)
                                .utc()
                                .format("DD/MM/YYYY HH:mm:ss")
                            : "-"}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <AsyncSelect
                      name="articleVariant"
                      optionValue="id"
                      optionLabel="name"
                      placeholder="Seleziona variante del prodotto"
                      isClearable={false}
                      value={articleVariant}
                      onSearch={(selector, value) => setArticleVariant(value)}
                      loadOptions={(inputValue, pagination) => {
                        return getAllArticles(
                          inputValue,
                          pagination,
                          {
                            orderBy: "asc",
                            selector: "name",
                          },
                          [
                            {
                              selector: "parent.id",
                              value: id,
                              preciseSearch: true,
                            },
                          ]
                        );
                      }}
                    />
                  </div>
                </div>
                {articleVariant && (
                  <React.Fragment>
                    <div className="row mt-4">
                      <div className="col">
                        <h6>{articleVariant.name}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">Rif. Store: </label>{" "}
                            <label>
                              {articleVariant.storeReference || "-"}
                            </label>
                          </div>
                        </div>
                        {articleVariant.barcodeType && (
                          <div className="row">
                            <div className="col">
                              <label className="fw-semibold">
                                {articleVariant.barcodeType}:{" "}
                              </label>{" "}
                              <label>{articleVariant.barcode || "-"}</label>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">SKU: </label>{" "}
                            <label>{articleVariant.sku || "-"}</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">ASIN: </label>{" "}
                            <label>{articleVariant.asin || "-"}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">Peso: </label>{" "}
                            <label>
                              {articleVariant.weight || "-"}{" "}
                              {articleVariant.weight &&
                                articleVariant.weightUnit}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">Codice tassa:</label>{" "}
                            <label>{articleVariant.taxCode || "-"}</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Paese di origine:
                            </label>{" "}
                            <label>
                              {articleVariant.originCountryCode || "-"}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Spedizione prevista:
                            </label>{" "}
                            <label>
                              {articleVariant.shippingRequired ? "SI" : "NO"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Prezzo originale:
                            </label>{" "}
                            <label>{articleVariant.originalPrice || "-"}</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Prezzo corrente:
                            </label>{" "}
                            <label>{articleVariant.price || "-"}</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Data creazione:{" "}
                            </label>{" "}
                            <label>
                              {articleVariant.storeCreationDate
                                ? moment(articleVariant.storeCreationDate)
                                    .utc()
                                    .format("DD/MM/YYYY HH:mm:ss")
                                : "-"}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Data aggiornamento:{" "}
                            </label>{" "}
                            <label>
                              {articleVariant.storeUpdateDate
                                ? moment(articleVariant.storeUpdateDate)
                                    .utc()
                                    .format("DD/MM/YYYY HH:mm:ss")
                                : "-"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col-4">
            <Card>
              <CardBody>
                <CarouselImages
                  data={articleImages}
                  srcValue={"imageUrl"}
                  maxHeight={300}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
