import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class KPIService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  statistics(params = {}) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(params),
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/kpi/statistics/shipments`,
      requestOptions
    );
  }

  performance(params = {}) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(params),
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/kpi/performance/shipments`,
      requestOptions
    );
  }
}
