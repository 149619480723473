import React, { useState, useEffect } from "react";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import { Link } from "react-router-dom";
import { convertFromJson } from "fiql-query-builder";
import useFetch from "../../customHooks/useFetch";
import { getUniqueSelectors } from "../../utilities";

const ParcelsList = ({ shipmentId }) => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 5,
    sort: "desc",
  });
  const [queryParams, setQueryParams] = useState([
    {
      equals: {
        selector: "shipmentId",
        args: shipmentId,
      },
    },
  ]);
  const [querySearch, setQuerySearch] = useState();

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const columns = [
    {
      Header: "Rif. interno",
      accessor: "internalReference",
    },
    {
      Header: "Larghezza (cm)",
      accessor: "width",
    },
    {
      Header: "Profondità (cm)",
      accessor: "length",
    },
    {
      Header: "Altezza (cm)",
      accessor: "height",
    },
    {
      Header: "Volume (m3)",
      accessor: "volume",
      Cell: ({ row }) => {
        return row.original.volume;
      },
    },
    {
      Header: "Peso (kg)",
      accessor: "weight",
      Cell: ({ row }) => {
        return row.original.weight.toFixed(2);
      },
    },
    {
      Header: "Etichetta",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <div className="text-center">
            {row.original.url ? (
              <Link to={row.original.url} target="blank">
                <i className="ph-download cursor-pointer"></i>
              </Link>
            ) : (
              <i style={{ color: "lightgrey" }} className="ph-download"></i>
            )}
          </div>
        );
      },
    },
  ];

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const { data, loading, error, refreshData } = useFetch(
    `/shipment-service/parcels?page=${pagination.page}&size=${
      pagination.size
    }&sort=internalReference,${pagination.sort}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination]);

  return (
    <TableAdvanced
      hasFilter={true}
      columns={columns}
      data={data}
      className="custom-header-css"
      tableClass={"table-xxs"}
      paginationOpt={pagination}
      onPaginationChange={handlePagination}
      refreshTable={refreshData}
      onSearch={onSearch}
      onFreeSearch={onSearch}
      showLoader={loading}
    />
  );
};

export default ParcelsList;
