import React, { useRef } from "react";
import debounce from "lodash.debounce";

import { useListContext } from "./Providers/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Omnisearch = ({ omnisearch, className = "" }) => {
  const context = useListContext();

  const debouncedOmnisearch = useRef(
    debounce(async (value) => {
      context.onFreeSearch(value);
    }, 300)
  ).current;

  return (
    context && (
      <div className={`input-box-icon left ${className}`}>
        <FontAwesomeIcon icon={faSearch} className="icon" />
        <input
          className="input-box"
          placeholder={omnisearch.placeholder}
          onChange={(e) => {
            debouncedOmnisearch(e.target.value);
          }}
        />
      </div>
    )
  );
};

export { Omnisearch };
