import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class WarehouseArticlesService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(parentId, params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/logistic-service/warehouse-article`, params) +
        `&parentId=${parentId}`,
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/warehouse-article/${id}`,
      requestOptions
    );
  }

  create(warehouseArticle) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(warehouseArticle),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/warehouse-article`,
      requestOptions
    );
  }

  edit(warehouseArticle) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(warehouseArticle),
    };
    return this.httpProxy.fetch(
      API_BASE_URL +
        `/logistic-service/warehouse-article/${warehouseArticle.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/logistic-service/warehouse-article/${id}`,
      requestOptions
    );
  }
}
