import React, { createContext, useState, useContext } from "react";
import { WarehouseArticlesService } from "../../../services/logistic/warehouseArticles";

const warehouseArticlesService = new WarehouseArticlesService();

const CreateEditWarehouseArticleContext = createContext();

const CreateEditWarehouseArticleProvider = ({ children, unmountCallback }) => {
  const [warehouseArticle, setWarehouseArticle] = useState({
    effectiveStockQuantity: 0,
    effectiveCommittedQuantity: 0,
    blockedQuantity: 0,
    effectiveAvailableQuantity: 0,
    effectiveIncomingQuantity: 0,
  });
  const [warehouseArticleLoader, setWarehouseArticleLoader] = useState(null);
  const [warehouseArticleError, setWarehouseArticleError] = useState(null);

  const getWarehouseArticle = (id) => {
    setWarehouseArticleLoader(true);
    warehouseArticlesService
      .get(id)
      .then((res) => {
        setWarehouseArticle(res.data);
        setWarehouseArticleLoader(false);
      })
      .catch((err) => {
        setWarehouseArticleError(err);
      });
  };

  const createWarehouseArticle = (newWarehouseArticle) => {
    warehouseArticlesService
      .create(newWarehouseArticle)
      .then((res) => {
        setWarehouseArticle(res.data);
      })
      .catch((err) => {
        setWarehouseArticleError(err);
      });
  };

  const editWarehouseArticle = (warehouseArticle) => {
    warehouseArticlesService
      .edit(warehouseArticle)
      .then((res) => {
        setWarehouseArticle(warehouseArticle);
      })
      .catch((err) => {
        setWarehouseArticleError(err);
      });
  };

  return (
    <CreateEditWarehouseArticleContext.Provider
      value={{
        //#region Warehouse Article
        warehouseArticle,
        warehouseArticleLoader,
        warehouseArticleError,
        setWarehouseArticleLoader,
        getWarehouseArticle,
        createWarehouseArticle,
        editWarehouseArticle,
        //#endregion
        unmountCallback,
      }}
    >
      {children}
    </CreateEditWarehouseArticleContext.Provider>
  );
};

const useCreateEditWarehouseArticleContext = () => {
  return useContext(CreateEditWarehouseArticleContext);
};

export {
  CreateEditWarehouseArticleProvider,
  useCreateEditWarehouseArticleContext,
};
