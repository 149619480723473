import React, { useState, useEffect } from "react";
import { Form, Row, Col, Label, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import RegistryService from "../../services/registry";

const registryService = new RegistryService();

const CreateCustomer = ({ modalToogle, refreshTable, userOwnerId }) => {
  const [companyOwner, setCompanyOwner] = useState(null);

  useEffect(() => {
    registryService
      .getCompany(userOwnerId)
      .then((response) => {
        console.log("company", response);
        setCompanyOwner(response);
      })
      .catch((err) => {
        console.log("company", err);
      });
  }, []);

  const create = (body) => {
    registryService
      .createCompany(body)
      .then((response) => {
        console.log("registryService", response);
        refreshTable();
        modalToogle();
        //Probabilmente bisogna fare una funzione nel padre (notify) con il componente Toast nel padre
        toast.success("Cliente creato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel salvataggio del cliente",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "CUSTOMER",
      name: "",
      // internalReference: '',
      vatNumber: "",
      externalReference: "",
      logoDarkUrl: "",
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string().required("Campo obbligatorio"),
      // internalReference:Yup.string(),
      vatNumber: Yup.number(),
      externalReference: Yup.string(),
      logoDarkUrl: Yup.string()
        .max(500)
        .matches(
          /\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i,
          "Inserire una url valida"
        ),
    }),
    onSubmit: (values) => {
      create(values);
    },
  });

  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">Azienda:</Label>
          <Col lg={9}>
            <h3>{companyOwner?.name}</h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            Ragione sociale <span className="text-danger">*</span>
          </Label>
          <Col lg={9}>
            <Input
              type="hidden"
              name="type"
              className="form-control"
              value={"CUSTOMER"}
            />
            <Input
              type="text"
              name="name"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">PIVA</Label>
          <Col lg={9}>
            <Input
              type="number"
              name="vatNumber"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.vatNumber || ""}
              invalid={
                validation.touched.vatNumber && validation.errors.vatNumber
                  ? true
                  : false
              }
            />
            {validation.touched.vatNumber && validation.errors.vatNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.vatNumber}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">Riferimento esterno</Label>
          <Col lg={9}>
            <Input
              type="text"
              name="externalReference"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.externalReference || ""}
              invalid={
                validation.touched.externalReference &&
                validation.errors.externalReference
                  ? true
                  : false
              }
            />
            {validation.touched.externalReference &&
            validation.errors.externalReference ? (
              <FormFeedback type="invalid">
                {validation.errors.externalReference}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        {/* <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            Referente interno
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="internalReference"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.internalReference || ""}
              invalid={
                validation.touched.internalReference && validation.errors.internalReference
                  ? true
                  : false
              }
            />
            {validation.touched.internalReference && validation.errors.internalReference ? (
              <FormFeedback type="invalid">
                {validation.errors.internalReference}
              </FormFeedback>
            ) : null}
          </Col>
        </Row> */}
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">URL logo</Label>
          <Col lg={9}>
            <Input
              type="text"
              name="logoDarkUrl"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.logoDarkUrl || ""}
              invalid={
                validation.touched.logoDarkUrl && validation.errors.logoDarkUrl
                  ? true
                  : false
              }
            />
            {validation.touched.logoDarkUrl && validation.errors.logoDarkUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.logoDarkUrl}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <div className="card-footer d-flex justify-content-end">
          <Button type="submit" className="btn btn-primary ms-3">
            Salva <i className="ph-paper-plane-tilt ms-2"></i>
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CreateCustomer;
