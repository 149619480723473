import React, { useState, useEffect } from "react";
import { Form, Row, Col, Label, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";

import { calcSingleVolume, orderBykey } from "../../utilities";
import ParcelTypesService from "../../services/shipment-service/parcelTypes";
import ParcelTemplatesService from "../../services/shipment-service/parcelTemplates";

const parcelTypesService = new ParcelTypesService();
const parcelTemplatesService = new ParcelTemplatesService();

const Create = ({ modalToogle, refreshTable }) => {
  const [parcelTypes, setParcelTypes] = useState([]);

  const [parcelTypeCode, setParcelTypeCode] = useState({
    code: "PKG",
    name: "Pacco",
  });

  const getAllParcelTypes = () => {
    parcelTypesService
      .getAll()
      .then((response) => {
        setParcelTypes(orderBykey(response, "name"));
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel caricamento delle tipologie colli",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const createParcelTemplate = () => {
    parcelTemplatesService
      .create({ ...validation.values, parcelTypeCode: parcelTypeCode.code })
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success("Template collo creato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel salvataggio del template collo",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const handleSelectChange = (option, event) => {
    setParcelTypeCode(option);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      width: "",
      length: "",
      height: "",
      weight: "",
      volume: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Campo obbligatorio"),
      width: Yup.number().min(0),
      length: Yup.number().min(0),
      height: Yup.number().min(0),
      weight: Yup.number().min(0),
    }),
    onSubmit: (values) => {
      createParcelTemplate();
    },
  });

  useEffect(() => {
    const volume =
      calcSingleVolume(
        validation.values.width,
        validation.values.length,
        validation.values.height
      ) || "";

    validation.setFieldValue(
      "volume",
      volume.replace(/^0+(\d)|(\d)0+$/gm, "$1$2")
    );
  }, [
    validation.values.width,
    validation.values.length,
    validation.values.height,
  ]);

  useEffect(() => {
    getAllParcelTypes();
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <div className="row">
          <div className="col">
            <label className="form-label">Tipologia:</label>
            <Select
              name="parcelTypeCode"
              onChange={handleSelectChange}
              defaultMenuIsOpen={false}
              className="select"
              options={orderBykey(parcelTypes, "name")}
              value={
                parcelTypeCode || {
                  code: "PKG",
                  name: "Pacco",
                }
              }
              getOptionValue={(option) => option["code"]}
              getOptionLabel={(option) => option["name"]}
            />
          </div>
          <div className="col">
            <label className="form-label">Nome:</label>
            <Input
              type="text"
              name="name"
              className="form-control"
              placeholder="Nome template"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <label className="form-label">Larghezza (cm):</label>
            <Input
              type="number"
              name="width"
              className="form-control"
              placeholder="100"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.width || ""}
              invalid={
                validation.touched.width && validation.errors.width
                  ? true
                  : false
              }
            />
            {validation.touched.width && validation.errors.width ? (
              <FormFeedback type="invalid">
                {validation.errors.width}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">Profondità (cm):</label>
            <Input
              type="number"
              name="length"
              className="form-control"
              placeholder="100"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.length || ""}
              invalid={
                validation.touched.length && validation.errors.length
                  ? true
                  : false
              }
            />
            {validation.touched.length && validation.errors.length ? (
              <FormFeedback type="invalid">
                {validation.errors.length}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">Altezza (cm):</label>
            <Input
              type="number"
              name="height"
              className="form-control"
              placeholder="100"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.height || ""}
              invalid={
                validation.touched.height && validation.errors.height
                  ? true
                  : false
              }
            />
            {validation.touched.height && validation.errors.height ? (
              <FormFeedback type="invalid">
                {validation.errors.height}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <label className="form-label">Peso (kg):</label>
            <Input
              type="number"
              name="weight"
              className="form-control"
              placeholder="1"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.weight || ""}
              invalid={
                validation.touched.weight && validation.errors.weight
                  ? true
                  : false
              }
            />
            {validation.touched.weight && validation.errors.weight ? (
              <FormFeedback type="invalid">
                {validation.errors.weight}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col-4">
            <label className="form-label">Volume (m3):</label>
            <Input
              type="number"
              name="volume"
              disabled={true}
              className="form-control"
              placeholder="100"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.volume || ""}
              invalid={
                validation.touched.volume && validation.errors.volume
                  ? true
                  : false
              }
            />
            {validation.touched.volume && validation.errors.volume ? (
              <FormFeedback type="invalid">
                {validation.errors.volume}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button type="submit" className="btn btn-success ms-3">
            Salva <i className="ph-paper-plane-tilt ms-2"></i>
          </button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default Create;
