import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { useExportForInvoigincOrderContext } from "./providers/ExportForInvoicing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const ExportForInvoicing = () => {
  const exportForInvoicingOrderContext = useExportForInvoigincOrderContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {exportForInvoicingOrderContext.orders?.length > 1
              ? "Sei sicuro di voler esportare gli ordini selezionati?"
              : "Sei sicuro di voler esportare il seguente ordine?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (exportForInvoicingOrderContext.unmountCallback) {
              exportForInvoicingOrderContext.unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn ms-3"
          onClick={exportForInvoicingOrderContext.exportOrders}
          disabled={exportForInvoicingOrderContext.ordersLoader}
        >
          {exportForInvoicingOrderContext.ordersLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            "Esporta"
          )}
        </Button>
      </div>
    </form>
  );
};

export default ExportForInvoicing;
