import React, { useState, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Card, CardHeader, CardBody } from "reactstrap";
import {
  buildQuerySort,
  buildQuerySearch,
  isColorDark,
  modalSettingsDefault,
  stringToColor,
  getUniqueSelectors,
} from "../../utilities";
import { Loader } from "../../Components/common/Loader";
import TablePagination from "../../Components/common/tableAdvanced/TablePagination";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { getAllStores } from "../../utilities/asyncSelectCallAPI";
import { getStoreOptionStyle } from "../../Components/common/AsyncSelect/utilities";

const StoresIntegrations = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    size: 8,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/logistic-service/store-integrations?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Integrazioni" breadcrumbItem="Stores" />
      )}

      <div className="content" id="container">
        <Loader show={loading} />

        <Card>
          <CardBody>
            <div className="row align-items-end">
              <div className="col-3">
                <label className="form-label">Store</label>
                <AsyncSelect
                  name="storeCode"
                  onSearch={onSearch}
                  loadOptions={(inputValue, pagination) => {
                    return getAllStores(inputValue, pagination, {
                      orderBy: "asc",
                      selector: "name",
                    });
                  }}
                  optionStyle={getStoreOptionStyle}
                  isMulti={true}
                />
              </div>
            </div>
            <div className="row align-items-stretch mt-3">
              {data.content ? (
                data.content.map((data, index) => {
                  const ownerName =
                    data.ownerId === accessTokenPayload.owner
                      ? data.owner.name
                      : "Ereditato";
                  const bgColor = stringToColor(ownerName);
                  return (
                    <div className="col-3 mb-3" key={index}>
                      <Card
                        className="h-100 position-relative"
                        style={{ minHeight: 150 }}
                      >
                        <CardHeader className="text-center">
                          {data.used && (
                            <i
                              title="Configurazione utilizzata"
                              className="ph ph-link position-absolute top-0 end-0 m-3 cursor-pointer"
                            ></i>
                          )}
                          <img src={data.store.logoUrl} height={30} />
                        </CardHeader>
                        <CardBody className="text-center">
                          <label className="w-100">
                            <b>{data.name}</b>
                          </label>
                          <div
                            className={`badge ${
                              isColorDark(bgColor) ? "text-white" : "text-dark"
                            } mt-2`}
                            style={{
                              backgroundColor: bgColor,
                              whiteSpace: "normal",
                            }}
                          >
                            {ownerName}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <div className="row">
                  <div className="col">
                    <label className="text-center">
                      Nessuna integrazione da mostrare
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                <TablePagination
                  data={data}
                  onPaginationChange={handlePagination}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default StoresIntegrations;
