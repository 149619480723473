import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AddressBookService as AddressBookShipmentService } from "../../services/shipment-service/addressbook";
import { AddressBookService as AddressBookRegistryService } from "../../services/registry-service/addressbook";
import { LocationsService as LocationsShipmentService } from "../../services/shipment-service/locations";
import { LocationsService as LocationsRegistryService } from "../../services/registry-service/locations";
import { ParcelsService } from "../../services/shipment-service/parcels";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { useOidcUser } from "@axa-fr/react-oidc";
import { needsCustomData, customDataItemCheck } from "./util";
import { CustomDataService } from "../../services/shipment/customData";

const addressBookShipmentService = new AddressBookShipmentService();
const addressBookRegistryService = new AddressBookRegistryService();
const locationsShipmentService = new LocationsShipmentService();
const locationsRegistryService = new LocationsRegistryService();
const parcelsService = new ParcelsService();
const shipmentsService = new ShipmentsService();
const customDataService = new CustomDataService();

const Save = ({
  data,
  pickup,
  delivery,
  generalInformations,
  parcels,
  editShp,
  checkErrors,
  customData,
}) => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const navigate = useNavigate();
  const [isClone, setIsClone] = useState(
    useLocation().pathname.includes("clone")
  );
  const [isReturn, setIsReturn] = useState(
    useLocation().pathname.includes("return")
  );

  const [shipment, setShipment] = useState(data);

  //Create location in registry-service
  const createPickupLocationInAddressBook = () => {
    locationsRegistryService
      .create(pickup.location)
      .then((response) => {
        const newPickup = { ...pickup };
        newPickup.locationId = response.id;
        newPickup.ownerId = oidcUser.owner;
        createPickupAddressBookInAddressBook(newPickup);
      })
      .catch((exception) => {
        toast.error(`Non ho salvato la località di presa nella rubrica`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //Create addressBook in registry-service
  const createPickupAddressBookInAddressBook = (pickup) => {
    pickup.myAddress = true;
    addressBookRegistryService
      .create(pickup)
      .then()
      .catch((exception) => {
        toast.error(`Non ho salvato il contatto di presa nella rubrica`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //Edit location in registry-service
  const ediPickupLocationInAddressBook = () => {
    locationsRegistryService
      .edit(pickup.location)
      .then((response) => {
        editPickupAddressBookInAddressBook(pickup);
      })
      .catch((exception) => {
        toast.error(`Non ho salvato la località di presa nella rubrica`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //Edit addressBook in registry-service
  const editPickupAddressBookInAddressBook = (pickup) => {
    pickup.locationId = pickup.location.id;
    addressBookRegistryService
      .edit(pickup)
      .then()
      .catch((exception) => {
        toast.error(`Non ho salvato il contatto di presa nella rubrica`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //Create location in registry-service
  const createDeliveryLocationInAddressBook = () => {
    locationsRegistryService
      .create(delivery.location)
      .then((response) => {
        const newDelivery = { ...delivery };
        newDelivery.locationId = response.id;
        newDelivery.ownerId = oidcUser.owner;
        createDeliveryAddressBookInAddressBook(newDelivery);
      })
      .catch((exception) => {
        toast.error(
          `Non ho salvato la località di destinazione nella rubrica`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  //Create addressBook in registry-service
  const createDeliveryAddressBookInAddressBook = (delivery) => {
    addressBookRegistryService
      .create(delivery)
      .then()
      .catch((exception) => {
        toast.error(
          `Non ho salvato il contatto di destinazione nella rubrica`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  //Edit location in registry-service
  const editDeliveryLocationInAddressBook = () => {
    locationsRegistryService
      .edit(delivery.location)
      .then((response) => {
        editDeliveryAddressBookInAddressBook(delivery);
      })
      .catch((exception) => {
        toast.error(
          `Non ho salvato la località di destinazione nella rubrica`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  //Edit addressBook in registry-service
  const editDeliveryAddressBookInAddressBook = (delivery) => {
    delivery.locationId = delivery.location.id;
    addressBookRegistryService
      .edit(delivery)
      .then()
      .catch((exception) => {
        toast.error(
          `Non ho salvato il contatto di destinazione nella rubrica`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  //1) Delete pickup addressBook (it also cancels the location) (shipment-service)
  const deletePickupAddressBook = () => {
    if (shipment.pickupId) {
      addressBookShipmentService
        .delete(shipment.pickupId)
        .then()
        .catch((exception) => {
          toast.error(
            `Non ho cancellato il contatto e la località del mittente`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    }
  };

  //2) Create pickup location
  const createPickupLocation = (shipment) => {
    locationsShipmentService
      .create(pickup.location)
      .then((response) => {
        const newPickup = { ...pickup };
        newPickup.locationId = response.id;
        newPickup.ownerId = newPickup.ownerId || oidcUser.owner;
        createPickupAddressBook(shipment, newPickup);
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato la località del mittente`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //3) Create pickup addressBook (shipment-service)
  const createPickupAddressBook = (shipment, newPickup) => {
    addressBookShipmentService
      .create(newPickup)
      .then((response) => {
        shipment.pickupId = response.id;
        //Delete old Pickup addressBook
        deletePickupAddressBook();
        createDeliveryLocation({ ...shipment });
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato il contatto del mittente`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //4) Delete delivery addressBook (it also cancels the location) (shipment-service)
  const deleteDeliveryAddressBook = () => {
    if (shipment.deliveryId) {
      addressBookShipmentService
        .delete(shipment.deliveryId)
        .then()
        .catch((exception) => {
          toast.error(
            `Non ho cancellato il contatto e la località del destinatario`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    }
  };

  //5) Create delivery location
  const createDeliveryLocation = (shipment) => {
    locationsShipmentService
      .create(delivery.location)
      .then((response) => {
        const newDelivery = { ...delivery };
        newDelivery.locationId = response.id;
        newDelivery.ownerId = newDelivery.ownerId || oidcUser.owner;
        createDeliveryAddressBook(shipment, newDelivery);
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato la località del destinatario`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //6) Create delivery addressBook (shipment-service)
  const createDeliveryAddressBook = (shipment, newDelivery) => {
    addressBookShipmentService
      .create(newDelivery)
      .then((response) => {
        shipment.deliveryId = response.id;
        //Delete old Delivery addressBook
        deleteDeliveryAddressBook();
        saveShipment(shipment);
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato il contatto del destinatario`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //6) Save shipment (shipment-service)
  const saveShipment = (shipment) => {
    const newShipment = shipmentNormalizer(shipment);
    if (newShipment.id) {
      if (isClone || isReturn) {
        delete shipment.id;
        createShipment(newShipment);
        return false;
      }
      editShipment(newShipment);
      return false;
    }
    delete shipment.id;
    createShipment(newShipment);
    //create
  };

  //6a) Create shipment (shipment-service)
  const createShipment = (shipment) => {
    shipmentsService
      .create(shipment)
      .then((response) => {
        const newShipment = { ...response };
        createParcels(newShipment);
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato la spedizione`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //6b) Edit shipment (shipment-service)
  const editShipment = (shipment) => {
    shipmentsService
      .edit(shipment)
      .then((response) => {
        deleteParcels(shipment);
      })
      .catch((exception) => {
        editShp({ ...shipment });
        toast.error(`Non ho salvato la spedizione`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //7) Delete parcels
  const deleteParcels = (shipment) => {
    shipmentsService
      .deleteParcels(shipment.id)
      .then((response) => {
        createParcels(shipment);
      })
      .catch((exception) => {
        toast.error(`Non ho cancellato i colli della spedizione`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // 8) Create parcels (shipment-service)
  const createParcels = (shipment) => {
    const parcelsNormalized = parcelsNormalizer(shipment);
    parcelsNormalized.forEach((elem, index) => {
      parcelsService
        .create(elem)
        .then((response) => {
          if (index === parcels.length - 1) {
            deleteOptions(shipment);
          }
        })
        .catch((exception) => {
          toast.error(`Non ho salvato un collo`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    });
  };

  const deleteOptions = (shipment) => {
    shipmentsService
      .deleteOptions(shipment.id)
      .then((response) => {
        if (shipment.cashOnDeliveryValue || shipment.insuranceValue) {
          createOptions(shipment);
          return false;
        } else {
          removeCustomData(shipment);
          return false;
        }
        //navigate(`/shipments/rate/${shipment.id}`);
      })
      .catch((exception) => {
        toast.error(`Non ho cancellato le opzioni`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const createOptions = (shipment) => {
    let options = [];
    if (shipment.cashOnDeliveryValue) {
      options.push({ surchargeCode: "COD" });
    }
    if (shipment.insuranceValue) {
      options.push({ surchargeCode: "INSURANCE" });
    }
    shipmentsService
      .createOptions(options, shipment.id)
      .then((response) => {
        removeCustomData(shipment);
      })
      .catch((exception) => {
        toast.error(`Non ho salvato le opzioni`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const removeCustomData = (shipment) => {
    customDataService
      .deleteCustomData(shipment.id)
      .then((response) => {
        if (
          needsCustomData({
            pickup,
            delivery,
            parcelType: generalInformations.parcelType,
          })
        ) {
          saveCustomData(shipment);
          return false;
        }
        navigate(`/shipments/rate/${shipment.id}`);
      })
      .catch((exception) => {
        toast.error(`Non ho cancellato i dati doganali`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const saveCustomData = (shipment) => {
    let promises = [];
    customData.map((elem) => {
      elem = customDataNormalizer(elem);
      console.log("saveCustoMData post Normalize", elem);

      elem.shipmentId = shipment.id;
      elem.uuid = null;
      return promises.push(customDataService.createCustomData(elem));
    });
    console.log(promises);
    Promise.all(promises)
      .then((response) => {
        navigate(`/shipments/rate/${shipment.id}`);
      })
      .catch((exception) => {
        toast.error(`Non ho aggiunto i dati doganali`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const customDataNormalizer = (customData) => {
    let customDataNormalized = { ...customData };
    if (customData.unitPriceCurrency) {
      customDataNormalized.unitPriceCurrencyCode =
        customData.unitPriceCurrency.code;
    }
    if (customData.countryIsoCode) {
      customDataNormalized.countryIsoCode2 = customData.countryIsoCode.code;
    }
    if (customData.category) {
      customDataNormalized.categoryCode = customData.category.code;
    }
    return customDataNormalized;
  };

  const shipmentNormalizer = (shipment) => {
    let shipmentNormalized = {};
    if (shipment.pickupId) {
      shipmentNormalized.pickupId = shipment.pickupId;
    }
    if (shipment.pickupNote) {
      shipmentNormalized.pickupNote = shipment.pickupNote;
    }
    if (shipment.deliveryId) {
      shipmentNormalized.deliveryId = shipment.deliveryId;
    }
    if (shipment.deliveryNote) {
      shipmentNormalized.deliveryNote = shipment.deliveryNote;
    }
    if (generalInformations.shipmentType) {
      shipmentNormalized.shipmentType = generalInformations.shipmentType.code;
    }
    if (generalInformations.parcelType) {
      shipmentNormalized.parcelTypeCode = generalInformations.parcelType.code;
    }
    if (generalInformations.cashOnDeliveryValue) {
      shipmentNormalized.cashOnDeliveryValue =
        generalInformations.cashOnDeliveryValue;
    }
    if (generalInformations.cashOnDeliveryValueCurrency) {
      shipmentNormalized.cashOnDeliveryValueCurrency =
        generalInformations.cashOnDeliveryValueCurrency.code;
    }
    if (generalInformations.insuranceValue) {
      shipmentNormalized.insuranceValue = generalInformations.insuranceValue;
    }
    if (generalInformations.insuranceValueCurrency) {
      shipmentNormalized.insuranceValueCurrency =
        generalInformations.insuranceValueCurrency.code;
    }
    if (generalInformations.goodsValue) {
      shipmentNormalized.goodsValue = generalInformations.goodsValue;
    }
    if (generalInformations.goodsValueCurrency) {
      shipmentNormalized.goodsValueCurrency =
        generalInformations.goodsValueCurrency.code;
    }
    if (generalInformations.content) {
      shipmentNormalized.content = generalInformations.content;
    }
    if (generalInformations.externalReference) {
      shipmentNormalized.externalReference =
        generalInformations.externalReference;
    }
    if (generalInformations.note) {
      shipmentNormalized.note = generalInformations.note;
    }
    if (shipment.volume) {
      shipmentNormalized.volume = shipment.volume;
    }
    if (shipment.weight) {
      shipmentNormalized.weight = shipment.weight;
    }
    shipmentNormalized.senderId = shipment.senderId || oidcUser.owner;
    shipmentNormalized.id = shipment.id;

    return shipmentNormalized;
  };

  const parcelsNormalizer = (shipment) => {
    let newParcels = [];
    parcels.forEach((elem) => {
      let newParcel = { ...elem };
      newParcel.shipmentId = shipment.id;
      if (elem.templateId) newParcel.parcelTempleateId = elem.templateId.id;
      if (newParcel.quantity > 1) {
        for (let i = 0; i < newParcel.quantity; i++) {
          newParcels.push(newParcel);
        }
        delete newParcel.quantity;
        return true;
      }
      delete newParcel.quantity;
      newParcels.push(newParcel);
    });
    return newParcels;
  };

  const shipmentValidator = () => {
    let errors = [];

    if (!pickup.location.latitude && !pickup.location.longitude) {
      errors.push({
        section: "Mittente",
        error: "Località del mittente obbligatoria",
      });
    }

    if (!pickup.name) {
      errors.push({
        section: "Mittente",
        error: "La ragione sociale del mittente è obbligatorio",
      });
    }

    //Delivery
    if (!delivery.location.latitude && !delivery.location.longitude) {
      errors.push({
        section: "Destinatario",
        error: "Località del destinatario obbligatoria",
      });
    }

    if (!delivery.name) {
      errors.push({
        section: "Destinatario",
        error: "La ragione sociale del destinatario è obbligatorio",
      });
    }

    //Shipment
    if (!generalInformations.parcelType) {
      errors.push({
        section: "Tipologia collo",
        error: "Il parcelType non può essere nullo",
      });
    }

    //Parcels
    if (parcels.length === 0) {
      errors.push({
        section: "Colli",
        error: "Nella spedizione dev'essere presente almeno un collo",
      });
    } else {
      let parcelsCounter = 0;
      parcels.forEach((elem) => {
        parcelsCounter += parseInt(elem.quantity);
      });
      if (parcelsCounter > 100) {
        errors.push({
          section: "Colli",
          error: "Numero massimo consentito: 100 colli",
        });
      }

      parcels.every((elem) => {
        if (
          !elem.width ||
          !elem.length ||
          !elem.height ||
          !elem.weight ||
          !elem.quantity
        ) {
          errors.push({
            section: "Colli",
            error:
              "I campi Profondità, larghezza, altezza, peso e quantità sono obbligatori",
          });
          return false;
        }
      });
    }

    if (
      needsCustomData({
        pickup,
        delivery,
        parcelType: generalInformations.parcelType,
      })
    ) {
      console.log("customData", customData);
      if (customData && customData.length > 0) {
        let customDataItemErrors = [];
        customData.map((item) => {
          let itemErr = customDataItemCheck(item);
          if (itemErr) customDataItemErrors.push(itemErr);
        });
        if (customDataItemErrors && customDataItemErrors.length > 0) {
          errors.push({
            section: "Dati Doganali",
            sectionId: "customData",
            error: "Compila i dati doganali correttamente",
            innerErrors: customDataItemErrors,
          });
        }
      } else {
        errors.push({
          section: "Dati Doganali",
          sectionId: "customData",
          error: "La spedizione necessita dei dati doganali",
        });
      }
    }

    return errors;
  };

  const onSubmitShipment = () => {
    const errors = shipmentValidator(shipment);
    if (errors.length === 0) {
      if (pickup.createInAddressBook) {
        createPickupLocationInAddressBook();
      }
      if (pickup.editInAddressBook) {
        ediPickupLocationInAddressBook();
      }

      if (delivery.createInAddressBook) {
        createDeliveryLocationInAddressBook();
      }
      if (delivery.editInAddressBook) {
        editDeliveryLocationInAddressBook();
      }

      createPickupLocation({ ...shipment });
      return false;
    }
    checkErrors(errors);
    toast.error(
      `Attenzione! Alcuni dei campi obbligatori non sono compilati. Ricontrollare la spedizione`,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  useEffect(() => {
    setShipment(data);
  }, [data]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col text-start">
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={() => {
              navigate(`/shipments`);
            }}
          >
            <i className="ph-arrow-left ph-sm me-2"></i>Torna all'elenco
            spedizioni
          </button>
        </div>
        <div className="col text-end">
          <button
            color="light"
            type="button"
            className="btn btn-success"
            onClick={onSubmitShipment}
          >
            Scegli tariffa
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Save;
