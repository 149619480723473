import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class CollectionsService {
  // Initializing important variables
  constructor(domain) {
    this.httpClient = new HttpClient();
  }

  getCollection(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}`,
      requestOptions
    );
  }

  getShipmentsInCollection(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}/shipments`,
      requestOptions
    );
  }

  create(collection) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(collection),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/collections`,
      requestOptions
    );
  }

  // edit(shipment) {
  //   const requestOptions = {
  //     method: "PUT",
  //     body: JSON.stringify(shipment),
  //   };
  //   return this.httpClient.fetch(
  //     API_BASE_URL + `/shipment-service/shipments/${shipment.id}`,
  //     requestOptions
  //   );
  // }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}`,
      requestOptions
    );
  }

  book(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/collections/${id}/book`,
      requestOptions
    );
  }

  createShipmentInCollection(id, shipmentId) {
    const requestOptions = {
      method: "PUT",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/shipment-service/collections/${id}/shipments/${shipmentId}`,
      requestOptions
    );
  }
}
