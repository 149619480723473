import React from "react";
import { Button } from "reactstrap";
import { ContractsService } from "../../services/contract/contracts";
import { callErrorToast, callSuccessToast } from "../../utilities";

const contractService = new ContractsService();

const Delete = ({ id, unmountCallback }) => {
  const deleteContract = () => {
    contractService
      .delete(id)
      .then((res) => {
        callSuccessToast(res);
        unmountCallback();
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>Sei sicuro di voler eliminare questo contratto?</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-link" onClick={unmountCallback}>
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteContract}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
