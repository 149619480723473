import { getCompanyBadge } from "../../utilities";

export const userDefault = {
  email: "",
  password: "",
  name: "",
  surname: "",
  ownerId: "",
  timeZone: "",
  roles: [],
};

export const customOptionCompany = (option, test) => {
  return (
    <div className="row align-items-center">
      <div className="col">
        <label>{option.name}</label>
      </div>
      <div className="col-4 text-end">{getCompanyBadge(option.type)}</div>
    </div>
  );
};
