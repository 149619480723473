import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export default class AttachmentsService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/registry-service/attachments?`;
    url = createQueryString(url, filters);
    url += `&page=${0}&size=${20}&sort=${"asc"}&`;
    return this.httpClient.fetch(url, requestOptions);
  }
}
