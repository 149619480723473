import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";

export default class ParcelTypesService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/shipment-service/parcel-types`;
    return this.httpClient.fetch(url, requestOptions);
  }
}
