import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { DeleteOrderContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const Delete = () => {
  const { orders, ordersError, ordersLoader, deleteOrders, unmountCallback } =
    useContext(DeleteOrderContext);

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {orders?.length > 1
              ? "Sei sicuro di voler eliminare gli ordini selezionati?"
              : "Sei sicuro di voler eliminare il seguente ordine?"}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback();
            }
          }}
        >
          Annulla
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={deleteOrders}
          disabled={ordersLoader}
        >
          {ordersLoader ? <FontAwesomeIcon icon={faLoader} spin /> : "Elimina"}
        </Button>
      </div>
    </form>
  );
};

export default Delete;
