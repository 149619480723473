import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import moment from "moment";

export const useListProps = () => {
  const appContext = useAppContext();
  const language = appContext?.language;

  return {
    path: `/shipment-service/cod-collection-shipments`,
    banner: {
      enabled: false,
    },
    omnisearch: {
      enabled: true,
      placeholder: dictionary["internal_reference"][language],
    },
  };
};
