export default class API {
  constructor(domain) {
    this.API_URL_ALLABOX = process.env.REACT_APP_DOMAIN;
    this.API_URL_SHORTNER_ALLABOX = process.env.REACT_APP_DOMAIN_SHORTNER;
  }

  getURLShort() {
    return this.API_URL_SHORTNER_ALLABOX;
  }

  getURL() {
    return this.API_URL_ALLABOX;
  }
}
