import React from "react";
import AsyncSelect from "../../AsyncSelect/AsyncSelect";
import { dictionary } from "../../../../utilities/dictionary";
import { useAppContext } from "../../../../AppProvider";

export const AsyncSelectFilter = ({
  filter,
  value,
  onChange,
  isDisabled,
  searchKey,
}) => {
  const appContext = useAppContext();
  const language = appContext.language;

  return (
    <AsyncSelect
      name={filter.fieldName}
      optionStyle={filter.optionStyle}
      onSearch={(fieldName, options) => {
        if (options) {
          options = Array.isArray(options) ? options : [options];
        } else {
          options = [];
        }
        onChange(fieldName, options);
      }}
      loadOptions={filter.loadOptions}
      isMulti={filter.isMulti}
      value={value}
      placeholder={dictionary["select"][language]}
      closeMenuOnSelect={!filter.isMulti}
      isDisabled={isDisabled}
      searchKey={searchKey}
    />
  );
};
