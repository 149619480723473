import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";
import { AWS_LOCATION_KEY, AWS_LOCATION_PLACE_INDEX } from "../../constants";
import { LocationClient } from "@aws-sdk/client-location";
import { SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import AsyncSelect from "react-select/async";
import { translateLevel } from "../../pages/Locations/Zones/LocationListElement";
import { getLocationLevelBadge, locationNormalizer } from "../../utilities";

const authHelper = await withAPIKey(AWS_LOCATION_KEY); // use API Key id for credentials

const client = new LocationClient({
  region: "eu-west-1",
  ...authHelper.getLocationClientConfig(), // sets up the Location client to use the API Key defined above
});

export const LocationAutocomplete = ({
  onLocationChange,
  name,
  placeholder = "Scegli un valore",
  locationRef = null,
  className = "",
  isClearable = false,
}) => {
  const fetchLocations = async (text) => {
    const command = new SearchPlaceIndexForTextCommand({
      Text: text,
      IndexName: AWS_LOCATION_PLACE_INDEX,
      Language: "it",
    });
    const response = await client.send(command);
    return response;
  };

  const prepareLocations = (locationList) => {
    if (locationList) {
      const options = locationList.map((place) => {
        return { label: place.Place.Label, value: place.PlaceId, place };
      });
      return options;
    }
  };

  const handleChange = (select) => {
    if (onLocationChange) {
      onLocationChange(select);
    }
  };

  const handleInputChangeAsync = (select) => {
    if (select) {
      return new Promise((resolve) => {
        const geoData = fetchLocations(select).then((res) => res);
        geoData
          .then((data) => resolve(prepareLocations(data?.Results)))
          .catch((err) => console.log("err", err));
      });
    }
  };

  return (
    <>
      <AsyncSelect
        isClearable={isClearable}
        noOptionsMessage={() => <span>Nessuna località trovata</span>}
        ref={locationRef}
        cacheOptions={true}
        name={name}
        styles={{
          input: (styles) => ({
            ...styles,
            color: "var(--body-color) !important",
          }),
        }}
        isMulti={false}
        placeholder={placeholder}
        onChange={handleChange}
        loadOptions={handleInputChangeAsync}
        className={className}
        formatOptionLabel={(elem) => {
          return (
            <div className="row align-items-center">
              <div className="col">
                <label className="me-2">{elem.label}</label>
                {getLocationLevelBadge(
                  elem.place.Place.Categories
                    ? elem.place.Place.Categories[0]
                    : ""
                )}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
