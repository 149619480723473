import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities";

export class CarriersService {
  // Initializing important variables
  constructor() {
    this.httpClient = new HttpClient();
  }

  getCarriers(filters = {}) {
    const { page, size, search, freeSearch, sort } = filters;
    const requestOptions = {
      method: "GET",
    };
    const currentPage = page || 0;
    const rows = size || 10;
    let url = `${API_BASE_URL}/registry-service/carriers?page=${currentPage}&size=${rows}`;

    if (search) {
      url = url + "&search=" + search;
    }
    if (freeSearch) {
      url = url + "&freeSearch=" + freeSearch;
    }
    if (sort) {
      url = url + "&sort=" + sort;
    }

    return this.httpClient.fetch(url, requestOptions);
  }

  getCarrier(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/carriers/${code}`,
      requestOptions
    );
  }

  getCarrierSurcharges(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/carriers/${code}/surcharges`,
      requestOptions
    );
  }

  getCarrierCredentialLabels(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/carriers/${code}/credential-labels`,
      requestOptions
    );
  }

  getCashOnDeliveryCollectionMethod(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/registry-service/carriers/${code}/cash-on-delivery-collection-methods`,
      requestOptions
    );
  }

  getCarrierServices(filters = {}) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/registry-service/carrier-services?`;
    url = createQueryString(url, filters);
    url += `&page=${0}&size=${20}`;
    return this.httpClient.fetch(url, requestOptions);
  }

  getAttachmentTypes(code) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL +
        `/registry-service/carrier-services/${code}/attachment-types`,
      requestOptions
    );
  }
}
