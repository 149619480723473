import React, { useState, useEffect } from "react";
import { Alert, Card, CardBody, Spinner } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

import RuleGroup, { defaultRuleGroup } from "./ruleGroup";

const RuleGroupList = ({ list, changeRuleGroups }) => {
  const [ruleGroups, setRuleGroups] = useState(null);

  const addRuleGroup = (ruleGroup) => {
    const newRuleGroup = ruleGroup
      ? ruleGroup
      : { ...defaultRuleGroup, uuid: uuidv4(), rank: ruleGroups.length + 1 };

    const newRuleGroups = [...ruleGroups, newRuleGroup];
    setRuleGroups(newRuleGroups);
    changeRuleGroups(newRuleGroups);
  };

  const editRuleGroup = (ruleGroup) => {
    const newRuleGroups = ruleGroups.map((elem, index) => {
      if (elem.uuid === ruleGroup.uuid) {
        return { ...ruleGroup };
      }
      return { ...elem };
    });
    setRuleGroups(newRuleGroups);
    changeRuleGroups(newRuleGroups);
  };

  const removeRuleGroup = (ruleGroup) => {
    const newRuleGroups = ruleGroups.filter((elem) => {
      return elem.uuid !== ruleGroup.uuid;
    });
    setRuleGroups(newRuleGroups);
    changeRuleGroups(newRuleGroups);
  };

  useEffect(() => {
    if (list) {
      setRuleGroups([...list]);
    }
  }, [list]);

  return (
    <Card>
      <CardBody>
        <div className="row align-items-center">
          <div className="col">
            <h5 className="m-0">Tariffe</h5>
          </div>
          <div className="col text-end">
            <button
              type="button"
              className="btn btn-outline-success btn-sm btn btn-light"
              onClick={() => addRuleGroup()}
            >
              Aggiungi tariffa
              <i className="ph-plus ms-2"></i>
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <Alert color="info" className="mb-0">
              Si consiglia di utilizzare un rank più basso per tariffe più
              specifiche e un rank più alto per tariffe più generiche.
            </Alert>
          </div>
        </div>
        {!ruleGroups ? (
          <div className="row mt-3 text-center">
            <div className="col">
              <Spinner
                color="secondary"
                className={'top-50 start-50 translate-middle"'}
                size="sm"
                style={{
                  height: "5rem",
                  width: "5rem",
                }}
              ></Spinner>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col">
              {ruleGroups.length > 0 ? (
                ruleGroups.map((elem, index) => {
                  return (
                    <RuleGroup
                      key={index}
                      item={elem}
                      addRuleGroup={addRuleGroup}
                      editRuleGroup={editRuleGroup}
                      removeRuleGroup={removeRuleGroup}
                    />
                  );
                })
              ) : (
                <h6 className="text-center">Nessuna tariffa inserita</h6>
              )}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RuleGroupList;
