import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class ParcelsService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  getAll(filters) {
    const requestOptions = {
      method: "GET",
    };

    let url = `${API_BASE_URL}/shipment-service/parcels?page=${0}&size=${100}&`;
    url = createQueryString(url, filters);
    return this.httpClient.fetch(url, requestOptions);
  }

  create(parcel) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(parcel),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcels`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };

    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/parcels/${id}`,
      requestOptions
    );
  }
}
