import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { StoreIntegrationsService } from "../../../services/logistic/storeIntegrations";
import { OrdersService } from "../../../services/logistic/orders";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";
import { WarehousesService } from "../../../services/logistic/warehouses";
import { AddressBookService as LogisticAddressBookService } from "../../../services/logistic/addressBook";
import { AddressBookService as RegistryAddressBookService } from "../../../services/registry/addressBook";
import { CashOnDeliveryCollectionMethodsService } from "../../../services/registry/cashOnDeliveryCollectionMethods";
import { currenciesList } from "../../../constants";
import { normalizer as orderNormalizer } from "../utilities";
import { getCommonProperties, normalizer } from "../../AddressBook/utilities";
import {
  callErrorToast,
  modalSettingsDefault,
  valueIsEmpty,
} from "../../../utilities";

const ordersService = new OrdersService();
// const storeIntegrationsService = new StoreIntegrationsService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();
const logisticLocationsService = new LogisticLocationsService();
const logisticAddressBookService = new LogisticAddressBookService();
const warehousesService = new WarehousesService();

const CreateEditOrderContext = createContext();

const CreateEditOrderProvider = ({
  children,
  autosave = false,
  callback,
  parentId,
}) => {
  const { id } = useParams();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });

  const [newBillingContactEnabled, setNewBillingContactEnabled] =
    useState(false);

  const [order, setOrder] = useState({
    cashOnDeliveryCollectionMethodCode: { code: "CASH", name: "Contante" },
    currency: { code: "EUR", name: "Euro" },
  });
  const [orderError, setOrderError] = useState(null);
  const [orderLoader, setOrderLoader] = useState(false);

  const [warehousesError, setWarehousesError] = useState(null);

  const prevOrderError = useRef();
  const prevWarehouseError = useRef();

  const shippingCostHasChanged = useRef(false);
  const goodsValueHasChanged = useRef(false);
  const totalPriceHasChanged = useRef(false);

  const getWarehouses = () => {
    return warehousesService
      .all()
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        setWarehousesError(err);
      });
  };

  const getOrder = () => {
    setOrderLoader(true);
    ordersService
      .get(id)
      .then((res) => {
        const order = {
          ...res.data,
          currency: currenciesList.find(
            (elem) => elem.code === res.data.currency
          ),
        };

        setNewBillingContactEnabled(order.delivery?.id !== order.billing?.id);

        if (res.data.cashOnDeliveryCollectionMethodCode) {
          cashOnDeliveryCollectionMethodsService
            .get(res.data.cashOnDeliveryCollectionMethodCode)
            .then((res) => {
              setOrder({
                ...order,
                cashOnDeliveryCollectionMethodCode: res.data,
              });
              setOrderLoader(false);
            })
            .catch((err) => setOrderError(err));
          return false;
        }

        setOrder(order);
        setOrderLoader(false);
      })
      .catch((err) => setOrderError(err));
  };

  const createOrder = (save) => {
    setOrderLoader(true);

    const getWarehousesPromise = new Promise((resolve, reject) => {
      resolve(getWarehouses());
    });

    Promise.all([getWarehousesPromise]).then(([warehouses]) => {
      //2 - Viene preso il primo-> se non presente, errore
      const defaultWarehouse = warehouses[0];
      if (!defaultWarehouse) {
        setWarehousesError({
          response: {
            status: 400,
            message:
              "Nessun magazzino trovato. Crearne uno prima di inserire una nuova bolla entrata merce",
          },
        });
        return false;
      }

      const orderNormalized = orderNormalizer(
        { ...order, warehouse: defaultWarehouse },
        accessTokenPayload
      );
      ordersService
        .create(orderNormalized)
        .then((res) => {
          goodsValueHasChanged.current = true;
          shippingCostHasChanged.current = true;
          totalPriceHasChanged.current = true;
          setOrder({
            ...res.data,
            cashOnDeliveryCollectionMethodCode: {
              code: "CASH",
              name: "Contante",
            },
            currency: { code: "EUR", name: "Euro" },
          });
          setOrderLoader(false);

          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });
    });
  };

  const editOrder = (order, save) => {
    if (save || autosave) {
      const orderNormalized = orderNormalizer({ ...order }, accessTokenPayload);
      ordersService
        .edit(orderNormalized)
        .then((res) => {
          goodsValueHasChanged.current = true;
          shippingCostHasChanged.current = true;
          totalPriceHasChanged.current = true;
          setOrder(order);
          setOrderLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });

      return false;
    }

    setOrder(order);
  };

  const createEditDelivery = (contact) => {
    contact.type = "delivery";
    if (order.delivery?.id) {
      //Adding the old delivery and location IDs to the new contact
      contact = {
        ...contact,
        id: order.delivery.id,
        location: { ...contact.location, id: order.delivery.location.id },
        locationId: order.delivery.location.id,
      };
      editLocation(contact);
      return false;
    }
    createLocation(contact);
  };

  const createEditBilling = (contact) => {
    contact.type = "billing";
    //It enters into edit only when the delivery and billing IDs exist and are different
    if (
      order.billing?.id &&
      order.delivery?.id &&
      order.billing?.id !== order.delivery?.id
    ) {
      //Adding the old billing and location IDs to the new contact
      contact = {
        ...contact,
        id: order.billing.id,
        location: { ...contact.location, id: order.billing.location.id },
        locationId: order.billing.location.id,
      };
      editLocation(contact);
      return false;
    }
    createLocation(contact);
  };

  const createLocation = (contact) => {
    logisticLocationsService
      .create(contact.location)
      .then((res) => {
        createContact({
          ...contact,
          location: res.data,
          locationId: res.data.id,
        });
      })
      .catch((err) => {
        setOrderError(err);
      });
  };

  const editLocation = (contact) => {
    logisticLocationsService
      .edit(contact.location)
      .then((res) => {
        editContact(contact);
      })
      .catch((err) => {
        setOrderError(err);
      });
  };

  const createContact = (contact) => {
    logisticAddressBookService
      .create(contact)
      .then((res) => {
        switch (contact.type) {
          case "delivery":
            editOrder({ ...order, delivery: res.data, billing: res.data });
            break;
          case "billing":
            editOrder({ ...order, billing: res.data });
            break;
        }
        setModalSettings({
          ...modalSettingsDefault,
          open: false,
        });
      })
      .catch((err) => {
        setOrderError(err);
      });
  };

  const editContact = (contact) => {
    logisticAddressBookService
      .edit(contact)
      .then((res) => {
        switch (contact.type) {
          case "delivery":
            editOrder({
              ...order,
              delivery: contact,
            });
            break;
          case "billing":
            editOrder({
              ...order,
              billing: contact,
            });
            break;
        }
        setModalSettings({
          ...modalSettingsDefault,
          open: false,
        });
      })
      .catch((err) => {
        setOrderError(err);
      });
  };

  const removeError = (property) => {
    const newOrderError = { ...orderError };
    delete newOrderError.response?.data[property];
    if (!valueIsEmpty(newOrderError)) {
      prevOrderError.current = newOrderError;
      setOrderError(newOrderError);
    }
  };

  useEffect(() => {
    if (
      orderError &&
      JSON.stringify(prevOrderError.current) !== JSON.stringify(orderError)
    ) {
      callErrorToast(orderError);
    }
  }, [orderError]);

  useEffect(() => {
    let newOrder = { ...order };
    if (order.cashOnDeliveryValue) {
      newOrder.cashOnDeliveryCollectionMethodCode =
        newOrder.cashOnDeliveryCollectionMethodCode || {
          code: "CASH",
          name: "Contante",
        };
    } else {
      newOrder.cashOnDeliveryCollectionMethodCode = null;
    }
    setOrder(newOrder);
  }, [order.cashOnDeliveryValue]);

  useEffect(() => {
    if (order?.shippingCost !== undefined && shippingCostHasChanged.current) {
      const newOrder = {
        ...order,
        cashOnDeliveryValue:
          (parseFloat(order.goodsValue || 0) +
            parseFloat(order.shippingCost || 0)) *
          1.22,
      };
      editOrder(newOrder);
    }
    if (order?.shippingCost !== undefined) {
      shippingCostHasChanged.current = true;
    }
  }, [order.shippingCost]);

  useEffect(() => {
    if (order?.goodsValue !== undefined && goodsValueHasChanged.current) {
      const newOrder = {
        ...order,
        cashOnDeliveryValue:
          (parseFloat(order.goodsValue || 0) +
            parseFloat(order.shippingCost || 0)) *
          1.22,
      };
      editOrder(newOrder);
    }
    if (order?.goodsValue !== undefined) {
      goodsValueHasChanged.current = true;
    }
  }, [order.goodsValue]);

  useEffect(() => {
    if (order?.totalPrice !== undefined && totalPriceHasChanged.current) {
      const newOrder = {
        ...order,
        goodsValue: order.totalPrice,
      };
      editOrder(newOrder);
    }
    if (order?.totalPrice !== undefined) {
      totalPriceHasChanged.current = true;
    }
  }, [order.totalPrice]);

  // useEffect(() => {
  //   let newOrder = { ...order };
  //   if (order.totalPrice !== undefined) {
  //     newOrder.goodsValue = newOrder.totalPrice;
  //     editOrder(newOrder);
  //     setOrder(newOrder);
  //   }
  // }, [order.totalPrice]);

  useEffect(() => {
    if (
      warehousesError &&
      JSON.stringify(prevWarehouseError.current) !==
        JSON.stringify(warehousesError)
    ) {
      callErrorToast(warehousesError);
    }
  }, [warehousesError]);

  return (
    <CreateEditOrderContext.Provider
      value={{
        order,
        orderError,
        orderLoader,
        getOrder,
        createOrder,
        editOrder,
        createEditDelivery,
        createEditBilling,

        newBillingContactEnabled,
        setNewBillingContactEnabled,

        modalSettings,
        setModalSettings,
        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </CreateEditOrderContext.Provider>
  );
};

const useCreateEditOrderContext = () => {
  return useContext(CreateEditOrderContext);
};

export { CreateEditOrderProvider, useCreateEditOrderContext };
