import React, { createContext, useContext, useState } from "react";
import { LocationsService } from "../../../services/logistic/locations";

const locationsService = new LocationsService();

const CreateEditLocationInLogisticContext = createContext();

const CreateEditLocationInLogisticProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [locationLoader, setLocationLoader] = useState(false);

  const createLocation = (location) => {
    locationsService
      .create(location)
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => {
        setLocationError(err);
      });
  };

  const editLocation = (location) => {
    locationsService
      .edit(location)
      .then((res) => {
        setLocation(location);
      })
      .catch((err) => {
        setLocationError(err);
      });
  };

  return (
    <CreateEditLocationInLogisticContext.Provider
      value={{
        location,
        locationLoader,
        locationError,
        setLocation,
        createLocation,
        editLocation,
        // registryContact,
        // setRegistryContact,
      }}
    >
      {children}
    </CreateEditLocationInLogisticContext.Provider>
  );
};

const useLogisticContext = () => {
  return useContext(CreateEditLocationInLogisticContext);
};

export { CreateEditLocationInLogisticProvider, useLogisticContext };
