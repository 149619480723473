import React from "react";
import Select from "react-select";

const SelectAlbx = ({
  name,
  className,
  onChange,
  options = [],
  value,
  placeholder,
  isMulti = false,
  optionStyle,
  optionValue = "value",
  optionLabel = "label",
  closeMenuOnSelect = true,
  isClearable = false,
  isDisabled = false,
}) => {
  return (
    <Select
      name={name}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onChange={onChange}
      className={className}
      options={options}
      value={value}
      getOptionValue={(option) => option[optionValue]}
      getOptionLabel={(option) => option[optionLabel]}
      placeholder={placeholder}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      formatOptionLabel={(option) =>
        optionStyle
          ? optionStyle(option, optionValue, optionLabel)
          : option[optionLabel]
      }
      styles={{
        container: (provided) => ({
          ...provided,
        }),
        control: (provided, state) => ({
          ...provided,
          width: "100%", // Mantieni il controllo al 100% del contenitore
          minWidth: 0, // Rimuovi qualsiasi larghezza minima
          overflow: "hidden", // Nascondi contenuto in eccesso
          whiteSpace: "nowrap", // Impedisci ritorno a capo
          outline: "none", // Rimuove l'outline blu di default
          boxShadow: state.isFocused ? "none" : null, // Rimuove anche il box-shadow
          borderColor: state.isFocused ? "#999" : provided.borderColor, // Definisci il colore del bordo quando è in focus
          "&:hover": {
            borderColor: "#999", // Cambia il colore del bordo quando è in hover
          },
        }),
        valueContainer: (provided) => ({
          ...provided,
          display: "flex",
          flexWrap: "nowrap", // Evita che le opzioni vadano a capo
          overflow: "hidden", // Nascondi il testo in eccesso
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 2,
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? "#f5f6a8" // Colore di sfondo su hover
            : state.isSelected
            ? "#d9dfe6" // Colore di sfondo quando è selezionato
            : "#fff", // Colore di sfondo predefinito
          color: state.isSelected ? "#000" : "#333", // Colore del testo quando è selezionato
          cursor: "pointer",
          ":active": {
            backgroundColor: "#f5f7fa", // Colore di sfondo al clic
          },
        }),
      }}
    />
  );
};

export default SelectAlbx;
