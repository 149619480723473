import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

export const ExportForInvoicingOrderContext = createContext();

const ordersService = new OrdersService();

const ExportForInvoicingOrderProvider = ({
  children,
  data,
  unmountCallback,
}) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const exportOrders = () => {
    setOrdersLoader(true);
    ordersService
      .exportTeamsystem(data)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = "orders_export_teamsystem_" + Date.now() + ".csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setOrdersLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        setOrdersError(err);
        setOrdersLoader(false);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <ExportForInvoicingOrderContext.Provider
      value={{
        //#region Order
        orders,
        ordersError,
        ordersLoader,
        exportOrders,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </ExportForInvoicingOrderContext.Provider>
  );
};

const useExportForInvoigincOrderContext = () => {
  return useContext(ExportForInvoicingOrderContext);
};

export { ExportForInvoicingOrderProvider, useExportForInvoigincOrderContext };
