import moment from "moment";
import { getAllLogistics } from "../../utilities/asyncSelectCallAPI";
import {
  getOrderStatusOptionStyle,
  getLogisticOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import { writeLabelAddress } from "../../utilities";

export const allSelectedWarehousesCanBeDeleted = (
  warehouseSelectedIds = [],
  warehouses = []
) => {
  const idSelected = warehouseSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const warehousesSelected = warehouses.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllWarehousesBeDeleted(warehousesSelected);
};

export const canWarehouseBeDeleted = (warehouse) => {
  // if (
  //   warehouse.status === "BOOKED" &&
  //   !warehouse.carrier.warehouseDeleteEnabled
  // ) {
  //   return false;
  // }
  return true;
};

export const canAllWarehousesBeDeleted = (warehouses) => {
  if (!warehouses || warehouses.length === 0) return false;
  const selectedWarehousesCanBeDeleted = warehouses.map((x) =>
    canWarehouseBeDeleted(x)
  );
  return selectedWarehousesCanBeDeleted.every((x) => x);
};

// //#region Index columns
export const columns = [
  {
    position: 1,
    Header: "Logistica",
    accessor: "logisticIntegration.logistic.code",
    filter: {
      active: true,
      name: "logisticIntegration.logisticCode",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllLogistics(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getLogisticOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.logisticIntegration?.logistic?.logoUrl) {
        return (
          <img width={50} src={row.logisticIntegration?.logistic?.logoUrl} />
        );
      }
      return row.logisticIntegration?.name || "-";
    },
  },
  {
    position: 2,
    Header: "Nome",
    accessor: "name",
  },
  {
    position: 3,
    Header: "Località",
    accessor: "location",
    Cell: ({ cell }) => {
      return cell.row.original.location
        ? writeLabelAddress(cell.row.original.location)
        : "-";
    },
  },
];
// //#endregion

export const warehouseNormalizer = (warehouse, accessTokenPayload) => {
  warehouse.logisticIntegrationId = warehouse.logisticIntegration?.id;
  warehouse.ownerId = warehouse.ownerId || accessTokenPayload.owner;
  warehouse.locationId = warehouse.location?.id;
  return warehouse;
};
