import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import { ArticleImagesService } from "../../../services/logistic/articleImages";
import {
  defaultArticleImage,
  defaultArticleVariant,
  normalizer as articleNormalizer,
} from "../utilities";
import { callErrorToast } from "../../../utilities";

export const IndexArticleImagesContext = createContext();

const articleImagesService = new ArticleImagesService();

const IndexArticleImagesProvider = ({ children, autosave, callback }) => {
  const { id } = useParams();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [articleImages, setArticleImages] = useState([]);
  const [articleImagesError, setArticleImagesError] = useState(null);
  const [articleImagesLoader, setArticleImagesLoader] = useState(true);

  const getArticleImages = () => {
    articleImagesService
      .all({
        search: [
          {
            selector: "article.id",
            value: id,
            preciseSearch: true,
          },
        ],
      })
      .then((res) => {
        setArticleImages(res.data?.content || []);
        setArticleImagesLoader(false);
      })
      .catch((err) => {
        setArticleImagesError(err);
      });
  };

  // const createArticleImage = () => {
  //   articleImagesService
  //     .create({ ...defaultArticleImage, articleId: article.id })
  //     .then((res) => {
  //       setArticleImages([...articleImages, { ...res.data }]);
  //     })
  //     .catch((err) => {
  //       setArticleImagesError(err);
  //     });
  // };

  // const editArticleImage = (articleImage) => {
  //   articleImagesService
  //     .edit({ ...articleImage, articleId: article.id })
  //     .then((res) => {
  //       setArticleImages([
  //         ...articleImages.map((elem) => {
  //           if (elem.id === articleImage.id) {
  //             return articleImage;
  //           }
  //           return elem;
  //         }),
  //       ]);
  //     })
  //     .catch((err) => {
  //       setArticleImagesError(err);
  //     });
  // };

  // const deleteArticleImage = (id) => {
  //   articleImagesService
  //     .delete(id)
  //     .then((res) => {
  //       setArticleImages([...articleImages.filter((elem) => elem.id !== id)]);
  //     })
  //     .catch((err) => {
  //       setArticleImagesError(err);
  //     });
  // };

  useEffect(() => {
    if (articleImagesError) {
      callErrorToast(articleImagesError);
    }
  }, [articleImagesError]);

  return (
    <IndexArticleImagesContext.Provider
      value={{
        articleImages,
        articleImagesError,
        articleImagesLoader,
        getArticleImages,
        // createArticleImage,
        // editArticleImage,
        // deleteArticleImage,

        callback,
        autosave,
      }}
    >
      {children}
    </IndexArticleImagesContext.Provider>
  );
};

const useIndexArticleImagesContext = () => {
  return useContext(IndexArticleImagesContext);
};

export { IndexArticleImagesProvider, useIndexArticleImagesContext };
