import React from "react";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "reactstrap";

const shipmentsService = new ShipmentsService();

const Delete = ({ id, callback }) => {
  const deleteShipment = () => {
    shipmentsService
      .delete(id)
      .then((response) => {
        if (callback) {
          callback(true);
        }
        toast.success("Spedizione cancellata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(() => {
        toast.error("Spedizione non cancellata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>Sei sicuro di voler eliminare la seguente spedizione?</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (callback) {
              callback(false);
            }
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteShipment}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
