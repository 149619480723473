import React from "react";
import { Route, Routes } from "react-router-dom";
import AllaboxTemplate from "../Layouts/VerticalLayouts";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import { authProtetedRoutes, publicRoutes } from "./allRoutes";
import { OidcSecure } from "@axa-fr/react-oidc";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        {authProtetedRoutes.map((route, id) => (
          <React.Fragment key={id}>
            <Route
              element={
                <OidcSecure>
                  {route.template ? (
                    <AllaboxTemplate
                      pageName={route.name}
                      pageIcon={route.icon}
                    >
                      {route.element}
                    </AllaboxTemplate>
                  ) : (
                    route.element
                  )}
                </OidcSecure>
              }
              path={route.path}
              exact={false}
            />
          </React.Fragment>
        ))}
        {publicRoutes.map((route, id) => {
          return (
            <React.Fragment key={id}>
              <Route
                element={
                  <NonAuthLayout header={route.header}>
                    {route.element}
                  </NonAuthLayout>
                }
                path={route.path}
                exact={true}
              />
            </React.Fragment>
          );
        })}
      </Routes>
    </React.Fragment>
  );
};

export default Index;
