import { writeLabelAddress } from "../../utilities";

export const locationNormalizer = (location) => {
  if (location) {
    const place = location.place?.Place || location.Place;
    const locationNormalized = {
      countryIsoCode3: place.Country || null,
      houseNumber: place.AddressNumber || null,
      postalCode: place.PostalCode || null,
      street: place.Street || null,
      municipality: place.Municipality || null,
      district: place.Neighborhood || null,
      subRegion: place.SubRegion || null,
      subRegionName: place.SubRegion || null,
      label: place.Label || null,
      region: place.Region || null,
      regionName: place.Region || null,
      unit: place.Unit || null,
      languageCode: "IT" || null,
      matchLevelCode: place.Categories[0] || null,
      latitude: place.Geometry.Point[0] || null,
      longitude: place.Geometry.Point[1] || null,
    };

    // locationNormalized.label = writeLabelAddress(locationNormalized);
    return locationNormalized;
  }
  return null;
};
