import React from "react";
import { CollectionsService } from "../../services/shipment/collections";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast, callSuccessToast } from "../../utilities";

const collectionsService = new CollectionsService();

const Delete = ({ collections, unmountCallback }) => {
  const deleteCollections = () => {
    const promises = collections.map((elem) => {
      return collectionsService.delete(elem.id);
    });
    Promise.all(promises)
      .then((response) => {
        unmountCallback();
        callSuccessToast({ status: 200 });
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  return (
    <React.Fragment>
      <form>
        <div className="row mb-3">
          <div className="col">
            {collections.length > 1
              ? "Sei sicuro di voler eliminare i ritiri selezionati?"
              : "Sei sicuro di voler eliminare il seguente ritiro?"}
            <label></label>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-link"
            onClick={() => {
              if (unmountCallback) {
                unmountCallback(false);
              }
            }}
          >
            Annulla
          </button>
          <Button className="btn btn-danger ms-3" onClick={deleteCollections}>
            Elimina
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Delete;
