import { useAppContext } from "../AppProvider";
import { dictionary } from "./dictionary";

export const useTypes = () => {
  const appContext = useAppContext();
  const language = appContext?.language;

  const currencies = [
    { value: "EUR", label: "€" },
    { value: "USD", label: "$" },
    { value: "GBR", label: "£" },
  ];

  const shipments = [
    { value: "EXPRESS", label: dictionary["express"][language] },
    { value: "EXPRESS_RETURN", label: dictionary["express_return"][language] },
    { value: "POSTAL", label: dictionary["postal"][language] },
  ];

  const parcels = [
    { value: "ENV", label: dictionary["envelope"][language] },
    { value: "DOC", label: dictionary["document"][language] },
    { value: "PKG", label: dictionary["package"][language] },
    { value: "PAL", label: dictionary["pallet"][language] },
  ];

  const orderStatus = [
    { value: "DRAFT", label: dictionary["draft"][language] },
    { value: "CONFIRMED", label: dictionary["confirmed"][language] },
    { value: "RECEIVED", label: dictionary["received"][language] },
    { value: "CANCELLED", label: dictionary["cancelled"][language] },
    { value: "PROCESSING", label: dictionary["processing"][language] },
    { value: "SHIPPED", label: dictionary["shipped"][language] },
    { value: "ERROR", label: dictionary["error"][language] },
  ];

  const shipmentStatus = [
    {
      value: "DRAFT",
      label: dictionary["draft"][language],
      color: "#C8C6C6",
    },
    {
      value: "BOOKED",
      label: dictionary["booked"][language],
      color: "#A5A3A3",
    },
    {
      value: "DELETED",
      label: dictionary["cancelled"][language],
      color: "#E51212",
    },
    {
      value: "CONFIRMED",
      label: dictionary["confirmed"][language],
      color: "#7E7D7D",
    },
    {
      value: "AVAILABLE",
      label: dictionary["available"][language],
      color: "#EF7414",
    },
    {
      value: "IN_TRANSIT",
      label: dictionary["in_transit"][language],
      color: "#70AFE2",
    },
    {
      value: "OUT_OF_DLV",
      label: dictionary["out_of_delivery"][language],
      color: "#EFA448",
    },
    {
      value: "EXCEPTION",
      label: dictionary["delivery_exception"][language],
      color: "#CB6656",
    },
    {
      value: "FAILED_DLV",
      label: dictionary["failed_delivery"][language],
      color: "#B489C9",
    },
    {
      value: "DELIVERED",
      label: dictionary["delivered"][language],
      color: "#5DBB85",
    },
    {
      value: "INFO_RCVD",
      label: dictionary["info_rcvd"][language],
      color: "#294978",
    },
    {
      value: "RET_SENDER",
      label: dictionary["ret_sender"][language],
      color: "#585a60",
    },
  ];

  const confirmationOptions = [
    { value: "true", label: dictionary["yes"][language], isBool: false },
    { value: "false", label: dictionary["no"][language], isBool: false },
  ];

  const confirmationBoolOptions = [
    { value: "true", label: dictionary["yes"][language], isBool: true },
    { value: "false", label: dictionary["no"][language], isBool: true },
  ];

  return {
    currencies,
    shipments,
    parcels,
    orderStatus,
    shipmentStatus,
    confirmationOptions,
    confirmationBoolOptions,
  };
};
