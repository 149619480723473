import React, { createContext, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { LogisticIntegrationsService } from "../../../services/logistic/logisticIntegrations";
import { WarehousesService } from "../../../services/logistic/warehouses";

const logisticIntegrationsService = new LogisticIntegrationsService();
const warehousesService = new WarehousesService();

const CreateEditWarehouseContext = createContext();

const CreateEditWarehouseProvider = ({ children }) => {
  const { id } = useParams();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [warehouse, setWarehouse] = useState(null);
  const [warehouseError, setWarehouseError] = useState(null);
  const [warehouseLoader, setWarehouseLoader] = useState(true);

  const [logisticIntegration, setLogisticIntegration] = useState(null);
  const [logisticIntegrationError, setLogisticIntegrationError] =
    useState(null);
  const [logisticIntegrationLoader, setLogisticIntegrationLoader] =
    useState(false);

  const [logisticIntegrationsError, setLogisticIntegrationsError] =
    useState(null);

  const getLogisticIntegrations = () => {
    return logisticIntegrationsService
      .all()
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        setLogisticIntegrationsError(err);
      });
  };

  const getLogisticIntegration = (id) => {
    logisticIntegrationsService
      .get(id)
      .then((res) => {
        setLogisticIntegration(res.data);
      })
      .catch((err) => {
        setLogisticIntegrationError(err);
      });
  };

  const createWarehouse = () => {
    const getLogisticIntregrationsPromise = new Promise((resolve, reject) => {
      resolve(getLogisticIntegrations());
    });
    Promise.all([getLogisticIntregrationsPromise]).then(
      ([logisticIntegrations]) => {
        const defaultLogisticIntegration = logisticIntegrations[0];
        if (!defaultLogisticIntegration) {
          setLogisticIntegrationError({
            response: {
              status: 400,
              message:
                "Nessuna integrazione logistica trovata. Crearne una prima di inserire un nuovo magazzino",
            },
          });
          return false;
        }

        const newWarehouse = {
          logisticIntegrationId: defaultLogisticIntegration.id,
          logisticIntegration: defaultLogisticIntegration,
          name: "Senza nome",
        };

        warehousesService
          .create(newWarehouse)
          .then((res) => {
            setWarehouse(res.data);
            setWarehouseLoader(false);
          })
          .catch((err) => {
            setWarehouseError(err);
          });

        setLogisticIntegration(defaultLogisticIntegration);
      }
    );
  };

  const editWarehouse = (warehouse) => {
    warehousesService
      .edit(warehouse)
      .then((res) => {
        setWarehouse(warehouse);
      })
      .catch((err) => {
        setWarehouseError(err);
      });
  };

  const getWarehouse = () => {
    warehousesService
      .get(id)
      .then((res) => {
        setWarehouse({ ...res.data });
        setWarehouseLoader(false);
      })
      .catch((err) => setWarehouseError(err));
  };

  return (
    <CreateEditWarehouseContext.Provider
      value={{
        //#region warehouse
        warehouse,
        warehouseError,
        warehouseLoader,
        createWarehouse,
        editWarehouse,
        getWarehouse,
        //#endregion
        //#region logistic integration
        logisticIntegrationsError,

        logisticIntegration,
        logisticIntegrationError,
        logisticIntegrationLoader,
        getLogisticIntegration,
        setLogisticIntegration,
        //#endregion
      }}
    >
      {children}
    </CreateEditWarehouseContext.Provider>
  );
};

const useWarehouseContext = () => {
  return useContext(CreateEditWarehouseContext);
};

export { CreateEditWarehouseProvider, useWarehouseContext };
