import React from "react";
import { Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";

import RegistryService from "../../services/registry";

const registryService = new RegistryService();

const Delete = ({ companyId, modalToogle, refreshTable }) => {
  const deleteCompany = () => {
    registryService
      .deleteCompanies(companyId)
      .then((response) => {
        refreshTable();
        modalToogle();
        //Probabilmente bisogna fare una funzione nel padre (notify) con il componente Toast nel padre
        toast.success("Affiliato eliminato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((exception) => {
        // let message = createMessageError(exception);

        console.log(exception);
        toast.error(
          "Si sono verificati dei problemi nel'eliminazione dell'affiliato",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>Sei sicuro di voler eliminare questo affiliato?</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            refreshTable();
            modalToogle();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteCompany}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
