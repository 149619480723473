export const doughnutCenterTextPlugin = (text) => {
  return {
    id: "centerText",
    beforeDraw: function (chart) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      ctx.restore();

      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text.toLocaleString(navigator.language), textX, textY);
      ctx.save();
    },
  };
};

export const doughnutOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const dataset = tooltipItem.dataset.data;
          const total = dataset.reduce((acc, value) => acc + value, 0);
          const currentValue = dataset[tooltipItem.dataIndex];
          const percentage = ((currentValue / total) * 100).toFixed(2);

          // Restituisce l'etichetta con il valore e la percentuale
          return `${tooltipItem.label}: ${currentValue} (${percentage}%)`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
};
