import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class AddressTranscodesService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  create(addressTranscode) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(addressTranscode),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/registry-service/address-transcode`,
      requestOptions
    );
  }
}
