import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";
import { AddressBookService } from "../../services/registry-service/addressbook";

const addressBookService = new AddressBookService();

const Delete = ({ addressBookId, modalToogle, refreshTable, entity }) => {
  const deleteAddressBook = () => {
    addressBookService
      .delete(addressBookId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success(
          `${
            entity === "destination"
              ? "Destinazione cancellata"
              : "Indirizzo cancellato"
          } correttamente`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nella cancellazione dell'addressbook",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{`Sei sicuro di voler eliminare ${
            entity === "destination"
              ? "la seguente Destinazione"
              : "il seguente Indirizzo"
          }?`}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteAddressBook}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
