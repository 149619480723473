import { useState } from "react";

export const Checkbox = ({ label, checked, onChange }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark"></span>
      {label && <span className="span-1">{label}</span>}
    </label>
  );
};
