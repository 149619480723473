import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import moment from "moment";

export const useListProps = () => {
  const appContext = useAppContext();
  const language = appContext?.language;

  return {
    path: "/registry-service/exports",
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary["creation_date"][language],
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary["creation_date"][language],
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary["my_exports"][language],
    omnisearch: {
      enabled: false,
      placeholder: dictionary["references"][language],
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary["creation_date"][language],
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    filters: {
      name: "export",
      preserve: false,
      list: [
        {
          fieldName: "creationUser",
          label: dictionary["creation_user"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "user@allabox.it",
          primary: true,
        },
        {
          fieldName: "type",
          label: dictionary["type"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "teamSystem",
          primary: true,
        },
        {
          fieldName: "description",
          label: dictionary["description"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "MyExport",
          primary: true,
        },
      ],
    },
  };
};
