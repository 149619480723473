import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { ShipmentsService } from "../../services/shipment-service/shipments";
import moment from "moment";
import { carrierLogos, isColorDark } from "../../utilities";
import "../../assets/scss/pages/tracking.scss";
import {
  createTimelineStatusPoints,
  eventsNormalizer,
  getStyle,
  notFoundDefault,
  takenChargeStatus,
} from "./utilities";
import Event from "./Event";
import { Footer } from "../../Components/common/Footer";
import { useAppContext } from "../../AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const shipmentsService = new ShipmentsService();

const Tracking = () => {
  const { reference1, reference2 } = useParams();
  let shipmentRefFromUrl, senderRefFromUrl;
  if (reference1 && reference2) {
    senderRefFromUrl = reference1;
    shipmentRefFromUrl = reference2;
  } else if (reference1) {
    shipmentRefFromUrl = reference1;
  }

  const refSearchButton = useRef(null);
  const [shipmentReference, setShipmentReference] =
    useState(shipmentRefFromUrl);
  const [senderReference, setSenderReference] = useState(senderRefFromUrl);
  const [lastSearch, setLastSearch] = useState(null);

  const [latestTrackingEvent, setLatestTrackingEvent] = useState(null);
  const [trackingEvents, setTrackingEvents] = useState(null);
  const [timelineStatusPoints, setTimelineStatusPoints] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  const appContext = useAppContext();

  const primaryColor = appContext.brandSettings?.primaryColor || "#191919";
  const secondaryColor = appContext.brandSettings?.secondaryColor || "#e1e506";
  const style = getStyle(primaryColor, secondaryColor);

  const getPublicTracking = () => {
    let error;
    setLoader(true);
    setLastSearch(shipmentReference);
    shipmentsService
      .getPublicTracking(
        encodeURIComponent(shipmentReference),
        senderReference ? encodeURIComponent(senderReference) : null
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 204) {
          setTrackingEvents(null);
          setTimelineStatusPoints(
            createTimelineStatusPoints(takenChargeStatus)
          );
          setLatestTrackingEvent(takenChargeStatus);
        } else if (response?.data) {
          setLatestTrackingEvent(response.data[0]);
          setTimelineStatusPoints(createTimelineStatusPoints(response.data[0]));
          setTrackingEvents(eventsNormalizer(response.data));
        } else {
          error = notFoundDefault;
        }
        setError(error);
      })
      .catch((err) => {
        setLoader(false);
        setLastSearch(null);
        setLatestTrackingEvent(null);
        setError(notFoundDefault);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      refSearchButton.current.click();
    }
  };

  useEffect(() => {
    if (shipmentReference) {
      getPublicTracking();
    }

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <React.Fragment>
      <style>
        {style.timeline}
        {style.buttons}
      </style>
      <div className="page-content bg-color-1">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content mb-4">
              <div className="row mt-4 justify-content-center">
                <div className="col-xxl-3 col-xl-3 col-lg-4">
                  <div
                    className="card my-card border-color-2"
                    style={{
                      borderColor: secondaryColor,
                    }}
                  >
                    {appContext.brandSettings?.logoDarkUrl && (
                      <div className="text-center">
                        <img
                          src={appContext.brandSettings.logoDarkUrl}
                          style={{ maxHeight: "40px", maxWidth: "100px" }}
                        />
                      </div>
                    )}
                    <label className="my-card-label-title mt-3 text-center">
                      Cerca la tua spedizione
                    </label>
                    <div className="input-box mt-3">
                      <label className="label-input" htmlFor="trackingCode">
                        Tracking Code
                      </label>
                      <input
                        type="text"
                        name="trackingCode"
                        className="my-input"
                        placeholder="23-C7E0901C"
                        onChange={(e) => setShipmentReference(e.target.value)}
                        value={shipmentReference || ""}
                      />
                    </div>
                    <div className="button-box mt-3">
                      <button
                        id="search-button"
                        className="button button-primary w-100"
                        onClick={getPublicTracking}
                        disabled={loader || shipmentReference?.length === 0}
                        ref={refSearchButton}
                      >
                        {loader ? (
                          <i className="spinner-border spinner-border-sm"></i>
                        ) : (
                          "Cerca"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-7 col-lg-8">
                  {(latestTrackingEvent || error) && (
                    <div
                      className="card my-card border-color-2 px-4 mb-4"
                      style={{
                        borderColor: secondaryColor,
                      }}
                    >
                      <h1>Dov’è il mio pacco?</h1>
                      {error && (
                        <div
                          className="alert alert-warning fade show mb-0"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      {latestTrackingEvent && (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-6 d-none d-lg-block d-xl-block d-xxl-block">
                              <div className="tracking-code-box">
                                <label className="tracking-code-title">
                                  Tracking code
                                </label>
                                <span
                                  className="tracking-code"
                                  style={{
                                    backgroundColor: secondaryColor,
                                    color: isColorDark(secondaryColor)
                                      ? "#fff"
                                      : "#000",
                                  }}
                                >
                                  {lastSearch}
                                </span>
                              </div>
                            </div>
                            {latestTrackingEvent.carrierLogoUrl && (
                              <div className="col-6 text-end d-none d-lg-block d-xl-block d-xxl-block">
                                <img
                                  width={75}
                                  src={latestTrackingEvent.carrierLogoUrl}
                                  title={latestTrackingEvent.carrierName}
                                />
                              </div>
                            )}
                            {latestTrackingEvent.carrierLogoUrl && (
                              <div className="col-12 text-center d-block d-lg-none d-xl-none d-xxl-none">
                                <img
                                  width={75}
                                  src={latestTrackingEvent.carrierLogoUrl}
                                  title={latestTrackingEvent.carrierName}
                                />
                              </div>
                            )}
                            <div className="col-12 d-block d-lg-none d-xl-none d-xxl-none">
                              <div className="tracking-code-box">
                                <label className="tracking-code-title">
                                  Tracking code
                                </label>
                                <span
                                  className="tracking-code w-100"
                                  style={{
                                    borderColor: secondaryColor,
                                  }}
                                >
                                  {shipmentReference}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr className="dotted" />
                          <div className="row">
                            <div className="col text-center">
                              <div
                                style={{
                                  backgroundColor:
                                    latestTrackingEvent.status.color,
                                }}
                                className={`status-container`}
                              >
                                <label>La tua spedizione risulta</label>
                                <span className={`status-name`}>
                                  {latestTrackingEvent.status.name}
                                </span>
                                {latestTrackingEvent.eventDate && (
                                  <span className="status-event-date">
                                    in data{" "}
                                    <b>
                                      {moment(
                                        latestTrackingEvent.eventDate
                                      ).format("DD/MM/YYYY HH:mm:ss")}
                                    </b>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4 d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
                            <div className="col">
                              <div className="timeline-horizontal-container">
                                <ol>
                                  <li
                                    className={`${
                                      timelineStatusPoints["BOOKED"]
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="event-container">
                                      <span className="event-name">
                                        REGISTRATA
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className={`${
                                      timelineStatusPoints["IN_TRANSIT"]
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="event-container">
                                      <span className="event-name">
                                        IN TRANSITO
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className={`${
                                      timelineStatusPoints["OUT_OF_DLV"]
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="event-container">
                                      <span className="event-name">
                                        IN CONSEGNA
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className={`${
                                      timelineStatusPoints["DELIVERED"]
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="event-container">
                                      <span className="event-name">
                                        CONSEGNATA
                                      </span>
                                    </div>
                                  </li>
                                  {timelineStatusPoints["EXCEPTION"] && (
                                    <li className="EXCEPTION">
                                      <i className="ph-warning"></i>
                                    </li>
                                  )}
                                  {timelineStatusPoints["RET_SENDER"] && (
                                    <li className="RET_SENDER">
                                      <FontAwesomeIcon
                                        icon={faArrowRotateLeft}
                                      />
                                      <i className="ph-warning"></i>
                                    </li>
                                  )}
                                </ol>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2 justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-10 col-xxl-10">
                              {trackingEvents && (
                                <div className="all-events-container">
                                  {Object.entries(trackingEvents).map(
                                    ([day, eventsOfDay], key) => {
                                      return (
                                        <div
                                          className="events-of-day-container"
                                          key={key}
                                        >
                                          <div className="events-of-day-header">
                                            <span>{day}</span>
                                          </div>
                                          <div className="events-of-day-body">
                                            {eventsOfDay.map((elem, i) => {
                                              return (
                                                <Event
                                                  key={i}
                                                  data={elem}
                                                  formatDate={"HH:mm"}
                                                />
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tracking;
