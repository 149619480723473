import React, { useState, useEffect, useRef } from "react";
import TableContainer from "../../Components/common/TableContainer";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

import CreateEditSurcharge from "./createEditSurcharge";
import { getSurchargeType } from "../../utilities";
import { percentageTargetTypes } from "../../constants";

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const SurchargeList = ({ list, carrierCode, changeOptionsAndSurcharges }) => {
  const [optionsAndSurcharges, setOptionsAndSurcharges] = useState(list);
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
    },
    {
      Header: "Tipologia",
      accessor: "onDemand",
      Cell: (cell) => {
        const row = cell.row.original;
        const type =
          row.surcharge.onDemand === false && row.surcharge.canBeRated === true
            ? "SURCHARGE"
            : row.surcharge.onDemand === true &&
              row.surcharge.canBeRated === true
            ? "OPTION"
            : "";
        return getSurchargeType(type);
      },
    },
    {
      Header: "Prezzo fisso",
      accessor: "fixedPrice",
      Cell: (cell) => {
        const row = cell.row.original;
        if (row.fixedPrice) {
          return parseFloat(row.fixedPrice).toFixed(2) + " €";
        }
        return "-";
      },
    },
    {
      Header: "Prezzo percentuale",
      accessor: "percentagePrice",
      Cell: (cell) => {
        const row = cell.row.original;
        if (row.percentagePrice) {
          return parseFloat(row.percentagePrice).toFixed(2) + " %";
        }
        return "-";
      },
    },
    {
      Header: "Riferito a",
      accessor: "percentageTarget",
      Cell: (cell) => {
        const row = cell.row.original;
        if (row.percentageTarget) {
          return percentageTargetTypes.find(
            (elem) => elem.code === row.percentageTarget
          )?.name;
        }
        return "-";
      },
    },
    {
      Header: "Action",
      Cell: (cell) => {
        const row = cell.row.original;
        const isSurcharge =
          row.surcharge.onDemand === false && row.surcharge.canBeRated === true;

        const type =
          row.surcharge.onDemand === false && row.surcharge.canBeRated === true
            ? "SURCHARGE"
            : row.surcharge.onDemand === true &&
              row.surcharge.canBeRated === true
            ? "OPTION"
            : "";

        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: `Modifica ${
                      isSurcharge ? "supplemento" : "opzione"
                    }`,
                    icon: "ph-note-pencil",
                    open: !modalSettings.open,
                    action: "edit",
                    surcharge: row,
                    type: type,
                    size: "lg",
                  });
                }}
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: `Duplica ${isSurcharge ? "supplemento" : "opzione"}`,
                    icon: "ph-users",
                    open: !modalSettings.open,
                    action: "clone",
                    surcharge: row,
                    type: type,
                    size: "lg",
                  });
                }}
              >
                <i className="ph-users me-2"></i>
                Duplica
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() => {
                  deleteOptionOrSurcharge(row.uuid);
                }}
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const addOrEditOptionOrSurcharge = (optionOrSurcharge) => {
    let newOptionsAndSurcharges = [];
    //Se azione è diversa da clona e ha un uuid siamo in edit
    if (modalSettings.action !== "clone" && optionOrSurcharge.uuid) {
      newOptionsAndSurcharges = optionsAndSurcharges.map((elem, index) => {
        if (elem.uuid === optionOrSurcharge.uuid) {
          return { ...optionOrSurcharge };
        }
        return { ...elem };
      });
    } else {
      optionOrSurcharge.uuid = uuidv4();
      newOptionsAndSurcharges = [...optionsAndSurcharges, optionOrSurcharge];
    }

    setOptionsAndSurcharges(newOptionsAndSurcharges);
    changeOptionsAndSurcharges(newOptionsAndSurcharges);
    setModalSettings({ ...modalSettingsDefault });
  };

  const deleteOptionOrSurcharge = (uuid) => {
    let newOptionsAndSurcharges = optionsAndSurcharges.filter(
      (elem) => elem.uuid !== uuid
    );

    setOptionsAndSurcharges(newOptionsAndSurcharges);
    changeOptionsAndSurcharges(newOptionsAndSurcharges);
  };

  useEffect(() => {
    setOptionsAndSurcharges([...list]);
  }, [list]);

  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-outline-success btn-sm btn btn-light"
            onClick={() =>
              setModalSettings({
                ...modalSettings,
                title: "Nuova opzione",
                icon: "ph-plus",
                open: !modalSettings.open,
                action: "create",
                type: "OPTION",
                size: "lg",
              })
            }
          >
            Aggiungi opzione
            <i className="ph-plus ms-2"></i>
          </button>
          <button
            type="button"
            className="btn btn-outline-success btn-sm btn btn-light ms-2"
            onClick={() =>
              setModalSettings({
                ...modalSettings,
                title: "Nuovo supplemento",
                icon: "ph-plus",
                open: !modalSettings.open,
                action: "create",
                type: "SURCHARGE",
                size: "lg",
              })
            }
          >
            Aggiungi supplemento
            <i className="ph-plus ms-2"></i>
          </button>
        </div>
      </div>
      {optionsAndSurcharges.length > 0 ? (
        <TableContainer
          columns={columns}
          data={optionsAndSurcharges}
          customPageSize={10}
          className="custom-header-css"
          fixedHeader={false}
          sort={false}
          tableClass={"table-xxs"}
        />
      ) : (
        <h6 className="text-center mt-3">
          Nessun opzione e supplemento inserito
        </h6>
      )}
      <Modal
        fade
        isOpen={modalSettings.open}
        className="modal-content"
        direction="end"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {/* {modalSettings.action === "create" && (
            <Create modalToogle={modalToggle} refreshTable={fetchData} />
          )} */}
          <CreateEditSurcharge
            data={modalSettings.surcharge}
            carrierCode={carrierCode}
            dataType={modalSettings.type}
            addOrEdit={addOrEditOptionOrSurcharge}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SurchargeList;
