import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ZonesService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/registry-service/zones`, params),
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/zones/${id}`,
      requestOptions
    );
  }

  create(zone) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(zone),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/zones`,
      requestOptions
    );
  }

  edit(zone) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(zone),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/zones/${zone.id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/zones/${id}`,
      requestOptions
    );
  }

  createLocationInZone(zoneId, location) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(location),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/registry-service/zones/${zoneId}/locations`,
      requestOptions
    );
  }
}
