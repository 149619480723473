import React, { useEffect, useRef, useState } from "react";
import { useListContext } from "../Providers/List";
import Select from "react-select";
import { dictionary } from "../../../../utilities/dictionary";
import debounce from "lodash.debounce";
import { useAppContext } from "../../../../AppProvider";
import { TextFilter } from "./TextFilter";
import { AsyncSelectFilter } from "./AsyncSelectFilter";
import { SelectFilter } from "./SelectFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroomWide,
  faCirclePlus,
  faMinusCircle,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { DatePickerFilter } from "./DatePickerFilter";
import { Collapse } from "reactstrap";

const Filters = React.memo(({ selectors = [], title, preserve = false }) => {
  const context = useListContext();
  const { language } = useAppContext();

  const [filters, setFilters] = useState(context.defaultFilters);
  const [isOpen, setIsOpen] = useState(false);

  const onClear = () => {
    context.onClear([]);
    setFilters({});
  };

  // Debounce function for searching
  const debouncedSearch = useRef(
    debounce((name, value, preciseSearch, forcedFilters) => {
      if (forcedFilters) {
        context.onForceSearch(forcedFilters);
        return false;
      }
      context.onSearch(name, value, preciseSearch, forcedFilters);
    }, 300)
  ).current;

  // Handle input change and debounce search
  const handleInputChange = (name, value, preciseSearch) => {
    let forcedFilters;
    setFilters((prev) => {
      const updatedFilters = { ...prev, [name]: value || undefined };
      selectors.forEach((s) => {
        const disablingParam = s.disablingParams?.find(
          (p) => p.fieldName === name
        );
        if (disablingParam && filters[s.fieldName]) {
          delete updatedFilters[s.fieldName];
          forcedFilters = { ...updatedFilters };
        }
      });
      if (!value || value?.length === 0) delete updatedFilters[name];

      if (title && preserve) {
        localStorage.setItem(
          title + "_filters",
          JSON.stringify(updatedFilters)
        );
      }
      return updatedFilters;
    });
    debouncedSearch(name, value, preciseSearch, forcedFilters);
  };

  // Handle select change
  const handleSelectChange = (name, value) => {
    setFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [name]: value?.length ? value : undefined,
      };
      if (!value?.length) delete updatedFilters[name];

      if (title && preserve) {
        localStorage.setItem(
          title + "_filters",
          JSON.stringify(updatedFilters)
        );
      }

      return updatedFilters;
    });
    context.onSearch(name, value, true);
  };

  // Common filter rendering function
  const renderFilter = (item) => {
    const FilterComponent = {
      text: TextFilter,
      "async-select": AsyncSelectFilter,
      select: SelectFilter,
      datepicker: DatePickerFilter,
    }[item.type];

    return (
      <FilterComponent
        key={item.fieldName}
        filter={item}
        value={
          filters[item.fieldName] || (item.type === "datepicker" ? [] : "")
        }
        onChange={
          item.type === "datepicker" ? handleSelectChange : handleInputChange
        }
        placeholder={item.placeholder}
        isDisabled={item.disablingParams?.every(
          (p) => !filters[p.fieldName] || !filters[p.fieldName]?.length > 0
        )}
        searchKey={item.searchKey}
      />
    );
  };

  // Separate primary and secondary filters
  const [primaryFilters, secondaryFilters] = [
    selectors.filter((item) => item.primary),
    selectors.filter((item) => !item.primary),
  ];

  return (
    context && (
      <div className="filters">
        <div className="row mb-2">
          <div className="col">
            <h3 className="m-0">{dictionary["filter_by"][language]}</h3>
          </div>
          <div className="col text-end">
            <button className="button-label-1" onClick={onClear}>
              <FontAwesomeIcon icon={faBroomWide} />{" "}
              {dictionary["clear"][language]}
            </button>
            {secondaryFilters.length > 0 && (
              <button
                className="button-label-1"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <>
                    <FontAwesomeIcon icon={faMinusCircle} />{" "}
                    {dictionary["filters"][language]}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCirclePlus} />{" "}
                    {dictionary["filters"][language]}
                  </>
                )}
              </button>
            )}
          </div>
        </div>

        <div className="row">
          {primaryFilters.map((item, index) => (
            <div className={`col-${item.col || 3} mb-2`} key={index}>
              <label className="label-1 mb-1">{item.label}</label>
              {renderFilter(item)}
            </div>
          ))}
        </div>

        <Collapse
          isOpen={isOpen}
          className="accordion-collapse mt-2"
          aria-controls="true"
        >
          <div className="row">
            {secondaryFilters.map((item, index) => (
              <div className={`col-${item.col || 3} mb-2`} key={index}>
                <label className="label-1 mb-1">{item.label}</label>
                {renderFilter(item)}
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    )
  );
});

export { Filters };
