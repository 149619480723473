import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../AppProvider";

const AllaboxTemplate = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem("sidebarOpen") === "true"
  );
  const contentRef = useRef(null);

  const appContext = useAppContext();

  const toggleSidebar = () => {
    localStorage.setItem("sidebarOpen", !isSidebarOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        appContext.setScrollTop(contentRef.current.scrollTop);
      }
    };

    const content = contentRef.current;

    if (content) {
      content.addEventListener("scroll", handleScroll);
    }
    appContext.setContentRef(contentRef);

    return () => {
      if (content) {
        content.removeEventListener("scroll", handleScroll);
      }
    };
  }, [contentRef]);

  return (
    <div className="app">
      <Header
        pageName={props.pageName}
        isSidebarOpen={isSidebarOpen}
        pageIcon={props.pageIcon}
        toggleMenu={toggleSidebar}
      />
      <div
        className={`sidebar-toggle ${
          isSidebarOpen ? "sidebar-toggle-open" : ""
        }`}
        onClick={toggleSidebar}
      >
        <div className="sidebar-shape d-none d-lg-block"></div>
        <div className="sidebar-arrow d-none d-lg-block">
          {isSidebarOpen ? (
            <FontAwesomeIcon size="lg" icon={faChevronLeft} />
          ) : (
            <FontAwesomeIcon size="lg" icon={faChevronRight} />
          )}
        </div>
      </div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`page-content content-unshift ${
          isSidebarOpen ? "content-shift" : ""
        }`}
      >
        <div className="content-wrapper">
          <div className="content-inner" ref={contentRef}>
            <div className="content" id="container">
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default AllaboxTemplate;
