import { API_BASE_URL } from "../../constants";
import { buildUrl, createQueryString } from "../../utilities";
import HttpInterface from "../../helpers/HttpInterface";

export class CustomDataService {
  constructor(domain) {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/shipment-service/custom-data`, params),
      requestOptions
    );
  }

  deleteCustomData(shipmentId) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${shipmentId}/custom-data`,
      requestOptions
    );
  }

  createCustomData(customData) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(customData),
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/custom-data`,
      requestOptions
    );
  }
}
