import React from "react";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getAllRegistryContacts } from "../../utilities/asyncSelectCallAPI";
import { writeLabelAddress } from "../../utilities";

export const QuickView = ({ defaultContact, callback, showSelect = true }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  return (
    <React.Fragment>
      {showSelect && (
        <div className="row mb-2">
          <div className="col">
            <label className="mb-1">Scegli contatto:</label>
            <AsyncSelect
              name="contact"
              optionValue="id"
              optionLabel="name"
              value={defaultContact}
              isClearable={false}
              onSearch={(selector, value) => {
                delete value.id;
                delete value.location.id;
                if (callback) {
                  callback(value);
                }
              }}
              loadOptions={(inputValue, pagination) => {
                return getAllRegistryContacts(
                  inputValue,
                  pagination,
                  {
                    orderBy: "asc",
                    selector: "name",
                  },
                  [
                    { selector: "myAddress", value: true, preciseSearch: true },
                    {
                      selector: "ownerId",
                      value: accessTokenPayload.owner,
                      preciseSearch: true,
                    },
                  ]
                );
              }}
              optionStyle={(option) => {
                return option.name + " - " + writeLabelAddress(option.location);
              }}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <label>Ragione sociale</label>
          <p className="fw-bold">{defaultContact?.name || "-"}</p>
        </div>
        <div className="col">
          <label>Nome</label>
          <p className="fw-bold">{defaultContact?.contactName || "-"}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>SDI</label>
          <p className="fw-bold">{defaultContact?.sdi || "-"}</p>
        </div>
        <div className="col">
          <label>Partita IVA</label>
          <p className="fw-bold">{defaultContact?.vatNumber || "-"}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Email</label>
          <p className="fw-bold">{defaultContact?.email || "-"}</p>
        </div>
        <div className="col">
          <label>Telefono</label>
          <p className="fw-bold">{defaultContact?.phone || "-"}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Rif. Cliente</label>
          <p className="fw-bold">{defaultContact?.externalReference || "-"}</p>
        </div>
        <div className="col">
          <label>PEC</label>
          <p className="fw-bold">{defaultContact?.pec || "-"}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Codice fiscale</label>
          <p className="fw-bold">{defaultContact?.fiscalCode || "-"}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Indirizzo</label>
          <p className="fw-bold">
            {writeLabelAddress(defaultContact?.location) || "-"}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
