import React from "react";
import { useConfirmShipmentContext } from "./providers/Confirm";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";

export const Confirm = () => {
  const appContext = useAppContext();
  const language = appContext.language;

  const context = useConfirmShipmentContext();
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary["confirm_shipment"][language]}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {context.shipments?.length > 1
              ? dictionary["ask_confirm_shipments"][language]
              : dictionary["ask_confirm_shipment"][language]}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary["cancel"][language]}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={() => context.confirmShipments()}
          disabled={context.shipmentsLoader}
          loading={context.shipmentsLoader}
        />
      </div>
    </React.Fragment>
  );
};
