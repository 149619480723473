import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callErrorToast, modalDefault } from "../../../utilities";
import { CodCollectionsService } from "../../../services/shipment/codCollections";
import { dictionary } from "../../../utilities/dictionary";
import { useAppContext } from "../../../AppProvider";

const codCollectionsService = new CodCollectionsService();

export const DetailsCodCollectionContext = createContext();

const DetailsCodCollectionProvider = ({ children }) => {
  const appContext = useAppContext();
  const language = appContext.language;

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [codCollection, setCodCollection] = useState(null);
  const [codCollectionLoader, setCodCollectionLoader] = useState(true);
  const [codCollectionError, setCodCollectionError] = useState(null);

  const prevCodCollectionError = useRef();

  const getCodCollection = (id) => {
    setCodCollectionLoader(true);
    codCollectionsService
      .get(id)
      .then((response) => {
        setCodCollection(response.data);
        setCodCollectionLoader(false);
      })
      .catch((err) => {
        setCodCollectionError(err);
        setCodCollectionLoader(false);
      });
  };

  useEffect(() => {
    if (
      codCollectionError &&
      JSON.stringify(prevCodCollectionError.current) !==
        JSON.stringify(codCollectionError)
    ) {
      callErrorToast(codCollectionError);
    }
  }, [codCollectionError]);

  return (
    <DetailsCodCollectionContext.Provider
      value={{
        title: dictionary["cod_collection_details"][language],
        modal,
        setModal,

        getCodCollection,
        codCollection,
        codCollectionLoader,
      }}
    >
      {children}
    </DetailsCodCollectionContext.Provider>
  );
};

const useDetailsCodCollectionContext = () => {
  return useContext(DetailsCodCollectionContext);
};

export { DetailsCodCollectionProvider, useDetailsCodCollectionContext };
