import React, { useState, useEffect } from "react";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useOidcUser, useOidcIdToken, useOidc } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";

import InputBox from "../../Components/common/InputBox";
import CompaniesService from "../../services/registry-service/companies";
import { UsersService } from "../../services/user/users";
import { getCompanyBadge, timezones, valueIsEmpty } from "../../utilities";
import ResetPassword from "../Users/ResetPassword";
import { dictionary } from "../../utilities/dictionary";
import { Loader } from "../../Components/common/Loader";

const usersService = new UsersService();
const companiesService = new CompaniesService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  userId: null,
  action: "",
};

const User = () => {
  const { oidcUser } = useOidcUser();
  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const getUser = () => {
    usersService
      .getUser(oidcUser.id)
      .then((response) => {
        response = response.data;
        timezones.forEach((elem) => {
          const timezone = elem.options.find(
            (option) => option.value === response.timeZone
          );
          if (timezone) {
            response.timeZone = timezone;
          }
        });

        setUser(response);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero dell'utenza",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getCompany = () => {
    companiesService
      .getCompany(oidcUser.owner)
      .then((response) => {
        setCompany(response);
      })
      .catch((err) => {
        toast.error("Si sono verificati dei problemi nel recupero dell'owner", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const saveUser = () => {
    const errors = userValidation();
    if (valueIsEmpty(errors)) {
      const userNormalized = userNormalizer(user);
      usersService
        .edit(userNormalized)
        .then((response) => {
          toast.success("Utente modificato correttamente", {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((err) => {
          let message = "";
          if (err.code === "ERR_NETWORK") {
            message = "Errore di rete, verifica la connessione.";
          }
          switch (err.response.status) {
            case 400:
              message = "400 - BadRequest";
              break;
            case 404:
              message = "404 - Utente non trovato";
              break;
            case 409:
              message = "409 - Utente già registrato";
              break;
            case 500:
              message = "500 - Internal server error.";
              break;
          }
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }

    setErrors(errors);
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });

    if (errors) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleTimezonesSelectChange = (option) => {
    setUser({ ...user, timeZone: option });
  };

  const userValidation = () => {
    let errors = {};
    var { name, surname, email, owner, roles, password, confirmPassword } =
      user;

    if (valueIsEmpty(name)) errors["name"] = dictionary["nullfield"]["it"];
    if (valueIsEmpty(surname))
      errors["surname"] = dictionary["nullfield"]["it"];
    if (valueIsEmpty(email)) errors["email"] = dictionary["nullfield"]["it"];

    return errors;
  };

  const userNormalizer = (user) => {
    const userNormalized = { ...user };
    userNormalized.ownerId = userNormalized.owner.id;
    userNormalized.timeZone = userNormalized.timeZone?.value;
    userNormalized.roles = userNormalized.roles.map((elem) => {
      return { code: elem.code };
    });
    return userNormalized;
  };

  useEffect(() => {
    if (oidcUser) {
      getUser();
      getCompany();
    }
  }, [oidcUser]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Il mio profilo"
          breadcrumbItem={
            (oidcUser?.name || "") + " " + (oidcUser?.surname || "")
          }
        />
      )}

      {loading ? <Loader show={loading} /> : ""}

      <div className="content" id="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="fw-semibold d-block">Azienda:</label>
                    <label className="me-1">{company?.name}</label>
                    {company?.type && getCompanyBadge(company.type)}
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Ruolo:</label>
                    {user.roles?.map((elem, key) => {
                      return <label key={key}>{elem.code}</label>;
                    })}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <label className="form-label">Nome:</label>
                    <InputBox
                      name="name"
                      value={user.name || ""}
                      placeholder={"Mario"}
                      type="text"
                      onChange={handleInputChange}
                      error={errors && errors["name"]}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Cognome:</label>
                    <InputBox
                      name="surname"
                      value={user.surname || ""}
                      placeholder={"Rossi"}
                      type="text"
                      onChange={handleInputChange}
                      error={errors && errors["surname"]}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label className="form-label">Email:</label>
                    <InputBox
                      name="email"
                      value={user.email || ""}
                      placeholder={"mario.rossi@email.it"}
                      type="text"
                      onChange={handleInputChange}
                      error={errors && errors["email"]}
                      disabled
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Timezone:</label>
                    <Select
                      name="timeZone"
                      onChange={handleTimezonesSelectChange}
                      defaultMenuIsOpen={false}
                      className="select"
                      options={timezones}
                      value={user.timeZone}
                      maxMenuHeight={200}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="d-flex justify-content-between">
                    <Button
                      type="button"
                      className="btn btn-light"
                      onClick={() =>
                        setModalSettings({
                          ...modalSettings,
                          title: "Reset password",
                          icon: "ph-lock",
                          open: !modalSettings.open,
                          action: "resetPassword",
                        })
                      }
                    >
                      Reset Password
                    </Button>
                    <Button type="button" className="btn" onClick={saveUser}>
                      Salva
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <Modal isOpen={modalSettings.open} className="modal-content">
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action == "resetPassword" && (
            <ResetPassword
              userId={user.id}
              modalToogle={modalToggle}
              refreshTable={getUser}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default User;
