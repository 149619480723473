import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import Select from "react-select";
import InputBox from "../../Components/common/InputBox";
import { getAllCarrierConfigurations } from "../../utilities/asyncSelectCallAPI";
import { getCarrierConfigurationOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import Flatpickr from "react-flatpickr";
import { FormFeedback } from "reactstrap";
import {
  contractDefault,
  contractNormalizer,
  contractValidation,
  getCarrierConfiguration,
} from "./utilities";
import {
  callErrorToast,
  callSuccessToast,
  contractTypes,
  getShipmentType,
  shipmentTypes,
  valueIsEmpty,
} from "../../utilities";
import { ContractsService } from "../../services/contract/contracts";

const contractsService = new ContractsService();

const CreateEdit = ({ id, unmountCallback, isClone = false }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [contract, setContract] = useState({
    ...contractDefault,
  });
  const [errors, setErrors] = useState({});

  const getContract = () => {
    contractsService
      .get(id)
      .then((response) => {
        let contract = { ...response.data };
        contract.shipmentType = shipmentTypes.find(
          (elem) => elem.code === contract.shipmentType
        );
        contract.type = contractTypes.find(
          (elem) => elem.value === contract.type
        );
        contract.validityFrom = new Date(contract.validityFrom);
        contract.validityTo = new Date(contract.validityTo);
        setContract(contract);
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  const create = (contract) => {
    contractsService
      .create(contract)
      .then((res) => {
        unmountCallback(res);
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  const edit = (contract) => {
    contractsService
      .edit(contract)
      .then((res) => {
        callSuccessToast(res);
        unmountCallback();
      })
      .catch((err) => {
        callErrorToast(err);
      });
  };

  const handleAsyncSelectChange = (selector, value) => {
    if (value) {
      delete errors[selector];
      setErrors(errors);
    }
    setContract({
      ...contract,
      [selector]: value,
    });
  };

  const handleSelectChange = (option, event) => {
    if (option) {
      delete errors[event.name];
      setErrors(errors);
    }
    setContract({
      ...contract,
      [event.name]: option,
    });
  };

  const handleInputChange = (event) => {
    if (event.target.value) {
      delete errors[event.target.name];
      setErrors(errors);
    }
    setContract({
      ...contract,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeTimePicker = (name, value) => {
    setContract({
      ...contract,
      [name]: value,
    });
  };

  const saveContract = () => {
    const errors = contractValidation(contract);
    if (valueIsEmpty(errors)) {
      const normalized = contractNormalizer(contract, accessTokenPayload.owner);
      if (id && !isClone) {
        edit(normalized);
        return false;
      }
      create(normalized);
      return false;
    }
    setErrors(errors);
  };

  useEffect(() => {
    if (id) {
      getContract();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <div className="col">
            <label className="form-label">Configurazione vettore:</label>
            <AsyncSelect
              name="carrierConfiguration"
              optionValue="id"
              optionLabel="name"
              value={contract.carrierConfiguration}
              onSearch={handleAsyncSelectChange}
              loadOptions={(inputValue, pagination) => {
                return getAllCarrierConfigurations(inputValue, pagination, {
                  orderBy: "asc",
                  selector: "name",
                });
              }}
              optionStyle={getCarrierConfigurationOptionStyle}
              error={errors["carrierConfiguration"]}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 align-items-start">
        <div className="col">
          <label className="form-label">Nome:</label>
          <InputBox
            name="name"
            value={contract.name || ""}
            type="text"
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </div>
        <div className="col">
          <label className="form-label">Rif.Esterno:</label>
          <InputBox
            name="externalReference"
            value={contract.externalReference || ""}
            type="text"
            onChange={handleInputChange}
            error={errors["externalReference"]}
          />
        </div>
      </div>
      <div className="row mt-3 align-items-start">
        <div className="col">
          <label className="form-label">Descrizione:</label>
          <InputBox
            name="description"
            value={contract.description || ""}
            type="text"
            onChange={handleInputChange}
            error={errors["description"]}
          />
        </div>
        <div className="col">
          <label className="form-label">Tipo contratto:</label>
          <Select
            name="type"
            value={contract.type || ""}
            onChange={handleSelectChange}
            options={contractTypes}
            getOptionValue={(option) => option["value"]}
            getOptionLabel={(option) => option["label"]}
            error={errors["type"]}
            className={`${errors["type"] && "is-invalid"}`}
          />
          {errors["type"] && (
            <FormFeedback type="invalid">{errors["type"]}</FormFeedback>
          )}
        </div>
      </div>
      <div className="row mt-3 align-items-start">
        <div className="col">
          <label className="form-label">Inizio validità:</label>
          <Flatpickr
            className={`form-control date-buttons ${
              errors["validityFrom"] && "is-invalid"
            }`}
            placeholder="Seleziona orario"
            options={{
              dateFormat: "d/m/Y",
              defaultDate: new Date(),
            }}
            onChange={(date, dateString) =>
              onChangeTimePicker("validityFrom", date[0])
            }
            value={contract.validityFrom}
          />
          {errors["validityFrom"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["validityFrom"]}
            </FormFeedback>
          )}
        </div>
        <div className="col">
          <label className="form-label">Fine validità:</label>
          <Flatpickr
            className={`form-control date-buttons ${
              errors["validityTo"] && "is-invalid"
            }`}
            placeholder="Seleziona orario"
            options={{
              dateFormat: "d/m/Y",
              defaultDate: new Date(new Date().getFullYear(), 11, 31),
            }}
            onChange={(date, dateString) =>
              onChangeTimePicker("validityTo", date[0])
            }
            value={contract.validityTo}
          />
          {errors["validityTo"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["validityTo"]}
            </FormFeedback>
          )}
        </div>
      </div>
      <div className="row mt-3 align-items-start">
        <div className="col-6">
          <label className="form-label">Tipologia spedizione:</label>
          <Select
            name="shipmentType"
            value={contract.shipmentType || ""}
            onChange={handleSelectChange}
            options={shipmentTypes}
            getOptionValue={(option) => option["code"]}
            getOptionLabel={(option) => option["label"]}
            error={errors["shipmentType"]}
            className={`${errors["shipmentType"] && "is-invalid"}`}
          />
          {errors["shipmentType"] && (
            <FormFeedback type="invalid">{errors["shipmentType"]}</FormFeedback>
          )}
        </div>
      </div>

      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (unmountCallback) {
                  unmountCallback();
                }
              }}
            >
              Annulla
            </button>
            <Button type="button" className="btn ms-3" onClick={saveContract}>
              Salva
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateEdit;
