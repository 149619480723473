import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Collapse,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";

import Rule, { defaultRule } from "./rule";
import { ZonesService } from "../../services/registry-service/zones";
import InputBox from "../../Components/common/InputBox";

const zonesService = new ZonesService();

export const defaultRuleGroup = {
  zoneIdFrom: "",
  zoneIdTo: "",
  rank: "",
  rules: [],
};

const RuleGroup = ({ item, addRuleGroup, editRuleGroup, removeRuleGroup }) => {
  const [ruleGroup, setRuleGroup] = useState({});
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [zones, setZones] = useState([]);

  const getAllZones = (inputValue) => {
    const filters = {
      freeSearch: inputValue,
      sort: "name,asc",
    };
    return zonesService
      .getZones(filters)
      .then((response) => {
        if (inputValue) {
          return response.content;
        }
        setZones(response.content || []);
      })
      .catch((exception) => {
        toast.error(`Non ho recuperato le zone`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const toggleCollapse = () => setCollapseIsOpen(!collapseIsOpen);

  const handleSelectZonesChange = (option, event) => {
    const newRuleGroup = {
      ...ruleGroup,
      [event.name]: option,
    };
    setRuleGroup(newRuleGroup);
    editRuleGroup(newRuleGroup);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newRuleGroup = {
      ...ruleGroup,
      [name]: value,
    };
    setRuleGroup(newRuleGroup);
    editRuleGroup(newRuleGroup);
  };

  const loadOptions = (inputValue) => {
    if (inputValue) {
      return getAllZones(inputValue);
    }
  };

  const cloneRuleGroup = () => {
    const newRuleGroup = { ...ruleGroup };
    delete newRuleGroup.id;
    newRuleGroup.uuid = uuidv4();
    newRuleGroup.rules = newRuleGroup.rules.map((elem) => {
      let newElem = { ...elem };
      delete newElem.id;
      return { ...newElem, uuid: uuidv4() };
    });

    addRuleGroup(newRuleGroup);
  };

  const addRule = () => {
    let newRule = { ...defaultRule, uuid: uuidv4() };
    if (ruleGroup.rules.length > 0) {
      newRule.weightFrom = ruleGroup.rules[ruleGroup.rules.length - 1].weightTo;
    }
    const newRuleGroup = {
      ...ruleGroup,
      rules: [...ruleGroup.rules, { ...newRule }],
    };

    setRuleGroup(newRuleGroup);
    editRuleGroup(newRuleGroup);
    setCollapseIsOpen(true);
  };

  const editRule = (rule) => {
    const isValid = validateRule(rule);
    if (isValid) {
      const newRules = ruleGroup.rules.map((elem, index) => {
        if (elem.uuid === rule.uuid) {
          return { ...rule };
        }
        return { ...elem };
      });

      setRuleGroup({ ...ruleGroup, rules: [...newRules] });
      editRuleGroup({ ...ruleGroup, rules: [...newRules] });

      return false;
    }
  };

  const removeRule = (rule) => {
    const newRules = ruleGroup.rules.filter((elem) => {
      return elem.uuid !== rule.uuid;
    });
    editRuleGroup({ ...ruleGroup, rules: [...newRules] });
    setRuleGroup({ ...ruleGroup, rules: [...newRules] });
  };

  const validateRule = (rule) => {
    let isValid = true;
    const indexRule = ruleGroup.rules.findIndex(
      (elem) => elem.uuid === rule.uuid
    );

    const previousRule = ruleGroup.rules[indexRule - 1];
    const nextRule = ruleGroup.rules[indexRule + 1];

    const ruleWeightFrom = rule.weightFrom ? parseFloat(rule.weightFrom) : null;
    const ruleWeightTo = rule.weightTo ? parseFloat(rule.weightTo) : null;
    const previousRuleWeightFrom = previousRule?.weightFrom
      ? parseFloat(previousRule.weightFrom)
      : null;
    const previousRuleWeightTo = previousRule?.weightTo
      ? parseFloat(previousRule.weightTo)
      : null;
    const nextRuleWeightFrom = nextRule?.weightFrom
      ? parseFloat(nextRule.weightFrom)
      : null;
    const nextRuleWeightTo = nextRule?.weightTo
      ? parseFloat(nextRule.weightTo)
      : null;

    //Se la regola non è nè la prima nè l'ultima
    if (indexRule !== 0 && indexRule !== ruleGroup.rules.length - 1) {
      console.log("non è ne la prima ne l'ultima");
      //Se la regola è la prima
    } else if (indexRule === 0) {
      //Se c'è più di una regola
      if (ruleGroup.rules.length > 1) {
        //Devo controllare che il numero inserito nel FROM non sia maggiore del TO della riga 0 e del FROM della riga 1
        if (ruleWeightFrom > ruleWeightTo) {
          isValid = false;
          toast.error(
            "Il peso DA non può essere maggiore del peso A della stessa regola"
          );
        }
        if (ruleWeightFrom > nextRuleWeightFrom) {
          isValid = false;
          toast.error(
            "Il peso DA non può essere maggiore del peso DA della regola successiva"
          );
        }
      }
      //Se la regola è l'ultima
    } else if (indexRule === ruleGroup.rules.length - 1) {
      console.log("");
    }

    return isValid;
  };

  useEffect(() => {
    setRuleGroup({ ...item });
  }, [item]);

  useEffect(() => {
    getAllZones();
  }, []);

  return (
    <Card className="mb-3">
      <CardHeader className="bg-light">
        <div className="row align-items-end">
          <div className="col-1">
            <Label>Rank</Label>
            <InputBox
              type="positive-integer-number"
              value={ruleGroup.rank}
              onChange={handleInputChange}
              name="rank"
            />
          </div>
          <div className="col">
            <Label>Zona di partenza</Label>
            <AsyncSelect
              loadOptions={loadOptions}
              name="zoneIdFrom"
              value={ruleGroup.zoneIdFrom || ""}
              noOptionsMessage={() => <div>Nessuna zona trovata</div>}
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => option["name"]}
              onChange={handleSelectZonesChange}
              defaultOptions={zones}
              maxMenuHeight={150}
            />
          </div>
          <div className="col">
            <Label>Zona di arrivo</Label>
            <AsyncSelect
              loadOptions={loadOptions}
              name="zoneIdTo"
              value={ruleGroup.zoneIdTo || ""}
              noOptionsMessage={() => <div>Nessuna zona trovata</div>}
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => option["name"]}
              onChange={handleSelectZonesChange}
              defaultOptions={zones}
              maxMenuHeight={150}
            />
          </div>
          <div className="col text-end">
            <button
              type="button"
              className="btn btn-outline-success btn-sm btn btn-light"
              onClick={addRule}
            >
              Aggiungi Regola
              <i className="ph-plus ms-2"></i>
            </button>
            <button
              title="Duplica tariffa"
              type="button"
              className="btn btn-outline-light btn-icon rounded-pill ms-2"
              onClick={cloneRuleGroup}
            >
              <i className="ph-users"></i>
            </button>
            <button
              title="Elimina tariffa"
              type="button"
              className="btn btn-flat-danger btn-icon rounded-pill ms-2"
              onClick={() => removeRuleGroup(ruleGroup)}
            >
              <i className="ph-trash"></i>
            </button>
            <button
              type="button"
              className="btn btn-outline-light btn-icon rounded-pill ms-2"
              onClick={toggleCollapse}
            >
              {collapseIsOpen ? (
                <i className="ph-caret-up"></i>
              ) : (
                <i className="ph-caret-down"></i>
              )}
            </button>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={collapseIsOpen}>
        <CardBody>
          {ruleGroup.rules && ruleGroup.rules.length > 0 ? (
            <div>
              <div className="row align-items-end">
                <div className="col">
                  <Label className="col-form-label">Tipologia</Label>
                </div>
                <div className="col text-center">
                  <Label>Peso (kg)</Label>
                </div>
                <div className="col">
                  <Label className="col-form-label">Incremento peso (kg)</Label>
                </div>
                <div className="col">
                  <Label className="col-form-label">Prezzo (€)</Label>
                </div>
                <div className="col-1"></div>
              </div>
              {ruleGroup.rules.map((elem, index) => {
                return (
                  <Rule
                    index={index}
                    item={elem}
                    key={index}
                    editRule={editRule}
                    removeRule={removeRule}
                  />
                );
              })}
            </div>
          ) : (
            <h6 className="text-center">Nessuna regola inserita</h6>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default RuleGroup;
