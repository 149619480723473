import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Card, CardBody } from "react-bootstrap";
import Papa from "papaparse";

import InputBox from "../../../Components/common/InputBox";
import { useUploadZoneContext } from "../providers/Upload";
import { getLocationLevelBadge } from "../../../utilities";
import { Loader } from "../../../Components/common/Loader";

export const Upload = () => {
  const { id } = useParams();

  const context = useUploadZoneContext();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    context.uploadFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const zone = {
      ...context.zone,
      [name]: value,
    };

    context.editZone(zone);
    context.removeError(name);
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;

    const zone = {
      ...context.zone,
      [name]: !context.zone[name],
    };

    context.editZone(zone);
  };

  const downloadErrorCsvOnClick = () => {
    const csv = Papa.unparse(context.zone.errors);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "uploadZoneErrors.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!id) {
      if (context.autosave) {
        context.createZone();
      }
    }
  }, []);

  return (
    <div className="content">
      {context.zoneLoader ? <Loader show={context.zoneLoader} /> : ""}
      <Card>
        <CardBody>
          <div className="row align-items-end">
            <div className="col">
              <label className="form-label">Nome</label>
              <InputBox
                name="name"
                value={context.zone?.name || ""}
                placeholder={"Zona 1"}
                type="Text"
                onChange={handleInputChange}
                error={context.zoneError?.response?.data?.name}
              />
            </div>
            <div className="col">
              <label className="form-label">Descrizione</label>
              <InputBox
                name="description"
                value={context.zone?.description || ""}
                placeholder={"Descrizione zona 1"}
                type="text"
                onChange={handleInputChange}
                error={context.zoneError?.response?.data?.description}
              />
            </div>
            <div className="col">
              <input
                className="btn btn-outline-success btn-sm btn btn-light w-auto"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-check mt-2">
                <input
                  name="pub"
                  type="checkbox"
                  className="form-check-input form-check-input-success"
                  value={context.zone?.pub || false}
                  checked={context.zone?.pub || false}
                  onChange={handleCheckboxChange}
                />
                <span className="form-check-label">Zona pubblica</span>
              </label>
            </div>
          </div>
        </CardBody>
      </Card>

      {context.zone.errors?.length > 0 && (
        <Alert className="alert-warning">
          Si sono verificati dei problemi con l'importazione di{" "}
          {context.zone.errors?.lenght} località.{" "}
          <button
            className="btn btn-outline-warning btn-sm btn btn-light w-auto"
            onClick={downloadErrorCsvOnClick}
          >
            Scarica CSV
          </button>
        </Alert>
      )}

      <div className="row mt-3">
        <div className="col">
          <h5>Incluse</h5>
          {context.zone.included?.map((elem) => {
            const place = elem.Place;
            return (
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-1">
                      <i
                        className={`ph ph-check-circle me-1`}
                        style={{
                          color: "green",
                        }}
                      ></i>
                    </div>
                    <div className="col">
                      <label> {place.Label}</label>
                    </div>
                    <div className="col-3">
                      <div>{getLocationLevelBadge(place.Categories[0])}</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
        <div className="col">
          <h5>Escluse</h5>
          {context.zone.excluded?.map((elem) => {
            const place = elem.Place;
            return (
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-1">
                      <i
                        className={`ph ph-prohibit-inset me-1`}
                        style={{
                          color: "red",
                        }}
                      ></i>
                    </div>
                    <div className="col">
                      <label> {place.Label}</label>
                    </div>
                    <div className="col-3">
                      <div>{getLocationLevelBadge(place.Categories[0])}</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
