import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { IncomingGoodDetailsService } from "../../../services/logistic/incomingGoodDetails";
import { incomingGoodDetailNormalizer } from "../utilities";
import { callErrorToast, valueIsEmpty } from "../../../utilities";

const incomingGoodDetailsService = new IncomingGoodDetailsService();

export const CreateEditIncomingGoodDetailContext = createContext();

const CreateEditIncomingGoodDetailProvider = ({
  children,
  id,
  autosave = false,
  callback,
  parentId,
}) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [incomingGoodDetail, setIncomingGoodDetail] = useState(null);
  const [incomingGoodDetailError, setIncomingGoodDetailError] = useState(null);
  const [incomingGoodDetailLoader, setIncomingGoodDetailLoader] =
    useState(false);

  const hasArticleChanged = useRef(false);
  const prevIncomingGoodDetailError = useRef();

  const getIncomingGoodDetail = () => {
    setIncomingGoodDetailLoader(true);
    incomingGoodDetailsService
      .get(id)
      .then((res) => {
        setIncomingGoodDetail(res.data);
        setIncomingGoodDetailLoader(false);
      })
      .catch((err) => {
        setIncomingGoodDetailError(err);
      });
  };

  const createEditIncomingGoodDetail = (id) => {
    if (!id) {
      createIncomingGoodDetail(true);
      return false;
    }
    editIncomingGoodDetail(incomingGoodDetail, true);
  };

  const createIncomingGoodDetail = (save) => {
    setIncomingGoodDetailLoader(true);

    const incomingGoodDetailNormalized = incomingGoodDetailNormalizer(
      { ...incomingGoodDetail },
      accessTokenPayload,
      parentId
    );

    incomingGoodDetailsService
      .create(incomingGoodDetailNormalized)
      .then((res) => {
        setIncomingGoodDetail(res.data);
        setIncomingGoodDetailLoader(false);

        if (save && callback) {
          callback();
        }
      })
      .catch((err) => {
        setIncomingGoodDetailError(err);
        setIncomingGoodDetailLoader(false);
      });
  };

  const editIncomingGoodDetail = (incomingGoodDetail, save) => {
    if (save || autosave) {
      const incomingGoodDetailNormalized = incomingGoodDetailNormalizer(
        incomingGoodDetail,
        accessTokenPayload,
        parentId
      );
      incomingGoodDetailsService
        .edit(incomingGoodDetailNormalized)
        .then((res) => {
          setIncomingGoodDetail(incomingGoodDetail);
          setIncomingGoodDetailLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setIncomingGoodDetailError(err);
          setIncomingGoodDetailLoader(false);
        });

      return false;
    }

    setIncomingGoodDetail(incomingGoodDetail);
  };

  const removeError = (property) => {
    const newIncomingGoodDetailError = { ...incomingGoodDetailError };
    delete newIncomingGoodDetailError.response?.data[property];
    if (!valueIsEmpty(newIncomingGoodDetailError)) {
      prevIncomingGoodDetailError.current = newIncomingGoodDetailError;
      setIncomingGoodDetailError(newIncomingGoodDetailError);
    }
  };

  useEffect(() => {
    if (incomingGoodDetail?.article && hasArticleChanged.current) {
      const newIncomingGoodDetail = {
        ...incomingGoodDetail,
        articleVariant: null,
      };
      editIncomingGoodDetail(newIncomingGoodDetail);
    }
    if (incomingGoodDetail?.article) {
      hasArticleChanged.current = true;
    }
  }, [incomingGoodDetail?.article]);

  useEffect(() => {
    if (
      incomingGoodDetailError &&
      JSON.stringify(prevIncomingGoodDetailError.current) !==
        JSON.stringify(incomingGoodDetailError)
    ) {
      callErrorToast(incomingGoodDetailError);
    }
  }, [incomingGoodDetailError]);

  return (
    <CreateEditIncomingGoodDetailContext.Provider
      value={{
        id,

        //#region Incoming good detail
        incomingGoodDetail,
        incomingGoodDetailError,
        incomingGoodDetailLoader,
        getIncomingGoodDetail,
        createIncomingGoodDetail,
        editIncomingGoodDetail,
        createEditIncomingGoodDetail,
        //#endregion

        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </CreateEditIncomingGoodDetailContext.Provider>
  );
};

const useIncomingGoodDetailContext = () => {
  return useContext(CreateEditIncomingGoodDetailContext);
};

export { CreateEditIncomingGoodDetailProvider, useIncomingGoodDetailContext };
