import React from "react";

import { useListContext } from "./Providers/List";
import { DatePickerFilter } from "./Filters/DatePickerFilter";

const DefaultDate = ({ className = "", selector }) => {
  const context = useListContext();

  return (
    context && (
      <div className={className}>
        <span className="span-1 me-1">{selector.label}</span>
        <DatePickerFilter
          onChange={(name, value) => context.onSearch(name, value, true)}
          filter={selector}
          className={className}
          value={context.defaultFilters[selector.fieldName]}
        />
      </div>
    )
  );
};

export { DefaultDate };
