import React from "react";
import { CreateEditLocationInLogisticProvider } from "./providers/CreateEditInLogistic";
import { CreateEdit } from "./CreateEdit";

const Location = ({ service, defaultLocation, callback }) => {
  let provider;
  switch (service) {
    case "logistic":
      provider = (
        <CreateEditLocationInLogisticProvider>
          <CreateEdit defaultLocation={defaultLocation} callback={callback} />
        </CreateEditLocationInLogisticProvider>
      );
      break;
    default:
      provider = <CreateEdit defaultLocation={defaultLocation} />;
      break;
  }
  return provider;
};

export default Location;
