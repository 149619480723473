import HttpClient from "../../helpers/HttpClient";
import { API_BASE_URL } from "../../constants/index";
import { createQueryString } from "../../utilities/index";

export class LocationsService {
  // Initializing important variables
  constructor(domain) {
    // this.API = new API();
    // this.domain = process.env.API_URL; // API server domain
    this.httpClient = new HttpClient();
  }

  create(location) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(location),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/locations`,
      requestOptions
    );
  }

  edit(location) {
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(location),
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/locations/${location.id}`,
      requestOptions
    );
  }
}
