import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  getClassForMultiActionElement,
} from "../../utilities";
import { allSelectedOrdersCanBeDeleted, columns } from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";

import { Link } from "react-router-dom";
import Delete from "./Delete";
import Confirm from "./Confirm";
import { DeleteOrderProvider } from "./providers/Delete";
import { ConfirmOrderProvider } from "./providers/Confirm";
import Cancel from "./Cancel";
import { CancelOrderProvider } from "./providers/Cancel";
import moment from "moment";
import ExportForInvoicing from "./ExportForInvoicing";
import { ExportForInvoicingOrderProvider } from "./providers/ExportForInvoicing";
import { ExportForActivationOrderProvider } from "./providers/ExportForActivation";
import ExportForActivation from "./ExportForActivation";

const Orders = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "desc",
      column: "creationDate",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([
    {
      and: [
        {
          greater_than_or_equal: {
            selector: "creationDate",
            args: moment().subtract(1, "months").startOf("day").format(),
          },
        },
        {
          less_than_or_equal: {
            selector: "creationDate",
            args: moment().endOf("day").format(),
          },
        },
      ],
    },
  ]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/logistic-service/orders?page=${pagination.page}&size=${pagination.size}${
      sort.length > 0 ? buildQuerySort(sort) : ""
    }${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = orderBykey(
    [
      ...columns,
      {
        Header: (
          <>
            <div
              className={
                "multi-action-label scale-animation " +
                getClassForMultiActionElement(statusOfTableRows).labelClass
              }
            >
              Azioni
            </div>

            <UncontrolledDropdown
              className={
                getClassForMultiActionElement(statusOfTableRows).dropDownClass
              }
            >
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className={"btn border-0 text-body"}
                data-bs-toggle="dropdown"
              >
                <i
                  className={
                    "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                    getClassForMultiActionElement(statusOfTableRows).iconClass
                  }
                ></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                <DropdownItem
                  href="#"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Esporta per attivazione",
                      icon: "ph-download",
                      open: !modalSettings.open,
                      orders: [
                        ...data.content
                          .filter((order) =>
                            statusOfTableRows
                              .filter((element) => element.value)
                              .map((x) => x.id)
                              .includes(order.id)
                          )
                          .map((order) => order.id),
                      ],
                      action: "exportForActivation",
                    })
                  }
                >
                  <i className="ph-download me-2"></i>
                  Esporta per attivazione
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Esporta per fatturazione",
                      icon: "ph-download",
                      open: !modalSettings.open,
                      orders: [
                        ...data.content
                          .filter((order) =>
                            statusOfTableRows
                              .filter((element) => element.value)
                              .map((x) => x.id)
                              .includes(order.id)
                          )
                          .map((order) => order.id),
                      ],
                      action: "exportForInvoicing",
                    })
                  }
                >
                  <i className="ph-download me-2"></i>
                  Esporta per fatturazione
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className={` 
                   ${
                     allSelectedOrdersCanBeDeleted(
                       statusOfTableRows,
                       data?.content
                     )
                       ? "text-danger"
                       : "disabled"
                   }
                  `}
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina selezionati",
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      orders: [
                        ...data.content
                          .filter((order) =>
                            statusOfTableRows
                              .filter((element) => element.value)
                              .map((x) => x.id)
                              .includes(order.id)
                          )
                          .map((order) => order.id),
                      ],
                      action: "delete",
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina selezionati
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
        pinning: "right",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className="btn border-0 text-body"
                data-bs-toggle="dropdown"
              >
                <i className="ph-list"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                <DropdownItem href={`/orders/details/${row.original.id}`}>
                  <i className="ph-magnifying-glass me-2"></i>
                  Dettaglio
                </DropdownItem>
                {original.lastStatus === "DRAFT" && (
                  <React.Fragment>
                    <DropdownItem href={`/orders/edit/${row.original.id}`}>
                      <i className="ph-note-pencil me-2"></i>
                      Modifica
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        setModalSettings({
                          ...modalSettings,
                          title: "Conferma ordine",
                          icon: "ph-check",
                          open: !modalSettings.open,
                          action: "confirm",
                          orders: [original.id],
                          currency: original.currency,
                          cashOnDeliveryValue: original.cashOnDeliveryValue,
                          cashOnDeliveryCollectionMethodCode:
                            original.cashOnDeliveryCollectionMethodCode,
                        });
                      }}
                    >
                      <i className="ph-check me-2"></i>
                      Conferma
                    </DropdownItem>
                    <DropdownItem
                      className="text-danger"
                      href="#"
                      onClick={() => {
                        setModalSettings({
                          ...modalSettings,
                          title: "Elimina ordine",
                          icon: "ph-trash",
                          open: !modalSettings.open,
                          action: "delete",
                          orders: [original.id],
                        });
                      }}
                    >
                      <i className="ph-trash me-2"></i>
                      Elimina
                    </DropdownItem>
                  </React.Fragment>
                )}
                {original.lastStatus === "CONFIRMED" && (
                  <DropdownItem
                    className="text-danger"
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Elimina ordine",
                        icon: "ph-trash",
                        open: !modalSettings.open,
                        action: "cancel",
                        orders: [original.id],
                      });
                    }}
                  >
                    <i className="ph-trash me-2"></i>
                    Elimina
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Ordini" breadcrumbItem="Elenco ordini" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  to={"/orders/create"}
                  className="nav-link float-end"
                  data-key="t-basic-action"
                >
                  <Button
                    color="light"
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    Nuovo ordine <i className="ph-plus ms-2"></i>
                  </Button>
                </Link>
              </div>
            </div>
            <TableAdvanced
              selectMultipleRows={true}
              statusOfTableRows={statusOfTableRows}
              onChangeStatusRow={onChangeStatusRow}
              onChangeStatusAllRows={onChangeStatusAllRows}
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              omnisearch={{
                active: true,
                onSearch: setFreeSearch,
                placeholder: "Riferimenti",
              }}
              showLoader={loading}
              myData={{ active: true, label: "I miei ordini" }}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "delete" && (
            <DeleteOrderProvider
              data={modalSettings.orders}
              unmountCallback={modalToggle}
            >
              <Delete />
            </DeleteOrderProvider>
          )}
          {modalSettings.action === "confirm" && (
            <ConfirmOrderProvider
              data={modalSettings.orders}
              unmountCallback={modalToggle}
            >
              <Confirm
                cashOnDeliveryCollectionMethodCode={
                  modalSettings.cashOnDeliveryCollectionMethodCode
                }
                cashOnDeliveryValue={modalSettings.cashOnDeliveryValue}
                currency={modalSettings.currency}
              />
            </ConfirmOrderProvider>
          )}
          {modalSettings.action === "cancel" && (
            <CancelOrderProvider
              data={modalSettings.orders}
              unmountCallback={modalToggle}
            >
              <Cancel />
            </CancelOrderProvider>
          )}
          {modalSettings.action == "exportForInvoicing" && (
            <ExportForInvoicingOrderProvider
              data={modalSettings.orders}
              unmountCallback={modalToggle}
            >
              <ExportForInvoicing />
            </ExportForInvoicingOrderProvider>
          )}
          {modalSettings.action == "exportForActivation" && (
            <ExportForActivationOrderProvider
              data={modalSettings.orders}
              unmountCallback={modalToggle}
            >
              <ExportForActivation />
            </ExportForActivationOrderProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Orders;
