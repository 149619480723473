import React from "react";
import { Button } from "reactstrap";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";

const usersService = new UsersService();

const Delete = ({ userId, modalToogle, refreshTable }) => {
  const deleteUser = () => {
    usersService
      .delete(userId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success("Utente eliminato correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 404:
            message = "404 - Utente non trovato";
            break;
          case 409:
            message = "409 - Utente già registrato";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>Sei sicuro di voler eliminare il seguente utente?</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
            refreshTable();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteUser}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};
export default Delete;
