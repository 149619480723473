export const incomingGoodDetailNormalizer = (
  incomingGoodDetail,
  accessTokenPayload,
  parentId
) => {
  incomingGoodDetail.ownerId =
    incomingGoodDetail.ownerId || accessTokenPayload.owner;
  incomingGoodDetail.articleId = incomingGoodDetail.article?.id;
  incomingGoodDetail.articleVariantId = incomingGoodDetail.articleVariant?.id;
  incomingGoodDetail.incomingGoodsId = parentId;
  return incomingGoodDetail;
};

// //#region Index columns
export const columns = [
  {
    position: 2,
    Header: "Prodotto",
    accessor: "article.name",
  },
  {
    position: 3,
    Header: "Quantità attesa",
    accessor: "expectedQuantity",
  },
  {
    position: 4,
    Header: "Quantità effettiva",
    accessor: "effectiveQuantity",
  },
  {
    position: 5,
    Header: "Note mittente",
    accessor: "senderNote",
  },
  {
    position: 6,
    Header: "Note destinatario",
    accessor: "receiverNote",
  },
];
// //#endregion
