import React, { useState, useEffect, useContext } from "react";
import InputBox from "../../Components/common/InputBox";
import { LocationAutocomplete } from "../../Components/common/LocationAutocomplete";
import { callErrorToast, locationNormalizer } from "../../utilities";
import { useCreateEditContactContext } from "./providers/CreateEdit";

export const CreateEdit = () => {
  const context = useCreateEditContactContext();

  const handleContactInputChange = (event) => {
    const { name, value } = event.target;
    context.setContact({
      ...context.contact,
      [name]: value,
    });
  };

  const handleContactCheckboxChange = (event) => {
    const { name, value } = event.target;
    context.setContact({
      ...context.contact,
      [name]: !context.contact[name],
    });
  };

  const handleLocationSelectChange = (option, event) => {
    //La normalizzazione della location dovrebbe essere fatta nel componente LocationAutocomplete -> da sistemare quando rifaccio il componente
    const optionNormalized = locationNormalizer(option);
    context.setContact({
      ...context.contact,
      location: optionNormalized,
    });
  };

  const handleLocationInputChange = (event) => {
    const { name, value } = event.target;
    context.setContact({
      ...context.contact,
      location: { ...context.contact.location, [name]: value },
    });
  };

  useEffect(() => {
    if (context.autosave) {
      if (context.contact) {
        context.callback(context.contact);
      }
    }
  }, [context.contact]);

  useEffect(() => {
    if (context.registryAddressBookError) {
      callErrorToast(context.registryAddressBookError);
    }
  }, [context.registryAddressBookError]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <label className="form-label">Ragione sociale</label>
          <InputBox
            name="name"
            value={context.contact?.name || ""}
            placeholder={"Sede azienda"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Nome</label>
          <InputBox
            name="contactName"
            value={context.contact?.contactName || ""}
            placeholder={"Mario Rossi"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-label">SDI</label>
          <InputBox
            name="sdi"
            value={context.contact?.sdi || ""}
            placeholder={"SDI"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Partita IVA</label>
          <InputBox
            name="vatNumber"
            value={context.contact?.vatNumber || ""}
            placeholder={"86334519757"}
            type="positive-integer-number"
            onChange={handleContactInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Rif. Cliente</label>
          <InputBox
            name="externalReference"
            value={context.contact?.externalReference || ""}
            placeholder={"EXTREF"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-label">Email</label>
          <InputBox
            name="email"
            value={context.contact?.email || ""}
            placeholder={"mario-rossi@email.it"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Telefono</label>
          <InputBox
            name="phone"
            value={context.contact?.phone || ""}
            placeholder={"Mario Rossi"}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-label">PEC</label>
          <InputBox
            name="pec"
            value={context.contact?.pec || ""}
            placeholder={"mario-rossi@email.it"}
            type="email"
            onChange={handleContactInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">Codice fiscale</label>
          <InputBox
            name="fiscalCode"
            value={context.contact?.fiscalCode || ""}
            type="text"
            onChange={handleContactInputChange}
          />
        </div>
      </div>
      <div className="box-grey mt-2">
        <div className="row">
          <div className="col">
            <label className="form-label">Cercà una località</label>
            <LocationAutocomplete
              onLocationChange={handleLocationSelectChange}
              placeholder="Inserisci un indirizzo"
            />
          </div>
        </div>
        {context?.contact?.location && (
          <React.Fragment>
            <div className="row mt-2">
              <div className="col-10">
                <label className="form-label">Via</label>
                <InputBox
                  name="street"
                  value={context.contact?.location.street || ""}
                  placeholder={"Mario Rossi"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col-2">
                <label className="form-label">Civico</label>
                <InputBox
                  name="houseNumber"
                  value={context.contact?.location.houseNumber || ""}
                  placeholder={"10"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label className="form-label">Unità</label>
                <InputBox
                  name="unit"
                  value={context.contact?.location.unit || ""}
                  placeholder={"A"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col">
                <label className="form-label">Codice postale</label>
                <InputBox
                  name="postalCode"
                  value={context.contact?.location.postalCode || ""}
                  placeholder={"10123"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col">
                <label className="form-label">Quartiere</label>
                <InputBox
                  name="district"
                  value={context.contact?.location.district || ""}
                  placeholder={"Centro"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col">
                <label className="form-label">Città</label>
                <InputBox
                  name="municipality"
                  value={context.contact?.location.municipality || ""}
                  placeholder={"Torino"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label className="form-label">Provincia</label>
                <InputBox
                  name="subRegionName"
                  value={context.contact?.location.subRegionName || ""}
                  placeholder={"Torino"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col">
                <label className="form-label">Regione</label>
                <InputBox
                  name="regionName"
                  value={context.contact?.location.regionName || ""}
                  placeholder={"Piemonte"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
              <div className="col">
                <label className="form-label">Paese</label>
                <InputBox
                  name="countryIsoCode3"
                  value={context.contact?.location.countryIsoCode3 || ""}
                  placeholder={"ITA"}
                  type="text"
                  onChange={handleLocationInputChange}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-check mb-2">
            <input
              name="priv"
              type="checkbox"
              className="form-check-input form-check-input-success"
              value={context.contact?.priv || false}
              checked={context.contact?.priv || false}
              onChange={handleContactCheckboxChange}
            />
            <span className="form-check-label">Privato</span>
          </label>
        </div>
        <div className="col">
          <label className="form-check mb-2">
            <input
              type="checkbox"
              name="gdo"
              className="form-check-input form-check-input-success"
              value={context.contact?.gdo || false}
              checked={context.contact?.gdo || false}
              onChange={handleContactCheckboxChange}
            />
            <span className="form-check-label">GDO</span>
          </label>
        </div>
        <div className="col">
          <label className="form-check mb-2">
            <input
              type="checkbox"
              name="ztl"
              className="form-check-input form-check-input-success"
              value={context.contact?.ztl || false}
              checked={context.contact?.ztl || false}
              onChange={handleContactCheckboxChange}
            />
            <span className="form-check-label">ZTL</span>
          </label>
        </div>
        <div className="col-4">
          <div className="form-check form-switch mb-2">
            <span className="form-check-label">Salva in rubrica</span>
            <input
              title="Salva in rubrica"
              readOnly
              onClick={() =>
                context.setSaveInAddressBook(!context.saveInAddressBook)
              }
              checked={context.saveInAddressBook || false}
              type="radio"
              className="form-check-input form-check-input-info cursor-pointer"
            />
          </div>
        </div>
      </div>
      {!context.autosave && (
        <div className="row mt-3">
          <div className="col">
            <button className="btn btn-link">Annulla</button>
          </div>
          <div className="col text-end">
            <button
              className="btn"
              onClick={() => {
                context.callback(context.contact);
                if (context.saveInAddressBook) {
                  context.createContactInRegistryAddressBook(context.contact);
                }
              }}
            >
              Salva
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
