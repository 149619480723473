import React, { createContext, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";

export const ConfirmOrderContext = createContext();

const ordersService = new OrdersService();

export const ConfirmOrderProvider = ({ children, data, unmountCallback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const confirmOrders = () => {
    setOrdersLoader(true);
    const promises = orders.map((elem) => {
      return ordersService.confirm(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setOrdersLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  return (
    <ConfirmOrderContext.Provider
      value={{
        //#region Order
        orders,
        ordersError,
        ordersLoader,
        confirmOrders,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </ConfirmOrderContext.Provider>
  );
};
